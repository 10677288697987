import { AuthContext } from "../services/AuthProvider";
import { useContext } from "react";
import authService from "../services/auth";

// this component blocks any users from accessing a page if they aren't logged in
export const ProtectedRoute = ({ children }) => {
  const auth = useContext(AuthContext);
  
  const steps = [
    { path: "/app/useraccess",visited:true },
    { path: "/app/groupconfig",visited:false },
    { path: "/app/grouppoa" ,visited:false},
    { path: "/app/groupcompanies",visited:false },
    { path: "/app/reportingcompany",visited:false },
    { path: "/app/groupdata",visited:false },
    { path: "/app/companyap",visited:false },
    { path: "/app/entityinput",visited:false },
    { path: "/app/editbfd",visited:false },
    { path: "/app/reviewcalc1",visited:false },
    { path: "/app/reviewcalc2",visited:false },
    { path: "/app/reviewcalc3" ,visited:false},
    { path: "/app/reviewcalc4",visited:false },
    { path: "/app/editcfd",visited:false },
    { path: "/app/summary",visited:false },
    { path: "/app/outputs",visited:false },
  ];

  const appSteps = JSON.parse(localStorage.getItem('steps')) || steps
 
  const setVisited = (href)=>{
    if(!appSteps.find(step=>href?.toString()?.endsWith(step?.path))) return steps
    let foundVisited = false
    return (appSteps.map(step=>{
      const returnStep = foundVisited?{...step,visited:false}:{...step,visited:true}
      if(href?.toString()?.endsWith(step?.path)) foundVisited=true
      return returnStep
    }))
  }

  const checkPrerequisite = (href) => {
    let prerequisiteVisited = false;
    
    if (href?.toString()?.endsWith(appSteps[0]?.path)) return true; // First step is always accessible
    
    appSteps.forEach((step, index) => {
      if (href?.toString()?.endsWith(step?.path)) {
        prerequisiteVisited = appSteps[index - 1]?.visited;
      }
    });

    if (!prerequisiteVisited) {
      // If the prerequisite is not visited, redirect the user
      window.location.pathname = '/app/' + appSteps.find(step => step.visited === true)?.path;
    }

    return prerequisiteVisited;
  };

  const navigateToLastVisited = () => {
    const lastVisitedPath = appSteps.find((step,i)=>appSteps[i+1]?.visited===false && i>0)?.path
    window.location.pathname = lastVisitedPath === '/app/groupconfig' ? '/' :  lastVisitedPath
  };

  if (!auth?.hasUser()) {
    // user is not authenticated, so call the backend login function to redirect to PwC Identity
    authService
      .login()
      .then((data) => {
        window.location.href = data;
      })
      .catch((error) => {
        console.log(error);
      });
    }
    // if user is logged in return the child page
    else if(!steps.find(step=>window.location.href.toString().endsWith(step.path)) ||
      checkPrerequisite(window.location.href)){ 
      const returnAppSteps = setVisited(window.location.href)
      localStorage.setItem('steps',JSON.stringify(returnAppSteps))
      return children;
    } else{
      localStorage.removeItem('cachedLocation')
      navigateToLastVisited()
    }
};
