
export function exportGroups(data, workbook) {
  const sheet = workbook?.addWorksheet("WBS logs");
  const headerRow = sheet.addRow([
    "Group Name",
    "Supervisors",
  ]);

  headerRow.eachCell((cell) => {
    cell.alignment = {
      wrapText: true,
      vertical: "middle",
      horizontal: "center",
    };
    cell.font = { bold: true };
  });

  sheet.autoFilter = {
    from: "A1",
    to: "A1",
  };

  for (let index in data) {

    let row = data[index];
    sheet.addRow([
      row?.id,
      ...(row?.data?.users?.filter(u => u.role === "Supervisor").map(u => u.email) || ["N/A"]),
    ]);
  }

  sheet.getColumn(1).width = 100;
  for (let i = 2; i < 10; i++){
    sheet.getColumn(i).width = 30;
  }
  sheet.getCell("A1").font = { bold: true };
  sheet.getCell("B1").font = { bold: true };

  return workbook;
}
