export function convertGbpToLc(fx_rate, number) {
    const value = number?.value ?? number ?? 0
    return (Number(value) || 0) * (Number(fx_rate) || 1);
}

export function calculateDTRAdjustment(net_dtr_adjustment_lc, corporation_tax_rate, ct_rate_override) {
    let overrideValue = ct_rate_override?.value ?? ct_rate_override ?? 0
    if (overrideValue > 100) {
        overrideValue = 100;
    }

    if (overrideValue < 0) {
        overrideValue = 0;
    }
    overrideValue = overrideValue/100;
    const corporationTaxRateValue = corporation_tax_rate?.value ?? corporation_tax_rate ?? 0
    
    if (overrideValue && overrideValue !== 0) {
        return Math.round((Number(net_dtr_adjustment_lc) || 0)/overrideValue);
    }
    else {
        return Math.round((Number(net_dtr_adjustment_lc) || 0)/(corporationTaxRateValue || 1));
    }
}

export function covertToPercentage(corporation_tax_rate) {
    corporation_tax_rate = (Number(corporation_tax_rate) || 1) * 100;
    const corporation_tax_rate_percentage = parseFloat(corporation_tax_rate.toFixed(2))  + "%";
    return corporation_tax_rate_percentage;
}