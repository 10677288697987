import { Panel, Loading, Table, Column, Tooltip } from '@appkit4/react-components';
import { Button, Checkbox } from "../../components/ReadonlyAwareInputs";
import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { BackButton, NextButton } from '../../components/ProgressNav';
import APModal from './APModal';
import { getGroupData, setGroupData, getSelectedPeriod, getGroupName, getPoaIndex } from '../../services/GroupContext';
import toast from '../../components/DismissibleToast';
import dayjs, { min } from 'dayjs';
import utc from 'dayjs/plugin/utc';
import React from 'react';
import isBetween from "dayjs/plugin/isBetween";
import { AuthContext } from '../../services/AuthProvider';
import { get_company_periods_only } from '../../services/dredger/dredger.service';
import { get_relevant_companies, get_relevant_periods } from '../../utils/PeriodUtils';
import { processDateJS, processDate, getToday } from '../../utils/dateProcessor';
import sqldatabaseService from '../../services/sqldatabase/sqldatabase.service';
import CompanyPanel from '../../components/CompanyPanel';
import { calculateCapProps, carryOverAdjustedCaps, getCompanyAPsforPoA } from '../../utils/adjustedCapsUtil';
dayjs.extend(utc)
dayjs.extend(isBetween)

const CompanyAP = () => {
    const [isPosting, setIsPosting] = useState(false);
    const navigate = useNavigate();
    const selectedPeriod = getSelectedPeriod();
    const { period_start, period_end } = selectedPeriod;


    const auth = useContext(AuthContext);

    const [saved_data, set_saved_data] = useState();
    const [companies, setCompanies] = useState([])

    const [groupName, setGroupName] = useState();
    const [ultimateParent, setUltimateParent] = useState('')

    const [isError, setIsError] = useState(false);
    const [conflictingAPs, setConflictingAPs] = useState([]);

    const [selectedCompany, setSelectedCompany] = useState()

    const [show_ap_modal, set_show_ap_modal] = useState(false);

    const [loading, set_loading] = useState(true);
    const [is_refreshing, set_is_refreshing] = useState(false);
    const isReadOnly = auth.isReadOnlyUser(getGroupName());

    const onAPModalSave = (grpData) => {
        set_saved_data(grpData);
    }

    useEffect(() => {
        const fetchData = async () => {
            let group_data = getGroupData();
            if (!group_data) {
                return;
            }

            if (!group_data?.periods_of_account) {
                return;
            }

            if (!period_start || !period_end) {
                return
            }

            set_saved_data(group_data);
            setGroupName(group_data?.group_name);
            setUltimateParent(group_data?.ult_parent_name);

            const relevant_companies = get_relevant_companies(period_start, period_end, group_data);
            setCompanies(relevant_companies)

            const relevant_caps = get_relevant_periods(period_start, period_end, group_data);
            const userEmail = auth?.getEmail();

            const index = getPoaIndex()
            const poa = group_data?.periods_of_account[index];
            const onesource_companies = relevant_companies?.filter(c => c?.from_onesource);
            if (relevant_caps?.length === 0 && !poa?.onesource_cap_extration && onesource_companies?.length > 0) {
                // No caps - import from OneSource

                try {
                    const company_accounting_periods = await get_company_periods_only(onesource_companies, period_start, period_end, userEmail);

                    poa.onesource_cap_extration = true;
                    group_data.periods_of_account[index] = poa;
                    const current_periods = group_data?.company_accounting_periods || []
                    group_data = {
                        ...group_data,
                        company_accounting_periods: [...current_periods, ...company_accounting_periods]
                    }

                    set_saved_data(group_data)
                } catch (error) {
                    console.error('Failed to fetch OneSource data on initial page visit', error)
                    toast.error('Something went wrong while fetching data from OneSource')
                }
            }

            set_loading(false);
        };

        fetchData();
    }, []);

    // when modal is closed recalculate coverage
    useEffect(() => {
        if (companies?.length > 0) {
            let hasFullCoverage = true;
            let updatedCompanies = [...companies];
            for (const company of updatedCompanies) {
                const id = company?.company_ID;
                const coverage = calculate_poa_coverage(id);
                company.poa_coverage = coverage;

                if (coverage < 100) {
                    hasFullCoverage = false;
                }
            }
            setCompanies(updatedCompanies);
            setIsError(!hasFullCoverage);
        }
    }, [show_ap_modal, saved_data])

    useEffect(() => {
        // Check if any PoAs have a refreshing key. If so, there is a conflict which hasn't been resolved
        const caps = saved_data?.company_accounting_periods;
        if (!caps) {
            return;
        }

        setConflictingAPs(caps?.filter(cap => cap?.refreshing?.colour === 'red'))
    }, [saved_data])

    const removeDuplicates = (caps) => {
        if (!caps) {
            return [];
        }
        const seen = new Set();
        return caps?.filter(cap => {
            const datePair = `${cap?.company_ID}-${cap?.start_date}-${cap?.end_date}`;
            if (seen?.has(datePair)) {
                return false;
            } else {
                seen?.add(datePair);
                return true;
            }
        });
    };

    const calculate_poa_coverage = (company_ID) => {
        let caps = getCompanyAPsWithinPoA(company_ID);
        if (!caps) {
            return 0;
        }

        const company = companies?.find((comp) => comp?.company_ID === company_ID);
        if (!company) {
            return 0;
        }

        if (caps?.length < 1) {
            return 0;
        }

        caps = removeDuplicates(caps);
        // period to be considered starts at the latest of the period start date and the date the company joined the group
        const period_start_date = dayjs.max(processDateJS(period_start), processDateJS(company?.date_join));
        // and ends at the minimum of the period start date and the date the company left the group
        const period_end_date = dayjs.min(processDateJS(period_end), processDateJS(company?.date_left)?.subtract(1, 'day'));
        let coveredDays = 0;
        caps?.forEach(cap => {
            const rangeStartDate = processDateJS(cap?.start_date);
            const rangeEndDate = processDateJS(cap?.end_date);

            // Find the overlapping period between the date range and the given period
            const overlapStart = dayjs.max(rangeStartDate, period_start_date);
            const overlapEnd = dayjs.min(rangeEndDate, period_end_date);

            // Calculate the number of overlapping days (inclusive)
            if (overlapStart?.isBefore(overlapEnd) || overlapStart?.isSame(overlapEnd)) {
                const overlapDays = overlapEnd?.diff(overlapStart, 'day') + 1;
                coveredDays += overlapDays;
            }
        });
        const periodLength = processDateJS(period_end_date)?.diff(processDateJS(period_start_date), 'days') + 1;

        const coverage = Math.min(100, (coveredDays / periodLength) * 100);
        return parseFloat((coverage)?.toFixed(2));
    }

    const getCompanyAPsWithinPoA = (companyID) => {
        if (!companyID) {
            return [];
        }

        const apList = saved_data?.company_accounting_periods;
        let filteredAPs = removeDuplicates(
            getCompanyAPsforPoA(apList, getSelectedPeriod()).filter(
                ap => ap.company_ID === companyID
            )
        ) ?? [];

        return filteredAPs
    }

    const save = () => {
        // Ensure all companies have 100% PoA coverage
        for (const company of companies) {
            const id = company?.company_ID;
            const coverage = calculate_poa_coverage(id);
            if (coverage < 100) {
                throw `${company.company_name}'s PoA coverage must be 100% before proceeding`;
            }
        }

        const any_conflict = saved_data?.company_accounting_periods?.some(cap => cap?.refreshing?.colour === 'red');
        if (any_conflict) {
            throw 'There are still unresolved conflicts. Please resolve them!';
        }

        setIsPosting(true);

        const newCaps = saved_data?.company_accounting_periods?.map(cap => {
            const newCap = {
                company_ID: cap?.company_ID,
                from_onesource: cap?.from_onesource,
                fx_rate: cap?.fx_rate,
                onesource_sync: cap?.onesource_sync,
                period_cy: cap?.period_cy,
                updated_date_db: cap?.updated_date_db,
                start_date: cap?.start_date,
                end_date: cap?.end_date,
            };
            return newCap;
        })

        saved_data.company_accounting_periods = saved_data?.company_accounting_periods.map( cap => calculateCapProps(saved_data, getSelectedPeriod(), cap));

        const oldAdjustedCaps = getSelectedPeriod().adjusted_caps;
        const newAdjustedCaps = getCompanyAPsforPoA(newCaps, getSelectedPeriod());

        saved_data.periods_of_account[getPoaIndex()].adjusted_caps = carryOverAdjustedCaps(newAdjustedCaps, oldAdjustedCaps);
        setGroupData(saved_data);

        if (isReadOnly) {
            return;
        }

        const response = sqldatabaseService?.postResponse(saved_data);
        return toast.promise(response, {
            loading: 'Saving Group Data...',
            success: () => {
                setIsPosting(false);
                return 'Saved Group Data';
            },
            error: 'Error saving group data',
        })
    }

    const [all_selected, set_all_selected] = useState(false);
    const [selected_companies, set_selected_companies] = useState([])
    const render_select_column = (row, field) => {
        const onCheckboxChange = (value) => {
            if (value) {
                selected_companies?.push(row);
            } else {
                selected_companies?.splice(selected_companies?.indexOf(row), 1);
            }
            set_selected_companies([...selected_companies]);
        }

        if (row?.from_onesource) {
            return (
                <Checkbox
                    hiddenInReadonly
                    value={row[field]}
                    checked={selected_companies?.includes(row)}
                    onChange={(value) => {
                        onCheckboxChange(value)
                    }}>
                </Checkbox>
            );
        }

        return (
            <Tooltip content={'This company was not imported from OneSource'}>
                <Checkbox disabled hiddenInReadonly/>
            </Tooltip>
        );
    }

    const handle_select_all = (value, event) => {
        if (event?.target?.parentNode?.className?.indexOf('ap-checkbox-label') > -1) {
            return;
        }

        set_all_selected(value => !value);
        if (value) {
            const onesource_companies = companies?.filter(c => c?.from_onesource);
            set_selected_companies([...onesource_companies]);
        } else {
            set_selected_companies([]);
        }
    }

    useEffect(() => {
        const onesource_companies = companies?.filter(c => c?.from_onesource);
        if (selected_companies?.length === onesource_companies?.length) {
            set_all_selected(true)
        } else {
            set_all_selected(false);
        }
    }, [selected_companies, companies])

    const refresh_from_onesource = async () => {
        if (selected_companies?.length === 0) {
            toast.error('Select companies from the table to refresh from OneSource')
            return;
        }

        set_is_refreshing(true);
        let response;
        const userEmail = auth?.getEmail();
        try {
            response = await get_company_periods_only(selected_companies, period_start, period_end, userEmail);
        } catch (error) {
            console.error('Failed to fetch from OneSource, is API alive? ', error)
            toast.error('Failed to fetch from OneSource, please try again')
            set_is_refreshing(false)
            return
        }

        const new_periods = response;
        const existing_periods = saved_data?.company_accounting_periods;
        new_periods?.forEach(new_cap => {
            const new_start = processDateJS(new_cap?.start_date);
            const new_end = processDateJS(new_cap?.end_date);

            for (const old_cap of existing_periods) {
                if (old_cap?.company_ID !== new_cap?.company_ID) {
                    continue;
                }

                const old_start = processDateJS(old_cap?.start_date);
                const old_end = processDateJS(old_cap?.end_date);

                // If the new period is the exact same as an existing period, mark the existing one green
                if (old_start?.isSame(new_start) && old_end?.isSame(new_end)) {
                    old_cap.refreshing = { colour: 'green' }
                    return;
                }

                // If the new period has an overlap with any existing periods, mark the new period red
                if (new_start?.isSameOrBefore(old_end) && new_end?.isSameOrAfter(old_start)) {
                    new_cap.refreshing = { colour: 'red' }
                    existing_periods?.push(new_cap);
                    return;
                }
            }

            // If none of the conditions were triggered above, it means this new period doesn't have any conflicts - mark it as yellow
            new_cap.refreshing = { colour: 'yellow' }
            existing_periods?.push(new_cap);
        })

        setCompanies([...companies]) // Trigger a refresh of the Table component
        set_saved_data({
            ...saved_data,
            company_accounting_periods: existing_periods
        })
        set_selected_companies([])
        set_is_refreshing(false)
    }

    const has_conflict = (company_id) => {
        const relevant_caps = get_relevant_periods(period_start, period_end, saved_data, company_id);
        return relevant_caps?.some(cap => cap?.refreshing?.colour === 'red' || cap.refreshing?.color === 'yellow');
    }

    if (loading) {
        return (
            <Loading
                loadingType="linear"
                indeterminate={true}
                compact={false}
                className="page-loader"
            />
        )
    }

    return (
        <div className="ap-container">
            <div className='ap-container mt-4'>
                <CompanyPanel />
            </div>

            {(period_start && period_end) ?
                <>
                    <div className='ap-container mt-4'>
                        <Panel title='Accounting periods of UK group companies during the period of account:'>
                            <APModal
                                visible={show_ap_modal}
                                setVisible={set_show_ap_modal}
                                selectedPeriod={selectedPeriod}
                                selectedCompany={selectedCompany}
                                companies={companies}
                                companyAPs={getCompanyAPsWithinPoA(selectedCompany)}
                                setCompanies={setCompanies}
                                group_data={saved_data}
                                set_group_data={set_saved_data}
                                onSave={onAPModalSave}
                            />

                            <Table className="ap-table-checkable" originalData={companies} hasTitle selectedIndexs={selected_companies} disableDefaultSort skipInitialSort>
                                <Column field='company_ID' renderCell={render_select_column} >
                                    <Checkbox hiddenInReadonly checked={all_selected} onChange={(value, event) => handle_select_all(value, event)} />
                                </Column>
                                <Column field='company_name'>Company Name</Column>
                                <Column field='tax_office'>Tax Office</Column>
                                <Column field='utr'>UTR</Column>


                                { /*
                                    The field prop has to be a valid key in row otherwise it shows as blank. It's set to company_name since that always exists
                                */}


                                <Column field='company_name' renderCell={(row) => row?.date_join ? processDateJS(row?.date_join)?.format('DD/MM/YYYY') : "N/A"}>Date Joined</Column>
                                <Column field='company_name' renderCell={(row) => row?.date_left ? processDateJS(row?.date_left)?.format('DD/MM/YYYY') : "N/A"}>Date Left</Column>
                                <Column field='company_name' renderCell={(row) => {
                                    const companyAP = getCompanyAPsWithinPoA(row?.company_ID);
                                    if (companyAP[0]) {
                                        const minDate = companyAP[0].start_date;
                                        const maxDate = companyAP[companyAP.length - 1].end_date;
                                        const dateRange = `${processDateJS(minDate)?.format('DD/MM/YYYY')} - ${processDateJS(maxDate)?.format('DD/MM/YYYY')}`
                                        const numOfAPs = companyAP?.length?.toLocaleString();

                                        return `${dateRange} (#${numOfAPs})`;
                                    } else {
                                        return "-";
                                    }
                                }}>Accounting period date range</Column>
                                <Column field='company_name' renderCell={(row) => {
                                    let coverage = Math.max(calculate_poa_coverage(row?.company_ID).toLocaleString(), 0);
                                    return `${coverage}%`
                                }}>PoA Coverage</Column>
                                <Column field='company_name' renderCell={(row) =>
                                    <div className='d-flex flex-row gap-2 items-center justify-start'>
                                        <Button viewEditButton neverReadonly style={{ width: 60 }} compact onClick={() => {
                                            setSelectedCompany(row.company_ID);
                                            set_show_ap_modal(true);
                                        }}
                                        />

                                        {has_conflict(row?.company_ID) && <p className='mb-0'>⚠️ Conflict</p>}

                                    </div>

                                } />
                            </Table>
                        </Panel>
                    </div>

                    <div className='container-fluid mt-4'>
                        <div className='d-flex justify-content-between align-items-center'>
                            <div>
                                <BackButton disabled={isPosting} />
                            </div>
                            <div className='d-flex gap-2'>
                                <Tooltip content={'Select companies from the table to refresh from OneSource'}>
                                    <Button
                                        hiddenInReadonly
                                        onClick={refresh_from_onesource}
                                        disabled={is_refreshing || isPosting}
                                    >
                                        {selected_companies?.length === 0 ?
                                            'Refresh from OneSource' :
                                            `Refresh ${selected_companies?.length} ${selected_companies?.length === 1 ? 'company' : 'companies'} from OneSource`}
                                    </Button>
                                </Tooltip>



                                <Tooltip disabled={conflictingAPs.length === 0} content={conflictingAPs.length > 0 && 'There are unresolved conflicts after refreshing from OneSource'}>
                                    {/* Wrapping the button in a div otherwise the tooltip doesn't show when the button is disabled */}
                                    <div>
                                        <Button hiddenInReadonly loading={isPosting} disabled={isError || conflictingAPs.length > 0} onClick={save}>Save</Button>

                                    </div>
                                </Tooltip>

                                <Tooltip disabled={conflictingAPs.length === 0} content={conflictingAPs.length > 0 && 'There are unresolved conflicts after refreshing from OneSource'}>
                                    {/* Wrapping the button in a div otherwise the tooltip doesn't show when the button is disabled */}
                                    <div>
                                        <NextButton loading={isPosting} disabled={isError || conflictingAPs.length > 0} preNavigation={save} />
                                    </div>
                                </Tooltip>
                            </div>
                        </div>
                    </div>
                </>

                :

                <>
                    <div className='ap-container mt-4'>
                        <Button kind='negative' onClick={() => navigate('/app/grouppoa')}>No Group PoA has been selected. Click here to go back</Button>
                    </div>
                </>
            }
        </div >
    );
};

export default CompanyAP;
