import dayjs from "dayjs";
import utc from 'dayjs/plugin/utc';
import { metrics } from "./EntityCalculations";
import { cap_GBP_conversion_values } from '../../utils/CapCurrencyValues'
import { processDateJS } from "../../utils/dateProcessor";
import React from 'react';

dayjs.extend(utc)


// function to calculate the first half of values for the review calc 2 page
// adjusted caps is the output from review calc 1 - contains all cap data filtered for the period, adjusted to pro-rate the values to the period
const getGroupFixedRatioValues1 = (poa) => {
    // convert local currency amounts into GBP
    let GBP_CAPS = []
    if (!poa?.adjusted_caps) {
        // throw error because CAP data does not exist
        throw new Error('No CAP data is available!')
    }
    // convert values to local currency
    // SHOULD WE USE POA OR CAP CURRENCY / FX RATE HERE?
    
    GBP_CAPS = poa?.adjusted_caps?.map((cap) => {
            cap_GBP_conversion_values?.forEach((key) => {
                if (cap?.hasOwnProperty(key)) {
                    const newKey = key + '_gbp'
                    if (cap[key] && cap[key].hasOwnProperty('value')) {
                        cap[newKey] = { value: cap[key]?.value / cap?.fx_rate, manual_adjustment: cap[key]?.manual_adjustment }
                    }
                    else {
                        cap[newKey] = cap[key] / cap?.fx_rate
                    }
                }
            })
        return cap;
    })
    

    // calculate sum of values for each company to get aggregate values:
    let Aggregate_Net_Tax_Interest_Expense_Income = 0;
    let Aggregate_Tax_EBITDA = 0;
    let Aggregate_Net_Tax_Interest_Income = 0;
    let Aggregate_Net_Tax_Interest_Expense = 0;
    GBP_CAPS?.forEach(cap => {
        // const ntie_total = metrics.filter(m => m.metric_id === 'net_tax_interest_totals')[0].calculation(cap);        
        if (cap?.net_tax_interest_expense_gbp > 0) {
            Aggregate_Net_Tax_Interest_Expense += cap?.net_tax_interest_expense_gbp;
        }
        else {
            Aggregate_Net_Tax_Interest_Income += cap?.net_tax_interest_expense_gbp;
        }
        Aggregate_Net_Tax_Interest_Expense_Income += cap?.net_tax_interest_expense_gbp;
        Aggregate_Tax_EBITDA += cap?.tax_ebitda_gbp?.value ?? cap?.tax_ebitda_gbp ?? 0;
    });
    // check the aggregate values are in the correct range and calculate final result
    poa.aggregate_net_tax_interest_expense_income = Aggregate_Net_Tax_Interest_Expense_Income;
    poa.aggregate_net_tax_interest_expense = Aggregate_Net_Tax_Interest_Expense > Math.abs(Aggregate_Net_Tax_Interest_Income) ? Aggregate_Net_Tax_Interest_Expense - Math.abs(Aggregate_Net_Tax_Interest_Income)  : 0
    poa.aggregate_net_tax_interest_income = Math.abs(Aggregate_Net_Tax_Interest_Income) > Aggregate_Net_Tax_Interest_Expense ?  (Math.abs(Aggregate_Net_Tax_Interest_Income)  - Aggregate_Net_Tax_Interest_Expense) : 0
    poa.aggregate_tax_ebitda = Aggregate_Tax_EBITDA >= 0 ? Aggregate_Tax_EBITDA : 0

    // calculate FR and GR debt caps
    // TODO - excess debt cap needs to be calculated as won't be available when new period has just been created
    if (poa?.excess_debt_cap_from_prior_period == null){ poa.excess_debt_cap_from_prior_period = 0}
    poa.fr_debt_cap = poa?.adjusted_net_group_interest_expense + poa?.excess_debt_cap_from_prior_period;
    poa.gr_debt_cap = poa?.group_ratio_blended_election ? poa?.blended_net_group_interest_expense + poa?.excess_debt_cap_from_prior_period : poa?.qualifying_net_group_interest_expense + poa?.excess_debt_cap_from_prior_period

    // calculate basic interest allowance
    poa.fr_percentage = 0.3;
    poa.fr_basic_interest_allowance = Math.min(poa?.aggregate_tax_ebitda * poa?.fr_percentage, poa?.fr_debt_cap);
    poa.gr_percentage = 0;

    if (poa?.group_ratio_blended_election) {
        poa.gr_percentage = poa?.blended_group_ratio / 100; 
    }
    else if (poa?.group_ebitda != 0 && poa?.qualifying_net_group_interest_expense / poa?.group_ebitda < 0){
            poa.gr_percentage = 1    
    } 
    else if (poa?.group_ebitda == 0){
        poa.gr_percentage = 1
    }
    else {
        //poa.gr_percentage = poa?.local_curr_qualifying_net_group_interest_expense === 0 ? 1 : Math.min(1, poa?.qualifying_net_group_interest_expense / poa?.group_ebitda);
        poa.gr_percentage = Math.min(1, poa?.qualifying_net_group_interest_expense / poa?.group_ebitda);
    }

    poa.gr_basic_interest_allowance = Math.min(poa?.aggregate_tax_ebitda * poa?.gr_percentage, poa?.gr_debt_cap); // check this with Birger
    poa.gr_interest_allowance = poa?.gr_basic_interest_allowance + poa?.aggregate_net_tax_interest_income;
    poa.fr_interest_allowance = poa?.fr_basic_interest_allowance + poa?.aggregate_net_tax_interest_income;
    return poa;
}
// this is called after the unused allowance calcs have been done
const getGroupFixedRatioValues2 = (poa) => {

    const isLeapYear = (year) => {
        const isDivisibleBy4 = year % 4 === 0;
        const isDivisibleBy100 = year % 100 === 0; 
        const isDivisibleBy400 = year % 400 === 0;

        if (isDivisibleBy4) {
            return true;
        }
        else if (isDivisibleBy100) {
            if (isDivisibleBy400) {
                return true;
            }
            else {
                return false;
            }
        }
        else {
            return false;
        }
    }

    const periodLength = processDateJS(poa?.period_end)?.diff(processDateJS(poa?.period_start), "day") + 1;
    // calculate FR/GR values 1
    poa.periodLength = periodLength

    const startDate = new Date(poa?.period_start);
    const endDate = new Date(poa?.period_end)
    const startDateYear = startDate?.getFullYear();
    const endDateYear = endDate?.getFullYear();

    const de_minimis_without_leap_year = periodLength/ 365 * 2000000;
    const de_minimis_with_leap_year = (periodLength-1)/ 365 * 2000000;

    // Check if POA period contains 29/02
    if (isLeapYear(startDateYear)) {
        if (poa?.period_start < "29/02/", startDateYear) {
            if (poa?.period_end > "29/02/", startDateYear) {
                poa.interest_capacity_de_minimis = de_minimis_with_leap_year;
            }
        }
    }
    else if (isLeapYear(endDateYear)) {
        if (poa?.period_end > "29/02/", endDateYear) {
            poa.interest_capacity_de_minimis = de_minimis_with_leap_year;
        }
    }
    else if (endDateYear - startDateYear > 1) {
        const middleYear = startDateYear + 1;
        if (isLeapYear(middleYear)) {
            poa.interest_capacity_de_minimis = de_minimis_with_leap_year;
        }
    }
    else {
        poa.interest_capacity_de_minimis = de_minimis_without_leap_year;
    }

    poa.gr_interest_capacity = Math.max(poa?.gr_interest_allowance + poa?.allowance_calculations?.gr_unused_allowance, poa?.interest_capacity_de_minimis)
    poa.fr_interest_capacity = Math.max(poa?.fr_interest_allowance + poa?.allowance_calculations?.fr_unused_allowance, poa?.interest_capacity_de_minimis)
    poa.gr_bf_interest_allowance_used = Math.min(poa?.allowance_calculations?.gr_unused_allowance, Math.max(poa?.aggregate_net_tax_interest_expense - poa?.gr_interest_allowance, 0));
    poa.fr_bf_interest_allowance_used = Math.min(poa?.allowance_calculations?.fr_unused_allowance, Math.max(poa?.aggregate_net_tax_interest_expense - poa?.fr_interest_allowance, 0));
        // calculate FR/GR values 2
    poa.fr_disallowance = Math.max(poa?.aggregate_net_tax_interest_expense - poa?.fr_interest_capacity, 0);
    poa.gr_disallowance = Math.max(poa?.aggregate_net_tax_interest_expense - poa?.gr_interest_capacity, 0);
    poa.fr_group_reactivation_cap = Math.max(poa?.fr_interest_allowance - poa?.aggregate_net_tax_interest_expense, 0);
    poa.gr_group_reactivation_cap = Math.max(poa?.gr_interest_allowance - poa?.aggregate_net_tax_interest_expense, 0);
    poa.fr_excess_debt_cap_generated = Math.min(Math.max(poa?.fr_debt_cap - poa?.fr_percentage * poa?.aggregate_tax_ebitda, 0), poa?.excess_debt_cap_from_prior_period + poa?.fr_disallowance);
    poa.gr_excess_debt_cap_generated = Math.min(Math.max(poa?.gr_debt_cap - poa?.gr_percentage * poa?.aggregate_tax_ebitda, 0), poa?.excess_debt_cap_from_prior_period + poa?.gr_disallowance);

    return poa;
}

export { getGroupFixedRatioValues1, getGroupFixedRatioValues2 }