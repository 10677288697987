import React, { useState, useEffect, useCallback } from 'react';
import { Modal, Combobox } from '@appkit4/react-components'; 
import { Button, CalendarPicker } from "../../components/ReadonlyAwareInputs";
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { getPOAEndDates } from '../../services/GroupContext';
import { processDateJS } from '../../utils/dateProcessor';
import "./ReportingCompany.scss";
import CustomTooltipIcon from '../../components/customTooltipIcon/customTooltipIcon';

dayjs.extend(utc);

const RevocationModal = ({ visible, setVisible, activeCompany, onRevocation, selectedPeriod }) => {
  const [validRevocation, setValidRevocation] = useState(true);
  const [revocationDate, setRevocationDate] = useState(null);
  const [firstRevocationPeriod, setFirstRevocationPeriod] = useState(null);
  const [revocationDateError, setRevocationDateError] = useState('');

  const POAEndDates = getPOAEndDates();

  // Get the selected revocation period end date for validation
  const firstRevocationPeriodEnd = firstRevocationPeriod ? processDateJS(firstRevocationPeriod) : null;

  const validateRevocationDate = useCallback(() => {
    if (!revocationDate || !firstRevocationPeriodEnd) return '';

    const revocationDateJS = processDateJS(revocationDate);

    // Rule (1): Revocation must be after the end of the first revocation period
    if (revocationDateJS.isSameOrBefore(firstRevocationPeriodEnd)) {
      return 'The revocation date cannot be before the end of the selected Period of Account';
    }

    // Rule (2): Between 1 April 2018 and 12 February 2019 (within 6 months)
    const dateThresholdStart = dayjs('2018-03-31');
    const dateThresholdEnd = dayjs('2019-02-12');
    
    if (revocationDateJS.isAfter(dateThresholdStart) && revocationDateJS.isBefore(dateThresholdEnd)) {
      if (revocationDateJS.isAfter(firstRevocationPeriodEnd.add(6, 'months'))) {
        return 'Revocations made before 12 February 2019 had to be made before 1 April 2018 or within 6 months of the end of the selected Period of Account. Please see the Technical Guide for further information.';
      }
    }

    // Rule (3): After 12 February 2019 (within 12 months)
    if (revocationDateJS.isAfter(dateThresholdEnd)) {
      if (revocationDateJS.isAfter(firstRevocationPeriodEnd.add(12, 'months'))) {
        return 'Revocations made after 12 February 2019 had to be made within 12 months of the end of the selected Period of Account. Please see the Technical Guide for further information.';
      }
    }

    return '';
  }, [revocationDate, firstRevocationPeriodEnd]);

  useEffect(() => {
    const errorMessage = validateRevocationDate();
    setRevocationDateError(errorMessage);
    setValidRevocation(!errorMessage); // Revocation is valid if no error
  }, [validateRevocationDate]);

  const handleSubmit = () => {
      const currentPoAEnd = processDateJS(selectedPeriod?.period_end)?.utc();
      const today = dayjs();

      // Ensure revocationDate is properly defined before usage
      const revocationDateJS = processDateJS(revocationDate)?.utc();
      const reason_appointment_lapsed = 'Manual Revocation'
      const firstPeriodLapsed = activeCompany?.appointer === "Company" ? processDateJS(firstRevocationPeriod).utc() : processDateJS(activeCompany?.first_period_appointment_lapsed)?.utc();

      // Determine if the company should be active or inactive
      const isActive = () => {
          const poaEndDate = processDateJS(activeCompany?.first_appointed_period_end_date)?.utc();

          if (activeCompany?.appointer === "HMRC" && poaEndDate?.isSame(currentPoAEnd)) {
              return true;
          }

          if (activeCompany?.appointer === "Company" && poaEndDate?.isSameOrBefore(currentPoAEnd) && !firstPeriodLapsed) {
            return true;
          }

          if (activeCompany?.appointer === "Company" && poaEndDate?.isSameOrBefore(currentPoAEnd) && firstPeriodLapsed?.isAfter(currentPoAEnd)) {
            return true;
          }
          if (activeCompany?.appointer === "Company" && poaEndDate?.isSameOrBefore(currentPoAEnd) && firstPeriodLapsed?.isSame(currentPoAEnd)
              && reason_appointment_lapsed === "Manual Revocation" && revocationDateJS?.isAfter(today)) {
                return true;
          }

          return false;
      };

      const newStatus = isActive() ? "Active" : "Inactive"

      const modifiedCompany = {
          ...activeCompany,
          reason_appointment_lapsed,
          revocation_date: revocationDateJS?.format('DD/MM/YYYY'),
          first_period_appointment_lapsed: firstPeriodLapsed?.format('DD/MM/YYYY'),
          status: newStatus,  // Set status dynamically
      };

      onRevocation(modifiedCompany);  // Call the revocation handler
      setVisible(false);
  };

  return (
    <Modal
      visible={visible}
      title="Record Revocation"
      ariaLabel="Record Revocation"
      onCancel={() => setVisible(false)}
      maskCloseable={false}
      modalStyle={{ width: '33.75rem' }}
      footerStyle={{ paddingTop: '8px', marginTop: '-8px', minHeight: '64px' }}
      footer={
        <>
          <Button neverReadonly onClick={() => setVisible(false)} kind="secondary">Cancel</Button>
          <Button hiddenInReadonly disabled={!validRevocation} onClick={handleSubmit}>Submit</Button>
        </>
      }
      bodyStyle={{ minHeight: '200px' }}
    >
      <div style={{ display: 'grid', gridGap: '10px' }}>
        <div className="row">
          <label className="col-sm-2 col-form-label">Reporting Company Name</label>
          <div className="col-sm-10">
            <b>{activeCompany?.company_name}</b>
          </div>
        </div>

        <div className="row">
          <label className="col-sm-2 col-form-label">First Revocation Period:</label>
          <div className="flex-container-modal">
            <Combobox
              data={POAEndDates}
              placeholder="First Revocation Period"
              required={true}
              onSelect={(value) => setFirstRevocationPeriod(value)}
              value={firstRevocationPeriod}
              className='flex-grow'
            />
            <CustomTooltipIcon tooltipText={<p>Select the end of the first Period of Account for which the revocation is to take effect (the "relevant" period).</p>}/>
          </div>
        </div>

        <div className="row">
          <label className="col-sm-2 col-form-label">Revocation Date:</label>
          <div className="flex-container-modal">
            <CalendarPicker
              placeholder="dd/mm/yyyy"
              format="DD/MM/YYYY"
              locale="en"
              required={true}
              value={revocationDate}
              onChange={(value) => setRevocationDate(processDateJS(value))}
              error={!!revocationDateError}
              useCustomValidation
              customErrorNode={revocationDateError ? revocationDateError : undefined}
              minDate={processDateJS(firstRevocationPeriod)?.add(1, "day")}
              className="flex-grow"
              fieldWidth='100%'
            />
            <CustomTooltipIcon tooltipText={<p>Enter the date of revocation. If the revocation is made by a company within the group it must be made within the period of 12 months beginning immediately after the end of the specified period of account (6 months for appointments made before 12 February 2019).</p>}/>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default RevocationModal;
