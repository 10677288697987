import { Panel, Loading, Tooltip } from '@appkit4/react-components'; 
import { Button, Input, CalendarPicker, Switch } from "../../components/ReadonlyAwareInputs";
import { useState, useEffect, useContext } from 'react';
import { BackButton, NextButton, ProgressNav } from '../../components/ProgressNav';
import { getGroupData, setGroupData, getGroupName, getSelectedPeriod, getFormattedSelectedPeriod } from '../../services/GroupContext';
import toast from '../../components/DismissibleToast';
import sqlService from '../../services/sqldatabase/sqldatabase.service';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { AuthContext } from '../../services/AuthProvider';
import { processDate, processDateJS } from '../../utils/dateProcessor';
import { isDate } from 'lodash';

import { get_company_data, get_company_id_by_name, get_group_companies } from '../../services/dredger/dredger.service';
import _, { isSet } from 'lodash';
import CompanyRefreshModal from '../group-companies/CompanyRefreshModal';
import "./groupCompanies.scss";
import CustomTableHeader from '../../components/tableHeader/tableHeader';
import CompanyPanel from '../../components/CompanyPanel';

const TAX_OFFICE_REGEX = /^\d{3}$/;
const UTR_REGEX = /^\d{5}-\d{5}$/;

const GroupCompanies = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [isPosting, setIsPosting] = useState(false);

    const [isError, setIsError] = useState(false)
    const [tableData, setTableData] = useState([])
    
    const [dateLeftError, setDateLeftError] = useState([]);
    const [dateJoinedError, setDateJoinedError] = useState([]);
    
    const auth = useContext(AuthContext)
    const [isReadOnly, setIsReadOnly] = useState();
    const selected_period = getSelectedPeriod()
    const {
        period_start:PoA_start_date,
        period_end:PoA_end_date,
        PoAPosition:PoA_Position,
    } = selected_period;
    
    useEffect(() => {
        setIsReadOnly(auth.isReadOnlyUser(getGroupName()));
    }, [])

    useEffect(() => {
        const savedData = getGroupData();
        if (!savedData) {
            return;
        }

        const companies = savedData.companies.map(c => {
            return ({
                ...c,
                onesource_group_id: c.onesource_group_id,
                company_ID: c.company_ID ? c.company_ID : Math.random().toString(36).substring(2, 22),
                company_name: c.company_name ? c.company_name : undefined,
                tax_office: c.tax_office ? c.tax_office : undefined,
                utr: c.utr ? c.utr : undefined,
                date_join: c.date_join ? new Date(c.date_join) : undefined,
                date_left: c.date_left ? new Date(c.date_left) : undefined,
                from_onesource: c.hasOwnProperty('from_onesource') ? c.from_onesource : false,
                uk_group_company: c.hasOwnProperty('uk_group_company') ? c.uk_group_company : true,
            })
        })
        setTableData(companies)
        const dateValidators = companies.map(() => false);
        setDateLeftError(dateValidators);

        setIsLoading(false);
    }, [])

    const tableHasError = () => {
        let errorFlag = false;

        for (const index in tableData) {
            const row = tableData[index];
            if (!row['company_name']) {
                errorFlag = true;
                break;
            }

            if (row['tax_office']) {
                if (!String(row['tax_office']).match(TAX_OFFICE_REGEX)) {
                    errorFlag = true;
                    break;
                }
            }

            if (row['utr']) {
                if (!row['utr'].match(UTR_REGEX)) {
                    errorFlag = true;
                    break;
                }
            }
            if (row['uk_group_company'] && (!row['utr'] || !row['tax_office'])) {
                errorFlag = true;
                break;
            }
            if (is_duplicate_utr(row['utr'])) {
                errorFlag = true;
                break;
            }
            
            const dateJoin = tableData[index]['date_join'];
            const dateLeft = tableData[index]['date_left'];

            dateJoinedError[index] = dateJoin && (
                (dayjs(dateJoin).isAfter(dayjs(PoA_end_date)) || dayjs(dateJoin).isBefore(dayjs(PoA_start_date)))
                || 
                (dateLeft && dayjs(dateJoin).isAfter(dayjs(dateLeft)))
            );

            dateLeftError[index] = dateLeft && (
                (dayjs(dateLeft).isAfter(dayjs(PoA_end_date)) || dayjs(dateLeft).isBefore(dayjs(PoA_start_date)))
                ||
                (dateJoin && dayjs(dateLeft).isBefore(dayjs(dateJoin)))
            );
        }

        errorFlag |= dateJoinedError.some(err=>err);
        errorFlag |= dateLeftError.some(err=>err);

        return errorFlag;
    }

    useEffect(() => {
        setIsError(tableHasError());
    }, [tableData]);

    const is_duplicate_utr = (utr) => {
        const matches = tableData.filter(row => row.uk_group_company && row.utr === utr);
        return matches.length > 1
    }


    const tableChange = (index, value, name) => {
        const rowsInput = [...tableData];
        
        if (value && (name === 'date_join' || name === 'date_left')) {
            value = processDate(value);
        }
        
        rowsInput[index][name] = value;
        setTableData(rowsInput);
    };


    const getDateLeftMinDate = (dateJoin) => {
        return dateJoin ? processDateJS(dateJoin)?.add(1, 'day').toDate() : undefined;
    }

    const addTableRows = () => {
        setTableData((prevState) => ([...prevState,
        {
            "onesource_group_id": undefined,
            "company_ID": Math.random().toString(36).substring(2, 22),
            "company_name": "",
            "tax_office": "",
            "utr": "",
            "date_join": undefined,
            "date_left": undefined,
            "from_onesource": false,
            "uk_group_company": true
        }]));

        setDateLeftError([...dateLeftError, false]);
        setDateJoinedError([...dateJoinedError, false]);
    }

    const deleteTableRows = (e, index) => {
        const rows = [...tableData];
        rows.splice(index, 1);
        setTableData(rows);

        
        const dateJoinedValidators = [...dateJoinedError];
        dateJoinedValidators.splice(index, 1);
        setDateJoinedError(dateJoinedValidators);

        const dateLeftValidators = [...dateLeftError];
        dateLeftValidators.splice(index, 1);
        setDateLeftError(dateLeftValidators);
    }

    const save = () => {
        if (!tableData || tableData.length === 0) {
            throw 'You must provide at least one company...';
        }

        if (tableHasError()) {
            throw 'There are some required fields missing...';
        }

        // TODO check if parent name has changed
        setIsPosting(true);

        const companies = tableData.map(row => {
            return {
                onesource_group_id: row.onesource_group_id,
                company_ID: row.company_ID,
                company_name: row.company_name,
                from_onesource: row?.from_onesource || false,
                tax_office: row.tax_office,
                utr: row.utr,
                uk_group_company: row.uk_group_company,
                date_join: row.date_join,
                date_left: row.date_left
            }
        })
        const savedData = getGroupData()
        // filter caps in case a company has been deleted.
        const caps = savedData.company_accounting_periods ? savedData.company_accounting_periods.filter((cap) => companies.findIndex((comp) => comp.company_ID === cap.company_ID) > -1) : []
        const newJson = {
            ...getGroupData(),
            companies: companies,
            company_accounting_periods: caps
        }

        setGroupData(newJson);
        
        if (isReadOnly) {
            return;
        }

        const result = sqlService.postResponse(newJson);
        return toast.promise( result, {
            loading: 'Saving Group Companies...',
            success: () => {
                setIsPosting(false);
                return 'Saved Group Companies!';
            },
            error: () => {
                setIsPosting(false);
                return 'Something went wrong saving Group Companies';
            },
        })
    }

    const [is_refreshing, set_is_refreshing] = useState(false);
    const [show_new_company_modal, set_show_new_company_modal] = useState(false);

    // Companies found in OneSource but don't exist locally
    const [new_companies, set_new_companies] = useState([]);

    // Companies found locally but doesn't exist in OneSource
    const [missing_companies, set_missing_companies] = useState([]);

    const refresh_from_onesource = async () => {
        const group_data = getGroupData();
        if (!group_data) {
            return;
        }

        const is_onesource_group = isFinite(group_data.group_id); // Only OneSource groups have a group_id that is a number. isFinite checks if it is a numebr
        if (!is_onesource_group) {
            toast.error('The current group was not imported from OneSource')
            return;
        }

        set_is_refreshing(true);

        try {
            const userEmail = auth.getEmail();
            const onesource_companies = await get_group_companies(Number(group_data.group_id), userEmail);
            const current_companies = tableData.map(c => c.company_ID);

            // Get a list of companies that were found in OneSource but do not exist locally
            const new_companies = onesource_companies.filter(new_company => {
                return !current_companies.includes(new_company.company_ID)
            })

            // Get a list of companies that exist locally but not found in OneSource
            const missing_companies = tableData.filter(existing_company => {
                return !onesource_companies.find(c => c.company_ID === existing_company.company_ID);
            })

            if (new_companies.length === 0 && missing_companies.length === 0) {
                toast.success('No new companies were found in OneSource')
                set_is_refreshing(false);
                return;
            }

            set_new_companies(new_companies);
            set_missing_companies(missing_companies)
            set_show_new_company_modal(true);
        } catch (error) {
            console.error(error)
            toast.error('Something went wrong when fetching from OneSource. Please try again!')
        }

        set_is_refreshing(false);
    }


    const setJoinDateDisabled = (joinDate)=>{
        // date join ==> must be (disabled) when
        // ==> PoA_position = 'start' && join_date > PoA_end 
        // ==> PoA_position = 'inBetween' || 'last' && join_date > PoA_end || join_date < PoA_start
        if(!joinDate) return false
        if(PoA_Position === "start"){
            if (joinDate > PoA_end_date) return true
        }
        else{
            if(joinDate > PoA_end_date || joinDate < PoA_start_date) return true
        }
        return false
    }

    const setLeaveDateDisabled = (leaveDate)=>{
        // date leave ==> must be (disabled) when
        // ==> PoA_position = 'last' && join_leave < PoA_start
        // ==> PoA_position = 'middle' || 'start' && leave_date < PoA_start || leave_date > PoA_end
        if(!leaveDate) return false
        if(PoA_Position === "start"){
            if (leaveDate > PoA_end_date) return true
        }
        else{
            if(leaveDate < PoA_start_date || leaveDate > PoA_end_date) return true
        }
        return false
    }

    const getJoinDate = (joinDate) =>{
        if(PoA_Position === 'start'){
            if(joinDate<=PoA_end_date) return joinDate
        }else{
            if(joinDate>=PoA_start_date && joinDate<=PoA_end_date) return joinDate
        }
        return undefined
    }


    if (isLoading) {
        return (
            <Loading
                loadingType="linear"
                indeterminate={true}
                compact={false}
                className="page-loader"
            ></Loading>
        );
    }



    return (
        <div className="ap-container">
            <CompanyPanel/>


            <Panel className='mt-4' style={{ paddingTop: 10 }}>
                <h2>Group companies during the selected period of account</h2>
   
                <p>The list below should include all companies within the Worldwide Group which are UK Group Companies.</p>
                <p>Where the Group has been linked to ONESOURCE, the companies should have been identified as belonging to the group, and the list should be pre-populated accordingly. Please review the list and make any necessary adjustments. If group companies are on ONESOURCE but are missing from the list, please get in touch with us at <a href="mailto:uk_cir_app_support@pwc.com?subject=ONESOURCE linking support" target="_blank">uk_cir_app_support@pwc.com</a>.</p>
                <p>Where the group is not linked to ONESOURCE, group companies can be added manually (using the + button).</p>
                <p>Where a UK company has joined (e.g. by acquisition or incorporation) or left (e.g. by sale or elimination) the group in the period, this information must be entered, but the company should still be a UK group company for the period of joining / leaving. See tooltips on these columns for further details.</p>
                <p>It is only necessary to include UK Group Companies (any company which is a member of the group and within the charge to UK corporation tax at some point in the period) within this list. Whilst other entities (e.g. overseas companies, dormant entities, etc) can be added if desired, they must be reflected as being non-UK Group Companies using the toggle.</p>

                <table className="table">
                    <thead>

                        <tr className='flex'>

                            <CustomTableHeader title=<span>Company Name <span style={{color: 'white'}}>______________</span></span> required={true} tooltipText={<p>This is the company name. The name entered here will be the name populated on the Interest Restriction Return ('IRR' / 'CIR Return').</p>} key={1} />
  
                            <CustomTableHeader title="UK Group Company?" required={false} tooltipText={<p>This toggle reflects whether the company is a UK Group Company during the period. Please refer to s492 TIOPA 2010 for further details.</p>} key={8} />

                            <CustomTableHeader title="From OneSource?" required={false} tooltipText={<p>This toggle reflects whether the company is linked to OneSource for data extraction. When first enabled, the app will attempt to establish a connection to OneSource using the Company Name, and return the Tax Office and UTR if successful.</p>} key={2} />

                            <CustomTableHeader title="Tax office" required={false} tooltipText={<p>This is the 3 digit tax office code number of the company as provided by HMRC.</p>} key={3} />

                            <CustomTableHeader title="UTR" required={false} tooltipText={<p>This is the 10 digit unique tax reference number of the company as provided by HMRC.</p>} key={4} />

                            <CustomTableHeader title="Date joined (if during PoA)" required={false} size="big" tooltipText={<><p>This is the date that the company joined the Group. If the company was a member of the Group prior to the start of the period of account for which the model was first used, it is not necessary to complete this date. It is only necessary to add a date where the company was acquired or incorporated at a later date.</p></>} key={5} />

                            <CustomTableHeader title="Date left (if during PoA)" required={false} tooltipText={<><p>This is the date on which any company left the Worldwide Group (e.g. through disposal, liquidation, becoming dormant, etc). The date included here will be the last date for which the company is included as a UK Group Company.</p><p>If the company has been sold, the date entered should generally be the day BEFORE the date of sale, in accordance with HMRC guidance at CFM95730 to prevent the day of sale being included in both the selling and acquiring groups. However, this may depend on the timing of the sale - if, for example, the company is sold after closing on 31 December, it may be more appropriate to include 31 December in the selling group and not in the acquiring group. In all cases, correspondence between the selling and acquiring groups is required to ensure that all amounts are included only once.</p><p>Where a company is eliminated, the date it leaves the group should be the day the elimination takes place, so that all of the accounting period of the company is included in the group.</p></>} key={6} />

                            <CustomTableHeader title="Present in period" required={false} tooltipText={<p>This column highlights whether the company was part of the group during the period of account. If the company joined the group after the period of account or left the group before the period of account the indicator will be red and the company should not be a UK group company for the period.</p>} key={7} />

                            <th>
                                <Button add disabled={isError} className="btn btn-outline-success" onClick={addTableRows} ><span className="Appkit4-icon icon-plus-outline"></span></Button>
                            </th>
                        </tr>

                    </thead>
                    <tbody>
                        {tableData.map((data2, index) => {
                            return (
                                <tr key={index} className='flex'>
                                    <td style={{ textAlign: 'center', verticalAlign: dateLeftError && dateLeftError[index] ? 'middle' : 'top' }} key={1}>
                                        <Input className="col" value={data2['company_name']} onChange={(evnt) => { tableChange(index, evnt, 'company_name') }} error={!data2['company_name']} />
                                    </td>
                                    <td key={8} style={{
                                        verticalAlign: dateLeftError && dateLeftError[index] ? 'middle' : 'top',
                                        paddingTop: dateLeftError && dateLeftError[index] ? '' : '0.9rem'
                                    }}>
                                        <Switch style={{ alignItems: 'center', justifyContent: 'center' }} defaultChecked={data2['uk_group_company']} checked={data2['uk_group_company']} onChange={(evnt) => { tableChange(index, evnt, 'uk_group_company') }} />
                                    </td>
                                    <td key={2} style={{
                                        verticalAlign: dateLeftError && dateLeftError[index] ? 'middle' : 'top',
                                        paddingTop: dateLeftError && dateLeftError[index] ? '' : '0.9rem'
                                    }}>
                                        <Switch
                                            style={{ alignItems: 'center', justifyContent: 'center' }}
                                            defaultChecked={false}
                                            checked={Boolean(data2['from_onesource'])}
                                            onChange={async (value) => {
                                                if (!value) {
                                                    tableChange(index, value, 'from_onesource')
                                                    return
                                                }

                                                const name = data2?.company_name;
                                                if (!name) {
                                                    toast.error('Please enter a Company Name to search OneSource')
                                                    return;
                                                }

                                                const userEmail = auth.getEmail();

                                                try {
                                                    const company = await get_company_id_by_name(name, userEmail);
                                                    const company_id = Number(company.company_ID)
                                                    if (tableData.find(company => Number(company.company_ID) === company_id)) {
                                                        toast.error('This company is already included in this table!')
                                                        return;
                                                    }

                                                    const saved_data = getGroupData();
                                                    if (!saved_data) {
                                                        toast.error('Failed to fetch saved data...')
                                                        return;
                                                    }

                                                    saved_data.companies = [...saved_data.companies, company]
                                                    //saved_data.company_accounting_periods = [...saved_data.company_accounting_periods, caps];

                                                    const new_table_data = tableData;
                                                    new_table_data[index] = company;
                                                    setTableData(new_table_data);
                                                    setGroupData(saved_data);
                                                    toast.success('Found data in OneSource')
                                                } catch (error) {
                                                    toast.error(String(error))
                                                    return;
                                                }

                                                tableChange(index, value, 'from_onesource')
                                            }}
                                        />
                                    </td>
                                    <td key={3} style={{ verticalAlign: dateLeftError && dateLeftError[index] ? 'middle' : 'top' }}>
                                        <Input 
                                            className="col" 
                                            value={data2['tax_office']} 
                                            error={(!data2['tax_office'] && data2['uk_group_company']) || (data2['tax_office'] && !data2['tax_office'].match(/^\d{3}$/))} 
                                            onChange={(value) => { if ( value.length <= 3) tableChange(index, value, 'tax_office') }} 
                                            placeholder={"xxx"}
                                            required={data2['uk_group_company']}
                                        />
                                    </td>
                                    <td key={4} style={{ verticalAlign: dateLeftError && dateLeftError[index] ? 'middle' : 'top' }}>
                                        <Input
                                            className='col'
                                            value={data2['utr']}
                                            onChange={(value) => {
                                                if (value.length > 11) return;

                                                if (value.match(/^\d{5}-\d{0,5}$/) || value.match(/^\d{0,5}$/)) { // correct format
                                                    tableChange(index, value, 'utr')
                                                }
                                                else if (value.match(/^\d{0,}-?\d{0,}$/)) { // dash missing or in wrong place
                                                    const removeDash = value.replace('-', '')
                                                    const firstDigits = removeDash.substring(0, 5);
                                                    const laterDigits = removeDash.substring(5)
                                                    tableChange(index, firstDigits + '-' + laterDigits, 'utr')
                                                }
                                                else {
                                                    tableChange(index, "", 'utr')
                                                }
                                            }}
                                            error={(!data2['utr'] && data2['uk_group_company']) || (data2['utr'] && (!data2['utr'].match(/^\d{5}-\d{5}$/) || is_duplicate_utr(data2['utr'])))}
                                            errorNode={is_duplicate_utr() && 'Duplicate UTR'}
                                            required={data2['uk_group_company']}
                                            placeholder={'xxxxx-xxxxx'}
                                        ></Input>
                                    </td>
                                    <td key={5} style={{ verticalAlign: dateLeftError && dateLeftError[index] ? 'middle' : 'top' }}>
                                        <CalendarPicker
                                            className="col"
                                            minDate={new Date(PoA_start_date)}
                                            maxDate={new Date(PoA_end_date)}
                                            fieldWidth={150}
                                            disabled={setJoinDateDisabled(data2?.date_join)}
                                            value={data2?.date_join}
                                            format="DD/MM/YYYY"
                                            placeholder="dd/mm/yyyy"
                                            error={dateJoinedError[index]}
                                            useCustomValidation
                                            customErrorNode='Must be within PoA and not after Date Left'
                                            onChange={(evnt) => {
                                                tableChange(index, evnt, 'date_join');
                                            }}
                                        />
                                    </td>

                                    <td key={6} style={{ verticalAlign: dateLeftError && dateLeftError[index] ? 'middle' : 'top' }}>
                                        <CalendarPicker
                                            className="col"
                                            fieldWidth={150}
                                            value={data2?.date_left}
                                            format="DD/MM/YYYY"
                                            placeholder="dd/mm/yyyy"
                                            onChange={(evnt) => {
                                                tableChange(index, evnt, 'date_left');
                                            }}
                                            customErrorNode="Must be within PoA and not before Date Joined"
                                            minDate={new Date(PoA_start_date)}
                                            maxDate={new Date(PoA_end_date)}
                                            disabled={setLeaveDateDisabled(data2?.date_left)}
                                            error={dateLeftError[index]}
                                            useCustomValidation
                                        />
                                    </td>

                                    {/* joined before the period ended && left after the period started */}
                                    <td key={7} style={{ verticalAlign: dateLeftError && dateLeftError[index] ? 'middle' : 'top' }}>
                                        <div style={{width:"30px",height:"30px",borderRadius:"50%",margin:'auto',
                                            ...(
                                                (data2?.date_join===undefined||data2?.date_join<=new Date(selected_period.period_end) )
                                                &&
                                                 (data2?.date_left===undefined || data2?.date_left>=new Date(selected_period.period_start))?
                                                {backgroundColor:'#87e964'}:
                                                {backgroundColor:'#ff6666'}
                                        )
                                            }}></div>
                                    </td>
                                    <td style={{
                                        verticalAlign: dateLeftError && dateLeftError[index] ? 'middle' : 'top',
                                        paddingTop: dateLeftError && dateLeftError[index] ? '' : '0.3rem',
                                        marginLeft: '5px'
                                    }}>
                                        <Button className="btn btn-outline-danger" onClick={(e) => deleteTableRows(e, index)}><span className="Appkit4-icon icon-close-outline"></span></Button>
                                    </td>
                                </tr>
                            )
                        })}
                        <tr>
                            <Button add disabled={isError} className="btn btn-outline-success" onClick={addTableRows} ><span className="Appkit4-icon icon-plus-outline"></span></Button>
                        </tr>
                    </tbody>
                </table>

                <div className='d-flex justify-content-start mt-4'>
                    <Button className='w-12' disabled={is_refreshing} onClick={refresh_from_onesource}>
                        {is_refreshing ? <Loading loadingType={'circular'} circularWidth={25} indeterminate /> : 'Refresh from OneSource'}
                    </Button>

                    <CompanyRefreshModal
                        visible={show_new_company_modal}
                        set_visible={set_show_new_company_modal}
                        new_companies={new_companies}
                        missing_companies={missing_companies}
                        handle_submit={(selected_companies) => {
                            const group_data = getGroupData();
                            if (!group_data) {
                                toast.error('Failed to save the new companies!')
                                return;
                            }

                            const companies = [...tableData, ...selected_companies]
                            const new_group_data = { ...group_data, companies: companies}
                            setGroupData(new_group_data);
                            setTableData(companies);
                            set_show_new_company_modal(false);
                        }}
                    >

                    </CompanyRefreshModal>
                </div>
            </Panel>

            <div className='container-fluid mt-4'>
                <div className='d-flex justify-content-between align-items-center'> 
                    <div>
                        <BackButton disabled={isPosting} />
                    </div>
                    <div className='d-flex gap-2'>
                    <Button hiddenInReadonly disabled={isError} loading={isPosting} onClick={save}>Save</Button>
                    <NextButton disabled={isError} loading={isPosting} preNavigation={save} />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default GroupCompanies