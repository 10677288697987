import { processDateJS } from "./dateProcessor";
import dayjs from "dayjs";

function formatTimestamp(timestamp) {
  const date = new Date(timestamp._seconds * 1000); // Convert seconds to milliseconds
  return dayjs(date).format("DD/MM/YYYY"); // Format using dayjs
}

export function exportWBSLogs(data, workbook) {
  const sheet = workbook?.addWorksheet("WBS logs");
  const headerRow = sheet.addRow([
    "Group Name",
    "WBS code",
    "Charge type",
    "Incremental charge",
    "Number of OneSource companies",
    "Number of UK companies",
    "PoA Start",
    "PoA End",
    "Return Type",
    "timestamp",
  ]);

  headerRow.eachCell((cell) => {
    cell.alignment = {
      wrapText: true,
      vertical: "middle",
      horizontal: "center",
    };
    cell.font = { bold: true };
  });

  sheet.autoFilter = {
    from: "A1",
    to: "J1",
  };

  data.sort((a, b) => b.timestamp._seconds - a.timestamp._seconds);

  for (let entry of data) {
    let row = [
      entry.group_name,
      entry.wbs_code,
      entry.charge_type,
      entry.incremental_charge,
      entry.number_of_onesource_companies,
      entry.number_of_onesource_companies,
      processDateJS(entry.poa_start).format("DD/MM/YYYY"),
      processDateJS(entry.poa_end).format("DD/MM/YYYY"),
      entry.return_type,
      formatTimestamp(entry.timestamp),
    ];
    sheet.addRow(row);
  }

  sheet.getColumn(1).width = 50;
  sheet.getColumn(2).width = 15;
  sheet.getColumn(3).width = 15;
  sheet.getColumn(4).width = 17;
  sheet.getColumn(5).width = 17;
  sheet.getColumn(6).width = 17;
  sheet.getColumn(7).width = 14;
  sheet.getColumn(8).width = 14;
  sheet.getColumn(9).width = 14;
  sheet.getColumn(10).width = 14;
  sheet.getCell("A1").font = { bold: true };
  sheet.getCell("B1").font = { bold: true };
  sheet.getCell("C1").font = { bold: true };
  sheet.getCell("D1").font = { bold: true };
  sheet.getCell("E1").font = { bold: true };
  sheet.getCell("F1").font = { bold: true };
  sheet.getCell("G1").font = { bold: true };
  sheet.getCell("H1").font = { bold: true };
  sheet.getCell("I1").font = { bold: true };
  sheet.getCell("J1").font = { bold: true };

  return workbook;
}
