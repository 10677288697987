import { processDateJS } from "./dateProcessor";

export function processAbbreviatedReturn(savedData, poaIndex){
   // const companies = savedData?.companies?.filter(company => company?.uk_group_company);
    /*let activeCompany = {};
    if (savedData?.reporting_company && savedData?.reporting_company[0] != null) {
      const reportingCompanyList = savedData?.reporting_company?.map(Rcomp => {
        const company = savedData?.companies?.find((c) => c?.company_ID === Rcomp?.company_ID);
        if (company) {
          activeCompany = {
            ...company,
            first_appointed_period_end_date: Rcomp?.first_appointed_period_end_date,
            appointment_date: Rcomp?.appointment_date,
            appointer: Rcomp?.appointer,
            nomination_link: Rcomp?.nomination_link,
            reason_appointment_lapsed: Rcomp?.reason_appointment_lapsed,
            revocation_date: Rcomp?.revocation_date,
            first_period_appointment_lapsed: Rcomp?.first_period_appointment_lapsed
          };
        }
      });
    }*/
    

    const poaData = savedData?.periods_of_account[poaIndex];
    const relevantGroupCompanies = poaData?.adjusted_caps?.map(adjustedCap => adjustedCap.company_ID);
    const companies = savedData?.companies?.filter(company => relevantGroupCompanies?.includes(company.company_ID));
    companies.sort((a, b) => a.company_name.localeCompare(b.company_name));
    const group_restrictions = poaData?.group_disallowance > 0 ? 'Yes' : 'No';
    const group_reactivations = poaData?.group_reactivations > 0 ? 'Yes' : 'No';
    const gleC13 = savedData.periods_of_account[poaIndex]?.apply_group_ratio_election ? 'E' : '';
    const gleC14 = savedData.periods_of_account[poaIndex]?.group_ratio_blended_election ? 'E' : '';    
    const groupEBITDAGains = (savedData.periods_of_account[poaIndex]?.group_ebitda_gains_year && processDateJS(savedData.periods_of_account[poaIndex]?.period_end).isAfter(processDateJS(savedData.periods_of_account[poaIndex]?.group_ebitda_gains_year)) &&  savedData.periods_of_account[poaIndex]?.group_ebitda_gains) ? 'E' : '';
    const interestAllowance_alternative = (savedData.periods_of_account[poaIndex]?.interest_allowance_alt_calc_year && processDateJS(savedData.periods_of_account[poaIndex]?.period_end).isBefore(processDateJS(savedData.periods_of_account[poaIndex]?.interest_allowance_alt_calc_year)) && savedData.periods_of_account[poaIndex]?.interest_allowance_alt_calc) ? 'E' : '';
    const interestAllowance_nonConsolidated = savedData.periods_of_account[poaIndex]?.election_interest_allowance_non_consolidated_investment ? 'E' : '';
    const interestAllowance_consolidated = (savedData.periods_of_account[poaIndex]?.interest_allowance_consolidated_partnership_year&& processDateJS(savedData.periods_of_account[poaIndex]?.period_end).isAfter(processDateJS(savedData.periods_of_account[poaIndex]?.interest_allowance_consolidated_partnership_year)) && savedData.periods_of_account[poaIndex]?.interest_allowance_consolidated_partnership) ? 'E' : '';
    const arrayOfInvestorGroups = savedData.periods_of_account[poaIndex]?.investor_group_data;
    let investorGroupNames = '';
    if (arrayOfInvestorGroups){
      for (let i = 0; i < arrayOfInvestorGroups.length; i++){
        if(investorGroupNames === ''){ investorGroupNames = arrayOfInvestorGroups[i].investor_group}
        else {investorGroupNames = investorGroupNames + ", " +(arrayOfInvestorGroups[i].investor_group)} 
    }}
    const groupRatioBlended = savedData.periods_of_account[poaIndex]?.group_ratio_blended_election ? 'Yes: ' + investorGroupNames : '';
    const ultimateParent = savedData.companies.find(comp => comp.company_ID === savedData.ult_parent_ID)
    const ultimate_parent_name = ultimateParent?.company_name;
    const ultimate_parent_utr = ultimateParent?.utr
    const ultimate_parent_lei = savedData?.ult_parent_lei ? savedData?.ult_parent_lei : 'N/A'
    const reporting_company = savedData.reporting_company.find(comp => comp.company_ID === savedData.periods_of_account[poaIndex]?.reporting_companyID)
    const reporting_company_name = reporting_company?.company_name
    const reporting_company_utr = reporting_company?.utr
    const revised_return = poaData.return_status?.type === "Amended" ? 'Yes' : 'No' 
    const poaStart = processDateJS(savedData.periods_of_account[poaIndex]?.period_start).format('DD/MM/YYYY')
    const poaEnd = processDateJS(savedData.periods_of_account[poaIndex]?.period_end).format('DD/MM/YYYY')
    const poaInvestors = poaData?.investor_group_data
    const investorsArray = poaInvestors?.map(investor => investor['investor_group']);
    const investorsList = investorsArray ? (investorsArray.length > 1 ? investorsArray.join(', ') : investorsArray[0]) : '';


    const excelData = {
        // Group Period Return Type tab values
        start_period_account: ['','Start of period of account', '',poaStart],
        end_period_account:   ['','End of period of account', '',poaEnd],
        reporting_company:    ['','Reporting compamny', 'Name'],
        rep_comp_name:        ['','Reporting company', 'Name', reporting_company_name],
        rep_comp_UTR:         ['',' ', 'UTR (10 Digits)', reporting_company_utr],
        ultimate_parent:      ['','Ultimate parent', 'Name', ultimate_parent_name],
        ult_parent_line2:     ['','', 'UTR (if any, 10 digits)',ultimate_parent_utr],
        ult_parent_line3:     ['','', 'Jurisdiction of incorporation', savedData?.ult_parent_incorporation_juristriction],
        ult_parent_line4:     ['','', 'Legal Entity Identifier (LEI)', ultimate_parent_lei],
        ult_parent_optional:  ['','(Optional, where not name of ultimate parent)', 'Name by which group is to be known',''],
        abbreviated_election: ['','Abbreviated return election?', 'Yes'],
        revised_return:       ['','Revised return', revised_return],
        subject_to_restrictions: ['','Group subject to interest restrictions?', group_restrictions],
        subject_to_reactivations: ['','Group subject to interest reactivations?', group_reactivations],


        // Group Level Elections tab values
        gleRepComp:           ['', 'Name of reporting company', reporting_company_name],
        gleUltParent:         ['', 'Ultimate parent or name of group',  ultimate_parent_name],
        gleNameGroup:         ['', 'Name used for group, if applicable', ''],
        gleEndPoA:            ['', 'End of period of account', poaEnd],
        gleGroupOptional:     ['', 'Name used for group, if applicable', ''],
        gleRow13:             ['', 'Group ratio election (enter here only)', gleC13],
        gleRow14:             ['', 'Group ratio (blended) election (see below)', gleC14],
        gleRow15:             ['', 'Group EBITDA (chargeable gains) election', groupEBITDAGains, 'irrevocable'],
        gleRow16:             ['', 'Interest allowance (alternative calculation) election', interestAllowance_alternative,'irrevocable',],
        gleRow17:             ['', 'Interest allowance (non-consolidated investment) election (see below)', interestAllowance_nonConsolidated,],
        gleRow18:             ['', 'Interest allowance (consolidated partnerships) election (see below)', interestAllowance_consolidated, 'irrevocable',],
        gleRow21:             ['', 'Investor group(s) to which election relates'],
        gleRow23:             ['', investorsList],
        gleRow28:             ['', groupRatioBlended],
        gleRow33:             ['', ''],


        // UK Group Companies tab values
        ukGC_Row7:            ['','Name reporting company', , reporting_company_name, '',''],
        ukGC_Row8:            ['','Ultimate parent ', ,ultimate_parent_name, '',''],
        ukGC_Row9:            ['','Name used for group, if applicable', , , '',''],
        ukGC_Row10:           ['','End of period of account', , processDateJS(savedData.periods_of_account[poaIndex]?.period_end).format('DD/MM/YYYY'), '',''],
    
    }

        // initialises the structure to insert the rows into the sheet
        const ukGroupCompaniesTabList = [
            ['','','Unnecessary rows may be deleted','10 digits','Y/N']
          ]
          for (let i = 0; i < 300; i++){
            let row = ['', i + 1, '', '', '','', '', '', '', '']
            ukGroupCompaniesTabList.push(row)
          }
      
          const getCompanyData = (companyID) => {
            const company = companies.filter(company => company.company_ID === companyID);
            return company;
          }
      
          const fullCAPData = savedData?.company_accounting_periods
      
          const getCAPData = (companyID) => {
            const companyCAP = fullCAPData.filter(CAP => CAP.company_ID === companyID);
            return companyCAP;
          }
      
          const getADJCAPData = (companyID) => {
            const companyAP = poaData.adjusted_caps.filter(ap => ap.company_ID === companyID);
            return companyAP;
          }
      
      
          // insert companies into the sheet rows
          for (let i = 0; i < companies.length; i++){
            let companyData = getCompanyData(companies[i].company_ID);
            let companyCAPData = getCAPData(companyData.companyID);
            let companyADJCAPData = getADJCAPData(companies[i].company_ID);      
            let rowNumber = i + 1;
            let companyName = companies[i].company_name;
            let companyUTR = companies[i].utr;
            const QICExists = companyADJCAPData.map(ap => ap.election_s433_s434).some(value => value === true);
            let companyQIC = 'N';
            if(QICExists){
            companyQIC = 'Y'
            }
            //let companyQIC = companyCAPData.election_s433_s434 && companyCAPData.election_s433_s434 === true ? 'Y' : 'N';
            let row = ['',rowNumber,companyName,companyUTR,companyQIC];
            ukGroupCompaniesTabList[i+1] = row;
          }

    let group_period_data = [
        [], //1
        ['','Before submission export entire workbook in PDF format'],
        [],
        ['','This template is suitable for up to 300 companies. It can be only be used for an abbreviated return'],
        ['','You may delete uneccessary rows from sheet 3'], //5
        [], //6
        ['','Interest Restriction Return Page 1 (Abbreviated return)'], //7
        [], //8
        ['','Identifies worldwide group, return period and type of interest restriction return (Full or abbreviated return)'], //9
        [], //10
        ['','Period of account covered by interest restriction return'],
        excelData.start_period_account,
        excelData.end_period_account,
        [],
        excelData.rep_comp_name, //15
        excelData.rep_comp_UTR,
        [],
        excelData.ultimate_parent,
        excelData.ult_parent_line2,
        excelData.ult_parent_line3, //20
        excelData.ult_parent_line4,
        excelData.ult_parent_optional,
        [],
        ['','Type of return', 'Enter Yes or No'],
        excelData.abbreviated_election, //25
        excelData.revised_return,
        ['', 'For a revised return, specify differences in white space box provided below '],
        [],
        ['','Whether subject to interest restriction or reactivation (if so, full return required)', 'Enter Yes or No'],
        ['','','Enter Yes or No'],//30
        excelData.subject_to_restrictions, 
        excelData.subject_to_reactivations,
        [],
        ['','Ways in which revised return differs from the return it supersedes'],
        ['','A brief narrative is sufficient, e,g. list of UK group companies corrected.'],//35
        ['', ''], 
        [],
        ['','Free space to include notes to the return'],
        ['', ''],
        [],//40
        ['','Information required only in the unusual circumstances where there are deemed parents (s477 or 478 TIOPA 2010)'], 
        ['','', 'Name', 'UTR, if any, 10 digits'],
        ['','Deemed parent (1)', ],
        ['','Deemed parent (2)',],
        ['','Deemed parent (3)',],//45
        [], 
        ['','Key'],
        ['','Descriptive Field', 'Note'],
        [],
        [],//50
        [], 
    ];

    let group_level_elections_data = [
        [], //1
        ['','Interest Restriction Return Page 2'],
        [],
        ['','Group level elections (Abbbreviated return)'],
        [], //5
        ['','Fields below copy from p1'],
        excelData.gleRepComp,
        excelData.gleUltParent,
        excelData.gleGroupOptional,
        excelData.gleEndPoA, //10
        [],
        ['','Group level elections (Other than abbreviated return election)', 'Enter E to elect', 'Enter R to revoke'],
        excelData.gleRow13,
        excelData.gleRow14,
        excelData.gleRow15, //15
        excelData.gleRow16,
        excelData.gleRow17,
        excelData.gleRow18,
        [],
        ['','Group ratio blended election'], //20
        excelData.gleRow21,
        ['','Enter below (enlarge box or insert rows if necessary)'],
        excelData.gleRow23,
        [],
        ['','Interest allowance (non-consolidated investment) election'], //25
        ['','Non-consolidated investment(s) to which election relates'],
        ['','Enter below (enlarge box or insert rows if necessary)'],
        excelData.gleRow28,
        [],
        ['','Interest allowance (consolidated partnerships) election '],//30
        ['','Partnership or partnerships to which election relates (including partnership UTR(s) where applicable)'],
        ['','Enter below (enlarge box or insert rows if necessary)'], 
        excelData.gleRow33,
        [],
        ['','Key'], //35
        ['','Descriptive field or date','Note']
    ];

    const group_companies_data_pt1 = [
        [], //1
        ['','Interest Restriction Return Page 3 (Abbreviated return)'],
        [],
        ['','Identifies UK group companies',],
        [], //5
        ['','Fields below copy from p1',],
        excelData.ukGC_Row7,
        excelData.ukGC_Row8,
        excelData.ukGC_Row9,
        excelData.ukGC_Row10,//10
        [],
        ['', '', 'Full return', '', ''],
        ['', '', '', '', '',],
        ['', 'Index no.', 'UK Group companies - names', 'UTR', 'QIC election?']
    ]
  
      const group_companies_data_pt2 = [
        [], //316
        ['', '', 'Key', '',],
        ['', '', 'Descriptive field or date', 'Note'],
        ['', '', '', '', '', ''],
      ]
  
      const array1 = group_companies_data_pt1.concat(ukGroupCompaniesTabList);
      const group_companies_full_data = array1.concat(group_companies_data_pt2);


    return [group_period_data, group_level_elections_data, group_companies_full_data]
}