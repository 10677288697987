import React, { useState, useEffect, useCallback } from 'react';
import { Modal } from '@appkit4/react-components'; 
import { Button, Input, CalendarPicker, Select } from "../../components/ReadonlyAwareInputs";
import { getPOAEndDates, getFirstPOAStartDate } from '../../services/GroupContext';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { processDateJS } from '../../utils/dateProcessor';
import CustomTooltipIcon from '../../components/customTooltipIcon/customTooltipIcon';

dayjs.extend(utc);

const NewReportingCompanyModal = ({ visible, setVisible, companies, setActiveCompanies, draftCompanies, setDraftCompanies, companyToEdit, setPastCompanies, selectedPeriod }) => {
    const APPOINTER_OPTIONS = ['HMRC', 'Company'];
    const TAX_OFFICE_MAX_VALUE = 999;
    const UTR_MAX_VALUE = 9999999999;

    const [companyName, setCompanyName] = useState('');
    const [companyID, setCompanyID] = useState('');
    const [taxOffice, setTaxOffice] = useState(0);
    const [utr, setUTR] = useState('');
    const [firstPoaEnd, setFirstPoaEnd] = useState(null);
    const [dateAppointed, setDateAppointed] = useState(null);
    const [appointer, setAppointer] = useState('');
    const [nominationLink, setNominationLink] = useState('');
    const [validCompany, setValidCompany] = useState(true);
    const [validDraft, setValidDraft] = useState(true);
    const [firstPOAInputSwitch, setFirstPOAInputSwitch] = useState(true);
    const [dateError, setDateError] = useState('');

    const POAEndDates = getPOAEndDates();

    // Populate modal fields when companyToEdit changes
    useEffect(() => {
        if (visible) {
            // If editing an existing company, populate the form fields
            if (companyToEdit) {
                setCompanyName(companyToEdit.company_name || '');
                setCompanyID(companyToEdit.company_ID || '');
                setTaxOffice(companyToEdit.tax_office || '');
                setUTR(companyToEdit.utr || '');
                setFirstPoaEnd(dayjs(companyToEdit.first_appointed_period_end_date, 'DD/MM/YYYY') || null);
                setDateAppointed(companyToEdit.appointment_date ? dayjs(companyToEdit.appointment_date, 'DD/MM/YYYY') : null);
                setAppointer(companyToEdit.appointer || '');
                setNominationLink(companyToEdit.nomination_link || '');
                setFirstPOAInputSwitch(false)
            } else {
                // If adding a new company, reset all fieldssss
                setCompanyName('');
                setCompanyID('')
                setTaxOffice(0);
                setUTR('');
                setFirstPoaEnd(undefined);
                setDateAppointed(undefined);
                setAppointer('');
                setNominationLink('');
                setDateError('');
                setFirstPOAInputSwitch(true)
            }
        }
    }, [visible, companyToEdit]);;

    const validateAppointmentDate = useCallback(() => {
        const endOfPoA = processDateJS(firstPoaEnd);
        const appointmentDate = processDateJS(dateAppointed);

        if (!endOfPoA || !endOfPoA.isValid()) {
            return 'Please enter a valid PoA End Date';
        }

        if (!appointmentDate || !appointmentDate.isValid()) {
            return 'Please enter a valid Appointment Date';
        }

        const isCompanyAppointer = appointer === 'Company';

        // Strip time components to avoid time-based discrepancies
        const strippedAppointmentDate = appointmentDate.startOf('day');
        const strippedEndOfPoA = endOfPoA.startOf('day');

        // General validation: Appointment date must be after the PoA end date
        if (strippedAppointmentDate.isSameOrBefore(strippedEndOfPoA)) {
            return 'The nomination date cannot be before the end of the specified Period of Account';
        }

        const dateThresholdStart = dayjs('2018-03-31');
        const dateThresholdEnd = dayjs('2019-02-12');

        // Handle company appointer validation for pre-2019 cases (before 12 Feb 2019)
        if (isCompanyAppointer && strippedAppointmentDate.isAfter(dateThresholdStart) && strippedAppointmentDate.isBefore(dateThresholdEnd)) {
            // Calculate the exact 6-month boundary after PoA, accounting for variable month lengths
            const sixMonthsAfterPoA = strippedEndOfPoA.add(6, 'months').endOf('day');

            // If the appointment date is after the valid 6-month window, return error
            if (strippedAppointmentDate.isAfter(sixMonthsAfterPoA)) {
                return 'Nominations before 12 February 2019 must be within 6 months of the end of the Period of Account.';
            }
        }

        // Handle company appointer validation for post-2019 cases (on or after 12 Feb 2019)
        if (isCompanyAppointer && strippedAppointmentDate.isSameOrAfter(dateThresholdEnd)) {
            // Calculate the exact 12-month boundary after PoA, accounting for variable month lengths
            const twelveMonthsAfterPoA = strippedEndOfPoA.add(12, 'months').endOf('day');

            // If the appointment date is after the valid 12-month window, return error
            if (strippedAppointmentDate.isAfter(twelveMonthsAfterPoA)) {
                return 'Nominations after 12 February 2019 must be within 12 months of the end of the Period of Account.';
            }
        }

        return ''; // No error if all validation passes
    }, [appointer, dateAppointed, firstPoaEnd]);

    useEffect(() => {
        if (dateAppointed) {
            setDateError(validateAppointmentDate());
        } else {
            setDateError('');
        }
    }, [validateAppointmentDate, dateAppointed]);

    const handleSave = (status) => {
        let first_period_appointment_lapsed = null;
        if (appointer === 'HMRC' && firstPoaEnd) {
            first_period_appointment_lapsed = processDateJS(firstPoaEnd).add(1, 'day').format('DD/MM/YYYY');
        }

        // Use existing `company_ID` if editing or if a match in `companies` exists
        const existingCompany = companies.find(company => company.company_ID === companyID);
        const company_ID = companyToEdit ? (companyID || companyToEdit.company_ID) : existingCompany ? existingCompany.company_ID : `draft-${Date.now()}`;
        const companyPayload = {
            company_ID,
            company_name: companyName,
            tax_office: taxOffice,
            utr,
            first_appointed_period_end_date: processDateJS(firstPoaEnd)?.format('DD/MM/YYYY'),
            appointment_date: processDateJS(dateAppointed)?.format('DD/MM/YYYY'),
            appointer,
            nomination_link: nominationLink,
            first_period_appointment_lapsed,
            status,
        };

        if (status === "Draft") {
            // Add or update draft
            let selectedDraftIndex = -1;
            if(companyToEdit) selectedDraftIndex = draftCompanies.indexOf(companyToEdit);
            setDraftCompanies(prev => {
                return selectedDraftIndex !== -1
                    ? prev.map(draft => prev.indexOf(draft) === selectedDraftIndex ? companyPayload : draft)
                    : [...prev, companyPayload];
            });
        } else {
            // Determine if company should be active or inactive
            const currentPoAEnd = processDateJS(selectedPeriod?.period_end)?.utc();
            const today = dayjs();

            const isActive = () => {
                const poaEndDate = processDateJS(companyPayload?.first_appointed_period_end_date)?.utc();
                const firstPeriodLapsed = processDateJS(companyPayload?.first_period_appointment_lapsed)?.utc();
                const revocationDate = processDateJS(companyPayload?.revocation_date)?.utc();

                if (companyPayload.appointer === "HMRC" && poaEndDate?.isSame(currentPoAEnd)) {
                    return true;
                }

                if (companyPayload.appointer === "Company" && poaEndDate?.isSameOrBefore(currentPoAEnd) && !companyPayload.first_period_appointment_lapsed) {
                    return true;
                }

                if (companyPayload.appointer === "Company" && poaEndDate?.isSameOrBefore(currentPoAEnd) && firstPeriodLapsed?.isAfter(currentPoAEnd)) {
                    return true;
                }

                if (companyPayload.appointer === "Company" && poaEndDate?.isSameOrBefore(currentPoAEnd) && firstPeriodLapsed?.isSame(currentPoAEnd)
                    && companyPayload.reason_appointment_lapsed === "Manual Revocation" && revocationDate?.isAfter(today)) {
                    return true;
                }

                return false;
            };

            const newStatus = isActive() ? "Active" : "Inactive";
            const updatedCompany = { ...companyPayload, status: newStatus };

            if (newStatus === "Active") {
                setActiveCompanies([updatedCompany]); // Set the new active company, replacing previous active
            } else {
                setPastCompanies(prev => [...prev, updatedCompany]); // Add to past if inactive
            }

            // Remove from drafts if it was previously saved as a draft
            setDraftCompanies(drafts => drafts.filter(draft => draft.company_ID !== company_ID));
        }

        setVisible(false); // Close the modal after saving
    };




    useEffect(() => {
        const isValidCompany = () => {
            if (!companyName || !taxOffice || taxOffice > TAX_OFFICE_MAX_VALUE || !utr || utr > UTR_MAX_VALUE || !firstPoaEnd || !dateAppointed || !appointer || !nominationLink || !!dateError) {
                return false;
            }
            if(nominationLink && !(urlRegex).test(nominationLink)){
                return false
            }
            return true;
        };
        setValidCompany(isValidCompany());
    }, [companyName, taxOffice, utr, firstPoaEnd, dateAppointed, appointer, nominationLink, dateError]);

    useEffect(() => {
        const isValidDraft = () => {
            if (!companyName || !taxOffice || taxOffice > TAX_OFFICE_MAX_VALUE || !utr || utr > UTR_MAX_VALUE || !firstPoaEnd || appointer === 'HMRC' || !!dateError || nominationLink || dateAppointed) {
                return false;
            }
            return true;
        };
        setValidDraft(isValidDraft());
    }, [companyName, taxOffice, utr, firstPoaEnd, dateAppointed, appointer, nominationLink, dateError]);

    useEffect(() => {
        if (companyID) {
            const selectedCompany = companies.find(company => company.company_ID === companyID);
            if (selectedCompany) {
                setCompanyName(selectedCompany.company_name);
                setTaxOffice(selectedCompany.tax_office);
                setUTR(selectedCompany.utr);
            }
        }
    }, [companyID, companies]);
    const urlRegex =/^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_\+.~#?&\/=]*)$/

    return (
        <Modal
            visible={visible}
            title={companyToEdit ? "Edit Reporting Company" : "Set the Active Reporting Company"}
            ariaLabel="Set the Active Reporting Company"
            onCancel={() => setVisible(false)}
            maskCloseable={false}
            modalStyle={{ width: '33.75rem' }}
            footer={
                <>
                    <Button neverReadonly onClick={() => setVisible(false)} kind="secondary">Cancel</Button>
                    <Button
                        disabled={!validDraft}
                        onClick={() => handleSave("Draft")}
                        style={{ marginRight: '8px' }}
                    >
                        Save as Draft
                    </Button>
                    <Button
                        disabled={!validCompany}
                        onClick={() => handleSave("Active")}
                    >
                        Submit
                    </Button>
                </>
            }
            bodyStyle={{ minHeight: '200px' }}
        >
            <div className="container" style={{ display: 'grid', gridGap: '10px' }}>
                <div className='flex-container-modal'>
                    <Select
                        data={companies?.map(company => ({ label: company.company_name, value: company.company_ID }))}
                        required={true}
                        placeholder="Please select a reporting company"
                        value={companyID} // Use companyID as the selected value
                        onSelect={(value) => setCompanyID(value)} // Set selected companyID
                        className="flex-grow"
                    />

                    <CustomTooltipIcon tooltipText={<p>Select the reporting company from the list of UK Group Companies</p>}/>
                </div>
                <div className='flex-container-modal'>
                    <Input readonly type="number" title="Tax Office" value={taxOffice} className="flex-grow" />
                    <CustomTooltipIcon tooltipText={<p>This is pre-populated with the 3 digit tax office code number of the company as provided by HMRC.</p>} />
                </div>
                <div className='flex-container-modal'>
                    <Input readonly title="UTR" value={utr} className="flex-grow" />
                    <CustomTooltipIcon tooltipText={<p>This is pre-populated with the 10 digit unique tax reference number of the company as provided by HMRC.</p>} />
                </div>

                <div style={{ display: 'flex', flexDirection: 'row', overflow: 'hidden' }} className='flex-container-modal'>
                    {!firstPOAInputSwitch ? (
                        <div>
                            <CalendarPicker
                                fieldWidth={350}
                                placeholder="dd/mm/yyyy"
                                format="DD/MM/YYYY"
                                locale="en"
                                required={true}
                                fieldTitle="End of first PoA"
                                value={firstPoaEnd}
                                onChange={(value) => setFirstPoaEnd(processDateJS(value))}
                                error={dateError}
                                maxDate={processDateJS(getFirstPOAStartDate()).subtract(1, "day")}
                                customErrorNode
                                useCustomValidation
                            />
                        </div>
                    ) : (
                        <div>
                            <Select
                                data={POAEndDates}
                                placeholder="End of first PoA"
                                required={true}
                                dropdownRenderMode="portal"
                                onSelect={(value) => setFirstPoaEnd(dayjs(value, 'DD/MM/YYYY'))}
                                value={firstPoaEnd ? dayjs(firstPoaEnd).format('DD/MM/YYYY') : ''}
                            />
                        </div>
                    )}
                    <Button
                        style={{ flex: '1', height: '100%' }}
                        onClick={() => {
                            setFirstPOAInputSwitch(!firstPOAInputSwitch);
                            setFirstPoaEnd(null);
                        }}
                    >
                        {firstPOAInputSwitch ? 'Before first CIR Analyser Period' : 'CIR Analyser Period'}
                    </Button>
                    <CustomTooltipIcon tooltipText={<p>Select the end of the first Period of Account for which the appointment is to take effect (the "relevant" period). If this is a period before the model was used, click "Earlier Date" and enter the date manually.</p>} />
                </div>

                <div className='flex-container-modal'>
                    <Select
                        data={APPOINTER_OPTIONS}
                        placeholder="Please select an appointer"
                        value={appointer}
                        onSelect={(value) => setAppointer(value)}
                        dropdownRenderMode="portal"
                        className="flex-grow"
                    />
                    <CustomTooltipIcon tooltipText={<p>Select whether the appointment was made by company within the group or by HMRC. If the appointment was made by HMRC, it will not apply to future periods and the group MUST make their own appointment, within the relevant deadline, for the next period.</p>} />
                </div>
                
                <div className='flex-container-modal'>
                    <CalendarPicker
                        placeholder="dd/mm/yyyy"
                        format="DD/MM/YYYY"
                        locale="en"
                        fieldTitle="Date of Appointment"
                        value={dateAppointed}
                        onChange={(value) => setDateAppointed(processDateJS(value))}
                        error={dateError}
                        customErrorNode
                        useCustomValidation
                        className="flex-grow"
                        fieldWidth="100%"
                        />
                    <CustomTooltipIcon tooltipText={<p>Enter the date of appointment. If the appointment is made by a company within the group it must be made within the period of 12 months beginning immediately after the end of the specified period of account (6 months for appointments made before 12 February 2019). A transitional provision applies to ensure appointments made by 31 March 2018 that would otherwise be late are also valid.</p>} />
                </div>
                {dateError && (
                        <div style={{ color: 'red' }}>{dateError}</div>
                    )}

                <div className='flex-container-modal'>
                    <Input
                        type="text"
                        title="Nomination Link"
                        value={nominationLink}
                        onChange={(value) => setNominationLink(value)}
                        className="flex-grow"
                    />
                    <CustomTooltipIcon tooltipText={<p>Provide an AoDocs link to the reporting company nomination, to ensure that an appropriate record of the nomination is linked to the CIR Analyser.</p>} />
                </div>
                <small style={{fontSize:'0.8rem'}}>Please provide a URL beginning with http:// or https://</small>
                {(nominationLink && !(urlRegex).test(nominationLink))&&<div style={{ color: 'red' }}>Please provide a valid URL</div>}
            </div>
        </Modal>
    );
};

export default NewReportingCompanyModal;
