import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../services/AuthProvider";
import { Button } from "@appkit4/react-components";
import { LOCAL_KEYS } from "../services/GroupContext";

const Logout = () => {
  const auth = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    if (!auth.hasUser())
      return;

    setLoading(true);
    auth.logout().then(() => {
      setLoading(false);
    });
  },[])
  
  if (loading){
    return <div></div>
  }

  return (
    <div className="ap-container">
        <h1>
            You have been logged out!
        </h1>
        <Button onClick={()=>{window.location.assign("/")}}>
          Log back in
        </Button>
    </div>
  );
};

export default Logout;