export function formatCurrency(amount) {
    if (amount === null || amount === undefined || amount === '') {
        return '-';
    }

    // Convert negative numbers to positive and format as (X,XXX)
    const isNegative = amount < 0;
    const formattedAmount = Math.abs(amount).toLocaleString('en-US');

    return isNegative ? `(${formattedAmount})` : formattedAmount;
}

export function formatNumber(value) {
    if (!value) return String(0); // Return empty string if value is null or undefined
    // Convert value to a number, add commas for thousands separator, and return as string
    return Math.round(Number(value))?.toLocaleString('en-US');
}

export function formatNegativeNumber(value) {
    const formattedVal = Number(value);
    return formattedVal > 0 ? `-${formatNumber(formattedVal)}` : formatNumber(formattedVal);
}
