
import { getPoaIndex, getSelectedPeriod, getGroupData} from "../services/GroupContext";
import { processDateJS } from "./dateProcessor";
import { get_relevant_periods } from '../utils/PeriodUtils';


export function processExcel(savedData){
    let companies = []
    const message = "©" + new Date().getFullYear() + " PricewaterhouseCoopers LLP. All rights reserved. ‘PwC’ refers to the UK member firm, and may sometimes refer to the PwC network. Each member firm is a separate legal entity. Please see www.pwc.com/structure for further details."
    const selectedPeriodOfAccount = getSelectedPeriod();
    const excelData = {
        compNames: ["Company name"],
        APSD: ['Accounting Period Start Date'],
        APED: ["Accounting Period End Date"],
        period_length: ["Accounting Period length"],
        date_joined: ["Date joined group"],
        date_lefted: ["Date left group"],
        regarded_period: ["Regarded period for CIR (days)"],
        disregarded_percent: ["Percentage of the period that is regarded"],

        currency: ["Currency"],
        fx: ["Exchange rate (1 GBP = X Currency)"],

        nltr_total_per_source_sheet_for_period:["Total per Computation Schedule for period"],
        note_1: ["Less: Included in the above"],
        nltr_exclude_exchange_gains_losses: ["Exclude Exchange gains/(losses)"],
        nltr_exclude_impairment_gains_losses: ["Exclude Impairment gains/(losses)"],
        nltr_exclude_tax_interest_income_double_relief: ["Exclude Tax-Interest Income subject to Double Tax Relief - s388"],
        nltr_adjustment_amortised_cost_basis: ["Adjustment for election for amortised cost basis for fair valued creditor relationships"], 
        nltr_adjustment_qualifying_election: ["Adjustment for election for Qualifying Infrastructure Company"],
        nltr_exclude_defined_benefit_pension: ["Exclude defined benefit pension income/expense included on loan relationships statement"],
        nltr_bank_expenses: ["Add bank charges and similar interest type expenses not included in the total above (i.e. not in Loan Relationships schedule)"],
        nltr_adjustment_gaap_transition: ["Adjustment for GAAP transition items"],
        nltr_adjustment_lease_finance_cost: ["Adjustment for finance costs associated with leases"],
        nltr_other_adjustments_1: ["Other Adjustments 1"],
        nltr_other_adjustments_2: ["Other Adjustments 2"],
        nltr_other_adjustments_3: ["Other Adjustments 3"],
        ntlr_subtotal: ["Non-trading loan relationships - Subtotal"],

        ntdc_total_per_source_sheet_for_period: ["Total per Computation Schedule for period"],
        ntdc_exclude_exchange_gains_losses: ["Exclude Exchange gains/(losses)"],
        ntdc_exclude_impairment_gains_losses: ["Exclude Impairment gains/(losses)"],
        ntdc_exclude_tax_interest_income_double_relief: ["Exclude Tax-Interest Income subject to Double Tax Relief - s388"],
        ntdc_exclude_ordinary_trade: ["Exclude amounts on contracts arising in the ordinary course of trade"],
        "ntdc_exclude_derivatives_without_subject_matters_384(4)_s387(4)": ["Exclude amounts for derivatives without an underlying subject matter specified in s384(4) / s387(4)"],
        ntdc_adjustment_qualifying_election: ["Adjustment for election for Qualifying Infrastructure Company"],
        ntdc_adjustment_gaap_transition: ["Adjustment for GAAP transition items"],
        ntdc_adjustment_disregard_regulations: ["Adjustment for election to apply the Disregard Regulations to pre 1 April 2020 derivative contracts (Regulations 7, 8 and 9)"],
        ntdc_other_adjustments_1: ["Other Adjustments 1"],
        ntdc_other_adjustments_2: ["Other Adjustments 2"],
        ntdc_other_adjustments_3: ["Other Adjustments 3"],
        ntdc_subtotal: ["Non-trading derivative contracts - subtotal"],

        tlr_total_per_source_sheet_for_period:["Total per Computation Schedule for period"],
        note_1: ["Less: Included in the above"],
        tlr_exclude_exchange_gains_losses: ["Exclude Exchange gains/(losses)"],
        tlr_exclude_impairment_gains_losses: ["Exclude Impairment gains/(losses)"],
        tlr_exclude_tax_interest_income_double_relief: ["Exclude Tax-Interest Income subject to Double Tax Relief - s388"],
        tlr_adjustment_amortised_cost_basis: ["Adjustment for election for amortised cost basis for fair valued creditor relationships"], 
        tlr_adjustment_qualifying_election: ["Adjustment for election for Qualifying Infrastructure Company"],
        tlr_exclude_defined_benefit_pension: ["Exclude defined benefit pension income/expense included on loan relationships statement"],
        tlr_bank_expenses: ["Add bank charges and similar interest type expenses not included in the total above (i.e. not in Loan Relationships schedule)"],
        tlr_adjustment_gaap_transition: ["Adjustment for GAAP transition items"],
        tlr_adjustment_lease_finance_cost: ["Adjustment for finance costs associated with leases"],
        tlr_other_adjustments_1: ["Other Adjustments 1"],
        tlr_other_adjustments_2: ["Other Adjustments 2"],
        tlr_other_adjustments_3: ["Other Adjustments 3"],
        tlr_subtotal: ["Trading loan relationships - Subtotal"],
    
        tdc_total_per_source_sheet_for_period: ["Total per Computation Schedule for period"],
        tdc_exclude_exchange_gains_losses: ["Exclude Exchange gains/(losses)"],
        tdc_exclude_impairment_gains_losses: ["Exclude Impairment gains/(losses)"],
        tdc_exclude_tax_interest_income_double_relief: ["Exclude Tax-Interest Income subject to Double Tax Relief - s388"],
        tdc_exclude_ordinary_trade: ["Exclude amounts on contracts arising in the ordinary course of trade"],
        "tdc_exclude_derivatives_without_subject_matters_384(4)_s387(4)": ["Exclude amounts for derivatives without an underlying subject matter specified in s384(4) / s387(4)"],
        tdc_adjustment_qualifying_election: ["Adjustment for election for Qualifying Infrastructure Company"],
        tdc_adjustment_gaap_transition: ["Adjustment for GAAP transition items"],
        tdc_adjustment_disregard_regulations: ["Adjustment for election to apply the Disregard Regulations to pre 1 April 2020 derivative contracts (Regulations 7, 8 and 9)"],
        tdc_other_adjustments_1: ["Other Adjustments 1"],
        tdc_other_adjustments_2: ["Other Adjustments 2"],
        tdc_other_adjustments_3: ["Other Adjustments 3"],
        tdc_subtotal: ["Trading derivative contracts - subtotal"],
        
        ofa_total_per_source_sheet_for_period: ["Total Per Source Sheet For Period"],
        ofa_exclude_tax_interest_income_double_relief: ["Exclude Tax-Interest Income subject to Double Tax Relief - s388"],
        ofa_adjustment_qualifying_election: ["Adjustment for election for Qualifying Infrastructure Company"],
        ofa_adjustment_gaap_transition: ["Adjustment for GAAP transition items"],
        ofa_adjustment_finance_lease: ["Adjustment to include Expense (income) associated with finance leases"],
        ofa_adjustment_debt_factoring: ["Adjustment to include Expense (income) associated with debt factoring transactions"],
        ofa_adjustment_service_concession: ["Adjustment to include Expense (income) associated with service concession arrangement if and to the extent accounted for as a financial liability"],
        ofa_other_adjustments_1: ["Other Adjustments 1"],
        ofa_other_adjustments_2: ["Other Adjustments 2"],
        ofa_other_adjustments_3: ["Other Adjustments 3"],
        ofa_subtotal: ["Other financing arrangements - subtotal"],

        net_tax_interest_totals: ["Total net tax-interest expense/(income)"],

        cte_condition_a: ["Adjusted corporation tax earnings - Condition A (TIOPA 2010, s406(3))"],
        cte_condition_b: ["Adjusted corporation tax earnings - Condition B (TIOPA 2010, s406(4))"],
        cte_restrictions_reactivations_included: ["CIR (Restrictions) and reactivations included in the above"],
        tebitda_adjustments: ["Adjustments"],
        cte_subtotal: ["Corporation tax earnings (excluding restrictions and reactivations)"],
    
        s407_tiopa_net_tax_interest_expense: ["Net tax-interest expense/(income) - s407(1)(a)"],
        s407_tiopa_capital_allowance_charges: ["Capital allowances and charges - s407(1)(b)"],
        s407_tiopa_relevant_intangible_debits_credits: ["Relevant intangible debits and credits - s407(1)(c)"],
        s407_tiopa_adjustment_intangible_debit_credits: ["Adjustment to intangible debits and credits brought in automatically (e.g. disposals above original cost)"],
        s407_tiopa_other_period_losses: ["Losses from other periods - s407(1)(d)(i) and (ii)"],
        s407_tiopa_loan_relationship_deficts_other_periods: ["Loan relationship deficits from other periods - s407(1)(e)"],
        s407_tiopa_expenses_management_other_periods: ["Expenses of management from other periods - s407(1)(f)"],
        s407_tiopa_group_relief: ["Group relief - s407(1)(g)"],

        s407_relief_rd_expenditure_credit: ["R&D expenditure credit - s407(3)(a)"],
        s407_relief_rd_additional_relief: ["R&D additional relief - s407(3)(b)"],
        s407_relief_trading_loss: ["Deemed trading loss for pre-trading expenditure - s407(3)(c)"],
        s407_relief_contaminated_land_relief: ["Contaminated land relief - s407(3)(d)"],
        s407_relief_creative_industries: ["Creative industries - s407(3)(e)-(j)"],
        s407_relief_charitable_donation: ["Qualifying charitable donation (from current or earlier period) - s407(3)(k)"],
        s407_relief_patent_box_profits: ["Profits from patent box chargeable at a lower rate - s407(3)(l)"],
        s407_relief_dtr_adjustment_tax_rate_equal_uk: ["DTR adjustment - s409"],
        s407_relief_adjustment_qualifying_infrastructure: ["Adjustment for election for Qualifying Infrastructure Company"],
        s407_relief_adjustment_gaap_items: ["Adjustment for GAAP transition items"],
        s407_relief_finance_lease_depreciation: ["Finance lease depreciation - s460(1)(d)"],
        s407_relief_reversal_of_lease_depreciation: ["Reversal of right-of-use lease depreciation in respect of operating leases"],
        s407_relief_long_funding_lease_deduction: ["Long funding lease deduction - s460(1)(a)"],
        s407_relief_finance_lease_rentals_receivable: ["Capital element of finance lease rentals receivable - s460(1)(c)"],
        s407_relief_long_funding_lessee: ["Long funding lessee - amount by which a deduction is reduced under section 379 of CTA 2010 - s460(1)(b)"],
        s407_relief_adjustment_misc_losses: ["Adjustment for miscellaneous losses not included in Condition B above"],
        s407_relief_group_relief: ["Group relief included above not covered by s407(1)(g)"],
        s407_relief_adjustment_disregarded_regulations: ["Adjustment for election to apply the Disregard Regulations to pre 1 April 2020 derivative contracts (Regulations 7, 8 and 9)"],
        s407_relief_other_adjustments_1: ["Other Adjustments 1"],
        s407_relief_other_adjustments_2: ["Other Adjustments 2"],
        s407_relief_other_adjustments_3: ["Other Adjustments 3"],
        tax_ebitda: ["Tax-EBITDA - Total for period"],

        tax_ebitda_adjustments: ["Tax-EBITDA Adjustments (to apportionment calculation only)"],

        //note_2: ["Reversal of right-of-use lease depreciation in respect of operating leases"],
    

        net_dtr_adjustment_gbp: ["Credit for foreign taxes under s18(2) TIOPA 2010 in GBP"],
        net_dtr_adjustment_lc: ["Credit for foreign taxes under s18(2) TIOPA 2010 in Local Currency"],
        corporation_tax_rate_percentage: ["Corporation Tax Rate"],
        ct_rate_override: ["CT Rate override"],
        s407_relief_dtr_adjustment_tax_rate_uk: ["DTR adjustment - s409"],

        non_consenting_company: ["Non-consenting company?"],
        trade_small_or_negligible: ["Trade ceased and/or small or negligible?"],
        trade_uncommercial_or_non_statutory: ["Trade uncommercial or non-statutory?"],
        investment_business_ceased: ["Investment business ceased?"],
        cta10_change_in_ownership_rules: ["CTA10 Change in Company Ownership rules apply?"],

        /*bfd_ntlr: ["BFD - Non-trading loan relationship amounts"],
        bfd_ntd: ["BFD - Non-trading derivative amounts"],
        bfd_tlr: ["BFD - Trading loan relationship amounts"],
        bfd_td: ["BFD - Trading derivatie amounts"],
        bfd_ofa: ["BFD - Other financing amounts"],
        bfd_total: ["BFD - Total"],*/

        type_reit_company: ["REIT"],
        type_ring_fence_company: ["Ring fence company"],
        type_investment_manager: ["Investment company"],
        type_insurance_company: ["Insurance company"],
        type_shipping_company: ["Shipping company"],
        type_cooperative_company: ["Cooperative/community benefit society etc."],
        type_charity_company: ["Charity"],
        type_banking_company: ["Banking company"],

        //CIR Calculations

        aggregate_ebitda: ["Aggregate Tax EBITDA","A"],
        fixed_ratio: ["Fixed ratio (30%)", "B = (A x 30%)"],
        angie: ["Adjusted Net Group Interest Expense (ANGIE)","C"],
        excess_debt: ["Excess Debt cap brought forward","D"],
        fixed_debt: ["Fixed Ratio Debt Cap (s400(1))","E = (C + D)"],
        basic_interest: ["Basic interest allowance - lower of B and E (s397(1))","F"],
        antii: ["Aggregate Net Tax Interest Income (ANTII)","G"],
        interest_allowance: ["Interest allowance (s396(1))","H = (F + G)"],

        qngie: ["Qualifying Net Group Interest Expense (QNGIE)","A"],
        group_ebitda: ["Group EBITDA","B"],
        group_ratio: ["Group ratio % (s399)","C = (A / B)"],

        aggregate_ebitda2: ["Aggregate Tax EBITDA","D"],
        group_ratio_ebitda: ["Group ratio % of Tax EBITDA","E = (D X C)"],

        excess_debt2: ["Excess Debt cap brought forward","F"],
        group_debt_cap: ["Group ratio debt cap (s400(2))","G = (A + F)"],
        

        group_basic_interest: ["Basic interest allowance - lower of E and G (s398(1))","H"],
        group_interest_allowance: ["Interest allowance (s396(1))","H = (F + G)"],

        group_ratio_election: ["Group Ratio Election being made?"],
        computation_int_allowance: [ "Interest allowance","A"],
        unused_allowance: ["Unused interest allowance brought forward","B"],
        de_minimis: ["De minimis (Note: Assumes no leap year)","C"],
        interest_capacity: ["Interest capacity (Greater of interest allowance plus brought forward allowance and de minimis) (s392)","D = Greater of (A + B) and C"],
        aggregate_interest_expense: ["Aggregate Net Tax Interest Expense","E"],
        disallowed_amount: ["Disallowed Amount (s373(1)-(2))","F = (E - D)"],
        available_reactivation: ["Amount available for Reactivation (Group reactivation cap) (s373(3)-(4))","G = (A - E)"],

        //CIR attributes

        current_int_allowance: ["Interest allowance for the current period","A"],
        current_tax_interest_expense: ["Aggregate Net Tax Interest Expense for current period","B"],
        reactivations: ["Reactivations in the current period","C"],
        current_yr_interest: ["Current Year Interest allowance used in current period","D (B + C)"],
        unused_interest_gen: ["Unused Interest Allowance generated in current period","E (A - D)"],
        unused_interest_bf: ["Unused Interest Allowance b/f used in current period (Period TBC)","F (B - A)"],

        excess_debt_cap_from_prior_periodEDC: [ "Excess debt cap generated in immediately preceding period","A"],
        angieorqngieEDC: ["ANGIE or QNGIE","B"],
        group_debt_capEDC: ["Current period debt cap (s400(1)-(2))","C = A + B"],
        group_debt_capEDC2: ["Current period debt cap (s400(1)-(2))","C"],
        group_percentage_tax_ebitdaEDC: ["Less: 30% or Group Ratio % of Aggregate Tax-EBITDA","D"],
        excess_debt_cap_generated_pre_cflimitEDC: ["Excess debt cap generated in current period (s400(3)-(5)) before the application of the carry forward limit","E = (C - D)"],
        //excess_immediate: ["Excess debt cap generated in immediately preceding period","A"],
        group_disallowanceEDC: ["Disallowed amount for current period","F"],
        cflimitEDC: ["Carry forward limit","G = (A + F)"],
        group_excess_debt_capEDC: ["Excess debt cap generated in the period after the application of the carry-forward limit", "H = Lower of E and G"],

        //Accounting Period Data
        apCompNames: ["Company name"],
        apStartDates: ['Accounting Period Start Date'],
        apEndDates: ["Accounting Period End Date"],
        apCurrency: ["Currency"],
        apFx: ["Exchange rate (1 GBP = X Currency)"],

        APnltr_total_per_source_sheet_for_period:["Total per Computation Schedule for period"],
        note_1: ["Less: Included in the above"],
        APnltr_exclude_exchange_gains_losses: ["Exclude Exchange gains/(losses)"],
        APnltr_exclude_impairment_gains_losses: ["Exclude Impairment gains/(losses)"],
        APnltr_exclude_tax_interest_income_double_relief: ["Exclude Tax-Interest Income subject to Double Tax Relief - s388"],
        APnltr_adjustment_amortised_cost_basis: ["Adjustment for election for amortised cost basis for fair valued creditor relationships"], 
        APnltr_adjustment_qualifying_election: ["Adjustment for election for Qualifying Infrastructure Company"],
        APnltr_exclude_defined_benefit_pension: ["Exclude defined benefit pension income/expense included on loan relationships statement"],
        APnltr_bank_expenses: ["Add bank charges and similar interest type expenses not included in the total above (i.e. not in Loan Relationships schedule)"],
        APnltr_adjustment_gaap_transition: ["Adjustment for GAAP transition items"],
        APnltr_adjustment_lease_finance_cost: ["Adjustment for finance costs associated with leases"],
        APnltr_other_adjustments_1: ["Other Adjustments 1"],
        APnltr_other_adjustments_2: ["Other Adjustments 2"],
        APnltr_other_adjustments_3: ["Other Adjustments 3"],
        APntlr_subtotal: ["Non-trading loan relationships - Subtotal"],

        APntdc_total_per_source_sheet_for_period: ["Total per Computation Schedule for period"],
        APntdc_exclude_exchange_gains_losses: ["Exclude Exchange gains/(losses)"],
        APntdc_exclude_impairment_gains_losses: ["Exclude Impairment gains/(losses)"],
        APntdc_exclude_tax_interest_income_double_relief: ["Exclude Tax-Interest Income subject to Double Tax Relief - s388"],
        APntdc_exclude_ordinary_trade: ["Exclude amounts on contracts arising in the ordinary course of trade"],
        APntdc_exclude_derivatives_without_subject_matters_384387: ["Exclude amounts for derivatives without an underlying subject matter specified in s384(4) / s387(4)"],
        APntdc_adjustment_qualifying_election: ["Adjustment for election for Qualifying Infrastructure Company"],
        APntdc_adjustment_gaap_transition: ["Adjustment for GAAP transition items"],
        APntdc_adjustment_disregard_regulations: ["Adjustment for election to apply the Disregard Regulations to pre 1 April 2020 derivative contracts (Regulations 7, 8 and 9)"],
        APntdc_other_adjustments_1: ["Other Adjustments 1"],
        APntdc_other_adjustments_2: ["Other Adjustments 2"],
        APntdc_other_adjustments_3: ["Other Adjustments 3"],
        APntdc_subtotal: ["Non-trading derivative contracts - subtotal"],

        APtlr_total_per_source_sheet_for_period:["Total per Computation Schedule for period"],
        note_1: ["Less: Included in the above"],
        APtlr_exclude_exchange_gains_losses: ["Exclude Exchange gains/(losses)"],
        APtlr_exclude_impairment_gains_losses: ["Exclude Impairment gains/(losses)"],
        APtlr_exclude_tax_interest_income_double_relief: ["Exclude Tax-Interest Income subject to Double Tax Relief - s388"],
        APtlr_adjustment_amortised_cost_basis: ["Adjustment for election for amortised cost basis for fair valued creditor relationships"], 
        APtlr_adjustment_qualifying_election: ["Adjustment for election for Qualifying Infrastructure Company"],
        APtlr_exclude_defined_benefit_pension: ["Exclude defined benefit pension income/expense included on loan relationships statement"],
        APtlr_bank_expenses: ["Add bank charges and similar interest type expenses not included in the total above (i.e. not in Loan Relationships schedule)"],
        APtlr_adjustment_gaap_transition: ["Adjustment for GAAP transition items"],
        APtlr_adjustment_lease_finance_cost: ["Adjustment for finance costs associated with leases"],
        APtlr_other_adjustments_1: ["Other Adjustments 1"],
        APtlr_other_adjustments_2: ["Other Adjustments 2"],
        APtlr_other_adjustments_3: ["Other Adjustments 3"],
        APtlr_subtotal: ["Trading loan relationships - Subtotal"],
    
        APtdc_total_per_source_sheet_for_period: ["Total per Computation Schedule for period"],
        APtdc_exclude_exchange_gains_losses: ["Exclude Exchange gains/(losses)"],
        APtdc_exclude_impairment_gains_losses: ["Exclude Impairment gains/(losses)"],
        APtdc_exclude_tax_interest_income_double_relief: ["Exclude Tax-Interest Income subject to Double Tax Relief - s388"],
        APtdc_exclude_ordinary_trade: ["Exclude amounts on contracts arising in the ordinary course of trade"],
        APtdc_exclude_derivatives_without_subject_matters_384387: ["Exclude amounts for derivatives without an underlying subject matter specified in s384(4) / s387(4)"],
        APtdc_adjustment_qualifying_election: ["Adjustment for election for Qualifying Infrastructure Company"],
        APtdc_adjustment_gaap_transition: ["Adjustment for GAAP transition items"],
        APtdc_adjustment_disregard_regulations: ["Adjustment for election to apply the Disregard Regulations to pre 1 April 2020 derivative contracts (Regulations 7, 8 and 9)"],
        APtdc_other_adjustments_1: ["Other Adjustments 1"],
        APtdc_other_adjustments_2: ["Other Adjustments 2"],
        APtdc_other_adjustments_3: ["Other Adjustments 3"],
        APtdc_subtotal: ["Trading derivative contracts - subtotal"],
        
        APofa_total_per_source_sheet_for_period: ["Total Per Source Sheet For Period"],
        APofa_exclude_tax_interest_income_double_relief: ["Exclude Tax-Interest Income subject to Double Tax Relief - s388"],
        APofa_adjustment_qualifying_election: ["Adjustment for election for Qualifying Infrastructure Company"],
        APofa_adjustment_gaap_transition: ["Adjustment for GAAP transition items"],
        APofa_adjustment_finance_lease: ["Adjustment to include Expense (income) associated with finance leases"],
        APofa_adjustment_debt_factoring: ["Adjustment to include Expense (income) associated with debt factoring transactions"],
        APofa_adjustment_service_concession: ["Adjustment to include Expense (income) associated with service concession arrangement if and to the extent accounted for as a financial liability"],
        APofa_other_adjustments_1: ["Other Adjustments 1"],
        APofa_other_adjustments_2: ["Other Adjustments 2"],
        APofa_other_adjustments_3: ["Other Adjustments 3"],
        APofa_subtotal: ["Other financing arrangements - subtotal"],

        APnet_tax_interest_totals: ["Total net tax-interest expense/(income)"],

        APcte_condition_a: ["Adjusted corporation tax earnings - Condition A (TIOPA 2010, s406(3))"],
        APcte_condition_b: ["Adjusted corporation tax earnings - Condition B (TIOPA 2010, s406(4))"],
        APcte_restrictions_reactivations_included: ["CIR (Restrictions) and reactivations included in the above"],
        APtebitda_adjustments: ["Adjustments"],
        APcte_subtotal: ["Corporation tax earnings (excluding restrictions and reactivations)"],
    
        APs407_tiopa_net_tax_interest_expense: ["Net tax-interest expense/(income) - s407(1)(a)"],
        APs407_tiopa_capital_allowance_charges: ["Capital allowances and charges - s407(1)(b)"],
        APs407_tiopa_relevant_intangible_debits_credits: ["Relevant intangible debits and credits - s407(1)(c)"],
        APs407_tiopa_adjustment_intangible_debit_credits: ["Adjustment to intangible debits and credits brought in automatically (e.g. disposals above original cost)"],
        APs407_tiopa_other_period_losses: ["Losses from other periods - s407(1)(d)(i) and (ii)"],
        APs407_tiopa_loan_relationship_deficts_other_periods: ["Loan relationship deficits from other periods - s407(1)(e)"],
        APs407_tiopa_expenses_management_other_periods: ["Expenses of management from other periods - s407(1)(f)"],
        APs407_tiopa_group_relief: ["Group relief - s407(1)(g)"],

        APs407_relief_rd_expenditure_credit: ["R&D expenditure credit - s407(3)(a)"],
        APs407_relief_rd_additional_relief: ["R&D additional relief - s407(3)(b)"],
        APs407_relief_trading_loss: ["Deemed trading loss for pre-trading expenditure - s407(3)(c)"],
        APs407_relief_contaminated_land_relief: ["Contaminated land relief - s407(3)(d)"],
        APs407_relief_creative_industries: ["Creative industries - s407(3)(e)-(j)"],
        APs407_relief_charitable_donation: ["Qualifying charitable donation (from current or earlier period) - s407(3)(k)"],
        APs407_relief_patent_box_profits: ["Profits from patent box chargeable at a lower rate - s407(3)(l)"],
        APs407_relief_dtr_adjustment_tax_rate_equal_uk: ["DTR adjustment - s409"],
        APs407_relief_adjustment_qualifying_infrastructure: ["Adjustment for election for Qualifying Infrastructure Company"],
        APs407_relief_adjustment_gaap_items: ["Adjustment for GAAP transition items"],
        APs407_relief_finance_lease_depreciation: ["Finance lease depreciation - s460(1)(d)"],
        APs407_relief_reversal_of_lease_depreciation: ["Reversal of right-of-use lease depreciation in respect of operating leases"],
        APs407_relief_long_funding_lease_deduction: ["Long funding lease deduction - s460(1)(a)"],
        APs407_relief_finance_lease_rentals_receivable: ["Capital element of finance lease rentals receivable - s460(1)(c)"],
        APs407_relief_long_funding_lessee: ["Long funding lessee - amount by which a deduction is reduced under section 379 of CTA 2010 - s460(1)(b)"],
        APs407_relief_adjustment_misc_losses: ["Adjustment for miscellaneous losses not included in Condition B above"],
        APs407_relief_group_relief: ["Group relief included above not covered by s407(1)(g)"],
        APs407_relief_adjustment_disregarded_regulations: ["Adjustment for election to apply the Disregard Regulations to pre 1 April 2020 derivative contracts (Regulations 7, 8 and 9)"],
        APs407_relief_other_adjustments_1: ["Other Adjustments 1"],
        APs407_relief_other_adjustments_2: ["Other Adjustments 2"],
        APs407_relief_other_adjustments_3: ["Other Adjustments 3"],
        APtax_ebitda: ["Tax-EBITDA - Total for period"],

        APtax_ebitda_adjustments: ["Tax-EBITDA Adjustments (to apportionment calculation only)"],


    }

    //Company Adjusted Caps
    let ntlr_potentialVariables;
    let tlr_potentialVariables;
    let ntdc_potentialVariables;
    let tdc_potentialVariables;
    let ofa_potentialVariables;
    let cte_potentialVariables;
    let dtr_potentialVariables;
    let adjustments_potentialVariables;
    let reliefs_potentialVariables;
    let bfd_potentialVariables;
    let cir_potentialVariables;
    let company_potentialVariables;

    //Company Accounting Periods
    let AP_potentialVariables;

    //CIR calculations
    let fixed_ratio_variables;
    let group_ratio_variables;
    let disallowed_variables;

    //CIR attributes
    let unused_variables;
    let excess_debt_cap_variables;

    // output 1, CIR Calculations and CIR Attributes
    for(let i=0; i<selectedPeriodOfAccount.adjusted_caps.length; i++){
        if(selectedPeriodOfAccount.adjusted_caps[i] !== undefined){
            companies.push(selectedPeriodOfAccount.adjusted_caps[i].company_name)
            excelData.compNames.push(selectedPeriodOfAccount.adjusted_caps[i].company_name)
            excelData.currency.push(selectedPeriodOfAccount.adjusted_caps[i].currency)
            excelData.APSD.push(processDateJS(selectedPeriodOfAccount.adjusted_caps[i].start_date).format('DD/MM/YYYY'))
            excelData.APED.push(processDateJS(selectedPeriodOfAccount.adjusted_caps[i].end_date).format('DD/MM/YYYY'))
            excelData.regarded_period.push(selectedPeriodOfAccount.adjusted_caps[i].inclusion_period_length);
            excelData.disregarded_percent.push(selectedPeriodOfAccount.adjusted_caps[i].inclusion_factor)
            excelData.fx.push(selectedPeriodOfAccount.adjusted_caps[i].fx_rate)
        
        
            for(let c =0;c<savedData.companies.length;c++){
                //checking the data is for the right company
                    if(savedData.companies[c].company_id === selectedPeriodOfAccount.adjusted_caps[i].company_id){
                        if(processDateJS(savedData.companies[c].date_join) != undefined){
                            excelData.date_joined.push(processDateJS(savedData.companies[c].date_join).format("DD/MM/YYYY"))
                        }else{
                            excelData.date_joined.push("N/A")
                                            
                        }
                        if(processDateJS(savedData.companies[c].date_left) != undefined){
                            excelData.date_lefted.push(processDateJS(savedData.companies[c].date_left).format("DD/MM/YYYY"))
                        }else{
                            excelData.date_lefted.push("N/A")
                        }
                    }
                    break
            }
        
            ntlr_potentialVariables = [
                [selectedPeriodOfAccount.adjusted_caps[i].nltr_total_per_source_sheet_for_period, 0, excelData.nltr_total_per_source_sheet_for_period],
                [selectedPeriodOfAccount.adjusted_caps[i].nltr_exclude_exchange_gains_losses, 0, excelData.nltr_exclude_exchange_gains_losses],
                [selectedPeriodOfAccount.adjusted_caps[i].nltr_exclude_impairment_gains_losses, 0, excelData.nltr_exclude_impairment_gains_losses],
                [selectedPeriodOfAccount.adjusted_caps[i].nltr_exclude_tax_interest_income_double_relief, 0, excelData.nltr_exclude_tax_interest_income_double_relief],
                [selectedPeriodOfAccount.adjusted_caps[i].nltr_adjustment_amortised_cost_basis, 0, excelData.nltr_adjustment_amortised_cost_basis],
                [selectedPeriodOfAccount.adjusted_caps[i].nltr_adjustment_qualifying_election, 0, excelData.nltr_adjustment_qualifying_election],
                [selectedPeriodOfAccount.adjusted_caps[i].nltr_exclude_defined_benefit_pension, 0, excelData.nltr_exclude_defined_benefit_pension],
                [selectedPeriodOfAccount.adjusted_caps[i].nltr_bank_expenses, 0, excelData.nltr_bank_expenses],
                [selectedPeriodOfAccount.adjusted_caps[i].nltr_adjustment_gaap_transition, 0, excelData.nltr_adjustment_gaap_transition],
                [selectedPeriodOfAccount.adjusted_caps[i].nltr_adjustment_lease_finance_cost, 0, excelData.nltr_adjustment_lease_finance_cost],
                [selectedPeriodOfAccount.adjusted_caps[i].nltr_other_adjustments_1, 0, excelData.nltr_other_adjustments_1],
                [selectedPeriodOfAccount.adjusted_caps[i].nltr_other_adjustments_2, 0, excelData.nltr_other_adjustments_2],
                [selectedPeriodOfAccount.adjusted_caps[i].nltr_other_adjustments_3, 0, excelData.nltr_other_adjustments_3],
                [selectedPeriodOfAccount.adjusted_caps[i].ntlr_subtotal, 0, excelData.ntlr_subtotal],
            ]
            tlr_potentialVariables = [
                [selectedPeriodOfAccount.adjusted_caps[i].tlr_total_per_source_sheet_for_period, 0, excelData.tlr_total_per_source_sheet_for_period],
                [selectedPeriodOfAccount.adjusted_caps[i].tlr_exclude_exchange_gains_losses, 0, excelData.tlr_exclude_exchange_gains_losses],
                [selectedPeriodOfAccount.adjusted_caps[i].tlr_exclude_impairment_gains_losses, 0, excelData.tlr_exclude_impairment_gains_losses],
                [selectedPeriodOfAccount.adjusted_caps[i].tlr_exclude_tax_interest_income_double_relief, 0, excelData.tlr_exclude_tax_interest_income_double_relief],
                [selectedPeriodOfAccount.adjusted_caps[i].tlr_adjustment_amortised_cost_basis, 0, excelData.tlr_adjustment_amortised_cost_basis],
                [selectedPeriodOfAccount.adjusted_caps[i].tlr_adjustment_qualifying_election, 0, excelData.tlr_adjustment_qualifying_election],
                [selectedPeriodOfAccount.adjusted_caps[i].tlr_exclude_defined_benefit_pension, 0, excelData.tlr_exclude_defined_benefit_pension],
                [selectedPeriodOfAccount.adjusted_caps[i].tlr_bank_expenses, 0, excelData.tlr_bank_expenses],
                [selectedPeriodOfAccount.adjusted_caps[i].tlr_adjustment_gaap_transition, 0, excelData.tlr_adjustment_gaap_transition],
                [selectedPeriodOfAccount.adjusted_caps[i].tlr_adjustment_lease_finance_cost, 0, excelData.tlr_adjustment_lease_finance_cost],
                [selectedPeriodOfAccount.adjusted_caps[i].tlr_other_adjustments_1, 0, excelData.tlr_other_adjustments_1],
                [selectedPeriodOfAccount.adjusted_caps[i].tlr_other_adjustments_2, 0, excelData.tlr_other_adjustments_2],
                [selectedPeriodOfAccount.adjusted_caps[i].tlr_other_adjustments_3, 0, excelData.tlr_other_adjustments_3],
                [selectedPeriodOfAccount.adjusted_caps[i].tlr_subtotal, 0, excelData.tlr_subtotal],
            ]
        
                        
            for(let x=0;x<ntlr_potentialVariables.length;x++){
                if(ntlr_potentialVariables[x][0] !== undefined && ntlr_potentialVariables[x][0].value !== null){
                    let value = typeof ntlr_potentialVariables[x][0] === 'object' ? ntlr_potentialVariables[x][0].value : ntlr_potentialVariables[x][0]
                    ntlr_potentialVariables[x][2].push(value)
                }else{
                    ntlr_potentialVariables[x][2].push(ntlr_potentialVariables[x][1])
                }
                if(tlr_potentialVariables[x][0] !== undefined && tlr_potentialVariables[x][0].value != null){
                    let value = typeof tlr_potentialVariables[x][0] === 'object' ? tlr_potentialVariables[x][0].value : tlr_potentialVariables[x][0]
                    tlr_potentialVariables[x][2].push(value)
                }else{
                    tlr_potentialVariables[x][2].push(tlr_potentialVariables[x][1])
                }
            }
            let PropNameNTDC = 'ntdc_exclude_derivatives_without_subject_matters_384(4)_s387(4)';
            ntdc_potentialVariables = [
                [selectedPeriodOfAccount.adjusted_caps[i].ntdc_total_per_source_sheet_for_period, 0, excelData.ntdc_total_per_source_sheet_for_period],
                [selectedPeriodOfAccount.adjusted_caps[i].ntdc_exclude_exchange_gains_losses, 0, excelData.ntdc_exclude_exchange_gains_losses],
                [selectedPeriodOfAccount.adjusted_caps[i].ntdc_exclude_impairment_gains_losses, 0, excelData.ntdc_exclude_impairment_gains_losses],
                [selectedPeriodOfAccount.adjusted_caps[i].ntdc_exclude_tax_interest_income_double_relief, 0, excelData.ntdc_exclude_tax_interest_income_double_relief],
                [selectedPeriodOfAccount.adjusted_caps[i].ntdc_exclude_ordinary_trade, 0, excelData.ntdc_exclude_ordinary_trade],
                [selectedPeriodOfAccount.adjusted_caps[i][PropNameNTDC], 0, excelData["ntdc_exclude_derivatives_without_subject_matters_384(4)_s387(4)"]], //TODO - Is this one working?
                [selectedPeriodOfAccount.adjusted_caps[i].ntdc_adjustment_qualifying_election, 0, excelData.ntdc_adjustment_qualifying_election],                    
                [selectedPeriodOfAccount.adjusted_caps[i].ntdc_adjustment_gaap_transition, 0, excelData.ntdc_adjustment_gaap_transition],
                [selectedPeriodOfAccount.adjusted_caps[i].ntdc_adjustment_disregard_regulations, 0, excelData.ntdc_adjustment_disregard_regulations],
                [selectedPeriodOfAccount.adjusted_caps[i].ntdc_other_adjustments_1, 0, excelData.ntdc_other_adjustments_1],
                [selectedPeriodOfAccount.adjusted_caps[i].ntdc_other_adjustments_2, 0, excelData.ntdc_other_adjustments_2],
                [selectedPeriodOfAccount.adjusted_caps[i].ntdc_other_adjustments_3, 0, excelData.ntdc_other_adjustments_3],
                [selectedPeriodOfAccount.adjusted_caps[i].ntdc_subtotal, 0, excelData.ntdc_subtotal],
            ]
            let PropNameTDC = 'tdc_exclude_derivatives_without_subject_matters_384(4)_s387(4)';
            tdc_potentialVariables = [
                [selectedPeriodOfAccount.adjusted_caps[i].tdc_total_per_source_sheet_for_period, 0, excelData.tdc_total_per_source_sheet_for_period],
                [selectedPeriodOfAccount.adjusted_caps[i].tdc_exclude_exchange_gains_losses, 0, excelData.tdc_exclude_exchange_gains_losses],
                [selectedPeriodOfAccount.adjusted_caps[i].tdc_exclude_impairment_gains_losses, 0, excelData.tdc_exclude_impairment_gains_losses],
                [selectedPeriodOfAccount.adjusted_caps[i].tdc_exclude_tax_interest_income_double_relief, 0, excelData.tdc_exclude_tax_interest_income_double_relief],
                [selectedPeriodOfAccount.adjusted_caps[i].tdc_exclude_ordinary_trade, 0, excelData.tdc_exclude_ordinary_trade],
                [selectedPeriodOfAccount.adjusted_caps[i][PropNameTDC], 0, excelData["tdc_exclude_derivatives_without_subject_matters_384(4)_s387(4)"]],
                [selectedPeriodOfAccount.adjusted_caps[i].tdc_adjustment_qualifying_election, 0, excelData.tdc_adjustment_qualifying_election],
                [selectedPeriodOfAccount.adjusted_caps[i].tdc_adjustment_gaap_transition, 0, excelData.tdc_adjustment_gaap_transition],
                [selectedPeriodOfAccount.adjusted_caps[i].tdc_adjustment_disregard_regulations, 0, excelData.tdc_adjustment_disregard_regulations],
                [selectedPeriodOfAccount.adjusted_caps[i].tdc_other_adjustments_1, 0, excelData.tdc_other_adjustments_1],
                [selectedPeriodOfAccount.adjusted_caps[i].tdc_other_adjustments_2, 0, excelData.tdc_other_adjustments_2],
                [selectedPeriodOfAccount.adjusted_caps[i].tdc_other_adjustments_3, 0, excelData.tdc_other_adjustments_3],
                [selectedPeriodOfAccount.adjusted_caps[i].tdc_subtotal, 0, excelData.tdc_subtotal],
            ]
            
            for(let x=0;x<ntdc_potentialVariables.length;x++){
                if(ntdc_potentialVariables[x][0] !== undefined && ntdc_potentialVariables[x][0].value != null){
                    let value = typeof ntdc_potentialVariables[x][0]  === 'object' ? ntdc_potentialVariables[x][0].value : ntdc_potentialVariables[x][0]
                    ntdc_potentialVariables[x][2].push(value)
                }else{
                    ntdc_potentialVariables[x][2].push(ntdc_potentialVariables[x][1])
                } 
                if(tdc_potentialVariables[x][0] !== undefined && tdc_potentialVariables[x][0].value != null){
                    let value = typeof tdc_potentialVariables[x][0]  === 'object' ? tdc_potentialVariables[x][0].value : tdc_potentialVariables[x][0]
                    tdc_potentialVariables[x][2].push(value)
                }else{
                    tdc_potentialVariables[x][2].push(tdc_potentialVariables[x][1])
                } 
            }
            
            ofa_potentialVariables = [
                [selectedPeriodOfAccount.adjusted_caps[i].ofa_total_per_source_sheet_for_period, 0, excelData.ofa_total_per_source_sheet_for_period],
                [selectedPeriodOfAccount.adjusted_caps[i].ofa_exclude_tax_interest_income_double_relief, 0, excelData.ofa_exclude_tax_interest_income_double_relief],
                [selectedPeriodOfAccount.adjusted_caps[i].ofa_adjustment_qualifying_election, 0, excelData.ofa_adjustment_qualifying_election],
                [selectedPeriodOfAccount.adjusted_caps[i].ofa_adjustment_gaap_transition, 0, excelData.ofa_adjustment_gaap_transition],
                [selectedPeriodOfAccount.adjusted_caps[i].ofa_adjustment_finance_lease, 0, excelData.ofa_adjustment_finance_lease],
                [selectedPeriodOfAccount.adjusted_caps[i].ofa_adjustment_debt_factoring, 0, excelData.ofa_adjustment_debt_factoring],
                [selectedPeriodOfAccount.adjusted_caps[i].ofa_adjustment_service_concession, 0, excelData.ofa_adjustment_service_concession],
                [selectedPeriodOfAccount.adjusted_caps[i].ofa_other_adjustments_1, 0, excelData.ofa_other_adjustments_1],
                [selectedPeriodOfAccount.adjusted_caps[i].ofa_other_adjustments_2, 0, excelData.ofa_other_adjustments_2],
                [selectedPeriodOfAccount.adjusted_caps[i].ofa_other_adjustments_3, 0, excelData.ofa_other_adjustments_3],
                [selectedPeriodOfAccount.adjusted_caps[i].ofa_subtotal, 0, excelData.ofa_subtotal],
            ]
        
            for(let x=0;x<ofa_potentialVariables.length;x++){
                if(ofa_potentialVariables[x][0] !== undefined && ofa_potentialVariables[x][0].value != null){
                    let value = typeof ofa_potentialVariables[x][0]  === 'object' ? ofa_potentialVariables[x][0].value : ofa_potentialVariables[x][0]
                    ofa_potentialVariables[x][2].push(value)
                }else{
                    ofa_potentialVariables[x][2].push(ofa_potentialVariables[x][1])
                } 
            }
            
            cte_potentialVariables = [
                [selectedPeriodOfAccount.adjusted_caps[i].cte_condition_a, 0, excelData.cte_condition_a],
                [selectedPeriodOfAccount.adjusted_caps[i].cte_condition_b, 0, excelData.cte_condition_b],
                [selectedPeriodOfAccount.adjusted_caps[i].cte_restrictions_reactivations_included, 0, excelData.cte_restrictions_reactivations_included],
                [selectedPeriodOfAccount.adjusted_caps[i].tebitda_adjustments, 0, excelData.tebitda_adjustments],
                [selectedPeriodOfAccount.adjusted_caps[i].cte_subtotal, 0, excelData.cte_subtotal],
            ]
        
            let cte_total =0;
            for(let x=0;x<cte_potentialVariables.length;x++){
                if(cte_potentialVariables[x][0] !== undefined && cte_potentialVariables[x][0].value != null){
                    let value = typeof cte_potentialVariables[x][0] === 'object' ? cte_potentialVariables[x][0].value : cte_potentialVariables[x][0]
                    cte_potentialVariables[x][2].push(value)
                    //cte_total = cte_total + value (JW-Not needed as we have a separate variable)
                }else{
                    cte_potentialVariables[x][2].push(cte_potentialVariables[x][1])
                } 
            }
            //Total of all variables in above section
            // excelData.cte_sum.push(cte_total) (JW - Not needed as we have a separate variable)
        
            adjustments_potentialVariables = [
                [selectedPeriodOfAccount.adjusted_caps[i].net_tax_interest_expense, 0, excelData.s407_tiopa_net_tax_interest_expense],
                [selectedPeriodOfAccount.adjusted_caps[i].net_tax_interest_expense, 0, excelData.net_tax_interest_totals],
                [selectedPeriodOfAccount.adjusted_caps[i].s407_tiopa_capital_allowance_charges, 0, excelData.s407_tiopa_capital_allowance_charges],
                [selectedPeriodOfAccount.adjusted_caps[i].s407_tiopa_relevant_intangible_debits_credits, 0, excelData.s407_tiopa_relevant_intangible_debits_credits],
                [selectedPeriodOfAccount.adjusted_caps[i].s407_tiopa_adjustment_intangible_debit_credits, 0, excelData.s407_tiopa_adjustment_intangible_debit_credits],
                [selectedPeriodOfAccount.adjusted_caps[i].s407_tiopa_other_period_losses , 0, excelData.s407_tiopa_other_period_losses],
                [selectedPeriodOfAccount.adjusted_caps[i].s407_tiopa_loan_relationship_deficts_other_periods, 0, excelData.s407_tiopa_loan_relationship_deficts_other_periods],
                [selectedPeriodOfAccount.adjusted_caps[i].s407_tiopa_expenses_management_other_periods, 0, excelData.s407_tiopa_expenses_management_other_periods],
                [selectedPeriodOfAccount.adjusted_caps[i].s407_tiopa_group_relief, 0, excelData.s407_tiopa_group_relief],
            ]
            
            for(let x=0;x<adjustments_potentialVariables.length;x++){
                if(adjustments_potentialVariables[x][0] !== undefined && adjustments_potentialVariables[x][0].value != null){
                    let value = typeof adjustments_potentialVariables[x][0]  === 'object' ? adjustments_potentialVariables[x][0].value : adjustments_potentialVariables[x][0]
                    adjustments_potentialVariables[x][2].push(adjustments_potentialVariables[x][0].value)
                }else{
                    if(adjustments_potentialVariables[x][0] !== undefined){
                        adjustments_potentialVariables[x][2].push(adjustments_potentialVariables[x][0])
                    }else{
                        adjustments_potentialVariables[x][2].push(adjustments_potentialVariables[x][1])
                    }     
                }                    
            }
            for(let c=0;c<savedData.company_accounting_periods.length;c++){
                if(savedData.company_accounting_periods[c].company_id===selectedPeriodOfAccount.adjusted_caps[i].company_id){
                    let calculate_ebitda = 
                        (selectedPeriodOfAccount.adjusted_caps[i].tax_ebitda.value ? selectedPeriodOfAccount.adjusted_caps[i].tax_ebitda.value : 0) - 
                        (savedData.company_accounting_periods[c].tax_ebitda ? savedData.company_accounting_periods[c].tax_ebitda : 0)
                    excelData.tax_ebitda_adjustments.push(calculate_ebitda) //ToDo - put this in.
                }
                break
            }         
            reliefs_potentialVariables = [
                [selectedPeriodOfAccount.adjusted_caps[i].s407_relief_rd_expenditure_credit, 0, excelData.s407_relief_rd_expenditure_credit],
                [selectedPeriodOfAccount.adjusted_caps[i].s407_relief_rd_additional_relief, 0, excelData.s407_relief_rd_additional_relief],
                [selectedPeriodOfAccount.adjusted_caps[i].s407_relief_trading_loss, 0, excelData.s407_relief_trading_loss],
                [selectedPeriodOfAccount.adjusted_caps[i].s407_relief_contaminated_land_relief, 0, excelData.s407_relief_contaminated_land_relief],
                [selectedPeriodOfAccount.adjusted_caps[i].s407_relief_creative_industries, 0, excelData.s407_relief_creative_industries],
                [selectedPeriodOfAccount.adjusted_caps[i].s407_relief_charitable_donation, 0, excelData.s407_relief_charitable_donation],
                [selectedPeriodOfAccount.adjusted_caps[i].s407_relief_patent_box_profits, 0, excelData.s407_relief_patent_box_profits],
                [selectedPeriodOfAccount.adjusted_caps[i].s407_relief_dtr_adjustment_tax_rate_equal_uk, 0, excelData.s407_relief_dtr_adjustment_tax_rate_equal_uk],
                [selectedPeriodOfAccount.adjusted_caps[i].s407_relief_adjustment_qualifying_infrastructure, 0, excelData.s407_relief_adjustment_qualifying_infrastructure],
                [selectedPeriodOfAccount.adjusted_caps[i].s407_relief_adjustment_gaap_items, 0, excelData.s407_relief_adjustment_gaap_items],
                [selectedPeriodOfAccount.adjusted_caps[i].s407_relief_finance_lease_depreciation, 0, excelData.s407_relief_finance_lease_depreciation],
                [selectedPeriodOfAccount.adjusted_caps[i].s407_relief_reversal_of_lease_depreciation, 0, excelData.s407_relief_reversal_of_lease_depreciation],
                [selectedPeriodOfAccount.adjusted_caps[i].s407_relief_long_funding_lease_deduction, 0, excelData.s407_relief_long_funding_lease_deduction],
                [selectedPeriodOfAccount.adjusted_caps[i].s407_relief_finance_lease_rentals_receivable, 0, excelData.s407_relief_finance_lease_rentals_receivable],
                [selectedPeriodOfAccount.adjusted_caps[i].s407_relief_long_funding_lessee, 0, excelData.s407_relief_long_funding_lessee],
                [selectedPeriodOfAccount.adjusted_caps[i].s407_relief_adjustment_misc_losses, 0, excelData.s407_relief_adjustment_misc_losses],
                [selectedPeriodOfAccount.adjusted_caps[i].s407_relief_group_relief, 0, excelData.s407_relief_group_relief],
                [selectedPeriodOfAccount.adjusted_caps[i].s407_relief_adjustment_disregarded_regulations, 0, excelData.s407_relief_adjustment_disregarded_regulations],
                [selectedPeriodOfAccount.adjusted_caps[i].s407_relief_other_adjustments_1, 0, excelData.s407_relief_other_adjustments_1], //ToDo - Check
                [selectedPeriodOfAccount.adjusted_caps[i].s407_relief_other_adjustments_2, 0, excelData.s407_relief_other_adjustments_2], //ToDo - Check
                [selectedPeriodOfAccount.adjusted_caps[i].s407_relief_other_adjustments_3, 0, excelData.s407_relief_other_adjustments_3], //ToDo - Check
                [selectedPeriodOfAccount.adjusted_caps[i].tax_ebitda, 0, excelData.tax_ebitda],
            ]
        
            for(let x=0;x<reliefs_potentialVariables.length;x++){
                if(reliefs_potentialVariables[x][0] !== undefined && reliefs_potentialVariables[x][0].value != null){
                    let value = typeof reliefs_potentialVariables[x][0]  === 'object' ? reliefs_potentialVariables[x][0].value : reliefs_potentialVariables[x][0]
                    reliefs_potentialVariables[x][2].push(value)
                }
                else if(reliefs_potentialVariables[x][0] !== undefined){
                    reliefs_potentialVariables[x][2].push(reliefs_potentialVariables[x][0])
                }
                else{
                    reliefs_potentialVariables[x][2].push(reliefs_potentialVariables[x][1])
                } 
            }
        
            dtr_potentialVariables = [
                [selectedPeriodOfAccount.adjusted_caps[i].net_dtr_adjustment_gbp, 0, excelData.net_dtr_adjustment_gbp],
                [selectedPeriodOfAccount.adjusted_caps[i].net_dtr_adjustment_lc, 0, excelData.net_dtr_adjustment_lc],
                [selectedPeriodOfAccount.adjusted_caps[i].corporation_tax_rate_percentage, 0, excelData.corporation_tax_rate_percentage],
                [selectedPeriodOfAccount.adjusted_caps[i].ct_rate_override, 0, excelData.ct_rate_override],
                [selectedPeriodOfAccount.adjusted_caps[i].s407_relief_dtr_adjustment_tax_rate_uk, 0, excelData.s407_relief_dtr_adjustment_tax_rate_uk],
            ]
        
        
            cir_potentialVariables = [
                [selectedPeriodOfAccount.adjusted_caps[i].non_consenting_company, "-", excelData.non_consenting_company],
                [selectedPeriodOfAccount.adjusted_caps[i].trade_small_or_negligible, "-", excelData.trade_small_or_negligible],
                [selectedPeriodOfAccount.adjusted_caps[i].trade_uncommercial_or_non_statutory, "-", excelData.trade_uncommercial_or_non_statutory],
                [selectedPeriodOfAccount.adjusted_caps[i].investment_business_ceased, "-", excelData.investment_business_ceased],
                [selectedPeriodOfAccount.adjusted_caps[i].cta10_change_in_ownership_rules, "-", excelData.cta10_change_in_ownership_rules],
            ]
            company_potentialVariables = [
                [selectedPeriodOfAccount.adjusted_caps[i].type_reit_company, "-", excelData.type_reit_company],
                [selectedPeriodOfAccount.adjusted_caps[i].type_ring_fence_company, "-", excelData.type_ring_fence_company],
                [selectedPeriodOfAccount.adjusted_caps[i].type_investment_manager, "-", excelData.type_investment_manager],
                [selectedPeriodOfAccount.adjusted_caps[i].type_insurance_company, "-", excelData.type_insurance_company],
                [selectedPeriodOfAccount.adjusted_caps[i].type_shipping_company, "-", excelData.type_shipping_company],
                [selectedPeriodOfAccount.adjusted_caps[i].type_cooperative_company, "-", excelData.type_cooperative_company],
                [selectedPeriodOfAccount.adjusted_caps[i].type_charity_company, "-", excelData.type_charity_company],
                [selectedPeriodOfAccount.adjusted_caps[i].type_banking_company, "-", excelData.type_banking_company],
            ]
            
            //True/False sections
            for(let x=0;x<cir_potentialVariables.length;x++){
                if(cir_potentialVariables[x][0] !== undefined){
                    if(cir_potentialVariables[x][0]){
                        cir_potentialVariables[x][2].push("Yes")
                    }else{
                        cir_potentialVariables[x][2].push("No")
                    }
                }else{
                    cir_potentialVariables[x][2].push(cir_potentialVariables[x][1])
                } 
            }
        
            for(let x=0;x<company_potentialVariables.length;x++){
                if(company_potentialVariables[x][0] !== undefined){
                    if(company_potentialVariables[x][0]){
                        company_potentialVariables[x][2].push("Yes")
                    }else{
                        company_potentialVariables[x][2].push("No")
                    }
                }else{
                    company_potentialVariables[x][2].push(company_potentialVariables[x][1])
                } 
            }

        }
    }

    fixed_ratio_variables = [
        [selectedPeriodOfAccount.aggregate_tax_ebitda, excelData.aggregate_ebitda],
        [(selectedPeriodOfAccount.aggregate_tax_ebitda) * 0.3, excelData.fixed_ratio],
        [selectedPeriodOfAccount.adjusted_net_group_interest_expense, excelData.angie],
        [selectedPeriodOfAccount.excess_debt_cap_from_prior_period, excelData.excess_debt],
        [selectedPeriodOfAccount.fr_debt_cap, excelData.fixed_debt],
        [selectedPeriodOfAccount.fr_basic_interest_allowance, excelData.basic_interest],
        [selectedPeriodOfAccount.aggregate_net_tax_interest_income, excelData.antii],
        [selectedPeriodOfAccount.fr_interest_allowance, excelData.interest_allowance],
    ]

    group_ratio_variables = [
        [selectedPeriodOfAccount.qualifying_net_group_interest_expense, excelData.qngie],
        [selectedPeriodOfAccount.group_ebitda, excelData.group_ebitda],
        [selectedPeriodOfAccount.gr_percentage, excelData.group_ratio],
        [selectedPeriodOfAccount.aggregate_tax_ebitda, excelData.aggregate_ebitda2],
        [(selectedPeriodOfAccount.gr_percentage) * (selectedPeriodOfAccount.aggregate_tax_ebitda), excelData.group_ratio_ebitda],
        [selectedPeriodOfAccount.gr_debt_cap, excelData.group_debt_cap],
        [selectedPeriodOfAccount.gr_basic_interest_allowance, excelData.group_basic_interest],
        [selectedPeriodOfAccount.gr_interest_allowance, excelData.group_interest_allowance],
    ]

    disallowed_variables = [
        [selectedPeriodOfAccount.apply_group_ratio_election, excelData.group_ratio_election],
        [selectedPeriodOfAccount.group_interest_allowance, excelData.computation_int_allowance],
        [selectedPeriodOfAccount.group_bf_interest_allowance, excelData.unused_allowance],
        [selectedPeriodOfAccount.interest_capacity_de_minimis, excelData.de_minimis],
        [selectedPeriodOfAccount.group_interest_capacity, excelData.interest_capacity],
        [selectedPeriodOfAccount.aggregate_net_tax_interest_expense, excelData.aggregate_interest_expense],
        [selectedPeriodOfAccount.group_disallowance, excelData.disallowed_amount],
        [selectedPeriodOfAccount.group_interest_reactivation_cap, excelData.available_reactivation],
    ]
    
    //CIR calculations
    for(let x=0;x<fixed_ratio_variables.length;x++){
        if(fixed_ratio_variables[x][0] !== undefined){
            fixed_ratio_variables[x][1].push(fixed_ratio_variables[x][0])
        }else{
            fixed_ratio_variables[x][1].push(0)
        } 
    }
    for(let x=0;x<group_ratio_variables.length;x++){
        if(group_ratio_variables[x][0] !== undefined){
            group_ratio_variables[x][1].push(group_ratio_variables[x][0])
        }else{
            group_ratio_variables[x][1].push(0)
        } 
    }
    for(let x=0;x<disallowed_variables.length;x++){
        if(disallowed_variables[x][0] !== undefined){
            if(disallowed_variables[x][0] === true){
                disallowed_variables[x][1].push("Yes")
            }else if(disallowed_variables[x][0] === false){
                disallowed_variables[x][1].push("No")
            }else{
                disallowed_variables[x][1].push(disallowed_variables[x][0])
            }
        }else{
            disallowed_variables[x][1].push(0)
            
        } 
    }

    unused_variables = [
        [selectedPeriodOfAccount.group_interest_allowance, 0, excelData.current_int_allowance],
        [selectedPeriodOfAccount.aggregate_net_tax_interest_expense, 0, excelData.current_tax_interest_expense],
    ]

    let angieorqngieEDC = "ANGIE or QNGIE"
    if (selectedPeriodOfAccount.ratio_type == "Group Ratio"){
        angieorqngieEDC = selectedPeriodOfAccount.qualifying_net_group_interest_expense
    }
    else if (selectedPeriodOfAccount.ratio_type == "Fixed Ratio"){
        angieorqngieEDC = selectedPeriodOfAccount.adjusted_net_group_interest_expense
    }
    else {angieorqngieEDC = 0}

    excess_debt_cap_variables = [
        [angieorqngieEDC, 0, excelData.angieorqngieEDC],
        [selectedPeriodOfAccount.excess_debt_cap_from_prior_period, 0, excelData.excess_debt_cap_from_prior_periodEDC],
        [selectedPeriodOfAccount.group_debt_cap, 0, excelData.group_debt_capEDC],
        [selectedPeriodOfAccount.group_debt_cap, 0, excelData.group_debt_capEDC2],
        [-1 * (selectedPeriodOfAccount.aggregate_tax_ebitda) * (selectedPeriodOfAccount.group_percentage), 0, excelData.group_percentage_tax_ebitdaEDC],
        [Math.max((selectedPeriodOfAccount.group_debt_cap) - ((selectedPeriodOfAccount.aggregate_tax_ebitda) * (selectedPeriodOfAccount.group_percentage)),0), 0, excelData.excess_debt_cap_generated_pre_cflimitEDC],
        [selectedPeriodOfAccount.group_disallowance, 0, excelData.group_disallowanceEDC],
        [(selectedPeriodOfAccount.excess_debt_cap_from_prior_period) + (selectedPeriodOfAccount.group_disallowance), 0, excelData.cflimitEDC],
        [selectedPeriodOfAccount.group_excess_debt_cap, 0, excelData.group_excess_debt_capEDC],
    ]
    
    //CIR attributes
    for(let x=0;x<unused_variables.length;x++){
        if(unused_variables[x][0] !== undefined){
            unused_variables[x][2].push(unused_variables[x][0])
        }else{
            unused_variables[x][2].push(unused_variables[x][1])
            
        } 
    }
    for(let x=0;x<excess_debt_cap_variables.length;x++){
        if(excess_debt_cap_variables[x][0] !== undefined){
            excess_debt_cap_variables[x][2].push(excess_debt_cap_variables[x][0])
        }else{
            excess_debt_cap_variables[x][2].push(excess_debt_cap_variables[x][1])
            
        } 
    }


    //output 4 - Accounting Periods
    const relevant_caps = get_relevant_periods(selectedPeriodOfAccount.period_start, selectedPeriodOfAccount.period_end, getGroupData());
    for(let i=0; i<relevant_caps.length; i++){
        excelData.apCompNames.push(relevant_caps[i].company_name)
        excelData.apStartDates.push(processDateJS(relevant_caps[i].start_date).format('DD/MM/YYYY'))
        excelData.apEndDates.push(processDateJS(relevant_caps[i].end_date).format('DD/MM/YYYY'))
        excelData.apFx.push(relevant_caps[i].fx_rate)
        excelData.apCurrency.push(relevant_caps[i].currency)
        
        let APPropNameNTDC = 'ntdc_exclude_derivatives_without_subject_matters_384(4)_s387(4)';
        let APPropNameTDC = 'tdc_exclude_derivatives_without_subject_matters_384(4)_s387(4)';

        AP_potentialVariables = [
            [relevant_caps[i].nltr_total_per_source_sheet_for_period, 0, excelData.APnltr_total_per_source_sheet_for_period],
            [relevant_caps[i].nltr_exclude_exchange_gains_losses, 0, excelData.APnltr_exclude_exchange_gains_losses],
            [relevant_caps[i].nltr_exclude_impairment_gains_losses, 0, excelData.APnltr_exclude_impairment_gains_losses],
            [relevant_caps[i].nltr_exclude_tax_interest_income_double_relief, 0, excelData.APnltr_exclude_tax_interest_income_double_relief],
            [relevant_caps[i].nltr_adjustment_amortised_cost_basis, 0, excelData.APnltr_adjustment_amortised_cost_basis],
            [relevant_caps[i].nltr_adjustment_qualifying_election, 0, excelData.APnltr_adjustment_qualifying_election],
            [relevant_caps[i].nltr_exclude_defined_benefit_pension, 0, excelData.APnltr_exclude_defined_benefit_pension],
            [relevant_caps[i].nltr_bank_expenses, 0, excelData.APnltr_bank_expenses],
            [relevant_caps[i].nltr_adjustment_gaap_transition, 0, excelData.APnltr_adjustment_gaap_transition],
            [relevant_caps[i].nltr_adjustment_lease_finance_cost, 0, excelData.APnltr_adjustment_lease_finance_cost],
            [relevant_caps[i].nltr_other_adjustments_1?.value??relevant_caps[i].nltr_other_adjustments_1, 0, excelData.APnltr_other_adjustments_1],
            [relevant_caps[i].nltr_other_adjustments_2?.value??relevant_caps[i].nltr_other_adjustments_2, 0, excelData.APnltr_other_adjustments_2],
            [relevant_caps[i].nltr_other_adjustments_3?.value??relevant_caps[i].nltr_other_adjustments_3, 0, excelData.APnltr_other_adjustments_3],
            [relevant_caps[i].ntlr_subtotal, 0, excelData.APntlr_subtotal],
            [relevant_caps[i].ntdc_total_per_source_sheet_for_period, 0, excelData.APntdc_total_per_source_sheet_for_period],
            [relevant_caps[i].ntdc_exclude_exchange_gains_losses, 0, excelData.APntdc_exclude_exchange_gains_losses],
            [relevant_caps[i].ntdc_exclude_impairment_gains_losses, 0, excelData.APntdc_exclude_impairment_gains_losses],
            [relevant_caps[i].ntdc_exclude_tax_interest_income_double_relief, 0, excelData.APntdc_exclude_tax_interest_income_double_relief],
            [relevant_caps[i].ntdc_exclude_ordinary_trade, 0, excelData.APntdc_exclude_ordinary_trade],
            [relevant_caps[i][APPropNameNTDC], 0, excelData.APntdc_exclude_derivatives_without_subject_matters_384387],
            [relevant_caps[i].ntdc_adjustment_qualifying_election, 0, excelData.APntdc_adjustment_qualifying_election],
            [relevant_caps[i].ntdc_adjustment_gaap_transition, 0, excelData.APntdc_adjustment_gaap_transition],
            [relevant_caps[i].ntdc_adjustment_disregard_regulations, 0, excelData.APntdc_adjustment_disregard_regulations],
            [relevant_caps[i].ntdc_other_adjustments_1?.value??relevant_caps[i].ntdc_other_adjustments_1, 0, excelData.APntdc_other_adjustments_1],
            [relevant_caps[i].ntdc_other_adjustments_2?.value??relevant_caps[i].ntdc_other_adjustments_2, 0, excelData.APntdc_other_adjustments_2],
            [relevant_caps[i].ntdc_other_adjustments_3?.value??relevant_caps[i].ntdc_other_adjustments_3, 0, excelData.APntdc_other_adjustments_3],
            [relevant_caps[i].ntdc_subtotal, 0, excelData.APntdc_subtotal],
            [relevant_caps[i].tlr_total_per_source_sheet_for_period, 0, excelData.APtlr_total_per_source_sheet_for_period],
            [relevant_caps[i].tlr_exclude_exchange_gains_losses, 0, excelData.APtlr_exclude_exchange_gains_losses],
            [relevant_caps[i].tlr_exclude_impairment_gains_losses, 0, excelData.APtlr_exclude_impairment_gains_losses],
            [relevant_caps[i].tlr_exclude_tax_interest_income_double_relief, 0, excelData.APtlr_exclude_tax_interest_income_double_relief],
            [relevant_caps[i].tlr_adjustment_amortised_cost_basis, 0, excelData.APtlr_adjustment_amortised_cost_basis],
            [relevant_caps[i].tlr_adjustment_qualifying_election, 0, excelData.APtlr_adjustment_qualifying_election],
            [relevant_caps[i].tlr_exclude_defined_benefit_pension, 0, excelData.APtlr_exclude_defined_benefit_pension],
            [relevant_caps[i].tlr_bank_expenses, 0, excelData.APtlr_bank_expenses],
            [relevant_caps[i].tlr_adjustment_gaap_transition, 0, excelData.APtlr_adjustment_gaap_transition],
            [relevant_caps[i].tlr_adjustment_lease_finance_cost, 0, excelData.APtlr_adjustment_lease_finance_cost],
            [relevant_caps[i].tlr_other_adjustments_1?.value??relevant_caps[i].tlr_other_adjustments_1, 0, excelData.APtlr_other_adjustments_1],
            [relevant_caps[i].tlr_other_adjustments_2?.value??relevant_caps[i].tlr_other_adjustments_2, 0, excelData.APtlr_other_adjustments_2],
            [relevant_caps[i].tlr_other_adjustments_3?.value??relevant_caps[i].tlr_other_adjustments_3, 0, excelData.APtlr_other_adjustments_3],
            [relevant_caps[i].tlr_subtotal, 0, excelData.APtlr_subtotal],
            [relevant_caps[i].tdc_total_per_source_sheet_for_period, 0, excelData.APtdc_total_per_source_sheet_for_period],
            [relevant_caps[i].tdc_exclude_exchange_gains_losses, 0, excelData.APtdc_exclude_exchange_gains_losses],
            [relevant_caps[i].tdc_exclude_impairment_gains_losses, 0, excelData.APtdc_exclude_impairment_gains_losses],
            [relevant_caps[i].tdc_exclude_tax_interest_income_double_relief, 0, excelData.APtdc_exclude_tax_interest_income_double_relief],
            [relevant_caps[i].tdc_exclude_ordinary_trade, 0, excelData.APtdc_exclude_ordinary_trade],
            [relevant_caps[i][APPropNameTDC], 0, excelData.APtdc_exclude_derivatives_without_subject_matters_384387],
            [relevant_caps[i].tdc_adjustment_qualifying_election, 0, excelData.APtdc_adjustment_qualifying_election],
            [relevant_caps[i].tdc_adjustment_gaap_transition, 0, excelData.APtdc_adjustment_gaap_transition],
            [relevant_caps[i].tdc_adjustment_disregard_regulations, 0, excelData.APtdc_adjustment_disregard_regulations],
            [relevant_caps[i].tdc_other_adjustments_1?.value??relevant_caps[i].tdc_other_adjustments_1, 0, excelData.APtdc_other_adjustments_1],
            [relevant_caps[i].tdc_other_adjustments_2?.value??relevant_caps[i].tdc_other_adjustments_2, 0, excelData.APtdc_other_adjustments_2],
            [relevant_caps[i].tdc_other_adjustments_3?.value??relevant_caps[i].tdc_other_adjustments_3, 0, excelData.APtdc_other_adjustments_3],
            [relevant_caps[i].tdc_subtotal, 0, excelData.APtdc_subtotal],
            [relevant_caps[i].ofa_total_per_source_sheet_for_period, 0, excelData.APofa_total_per_source_sheet_for_period],
            [relevant_caps[i].ofa_exclude_tax_interest_income_double_relief, 0, excelData.APofa_exclude_tax_interest_income_double_relief],
            [relevant_caps[i].ofa_adjustment_qualifying_election, 0, excelData.APofa_adjustment_qualifying_election],
            [relevant_caps[i].ofa_adjustment_gaap_transition, 0, excelData.APofa_adjustment_gaap_transition],
            [relevant_caps[i].ofa_adjustment_finance_lease, 0, excelData.APofa_adjustment_finance_lease],
            [relevant_caps[i].ofa_adjustment_debt_factoring, 0, excelData.APofa_adjustment_debt_factoring],
            [relevant_caps[i].ofa_adjustment_service_concession, 0, excelData.APofa_adjustment_service_concession],
            [relevant_caps[i].ofa_other_adjustments_1?.value??relevant_caps[i].ofa_other_adjustments_1, 0, excelData.APofa_other_adjustments_1],
            [relevant_caps[i].ofa_other_adjustments_2?.value??relevant_caps[i].ofa_other_adjustments_2, 0, excelData.APofa_other_adjustments_2],
            [relevant_caps[i].ofa_other_adjustments_3?.value??relevant_caps[i].ofa_other_adjustments_3, 0, excelData.APofa_other_adjustments_3],
            [relevant_caps[i].ofa_subtotal, 0, excelData.APofa_subtotal],
            [relevant_caps[i].ofa_subtotal + relevant_caps[i].tdc_subtotal + relevant_caps[i].tlr_subtotal + relevant_caps[i].ntdc_subtotal + relevant_caps[i].ntlr_subtotal, 0, excelData.APnet_tax_interest_totals,],
            [relevant_caps[i].cte_condition_a, 0, excelData.APcte_condition_a],
            [relevant_caps[i].cte_condition_b, 0, excelData.APcte_condition_b],
            [relevant_caps[i].cte_restrictions_reactivations_included, 0, excelData.APcte_restrictions_reactivations_included],
            [relevant_caps[i].tebitda_adjustments, 0, excelData.APtebitda_adjustments],
            [relevant_caps[i].cte_subtotal, 0, excelData.APcte_subtotal],
            [relevant_caps[i].ofa_subtotal + relevant_caps[i].tdc_subtotal + relevant_caps[i].tlr_subtotal + relevant_caps[i].ntdc_subtotal + relevant_caps[i].ntlr_subtotal, 0, excelData.APs407_tiopa_net_tax_interest_expense],
            [relevant_caps[i].s407_tiopa_capital_allowance_charges, 0, excelData.APs407_tiopa_capital_allowance_charges],
            [relevant_caps[i].s407_tiopa_relevant_intangible_debits_credits, 0, excelData.APs407_tiopa_relevant_intangible_debits_credits],
            [relevant_caps[i].s407_tiopa_adjustment_intangible_debit_credits, 0, excelData.APs407_tiopa_adjustment_intangible_debit_credits],
            [relevant_caps[i].s407_tiopa_other_period_losses, 0, excelData.APs407_tiopa_other_period_losses],
            [relevant_caps[i].s407_tiopa_loan_relationship_deficts_other_periods, 0, excelData.APs407_tiopa_loan_relationship_deficts_other_periods],
            [relevant_caps[i].s407_tiopa_expenses_management_other_periods, 0, excelData.APs407_tiopa_expenses_management_other_periods],
            [relevant_caps[i].s407_tiopa_group_relief, 0, excelData.APs407_tiopa_group_relief],
            [relevant_caps[i].s407_relief_rd_expenditure_credit, 0, excelData.APs407_relief_rd_expenditure_credit],
            [relevant_caps[i].s407_relief_rd_additional_relief, 0, excelData.APs407_relief_rd_additional_relief],
            [relevant_caps[i].s407_relief_trading_loss, 0, excelData.APs407_relief_trading_loss],
            [relevant_caps[i].s407_relief_contaminated_land_relief, 0, excelData.APs407_relief_contaminated_land_relief],
            [relevant_caps[i].s407_relief_creative_industries, 0, excelData.APs407_relief_creative_industries],
            [relevant_caps[i].s407_relief_charitable_donation, 0, excelData.APs407_relief_charitable_donation],
            [relevant_caps[i].s407_relief_patent_box_profits, 0, excelData.APs407_relief_patent_box_profits],
            [relevant_caps[i].s407_relief_dtr_adjustment_tax_rate_equal_uk, 0, excelData.APs407_relief_dtr_adjustment_tax_rate_equal_uk],
            [relevant_caps[i].s407_relief_adjustment_qualifying_infrastructure, 0, excelData.APs407_relief_adjustment_qualifying_infrastructure],
            [relevant_caps[i].s407_relief_adjustment_gaap_items, 0, excelData.APs407_relief_adjustment_gaap_items],
            [relevant_caps[i].s407_relief_finance_lease_depreciation, 0, excelData.APs407_relief_finance_lease_depreciation],
            [relevant_caps[i].s407_relief_reversal_of_lease_depreciation, 0, excelData.APs407_relief_reversal_of_lease_depreciation],
            [relevant_caps[i].s407_relief_long_funding_lease_deduction, 0, excelData.APs407_relief_long_funding_lease_deduction],
            [relevant_caps[i].s407_relief_finance_lease_rentals_receivable, 0, excelData.APs407_relief_finance_lease_rentals_receivable],
            [relevant_caps[i].s407_relief_long_funding_lessee, 0, excelData.APs407_relief_long_funding_lessee],
            [relevant_caps[i].s407_relief_adjustment_misc_losses, 0, excelData.APs407_relief_adjustment_misc_losses],
            [relevant_caps[i].s407_relief_group_relief, 0, excelData.APs407_relief_group_relief],
            [relevant_caps[i].s407_relief_adjustment_disregarded_regulations, 0, excelData.APs407_relief_adjustment_disregarded_regulations],
            [relevant_caps[i].s407_relief_other_adjustments_1, 0, excelData.APs407_relief_other_adjustments_1],
            [relevant_caps[i].s407_relief_other_adjustments_2, 0, excelData.APs407_relief_other_adjustments_2],
            [relevant_caps[i].s407_relief_other_adjustments_3, 0, excelData.APs407_relief_other_adjustments_3],
            [relevant_caps[i].tax_ebitda, 0, excelData.APtax_ebitda],        
            ]   
        for(let x=0;x<AP_potentialVariables.length;x++){
            if(AP_potentialVariables[x][0] !== undefined){
                AP_potentialVariables[x][2].push(AP_potentialVariables[x][0])
                }
            else{
                AP_potentialVariables[x][2].push(AP_potentialVariables[x][1])
            }
        }
    }



    
    const output1 = [excelData.compNames, 
        excelData.APSD,
        excelData.APED,
        excelData.period_length,
        excelData.date_joined,
        excelData.date_lefted,
        excelData.regarded_period,
        excelData.disregarded_percent,
        [],
        excelData.currency,
        excelData.fx,
        [],
        [ "Net tax-interest expense/(income) section"],
        [],
        ["Non-trading loan relationships"],
        excelData.nltr_total_per_source_sheet_for_period,
        excelData.note_1,
        excelData.nltr_exclude_exchange_gains_losses,
        excelData.nltr_exclude_impairment_gains_losses,
        excelData.nltr_exclude_tax_interest_income_double_relief,
        excelData.nltr_adjustment_amortised_cost_basis,
        excelData.nltr_adjustment_qualifying_election,
        excelData.nltr_exclude_defined_benefit_pension,
        excelData.nltr_bank_expenses,
        excelData.nltr_adjustment_gaap_transition,
        excelData.nltr_adjustment_lease_finance_cost,
        excelData.nltr_other_adjustments_1,
        excelData.nltr_other_adjustments_2,
        excelData.nltr_other_adjustments_3,
        excelData.ntlr_subtotal,
        [],
        ["Non-trading derivative contracts"],
        excelData.ntdc_total_per_source_sheet_for_period,
        excelData.note_1,
        excelData.ntdc_exclude_exchange_gains_losses,
        excelData.ntdc_exclude_impairment_gains_losses,
        excelData.ntdc_exclude_tax_interest_income_double_relief,
        excelData.ntdc_exclude_ordinary_trade,
        excelData["ntdc_exclude_derivatives_without_subject_matters_384(4)_s387(4)"],
        excelData.ntdc_adjustment_qualifying_election,
        excelData.ntdc_adjustment_gaap_transition,
        excelData.ntdc_adjustment_disregard_regulations,
        excelData.ntdc_other_adjustments_1,
        excelData.ntdc_other_adjustments_2,
        excelData.ntdc_other_adjustments_3,
        excelData.ntdc_subtotal,
        [],
        [ "Trading loan relationships"],
        excelData.tlr_total_per_source_sheet_for_period,
        excelData.note_1,
        excelData.tlr_exclude_exchange_gains_losses,
        excelData.tlr_exclude_impairment_gains_losses,
        excelData.tlr_exclude_tax_interest_income_double_relief,
        excelData.tlr_adjustment_amortised_cost_basis,
        excelData.tlr_adjustment_qualifying_election,
        excelData.tlr_exclude_defined_benefit_pension,
        excelData.tlr_bank_expenses,
        excelData.tlr_adjustment_gaap_transition,
        excelData.tlr_adjustment_lease_finance_cost,
        excelData.tlr_other_adjustments_1,
        excelData.tlr_other_adjustments_2,
        excelData.tlr_other_adjustments_3,
        excelData.tlr_subtotal,
        [],
        ["Trading derivative contracts"],
        excelData.tdc_total_per_source_sheet_for_period,
        excelData.note_1,
        excelData.tdc_exclude_exchange_gains_losses,
        excelData.tdc_exclude_impairment_gains_losses,
        excelData.tdc_exclude_tax_interest_income_double_relief,
        excelData.tdc_exclude_ordinary_trade,
        excelData["tdc_exclude_derivatives_without_subject_matters_384(4)_s387(4)"],
        excelData.tdc_adjustment_qualifying_election,
        excelData.tdc_adjustment_gaap_transition,
        excelData.tdc_adjustment_disregard_regulations,
        excelData.tdc_other_adjustments_1,
        excelData.tdc_other_adjustments_2,
        excelData.tdc_other_adjustments_3,
        excelData.tdc_subtotal,
        [],
        ["Other financing arrangements"],
        excelData.ofa_total_per_source_sheet_for_period,
        excelData.note_1,
        excelData.ofa_exclude_tax_interest_income_double_relief,
        excelData.ofa_adjustment_qualifying_election,
        excelData.ofa_adjustment_gaap_transition,
        excelData.ofa_adjustment_finance_lease,
        excelData.ofa_adjustment_debt_factoring,
        excelData.ofa_adjustment_service_concession,
        excelData.ofa_other_adjustments_1,
        excelData.ofa_other_adjustments_2,
        excelData.ofa_other_adjustments_3,
        excelData.ofa_subtotal,
        [],
        excelData.net_tax_interest_totals,
        [],
        [],
        ["Tax-EBITDA section"],  
        excelData.cte_condition_a,
        excelData.cte_condition_b,
        excelData.cte_restrictions_reactivations_included,
        excelData.tebitda_adjustments,
        excelData.cte_subtotal,
        [],
        ["Adjustments - s407 TIOPA 2010"],
        excelData.s407_tiopa_net_tax_interest_expense,
        excelData.s407_tiopa_capital_allowance_charges,
        excelData.s407_tiopa_relevant_intangible_debits_credits,
        excelData.s407_tiopa_adjustment_intangible_debit_credits,
        excelData.s407_tiopa_other_period_losses,
        excelData.s407_tiopa_loan_relationship_deficts_other_periods,
        excelData.s407_tiopa_expenses_management_other_periods,
        excelData.s407_tiopa_group_relief,
        excelData.s407_relief_rd_expenditure_credit,
        excelData.s407_relief_rd_additional_relief,
        excelData.s407_relief_trading_loss,
        excelData.s407_relief_contaminated_land_relief,
        excelData.s407_relief_creative_industries,
        excelData.s407_relief_charitable_donation,
        excelData.s407_relief_patent_box_profits,
        excelData.s407_relief_dtr_adjustment_tax_rate_equal_uk,
        excelData.s407_relief_adjustment_qualifying_infrastructure,
        excelData.s407_relief_adjustment_gaap_items,
        excelData.s407_relief_finance_lease_depreciation,
        excelData.s407_relief_reversal_of_lease_depreciation,
        excelData.s407_relief_long_funding_lease_deduction,
        excelData.s407_relief_finance_lease_rentals_receivable,
        excelData.s407_relief_long_funding_lessee,
        excelData.s407_relief_adjustment_misc_losses,
        excelData.s407_relief_group_relief,
        excelData.s407_relief_adjustment_disregarded_regulations,
        excelData.s407_relief_other_adjustments_1,
        excelData.s407_relief_other_adjustments_2,
        excelData.s407_relief_other_adjustments_3,
        excelData.tax_ebitda,
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        ["CIR specific attributes"],
        excelData.non_consenting_company,
        excelData.trade_small_or_negligible,
        excelData.trade_uncommercial_or_non_statutory,
        excelData.investment_business_ceased,
        excelData.cta10_change_in_ownership_rules,
        [],
        ["Company Type"],
        excelData.type_reit_company,
        excelData.type_ring_fence_company,
        excelData.type_investment_manager,
        excelData.type_insurance_company,
        excelData.type_shipping_company,
        excelData.type_cooperative_company,
        excelData.type_charity_company,
        excelData.type_banking_company,
    ]
    const group_ratio_election_Check = (value)=>savedData.periods_of_account[getPoaIndex()].group_ratio_election?value:[...value?.slice(0,-1),'N/A']

    const output2 = [
        ["Fixed Ratio Computation"],
        ["","","GBP"],
        excelData.aggregate_ebitda,
        excelData.fixed_ratio,
        [],
        excelData.angie,
        excelData.excess_debt,
        excelData.fixed_debt,
        [],
        excelData.basic_interest,
        excelData.antii,
        excelData.interest_allowance,
        [],
        [],
        ["Group Ratio Computation"],
        ["","","GBP"],
        group_ratio_election_Check(excelData.qngie),
        group_ratio_election_Check(excelData.group_ebitda),
        group_ratio_election_Check(excelData.group_ratio),
        [],
        group_ratio_election_Check(excelData.aggregate_ebitda2),
        group_ratio_election_Check(excelData.group_ratio_ebitda),
        [],
        group_ratio_election_Check(excelData.qngie),
        group_ratio_election_Check(excelData.excess_debt2),
        group_ratio_election_Check(excelData.group_debt_cap),
        [],
        group_ratio_election_Check(excelData.group_basic_interest),
        group_ratio_election_Check(excelData.antii),
        group_ratio_election_Check(excelData.group_interest_allowance),
        [],
        [],
        ["Computation of Disallowance/reactivation"],
        [],
        [],
        excelData.group_ratio_election,
        ["","","GBP"],
        excelData.computation_int_allowance,
        excelData.unused_allowance,
        [],
        excelData.de_minimis,
        [],
        excelData.interest_capacity,
        [],
        excelData.aggregate_interest_expense,
        [],
        excelData.disallowed_amount,
        [],
        excelData.available_reactivation,
        [],
    ]

    const output3 = [
        ["Unused Interest Allowance (s394)"],
         
        ["","","GBP"],
        [],
        excelData.current_int_allowance,
        [],
        excelData.current_tax_interest_expense,
        excelData.reactivations,
        excelData.current_yr_interest,
        [],
        excelData.unused_interest_gen,
        excelData.unused_interest_bf,
        [],
        [],
        ["Excess Debt Cap (s400)"],
        ["","","GBP"],
        excelData.excess_debt_cap_from_prior_periodEDC,
        excelData.angieorqngieEDC,
        excelData.group_debt_capEDC,
        [],
        excelData.group_debt_capEDC2,
        excelData.group_percentage_tax_ebitdaEDC,
        excelData.excess_debt_cap_generated_pre_cflimitEDC,
        [],
        excelData.excess_debt_cap_from_prior_periodEDC,
        excelData.group_disallowanceEDC,
        excelData.cflimitEDC,
        [],
        excelData.group_excess_debt_capEDC,
        [],
        [],
        ["This document has been prepared only for [client name] and solely for the purpose and on the terms agreed with [client name]. We accept no liability (including for negligence) to anyone else in connection with this document, and it may not be provided to anyone else."],
        ["If you receive a request under freedom of information legislation to disclose any information we provided to you, you will consult with us promptly before any disclosure."],
        ["[This is a draft prepared for discussion purposes only and should not be relied upon; the contents are subject to amendment or withdrawal and our final conclusions and findings will be set out in our final deliverable.]"],
        [message],
    ]

    const output4 
    = [excelData.apCompNames, 
        excelData.apStartDates,
        excelData.apEndDates,
        [],
        [],
        [],
        [],
        [],
        [],
        excelData.apCurrency,
        excelData.apFx,
        [],
        [ "Net tax-interest expense/(income) section"],
        [],
        ["Non-trading loan relationships"],
        excelData.APnltr_total_per_source_sheet_for_period,
        excelData.note_1,
        excelData.APnltr_exclude_exchange_gains_losses,
        excelData.APnltr_exclude_impairment_gains_losses,
        excelData.APnltr_exclude_tax_interest_income_double_relief,
        excelData.APnltr_adjustment_amortised_cost_basis,
        excelData.APnltr_adjustment_qualifying_election,
        excelData.APnltr_exclude_defined_benefit_pension,
        excelData.APnltr_bank_expenses,
        excelData.APnltr_adjustment_gaap_transition,
        excelData.APnltr_adjustment_lease_finance_cost,
        excelData.APnltr_other_adjustments_1,
        excelData.APnltr_other_adjustments_2,
        excelData.APnltr_other_adjustments_3,
        excelData.APntlr_subtotal,
        [],
        ["Non-trading derivative contracts"],
        excelData.APntdc_total_per_source_sheet_for_period,
        excelData.note_1,
        excelData.APntdc_exclude_exchange_gains_losses,
        excelData.APntdc_exclude_impairment_gains_losses,
        excelData.APntdc_exclude_tax_interest_income_double_relief,
        excelData.APntdc_exclude_ordinary_trade,
        excelData.APntdc_exclude_derivatives_without_subject_matters_384387,
        excelData.APntdc_adjustment_qualifying_election,
        excelData.APntdc_adjustment_gaap_transition,
        excelData.APntdc_adjustment_disregard_regulations,
        excelData.APntdc_other_adjustments_1,
        excelData.APntdc_other_adjustments_2,
        excelData.APntdc_other_adjustments_3,
        excelData.APntdc_subtotal,
        [],
        [ "Trading loan relationships"],
        excelData.APtlr_total_per_source_sheet_for_period,
        excelData.note_1,
        excelData.APtlr_exclude_exchange_gains_losses,
        excelData.APtlr_exclude_impairment_gains_losses,
        excelData.APtlr_exclude_tax_interest_income_double_relief,
        excelData.APtlr_adjustment_amortised_cost_basis,
        excelData.APtlr_adjustment_qualifying_election,
        excelData.APtlr_exclude_defined_benefit_pension,
        excelData.APtlr_bank_expenses,
        excelData.APtlr_adjustment_gaap_transition,
        excelData.APtlr_adjustment_lease_finance_cost,
        excelData.APtlr_other_adjustments_1,
        excelData.APtlr_other_adjustments_2,
        excelData.APtlr_other_adjustments_3,
        excelData.APtlr_subtotal,
        [],
        ["Trading derivative contracts"],
        excelData.APtdc_total_per_source_sheet_for_period,
        excelData.note_1,
        excelData.APtdc_exclude_exchange_gains_losses,
        excelData.APtdc_exclude_impairment_gains_losses,
        excelData.APtdc_exclude_tax_interest_income_double_relief,
        excelData.APtdc_exclude_ordinary_trade,
        excelData.APtdc_exclude_derivatives_without_subject_matters_384387,
        excelData.APtdc_adjustment_qualifying_election,
        excelData.APtdc_adjustment_gaap_transition,
        excelData.APtdc_adjustment_disregard_regulations,
        excelData.APtdc_other_adjustments_1,
        excelData.APtdc_other_adjustments_2,
        excelData.APtdc_other_adjustments_3,
        excelData.APtdc_subtotal,
        [],
        ["Other financing arrangements"],
        excelData.APofa_total_per_source_sheet_for_period,
        excelData.note_1,
        excelData.APofa_exclude_tax_interest_income_double_relief,
        excelData.APofa_adjustment_qualifying_election,
        excelData.APofa_adjustment_gaap_transition,
        excelData.APofa_adjustment_finance_lease,
        excelData.APofa_adjustment_debt_factoring,
        excelData.APofa_adjustment_service_concession,
        excelData.APofa_other_adjustments_1,
        excelData.APofa_other_adjustments_2,
        excelData.APofa_other_adjustments_3,
        excelData.APofa_subtotal,
        [],
        excelData.APnet_tax_interest_totals,
        [],
        [],
        ["Tax-EBITDA section"],  
        excelData.APcte_condition_a,
        excelData.APcte_condition_b,
        excelData.APcte_restrictions_reactivations_included,
        excelData.APtebitda_adjustments,
        excelData.APcte_subtotal,
        [],
        ["Adjustments - s407 TIOPA 2010"],
        excelData.APnet_tax_interest_totals,
        excelData.APs407_tiopa_capital_allowance_charges,
        excelData.APs407_tiopa_relevant_intangible_debits_credits,
        excelData.APs407_tiopa_adjustment_intangible_debit_credits,
        excelData.APs407_tiopa_other_period_losses,
        excelData.APs407_tiopa_loan_relationship_deficts_other_periods,
        excelData.APs407_tiopa_expenses_management_other_periods,
        excelData.APs407_tiopa_group_relief,
        excelData.APs407_relief_rd_expenditure_credit,
        excelData.APs407_relief_rd_additional_relief,
        excelData.APs407_relief_trading_loss,
        excelData.APs407_relief_contaminated_land_relief,
        excelData.APs407_relief_creative_industries,
        excelData.APs407_relief_charitable_donation,
        excelData.APs407_relief_patent_box_profits,
        excelData.APs407_relief_dtr_adjustment_tax_rate_equal_uk,
        excelData.APs407_relief_adjustment_qualifying_infrastructure,
        excelData.APs407_relief_adjustment_gaap_items,
        excelData.APs407_relief_finance_lease_depreciation,
        excelData.APs407_relief_reversal_of_lease_depreciation,
        excelData.APs407_relief_long_funding_lease_deduction,
        excelData.APs407_relief_finance_lease_rentals_receivable,
        excelData.APs407_relief_long_funding_lessee,
        excelData.APs407_relief_adjustment_misc_losses,
        excelData.APs407_relief_group_relief,
        excelData.APs407_relief_adjustment_disregarded_regulations,
        excelData.APs407_relief_other_adjustments_1,
        excelData.APs407_relief_other_adjustments_2,
        excelData.APs407_relief_other_adjustments_3,
        excelData.APtax_ebitda,
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
 /*       ["CIR specific attributes"],
        excelData.non_consenting_company,
        excelData.trade_small_or_negligible,
        excelData.trade_uncommercial_or_non_statutory,
        excelData.investment_business_ceased,
        excelData.cta10_change_in_ownership_rules,
        [],
        ["Company Type"],
        excelData.type_reit_company,
        excelData.type_ring_fence_company,
        excelData.type_investment_manager,
        excelData.type_insurance_company,
        excelData.type_shipping_company,
        excelData.type_cooperative_company,
        excelData.type_charity_company,
        excelData.type_banking_company,*/
    ]

return [output1, output2,output3,output4,companies]
}