import { validReturnTypes } from "../pages/GroupPOA/GroupPOA";
import { processDateJS } from "../utils/dateProcessor";
import { getChargeData, postCharge } from "./sqldatabase/sqldatabase.service";

const getChargeByReturnStatus = (returnStatus, noUkCompanies) => {
  const { type, isRevised } = returnStatus;

  if ( type === validReturnTypes.abbreviated )
    return 250;
  else if ( type === validReturnTypes.full ){
    if ( isRevised ) 
      return 500;
    else {
      if (noUkCompanies <= 5) return 800;
      else if ( noUkCompanies <= 15) return 1100;
      else if ( noUkCompanies <= 50 ) return 1600;
      else return 2300;
    }
  }

  throw `Unhandled return status ${JSON.stringify(returnStatus)}`;
}

export async function addCharge( chargeType, accountingPeriods, wbsCode, { period_start, period_end }, groupData ) {
  if ( ! chargeType?.length && ! ["fixed", "variable"].includes(chargeType.toLowerCase()) ){
    throw new Error("Error: Missing charge type");
  }
  chargeType = chargeType.toLowerCase();
  
  if ( ! accountingPeriods ){
    throw new Error("Error: Missing accounting periods");
  }
  
  if ( ! wbsCode?.length ){
    throw new Error("Error: Missing wbs code");
  }

  const selectedPeriod = groupData.periods_of_account?.find((p) => processDateJS(p?.period_start)?.isSame(period_start, 'day') && processDateJS(p?.period_end)?.isSame(period_end, 'day'))
  if ( ! selectedPeriod ){
    throw new Error(`Error: Selected period not in group data ${period_start},${ period_end}`);
  }
  const returnStatus = selectedPeriod.return_status

  const number_of_onesource_companies = accountingPeriods
    .filter( cap => cap.onesource_sync )
    .filter( (v, i, arr) => arr.indexOf( v ) === i )
    .length;

  const number_of_uk_companies = groupData.companies
    .filter( c => c.uk_group_company )
    .length;

  try {
    const { sumCharges, maxNoCompanies } = await getChargeData( groupData.group_name, period_end, chargeType );
    
    let incremental_charge = 0;
    if ( chargeType == "variable" ){
      incremental_charge = Math.max( (Math.max( maxNoCompanies, number_of_onesource_companies ) * 75) - sumCharges, 0 );

    } else if ( chargeType == "fixed" ){
      let returnTypeCharge = getChargeByReturnStatus( returnStatus, number_of_uk_companies );
      incremental_charge = Math.max( returnTypeCharge - sumCharges, 0 );
    }

    return postCharge({
      charge_type: chargeType,
      group_name: groupData.group_name,
      number_of_onesource_companies,
      number_of_uk_companies,
      poa_start: period_start,
      poa_end: period_end,
      wbs_code: wbsCode,
      incremental_charge,
      return_type: returnStatus.type
    });

  } catch ( e ){
    console.log(e);
  }
  
}