import { Panel, Loading, Table, Column, Notice } from '@appkit4/react-components';
import { Button } from "../../components/ReadonlyAwareInputs";
import { useContext, useEffect, useState } from 'react';
import { NextButton } from '../../components/ProgressNav';
import { getGroupName } from '../../services/GroupContext';
import NewUserModal from './NewUserModal';
import { AccessRoles } from '../../utils/AccessRoles';
import { AuthContext } from '../../services/AuthProvider';
import sqlService from '../../services/sqldatabase/sqldatabase.service';
import toast from '../../components/DismissibleToast';

const UserAccess = () => {
    const [isLoading, setIsLoading] = useState(false)
    const [isPosting, setIsPosting] = useState(false);
    const auth = useContext(AuthContext);
    const [users, setUsers] = useState([])
    const [showAddUserModal, setShowAddUserModal] = useState(false)

    useEffect(() => {
        setIsLoading(true);
        const getUsers = async () => {
            const response = await sqlService?.getGroupUsers(getGroupName());
            if (response?.status === 200) {
                setUsers(response?.data);
            }
            else if (response?.status === 404) {
                toast.error(`Group ${getGroupName()} not found`);
            }
            else {
                toast.error('Could not fetch users')
            }
            setIsLoading(false);
        }
        getUsers();
    }, [])

    const addUser = (email, role) => {
        if (!email) {
        throw new Error('no email provided');
        }

        const isEmail = (email) => {
        const emailFormat = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;
        return (!!email?.match(emailFormat));
        };
        if (!isEmail(email)) {
        throw new Error('email provided is invalid')
        }

        if (!role) {
        throw new Error('no role provided');
        }

        if (!AccessRoles()?.includes(role)) {
        throw new Error('not a valid role')
        }


        const newUsers = [...users];
        newUsers?.push({
            email: email,
            role: role
            })
        setUsers(newUsers);
    }

    const deleteUser = (email) => {
        const index = users?.findIndex((user) => user?.email === email);
        if (index > -1) {
        const tempUsers = [...users];
        tempUsers?.splice(index, 1);
        setUsers(tempUsers);
        }
    }

    const submit = () => {
        if (!auth?.userCanManageAccess(getGroupName())) {
            return;
        }
        setIsPosting(true);
        let newGroupUsers = users?.map(user => {
            return {email: user?.email, role: user?.role}
        });
        const result = sqlService?.postGroupUsers(getGroupName(), newGroupUsers);
        return toast.promise(result, {
            loading: 'Saving group access...',
            success: () => {
                setIsPosting(false);
                return 'Saved group access';
            },
            error: 'Failed to save group configuration!',
        })
    }

    if (isLoading) {
        return (
            <Loading
                loadingType="linear"
                indeterminate={true}
            compact={false}
                className="page-loader"
            />
        );
    }
    return (
        <div className="ap-container">
            { !auth?.userCanManageAccess(getGroupName()) &&
                <div className='container-fluid'>
                    <Notice
                        message="You don't have permissions to modify user roles, changes will not be saved."
                        status='icon-warning-fill'
                        closeable={false}
                        style={{backgroundColor: "white", width: "100%"}}
                    />
                </div>
            }
            <Panel title={`Manage Access`} className='mt-4 mb-4'>
                <p>This page allows the user to grant, revoke or change access to the CIR Analyser for the group. You must select one of the following roles, depending on the level of access you wish to grant to the user:</p>
                <ul>
                    <li>Reader - view only access</li>
                    <li>Editor - view and edit access</li>
                    <li>Supervisor - view, edit and access management</li>
                </ul>
            </Panel>
            <Panel title={`Manage Access`}>
                <div className='d-flex justify-content-end ap-container mt-4 mb-4'>
                    <Button kind='primary' icon="icon-plus-outline" onClick={() => setShowAddUserModal(true)}>Add user</Button>
                    <NewUserModal
                        visible={showAddUserModal}
                        setVisible={setShowAddUserModal}
                        addUser={addUser}
                        existingUsers={users}
                    ></NewUserModal>
                </div>

                <Table originalData={users} hasTitle>
                    <Column field='email' >Email</Column>
                    <Column field='role' >Access Level</Column>
                    <Column field='email' style={{ width: 50 }} renderCell={(row) => <><Button kind='secondary' style={{ width: 35 }} compact onClick={() => deleteUser(row?.email)}><span className="Appkit4-icon icon-close-outline"></span></Button></>}></Column>
                </Table>

                <div className='d-flex justify-content-end ap-container mt-4 mb-4'>
                    <NextButton loading={isPosting} preNavigation={submit} kind='primary' >Configure group</NextButton>
                </div>

            </Panel>
        </div>
    );
};

export default UserAccess;