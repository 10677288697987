import { 
  Button as ReactButton,
  Input as ReactInput,
  CalendarPicker as ReactCalendarPicker,
  Switch as ReactSwitch,
  InputNumber as ReactInputNumber,
  Select as ReactSelect,
  Combobox as ReactCombobox,
  Checkbox as ReactCheckbox,
} from "@appkit4/react-components";
import { DataEditor as GlideDataEditor } from "@glideapps/glide-data-grid";

import { getCurrentPeriodIsReadonly, getPeriodByIndex, getPeriodIsReadonly, getUserIsReadonly } from "../services/GroupContext";
import { periodAccessPages } from "./ProgressNav";
import toast from "react-hot-toast";

const currentReadonlyStatus = {};
let firstPeriodIsReadonly;

export function refreshReadonly(){
  currentReadonlyStatus[true] = getCurrentPeriodIsReadonly(true);
  currentReadonlyStatus[false] = getCurrentPeriodIsReadonly(false);

  firstPeriodIsReadonly = getPeriodIsReadonly(getPeriodByIndex(0));
}

export function getIsReadonly (enabledWhenFinalised=false, enabledInGroupConfigWhenFirstPeriodFinalized=false) {
  if ((currentReadonlyStatus[enabledWhenFinalised] ?? undefined) === undefined){
    currentReadonlyStatus[enabledWhenFinalised] = getCurrentPeriodIsReadonly(enabledWhenFinalised);
    firstPeriodIsReadonly = getPeriodIsReadonly(getPeriodByIndex(0));
  }
  if ((firstPeriodIsReadonly ?? undefined) === undefined){
    firstPeriodIsReadonly = getPeriodIsReadonly(getPeriodByIndex(0));
  }

  const periodIsReadonly = periodAccessPages.includes(window.location.pathname.toLowerCase()) && currentReadonlyStatus[enabledWhenFinalised];
  const groupConfigFinalised = (window.location.pathname.toLowerCase() === '/app/groupconfig') && firstPeriodIsReadonly && !enabledInGroupConfigWhenFirstPeriodFinalized;

  
  return getUserIsReadonly() || periodIsReadonly || groupConfigFinalised;
}

function SimpleReadOnlyAwareComponent(Component, { children, neverReadonly, enabledWhenFinalised, enabledInGroupConfigWhenFirstPeriodFinalized, hiddenInReadonly, disabled, ...args }){
  const isReadonly = getIsReadonly(enabledWhenFinalised, enabledInGroupConfigWhenFirstPeriodFinalized);

  return !(hiddenInReadonly && isReadonly) &&
      (<Component
        disabled={ disabled || (isReadonly && !neverReadonly ) }
        { ...args }
      >
        { children }
      </Component>)
}

function Button({ viewEditButton, children, onClick, ...args }){
  const isReadonly = getIsReadonly();
  children = viewEditButton ? isReadonly ? "View" : "Edit" : children;

  const errorSafeOnClick = (...args) => {
    try {
      onClick(...args);
    } catch (error){
      toast.error(error.error ?? error ?? "Error: Failed to preform action");
    }
  }

  return SimpleReadOnlyAwareComponent( ReactButton, { children, onClick:errorSafeOnClick, ...args} )
}

function Input( args ){
  return SimpleReadOnlyAwareComponent( ReactInput, args );
}

function CalendarPicker( args ){
  return SimpleReadOnlyAwareComponent( ReactCalendarPicker, args );
}

function Switch( args ){
  return SimpleReadOnlyAwareComponent( ReactSwitch, args );
}

function InputNumber( args ){
  return SimpleReadOnlyAwareComponent( ReactInputNumber, args );
}

function Select( args ){
  return SimpleReadOnlyAwareComponent( ReactSelect, args );
}

function Combobox( args ){
  return SimpleReadOnlyAwareComponent( ReactCombobox, args );
}

function Checkbox( args ){
  return SimpleReadOnlyAwareComponent( ReactCheckbox, args );
}

function DataEditor({ getCellContent, onCellEdited, ...args }){
  const isReadonly = getIsReadonly();

  const readonlyGetCellContent = (...args) => {
    const cellContent = getCellContent(...args);
    cellContent.allowOverlay = !isReadonly && cellContent.allowOverlay;
    return cellContent;
  }

  const readonlyOnCellEdited = (...args) => {
    if (isReadonly) 
      return;

    onCellEdited(...args);
  }

  return <GlideDataEditor
          {...args}
          getCellContent={readonlyGetCellContent}
          onCellEdited={readonlyOnCellEdited}
        />
}


export { Button, Input, CalendarPicker, Switch, InputNumber, Select, Combobox, Checkbox, DataEditor };