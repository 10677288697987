import { Loading, Modal, Accordion, AccordionItem } from '@appkit4/react-components';
import { Button, Input, InputNumber } from "../../components/ReadonlyAwareInputs";
import { useState, useEffect } from 'react';
import { setQNGIETotals } from '../../services/calculations/GroupDataCalcs';
import toast from '../../components/DismissibleToast';
import { WarningModal, generateTableRow, handleCancel, renderPrimaryAccordionTitle, renderSummary, renderTableKeyList, sumArray } from './groupDataUtil';
import { formatCurrency } from "../../utils/formatters";


const QNGIE = ({ setVisible, currentPeriod, setCurrentPeriod, save, errorState: [isError, setIsError], isParentError, isReadOnly }) => {
    const [isLoading, setIsLoading] = useState(false)

    const tables = {
        'expenses_related_party': generateTableRow('Relevant expense amounts included in ANGIE which relate to transactions with, or amounts owed to a person who is a related party of a member of the group - s414(3)(a)', useState([{ description: '', value: '' }]), useState(false), 'negative', ["The expressions relating to related parties are included in Chapter 11, Part 10, TIOPA 2010 and contain a broad overall definition as well as specific inclusions and exclusions. Care is needed in identifying related parties."]),
        'expenses_dependent_securities': generateTableRow('Relevant expense amounts included in ANGIE which relate to results-dependent securities - s414(3)(b)', useState([{ description: '', value: '' }]), useState(false), 'negative', ["The definition of results dependent securities is included in s415 TIOPA 2010."]),
        'expenses_equity_notes': generateTableRow('Relevant expense amounts included in ANGIE which relate to equity notes - s414(3)(c)', useState([{ description: '', value: '' }]), useState(false), 'negative', ["The definition of equity notes is included in s415 TIOPA 2010 and changed as a result of Finance Act 2023."]),
        'specialist_regimes_adjustments': generateTableRow('Other adjustments required as a result of specialist regimes', useState([{ description: '', value: '' }]), useState(false), null, ["Use this row to enter any additional adjustments that are required as a result of the application of specialist regimes or elections."]),
        'miscellaneous_adjustments': generateTableRow('Other adjustments for which there is no space above', useState([{ description: '', value: '' }]), useState(false), null, ["Use this row to enter any additional adjustments that may be required"]),
        
        //Interest Allowance (non-consolidated)
        'qngie_proportion_associated_groups_s427_5': generateTableRow('Adjustment to include the appropriate proportion of the QNGIE of each associated worldwide group headed by a company specified in the election - s427(5)', useState([{ description: '', value: '' }]), useState(false), 'positive', ["Where an Interest Allowance (Non-Consolidated Investment) election is made, it is necessary under s427 TIOPA 2010 to adjust NGIE to increase QNGIE by the amount of QNGIE of the assoiated worldwide group. Care is needed with this adjustment as certain assumptions need to be made in calculating ANGIE of the worldwide group, including that no relevant expense amounts arise on amounts owed to the principal worldwide group. See s428 TIOPA 2010 for more details. This adjustment can only be applied once, so the adjustment applied in arriving at ANGIE is disregarded in establishing ANGIE at the start of the calculation."]),
        
        //Adjustments for Qualifying Infrastructure Companies
        'exclude_qic_income_exempt_s440_s442_2b': generateTableRow('Adjustment to exclude relevant income amounts of a QIC that were exempted under s440 and are included in the group financial statements - s442(2)(b)', useState([{ description: '', value: '' }]), useState(false), null, ["Where an election has been made to treat a company as a qualifying infrastructure company, it is necessary to make an adjustment to remove any relevant income amounts that the company would otherwise have, to the extent that such amounts are exempt under the QIC rules, from the calculations. The adjustment should be an upwards adjustment, increasing QNGIE."]),
        'exclude_qic_expense_exempt_s438_s442_2b': generateTableRow('Adjustment to exclude relevant expense amounts of a QIC that were exempted under s438 (including as applied by s439) and are included in the group financial statements - s442(2)(b)', useState([{ description: '', value: '' }]), useState(false), null, ["Where an election has been made to treat a company as a qualifying infrastructure company, it is necessary to make an adjustment to remove any relevant expense amounts that the company would otherwise have, to the extent that such amounts are exempt under the QIC rules, from the calculations. The adjustment should be downwards adjustment, reducing QNGIE."]),
    };


    const downwardAdjustments = [
        tables['expenses_related_party']?.state[0], 
        tables['expenses_dependent_securities']?.state[0], 
        tables['expenses_equity_notes']?.state[0],
        tables['specialist_regimes_adjustments']?.state[0],
        tables['miscellaneous_adjustments']?.state[0],
    ];

    const infraCompanyAdjustments = [
        tables['exclude_qic_income_exempt_s440_s442_2b']?.state[0],
        tables['exclude_qic_expense_exempt_s438_s442_2b']?.state[0]
    ]

    const [activeKeys, setActiveKeys] = useState("");
    const [qngieSubTotal, setQngieSubTotal] = useState(0);
    const [angieBeforeAdjustments, setAngieBeforeAnyAdjustments] = useState(currentPeriod?.angie_amount_before_any_adjustments ? currentPeriod?.angie_amount_before_any_adjustments : 0)
    const onClickAccordion = (activeKeys) => {
        setActiveKeys(activeKeys)
    }

    useEffect (() => {
        let total = 
            sumArray(downwardAdjustments) + 
            sumArray([tables['qngie_proportion_associated_groups_s427_5']?.state[0]]) +
            sumArray(infraCompanyAdjustments); 
        setQngieSubTotal(total);
      }, [downwardAdjustments, tables['qngie_proportion_associated_groups_s427_5']?.state[0], infraCompanyAdjustments])

    const calculateQNGIE = () => {
        let total =
            sumArray(downwardAdjustments) +
            sumArray([tables['qngie_proportion_associated_groups_s427_5']?.state[0]]) +
            sumArray(infraCompanyAdjustments) ;   
        if (currentPeriod?.angie_amount_before_any_adjustments) {
            total += currentPeriod?.angie_amount_before_any_adjustments;
        }   
        return Math.round(total);
    }

    const submit = () => {
        const newPeriod = {
            ...currentPeriod,

            qngie: Object.keys(tables)?.reduce( (acc, k) => {
                acc[k] = tables[k]?.state[0];
                return acc;
            } , {})
        }
        
        setQNGIETotals(newPeriod);

        setCurrentPeriod(newPeriod);
        const promise = save(newPeriod);
        toast.promise(promise, {
            loading: 'Saving QNGIE data...',
            success: () => {
                setVisible(false);
                return 'Saved QNGIE data!'
            },
            error: 'Something went wrong saving QNGIE data',
        })
    }

    useEffect(() => {
        setIsError( Object.keys(tables)?.some( k => tables[k]?.error[0]) )
      }, [Object.keys(tables)?.map(k => tables[k]?.error[0])])

    useEffect(() => {
        const qngie = currentPeriod?.qngie;
        if (!qngie) {
            return;
        }

        Object.keys(tables)?.forEach(key => {
            tables[key]?.state[1](qngie[key])
        })
    }, [])

    const [isModalChanged, setIsModalChanged] = useState(false);

    useEffect(() => {
        let total = calculateQNGIE();
        if (currentPeriod?.local_curr_qualifying_net_group_interest_expense !== total) {
            setIsModalChanged(true);
        } else {
            setIsModalChanged(false);
        }
    }, [calculateQNGIE()]);

    const [showWarningModal, setShowWarningModal] = useState(false);

    if (isLoading) {
        return (
            <Loading
                loadingType="linear"
                indeterminate={true}
                compact={false}
                className="page-loader"
            ></Loading>
        );
    }
    return (
        <>
            <Modal
                visible={true}
                title={`Qualifying Net Group-Interest Expense`}
                onCancel={() => handleCancel(isModalChanged, isReadOnly, setVisible, setShowWarningModal)}
                maskCloseable={false}
                modalStyle={{ width: '90%', maxHeight: '90%' }}
                bodyStyle={{ maxHeight: '90%' }}
            >
                <table className="table">
                    <tbody>
                        <tr>
                            <td>
                                <div className="row">
                                    <p className="col-1">Selected input value currency:</p>
                                    <Input
                                        className="col"
                                        disabled
                                        value={currentPeriod?.currency}
                                    />
                                </div>
                            </td>
                            <td>
                                <div className="row">
                                    <p className="col-4">
                                        Entered FX rate: 1 GBP = X Local Currency (if required):
                                    </p>
                                    <InputNumber
                                        className="col"
                                        value={currentPeriod?.fx_rate}
                                        disabled
                                    />
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>

                <div className='row group-data-modal-top-input-header'>
                    <p>
                        Changes to the currency and exchange rate can be made in the Net group-interest expense section
                    </p>
                </div>

                {isError && <div className='err'>Description missing - a description must be provided for every value.</div>}



                <Accordion multiple={false} onClick={onClickAccordion} activeKeys={activeKeys}>
                    <AccordionItem title={renderPrimaryAccordionTitle('ANGIE before Interest Allowance (non-consolidated investment) and QIC elections', angieBeforeAdjustments, ["This amount is taken from the ANGIE calculation. To amend it, return to the ANGIE page."])} itemKey="1">
                    </AccordionItem>
                    <AccordionItem title={renderPrimaryAccordionTitle('Downward Adjustments', sumArray(downwardAdjustments))} itemKey="2">
                        {renderTableKeyList(tables, [
                            'expenses_related_party',
                            'expenses_dependent_securities',
                            'expenses_equity_notes',
                            'specialist_regimes_adjustments',
                            'miscellaneous_adjustments',
                        ])}
                    </AccordionItem>

                    <AccordionItem title={renderPrimaryAccordionTitle('Interest Allowance (non-consolidated investment election)', sumArray([tables['qngie_proportion_associated_groups_s427_5']?.state[0]]))} itemKey="3">
                        {renderTableKeyList(tables, [
                            'qngie_proportion_associated_groups_s427_5',
                        ])}
                    </AccordionItem>

                    <AccordionItem title={renderPrimaryAccordionTitle('Adjustments for Qualifying Infrastructure Companies', sumArray(infraCompanyAdjustments))} itemKey="4">
                        {renderTableKeyList(tables, [
                            'exclude_qic_income_exempt_s440_s442_2b',
                            'exclude_qic_expense_exempt_s438_s442_2b',
                        ])}
                    </AccordionItem>
                </Accordion>

                <h3>Summary</h3>

                {renderSummary(currentPeriod?.currency, currentPeriod?.fx_rate, (qngieSubTotal + angieBeforeAdjustments))}


                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'end', paddingTop: '30px', gap: 10 }}>
                    <Button neverReadonly onClick={() => handleCancel(isModalChanged, isReadOnly, setVisible, setShowWarningModal)} kind="secondary">Cancel</Button>
                    <Button hiddenInReadonly disabled={isError || isParentError} onClick={submit}>Submit</Button>
                </div>
            </Modal>

            <WarningModal
                visible={showWarningModal}
                setIsVisible={setShowWarningModal}
                callBackFunc={setVisible}
            >
            </WarningModal>
        </>

    );
};

export default QNGIE;
