import { Checkbox, Loading, Panel } from "@appkit4/react-components";
import { Button } from '../../components/ReadonlyAwareInputs'
import { BackButton, NextButton } from "../../components/ProgressNav";
import { GridCellKind } from "@glideapps/glide-data-grid";
import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../services/AuthProvider";
import { getGroupData, setGroupData, getPoaIndex, getGroupName, getSelectedPeriod } from "../../services/GroupContext";
import { processDate } from "../../utils/dateProcessor";
import { calculateCFD, calculateCFDADJ, convertGBPToLC, recalculateTotal, setBFDADJForEditCFD, setBFDasPrevCFD } from "../../utils/adjustedCapsUtil";
import sqldatabaseService from "../../services/sqldatabase/sqldatabase.service";
import toast from "../../components/DismissibleToast";
import CFDDataEditor from "./components/CFDDataEditor";
import { calculateIsBFDAdjEditable, calculateIsCFDAdjEditable } from "./util/EditCFDUtil";
import CustomTooltipIcon from "../../components/customTooltipIcon/customTooltipIcon";

const EditCFD = () => {
    const [isError, setIsError] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [isPosting, setIsPosting] = useState(false);
    const [freezeCFDAdj, setFreezeCFDAdj] = useState(false);

    const auth = useContext(AuthContext)

    const savedData = getGroupData();

    const [adjustedCaps, setAdjustedCaps] = useState([]);

    const disallowanceDataSections = [
        {
            title: 'Brought Forward Disallowances',
            editable: false,
            col1Data: [
                { displayValue: 'Non-trading loan relationship amounts', key: "bfd_non_trading_loan_relationships" },
                { displayValue: 'Non-trading derivative amounts', key: "bfd_non_trading_derivatives" },
                { displayValue: 'Trading loan relationship amounts', key: "bfd_trading_loan_relationships" },
                { displayValue: 'Trading derivative amounts' , key: "bfd_trading_derivatives" },
                { displayValue: 'Other financing amounts' , key: "bfd_other_financing" },
                { displayValue: 'Total', key: "bfd_subtotal" }
            ]
        },
        {
            title: 'Brought Forward Disallowances Adjustments (Negative)',
            metricGroup: 'bfdadj',
            editable: true,
            validation: 'negative',
            col1Data: [
                { displayValue: 'Non-trading loan relationship amounts', key: "bfdadj_non_trading_loan_relationships", metricType: 'nonTrading'},
                { displayValue: 'Non-trading derivative amounts', key: "bfdadj_non_trading_derivatives", metricType: 'nonTrading'},
                { displayValue: 'Trading loan relationship amounts', key: "bfdadj_trading_loan_relationships", metricType: 'trading'},
                { displayValue: 'Trading derivative amounts' , key: "bfdadj_trading_derivatives", metricType: 'trading'},
                { displayValue: 'Other financing amounts' , key: "bfdadj_other_financing", metricType: 'ofa'},
                { displayValue: 'Total', key: "bfdadj_subtotal" }
            ]
        },
        {
            title: 'CPD',
            editable: false,
            col1Data: [
                { displayValue: 'CPD Non-trading loan relationship amounts', key: "cpd_non_trading_loan_relationships" },
                { displayValue: 'CPD Non-trading derivative amounts', key: "cpd_non_trading_derivatives" },
                { displayValue: 'CPD Trading loan relationship amounts', key: "cpd_trading_loan_relationships" },
                { displayValue: 'CPD Trading derivative amounts' , key: "cpd_trading_derivatives" },
                { displayValue: 'CPD Other financing amounts' , key: "cpd_other_financing" },
                { displayValue: 'Total', key: "cpd_total" }
            ]
        },
        {
            title: 'CPR',
            metricGroup: 'cpr',
            editable: false,
            col1Data: [
                { displayValue: 'Non-trading loan relationship amounts', key: "cpr_non_trading_loan_relationships" },
                { displayValue: 'Non-trading derivative amounts', key: "cpr_non_trading_derivatives" },
                { displayValue: 'Trading loan relationship amounts', key: "cpr_trading_loan_relationships" },
                { displayValue: 'Trading derivative amounts' , key: "cpr_trading_derivatives" },
                { displayValue: 'Other financing amounts' , key: "cpr_other_financing" },
                { displayValue: 'Total', key: "cpr_subtotal" }
            ]
        },
        {
            title: 'Carried Forward Disallowances Adjustments (Negative)',
            metricGroup: 'cfdadj',
            editable: true,
            validation: 'negative',
            col1Data: [
                { displayValue: 'Non-trading loan relationship amounts', key: "cfdadj_non_trading_loan_relationships", metricType: 'nonTrading'},
                { displayValue: 'Non-trading derivative amounts', key: "cfdadj_non_trading_derivatives", metricType: 'nonTrading'},
                { displayValue: 'Trading loan relationship amounts', key: "cfdadj_trading_loan_relationships", metricType: 'trading'},
                { displayValue: 'Trading derivative amounts' , key: "cfdadj_trading_derivatives", metricType: 'trading'},
                { displayValue: 'Other financing amounts' , key: "cfdadj_other_financing", metricType: 'ofa'},
                { displayValue: 'Total', key: "cfdadj_subtotal" }
            ]
        },
        {
            title: 'Carried Forward Disallowances',
            editable: false,
            col1Data: [
                { displayValue: 'Non-trading loan relationship amounts', key: "cfd_non_trading_loan_relationships" },
                { displayValue: 'Non-trading derivative amounts', key: "cfd_non_trading_derivatives" },
                { displayValue: 'Trading loan relationship amounts', key: "cfd_trading_loan_relationships" },
                { displayValue: 'Trading derivative amounts' , key: "cfd_trading_derivatives" },
                { displayValue: 'Other financing amounts' , key: "cfd_other_financing" },
                { displayValue: 'Total', key: "cfd_subtotal" }
            ]
        },
    ]
    
    useEffect(() => {
        if (!savedData) {
            throw 'Something went wrong with fetching your cached data';
        }
        
        const calculatedAdjustedCaps = getSelectedPeriod().adjusted_caps?.sort((a, b) => {
            const nameComparison = String(a?.company_ID)?.localeCompare(b?.company_ID);
            return (nameComparison===0) ? (processDate(a.start_date) -  processDate(b.start_date) ) : nameComparison;
        });

        if (auth?.isReadOnlyUser(getGroupName())) {
            setIsLoading(false);
            setAdjustedCaps(calculatedAdjustedCaps);
        } else {
            const processedCompanies = [];
            
            calculatedAdjustedCaps?.forEach((adjustedCap, index) => {
                if(processedCompanies.includes(adjustedCap.company_ID)) {
                    // every non-first adjusted cap per company
                    // set BFD ammount as CFD ammount
                    const prevAdjustedCap = calculatedAdjustedCaps[index-1];
                    setBFDasPrevCFD(adjustedCap, prevAdjustedCap);
                    
                    // set BFDAdj ammount as negative current year BFD
                    setBFDADJForEditCFD(adjustedCap, false);
                } else {
                    //first adjustedCap for each company
                    processedCompanies.push(adjustedCap.company_ID);
                }
                
                // set CFDAdj as -(BFD + BFDAdj + CPD - CPR)
                calculateCFDADJ(adjustedCap);
                
                // calculate CFD
                calculateCFD(adjustedCap);
    
                // convert values to local currency
                convertGBPToLC(adjustedCap);

                // if cfdadj is uneditable, reset it to 0
                let adjResetFlag = false;
                disallowanceDataSections
                    .find(section => section.metricGroup === 'cfdadj')
                    ?.col1Data.forEach(metric => {
                        const { metricType, key: lcKey } = metric;
                        const gbpKey = `${lcKey}_gbp`;

                        const isNonTradingOrOFA = (metricType === 'nonTrading' || metricType === 'ofa');
                        const isTradingOrOFA = (metricType === 'trading' || metricType === 'ofa')
                        const isEditable = calculateIsCFDAdjEditable(isTradingOrOFA, isNonTradingOrOFA, adjustedCap);

                        if (!isEditable) {
                                adjResetFlag = true;
                                adjustedCap[lcKey] = 0;
                                adjustedCap[gbpKey] = 0;
                        }
                    });
                    
                if (adjResetFlag) {
                    recalculateTotal(adjustedCap, 'cfdadj', true);
                    recalculateTotal(adjustedCap, 'cfdadj', false);
                }
                
                // if bfdadj is uneditable, reset it to 0
                adjResetFlag = false;
                disallowanceDataSections
                    .find(section => section.metricGroup === 'bfdadj')
                    ?.col1Data.forEach(metric => {
                        const { metricType, key: lcKey } = metric;
                        const gbpKey = `${lcKey}_gbp`;

                        const isNonTradingOrOFA = (metricType === 'nonTrading' || metricType === 'ofa');
                        const isTradingOrOFA = (metricType === 'trading' || metricType === 'ofa')
                        const isEditable = calculateIsBFDAdjEditable(isTradingOrOFA, isNonTradingOrOFA, adjustedCap);

                        if (!isEditable) {
                                adjResetFlag = true;
                                adjustedCap[lcKey] = 0;
                                adjustedCap[gbpKey] = 0;
                        }
                    });
                
                if (adjResetFlag) {
                    recalculateTotal(adjustedCap, 'bfdadj', true);
                    recalculateTotal(adjustedCap, 'bfdadj', false);
                }

                adjustedCap.isFirstApForCompanyInPoa = ['Start', 'StartEnd'].includes(adjustedCap.adjCapPosInPoA);
            });

            
            setAdjustedCaps(calculatedAdjustedCaps);
    
            postAdjustdCaps(calculatedAdjustedCaps);
        }
        
    }, []);
    
    const postAdjustdCaps = (newAdjustedCaps) => {
        if (!savedData) {
            throw 'Something went wrong with fetching your cached data';
        }
        const poa = savedData?.periods_of_account[getPoaIndex()];
        poa.adjusted_caps = newAdjustedCaps;
        setGroupData(savedData);
        
        if (auth?.isReadOnlyUser(getGroupName())) {
            setIsLoading(false);
            return;
        }
        setIsPosting(true);
    
        const promise = sqldatabaseService?.postResponse(savedData);
        return toast.promise(promise, {
            loading: 'Processing Adjusted Caps',
            success: (response) => {
                setGroupData(savedData);
                setIsLoading(false);
                setIsPosting(false);
                return `Adjusted Caps Calculations saved to database`
            },
            error: 'Failed to save Adjusted Caps Calculations',
        });
    }

    const save = () => {
        if (!savedData) {
            throw 'Something went wrong with fetching your cached data';
        }

        postAdjustdCaps(adjustedCaps);
    }

    return (
        <div className="ap-container">
            {isLoading ? (
              <Loading
                loadingType="linear"
                indeterminate={true}
                compact={false}
                className="page-loader"
              ></Loading>
            ) :
            <Panel title="Carried Forward Disallowances" className="ap-mb-spacing-4">
                {/* <p>description here?</p> */}
                <p>The disallowances carried forward to the start of the next Period of Account may need to be adjusted in the table below. The table includes flags highlighting whether the inputs provided to date indicate that an adjustment could be required. Only these entries are unlocked for editing. As brought forward adjustments were made for first relevant accounting period of a company in Brought Forward Disallowances page, the brought forward amounts for this accounitng period are not editable.</p>
                <p><b>Brought forward adjustments</b><br/>
                <p>Disallowances may not available in an accounting period where the condition in s378(4) TIOPA 2010 is met and/or where there is a change in ownership of the company.</p>
                <ul type='a'>
                <li>Trade becoming uncommercial or non-statutory (Relevant trade amounts only)
                <CustomTooltipIcon size="big" tooltipText={<>
                        <p>Care must be taken in considering the carry forward of attributes for CIR purposes, where the change in ownership rules in CTA 2010 apply.</p>
                        <p>Disallowed CIR amounts which have been allocated to individual companies may be lost as a result of the application of the change in ownership rules applicable to "relevant non-trading debits" as defined in s730 CTA 2010.</p>
                        <p> This includes the restrictions where there is a significant increase in the capital of the company.</p>
                        <p>A restriction may also arise where a company with a disallowed amount carried forward is acquired alongside other group companies, and either that company or any other co-acquired company has a major change in business.</p>
                        <p>The potential restrictions which may arise as a consequence of a change in ownership are complex and should be carefully considered. Refer to s730 CTA 2010 for further details.</p>
                        </>}/>
                </li>
                <li>Change in Ownership of the company (Non-trade amounts only)
                <CustomTooltipIcon size="big" tooltipText={<>
                    <p>Under 378(4), where a company is allocated a disallowance under the CIR rules in respect of an amount that would otherwise be brought into account in calculating the profits or losses of a trade carried on by that company, and in a subsequent accounting period, the trade becomes uncommercial and non-statutory, the allocated disallowance cannot be carried forward for potential reactivation to the period in which the trade became uncommercial or non-statutory or any accounting period following that period.</p>
                    <p>Refer to s378(4) TIOPA 2010 for further details.</p>
                        </>}/>
                </li>
                </ul></p>
                <p><b>Carried forward adjustments</b><br/>
                <p>Disallowances may not available in a future accounting period (i.e. impacting carried forward amounts) where the condition in s378(3),(5) TIOPA 2010 is met and/or where there is a change in ownership of the company.</p>
                <ul type='a'>
                <li>Trade ceasing or becoming small or negligible (Relevant trade amounts only)
                <CustomTooltipIcon size="big" tooltipText={<>
                    <p>Under 378(3), where a company is allocated a disallowance under the CIR rules in respect of an amount that would otherwise be brought into account in calculating the profits or losses of a trade carried on by that company, and in a subsequent accounting period, the company either ceases to carry on that trade, or the scale of the trade becomes small or negligible, the allocated disallowance cannot be carried forward for potential reactivation to any accounting period following the period in which the trade ceases or becomes small or negligible.</p>
                    <p>Refer to s378(3) TIOPA 2010 for further details.</p>
                    </>}/>
                </li>
                <li>Investment business ceasing or becoming small or negligible (Non-trade (investment business) amounts only)
                <CustomTooltipIcon size="big" tooltipText={<>
                    <p>Under 378(6), where a company is allocated a disallowance under the CIR rules in respect of an amount that would otherwise be brought into account in calculating the profits or losses of an investment business carried on by that company, and in a subsequent accounting period, the company ceases to carry on the investment business or the scale of the investment business becomes small or negligible, the allocated disallowance cannot be carried forward for potential reactivation to any accounting period following the period in which the investment business ceases or becomes small or negligible.</p>
                    <p>Refer to s378(6) TIOPA 2010 for further details.</p>
                    </>}/>
                </li>
                <li>Change in Ownership of the company (Non-trade amounts only)
                <CustomTooltipIcon size="big" tooltipText={<>
                    <p>Care must be taken in considering the carry forward of attributes for CIR purposes, where the change in ownership rules in CTA 2010 apply.</p>
                        <p>Disallowed CIR amounts which have been allocated to individual companies may be lost as a result of the application of the change in ownership rules applicable to "relevant non-trading debits" as defined in s730 CTA 2010.</p>
                        <p> This includes the restrictions where there is a significant increase in the capital of the company.</p>
                        <p>A restriction may also arise where a company with a disallowed amount carried forward is acquired alongside other group companies, and either that company or any other co-acquired company has a major change in business.</p>
                        <p>The potential restrictions which may arise as a consequence of a change in ownership are complex and should be carefully considered. Refer to s730 CTA 2010 for further details.</p>
                        </>}/>
                </li>
                </ul></p>

                <div className='cfd-flex-row'>
                    <p>Freeze CIR Adjustment Rows</p>
                    <Checkbox 
                        checked={freezeCFDAdj}
                        onChange={(value) => {
                            setFreezeCFDAdj(value);
                        }}
                    />
                </div>
                <p className='err'>{isError}</p>
                <div id="portal" style={{ position: 'fixed', left: 0, top: 0, zIndex: 9999 }} />
                
                <CFDDataEditor {...{adjustedCaps, setAdjustedCaps, disallowanceDataSections, freezeCFDAdj}} />
                
                <div className='container-fluid mt-4'>
                    <div className='d-flex justify-content-between align-items-center'> 
                        <div>
                            <BackButton disabled={isPosting}/>
                        </div>
                        <div className='d-flex gap-2'>
                            <Button hiddenInReadonly loading={isPosting} onClick={save}>Save</Button>
                            <NextButton loading={isPosting} preNavigation={save} />
                        </div>
                    </div>
                </div>

            </Panel>}
        </div>
    );
}

export default EditCFD;
