import React, { useContext, useEffect, useState } from 'react';
import { Modal, Button } from '@appkit4/react-components';
import { AuthContext } from '../services/AuthProvider';
import authService from '../services/auth/auth.service';
import { LOCAL_KEYS } from '../services/GroupContext';


const IdleWarnModal = () => {
    const [timerExpired, setTimerExpired] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const auth = useContext(AuthContext)

    const timerHandler = () => {
        // check if last request timestamp is further than x mins in the past
        const lastRequest = localStorage.getItem(LOCAL_KEYS.lastRequest)
        if (lastRequest) {
            const timeDiff = Date.now() - lastRequest
            if (timeDiff > 890000) { // 15 mins
                // session has ended and user should now be logged out
                logout()
            }
            if (timeDiff > 600000) { // 10 mins
                // session is about to end, give user option to refresh the session
                setTimerExpired(true);
            }
        }
        else {
            setTimerExpired(false);
            clearInterval(localStorage.getItem(LOCAL_KEYS.timerID))
        }
    }

    const refreshUser = async () => {
        setIsLoading(true);
        // check if session has already expired
        const lastRequest = localStorage.getItem(LOCAL_KEYS.lastRequest)
        const timeDiff = Date.now() - lastRequest
        if (timeDiff > 890000) {
            logout()
        }
        const response = await authService?.refresh()
        if (response?.status === 200) {
            auth?.login(response?.data)
        }
        setTimerExpired(false);
        setIsLoading(false);
    }

    const logout = async () => {
        // clear timer so it doesn't trigger logout again
        setTimerExpired(false);
        clearInterval(localStorage.getItem(LOCAL_KEYS.timerID))
        // end the session and revoke the token
        await authService?.logout()
    }

    useEffect(() => {
        const timerID = setInterval(timerHandler, 5000)
        localStorage.setItem(LOCAL_KEYS.timerID, timerID)
    }, [])

    return (
        <Modal
            wrapperStyle={{zIndex: "10000"}}
            title={"You will be logged out soon"}
            ariaLabel={"You will be logged out soon"}
            visible={timerExpired}
            closable={false}
            modalStyle={{ width: '33.75rem'}}
            footerStyle={{ paddingTop: '8px', marginTop: '-8px', minHeight: '64px' }}
            footer={
                <>
                    <Button kind="primary" onClick={refreshUser} loading={isLoading}>Continue</Button>
                </>
            }
        >
            <div>
                <p>Click here to keep working.</p>
            </div>
        </Modal>
    );
};

export default IdleWarnModal;
