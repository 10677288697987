import { processDateJS } from "./dateProcessor";

// TODO: consider changing comparing start_date and end_date to using inclusion_period_start and inclusion_period_end in the below 2 functions
export function generateCAPId(cap) {
    if (!cap) {
        return;
    }
    return String(String(cap.company_ID) + '-' + String(processDateJS(cap.start_date)) + '-' + String(processDateJS(cap.end_date)));
}

export function generateAdjCapId(cap) {
    if (!cap) {
        return;
    }
    return String(String(cap.company_ID) + '-' + String(processDateJS(cap.inclusion_period_start)) + '-' + String(processDateJS(cap.inclusion_period_end)));
}

export function generateCAPIdByCompName(cap) {
    if(!cap) {
        return;
    }
    return String(String(cap.company_name) + '-' + String(processDateJS(cap.start_date)) + '-' + String(processDateJS(cap.end_date)));
}
