import { Panel, Loading } from '@appkit4/react-components'; 
import { Button, DataEditor } from "../../components/ReadonlyAwareInputs";
import { useEffect, useState, useCallback, useContext } from 'react';
import { BackButton, NextButton } from '../../components/ProgressNav';
import { getGroupData, setGroupData, getSelectedPeriod, getGroupName, getPoaIndex, getFormattedSelectedPeriod } from '../../services/GroupContext';
import toast from '../../components/DismissibleToast';
import "@glideapps/glide-data-grid/dist/index.css";
import {
    GridCellKind,
} from "@glideapps/glide-data-grid";
import dayjs from "dayjs";
import utc from 'dayjs/plugin/utc';
import { AuthContext } from '../../services/AuthProvider';
import { processDate, processDateJS } from '../../utils/dateProcessor';
import { getBFDValues } from '../../services/calculations/EntityCalculations';
import sqldatabaseService from '../../services/sqldatabase/sqldatabase.service';
import { adjustedCapCustomCondition } from './editBFDUtil';
import { convertBfdLcValuesToGBP, recalculateTotal, setBFDADJForEditBFD } from '../../utils/adjustedCapsUtil';
import CompanyPanel from '../../components/CompanyPanel';
import { getLightOrangeDataEditorColor } from '../../utils/stylingUtil';
import CustomTooltipIcon from '../../components/customTooltipIcon/customTooltipIcon';
dayjs.extend(utc)

const EditBFD = () => {
    const [isLoading, setIsLoading] = useState(true)
    const [isPosting, setIsPosting] = useState(false);
    const [isError, setIsError] = useState('')
    const [firstCapPerCompany, setFirstCapPerCompany] = useState([]);

    const selectedPeriod = getSelectedPeriod();
    const {period_start, period_end} = selectedPeriod;

    const auth = useContext(AuthContext)
    const savedData = getGroupData();
    const [savedCAPData, setSavedCapData] = useState([])
    const [table_data, set_table_data] = useState([]);
    const [table_columns, set_table_columns] = useState([]);
    const table_rows = [
        { metric_id: "start_date", display_name: "Accounting Period Start Date", mandatory: true, type: 'date', editable: false},
        { metric_id: "end_date", display_name: "Accounting Period End Date", mandatory: true, type: 'date', editable: false},
        { metric_id: "isFirstApForCompanyInGroup", display_name: "First PoA and AP in Model", mandatory: true, type: 'boolean', editable: false},
        { metric_id: "trade_uncommercial_or_non_statutory", display_name: "Trade Uncommercial or Non-Statutory", mandatory: true, type: 'boolean', editable: false},
        { metric_id: "cta10_change_in_ownership_rules", display_name: "Change in Ownership", mandatory: true, type: 'boolean', editable: false},


        { type: 'subheader', display_name: "Brought forward disallowance balances" },
        { metric_id: "bfd_non_trading_loan_relationships", display_name: "Non-trading loan relationship amounts", mandatory: true, type: 'number', editable: true, editable_group: 'bfd' },
        { metric_id: "bfd_non_trading_derivatives", display_name: "Non-trading derivative amounts", mandatory: true, type: 'number', editable: true, editable_group: 'bfd' },
        { metric_id: "bfd_trading_loan_relationships", display_name: "Trading loan relationship amounts", mandatory: true, type: 'number', editable: true, editable_group: 'bfd' },
        { metric_id: "bfd_trading_derivatives", display_name: "Trading derivative amounts", mandatory: true, type: 'number', editable: true, editable_group: 'bfd' },
        { metric_id: "bfd_other_financing", display_name: "Other financing amounts", mandatory: true, type: 'number', editable: true, editable_group: 'bfd' },
        { metric_id: "bfd_subtotal", display_name: "Total", type: 'number', editable: false },

        { type: 'subheader', display_name: "Brought forward disallowance adjustments (negative)" },
        { metric_id: "bfdadj_non_trading_loan_relationships", display_name: "Non-trading loan relationship adjustments", mandatory: true, type: 'number', editable: true, editable_group: 'bfdadjnt' },
        { metric_id: "bfdadj_non_trading_derivatives", display_name: "Non-trading derivative adjustments", mandatory: true, type: 'number', editable: true, editable_group: 'bfdadjnt' },
        { metric_id: "bfdadj_trading_loan_relationships", display_name: "Trading loan relationship adjustments", mandatory: true, type: 'number', editable: true, editable_group: 'bfdadjt' },
        { metric_id: "bfdadj_trading_derivatives", display_name: "Trading derivative adjustments", mandatory: true, type: 'number', editable: true, editable_group: 'bfdadjt' },
        { metric_id: "bfdadj_other_financing", display_name: "Other financing adjustments", mandatory: true, type: 'number', editable: true, editable_group: 'bfdadjofa' },
        { metric_id: "bfdadj_subtotal", display_name: "Total adjustments", type: 'number', editable: false },
    ];

    // get calculated values
    useEffect(() => {
        if (period_start && period_end) {
            const adjustedCaps = getSelectedPeriod()?.adjusted_caps

            const poa = savedData?.periods_of_account[getPoaIndex()];
            
            const calculatedAdjustedCaps = adjustedCaps?.sort((a, b) => {
                const nameComparison = a?.company_name?.localeCompare(b?.company_name);
                return (nameComparison===0) ? (processDate(a.start_date) -  processDate(b.start_date)) : nameComparison;
            }).map(cap => {
                const company = savedData?.companies?.find(c => cap?.company_ID === c?.company_ID);
                if (!company) {
                    toast.error('Failed to find company with ID: ', cap?.company_ID)
                    return {};
                }
                
                const company_name = company?.company_name;

                // get initial BFD values and add them to the table
                let cap_data = cap;
                cap_data = getBFDValues(savedData, poa, cap_data)
                recalculateTotal(cap_data, 'bfd', true);

                const adjusted_cap_custom_condition = adjustedCapCustomCondition(cap_data, savedData, period_start);
                if(adjusted_cap_custom_condition) {
                    setBFDADJForEditBFD(cap_data);
                }

                // if bfdadj is non-editable, reset it's value to 0
                let bfdAdjResetFlag = false;
                table_rows.filter(row => row.editable_group?.includes('bfdadj')).forEach(tableRow => {
                    if (!isEditableCalc(cap_data, tableRow.editable_group)) {
                        bfdAdjResetFlag = true;
                        const lcKey = tableRow.metric_id;
                        const gbpKey = lcKey + '_gbp';
                        cap_data[lcKey] = 0;
                        cap_data[gbpKey] = 0;
                    }
                });

                if (bfdAdjResetFlag) {
                    recalculateTotal(cap_data, 'bfdadj', true);
                    recalculateTotal(cap_data, 'bfdadj', false);
                }

                return {
                    ...cap_data,
                    company_ID: cap?.company_ID,
                    company_name,
                    start_date: processDate(cap?.start_date),
                    end_date: processDate(cap?.end_date),
                    currency: cap?.currency || 'GBP',
                    fx_rate: cap?.fx_rate || 1,
                    adjusted_cap_custom_condition
                }
            });

            poa.adjusted_caps = calculatedAdjustedCaps;
            setupTableData(calculatedAdjustedCaps)
            setSavedCapData(calculatedAdjustedCaps);

            if (auth?.isReadOnlyUser(getGroupName())) {
                setIsLoading(false);
                return;
            }

            const promise = sqldatabaseService?.postResponse(savedData);
            toast.promise(promise, {
                loading: 'Calculating Inclusion Period',
                success: (response) => {
                    setGroupData(savedData);
                    setIsLoading(false);
                    return `Inclusion Period Calculations saved to database`
                },
                error: 'Failed to save Inclusion Period Calculations',
            });
        }
        else {
            toast.error(`could not find POA data`, {
                duration: 30 * 1000 // 30s
              })
        }
    }, [])

    const setupTableData = (updatedCAPS) => {
        // set table column names based on caps passed in
        let temp_table_columns = [{ title: "", width: (Math.max(400, window?.innerWidth / 4.5)) }];
        let firstCapPerCompany = []
        updatedCAPS?.forEach((cap, index) => {
            const prevComp = index > 0 ? updatedCAPS[index - 1]?.company_ID : null;
            if (index === 0 || cap?.company_ID !== prevComp) {
                const companyName = savedData.companies?.find((c) => c?.company_ID === cap?.company_ID)['company_name']
                temp_table_columns?.push({ title: companyName, width: (Math.max(300, window?.innerWidth / 5)) })
                firstCapPerCompany.push(cap);
            }
        })
        setFirstCapPerCompany(firstCapPerCompany);
        set_table_columns(temp_table_columns);

        // set table data based on metrics in table_rows array
        let tempTableDataBuilder = [];
        table_rows?.forEach((rowHeader) => {
            let tempRowDataBuilder = [];
            updatedCAPS?.forEach((company, index) => {
                const prevComp = index > 0 ? updatedCAPS[index - 1]?.company_ID : null;
                // only show first ap in period for each company
                if (index === 0 || company?.company_ID !== prevComp) {
                    if (rowHeader?.type?.includes('blank')) {
                        tempRowDataBuilder?.push("")
                    }
                    else if (company?.hasOwnProperty(rowHeader?.metric_id)) {
                        if (rowHeader?.type === 'number') {
                            const value = !isNaN(company[rowHeader?.metric_id]) ? parseInt(company[rowHeader?.metric_id]) : 0;
                            tempRowDataBuilder?.push(value);
                        }
                        else {
                            tempRowDataBuilder?.push(company[rowHeader?.metric_id]);
                        }
                        
                    }
                    else {
                        tempRowDataBuilder?.push(0)
                    }
                }
            })
            tempTableDataBuilder?.push(tempRowDataBuilder);
        })
        set_table_data(tempTableDataBuilder);
    }

    // function to render the table cells
    const renderTable = useCallback(([col, row], rows, data) => {
        const metric = rows[row];
        const regularCellTheme = {bgCell: getLightOrangeDataEditorColor()};
        if (metric?.type === 'blank') {
            return {
                kind: GridCellKind?.Text,
                allowOverlay: false,
                data: " ",
                displayData: " ",
                span: [0, savedData?.length]
            };
        }

        if (metric?.type === 'header' || metric?.type === 'subheader' || metric?.type === 'note') {
            if(col === 0) {
                return {
                    kind: GridCellKind?.Text,
                    allowOverlay: false,
                    data: metric?.display_name,
                    displayData: metric?.display_name,
                    span: [0, savedData?.length],
                    themeOverride: {
                        baseFontStyle: "600 15px",
                        bgCell: "#F4F3F7"
                    }
                };
            } else {
                return {
                    kind: GridCellKind?.Text,
                    allowOverlay: false,
                    data: '',
                    displayData: '',
                    span: [0, savedData?.length],
                    themeOverride: {
                        bgCell: "#F4F3F7"
                    }
                }
            }
        }

        // Col 0 is the metric name
        if (col === 0) {
            return {
                kind: GridCellKind?.Text,
                data: metric?.display_name,
                allowOverlay: false,
                displayData: metric?.display_name,
                allowWrapping: true,
                themeOverride: regularCellTheme
            };
        }

        // set up default values
        let cellType = GridCellKind?.Text;
        let value = data[row][col-1];
        let displayValue = value ? String(value) : '0';
        let editable = metric?.hasOwnProperty('editable') ? metric?.editable : false;
        // check if cap should be editable based on elections
        editable = editable && columnIsEditable(col, data[0][col-1], data[1][col-1], metric['editable_group'])

        // if cell is only editable on selection of a checkbox
        if (metric?.type?.includes('edit_number')) {
            const isEditable = data[6][col-1];
            return {
                kind: GridCellKind?.Number,
                data: value,
                allowOverlay: isEditable,
                displayData: value ? Math?.round(value)?.toLocaleString() : '0',
                themeOverride: {...(!isEditable && regularCellTheme)}
            };
        }
        else if (metric?.type?.includes('date')) {
            value = displayValue = value ? processDateJS(value)?.format('DD/MM/YYYY') : '';
        } else if (metric?.type?.includes('number')) {
            cellType = GridCellKind?.Number;
            value = !isNaN(value) ? Math.round(value).toLocaleString() : 0;
            displayValue = value
        } else if (metric?.type?.includes('boolean')) {
            cellType = GridCellKind?.Boolean
            if (value !== true && value !== false) {
                value = false;
                displayValue = false;
            }
        }
        return {
            kind: cellType,
            data: value,
            allowOverlay: editable,
            displayData: displayValue,
            themeOverride: {...(!editable && regularCellTheme)},
            ...(metric?.type?.includes('boolean') && !editable && {readonly: true})
        };
    }, [table_columns, table_data]);

    const validateCell = ([col, row], newValue) => {
        const editKey = table_rows[row]?.metric_id;
        const editType = editKey?.split('_')[0];
        if(editType === 'bfd') {
            return newValue.data >=0;
        } else if(editType === 'bfdadj') {
            let bfdKey = editKey?.split('_');
            bfdKey[0] = 'bfd';
            bfdKey = bfdKey.join('_');
            const bfdValue = Array.isArray(table_data[row-7]) ? table_data[row-7][col-1] : table_data[row-7];
            return (
                newValue.data <= 0 &&
                (bfdValue ? newValue.data >= (- bfdValue || 0) : newValue.data === 0)
            );
        }
    }

    const [rowGrouping, setRowGrouping] = useState(() => ({
        groups: [{
            headerIndex: 0,
            isCollapsed: false
        }, {
            headerIndex: 10,
            isCollapsed: true,
            subGroups: [{
                headerIndex: 15,
                isCollapsed: false
            }, {
                headerIndex: 20,
                isCollapsed: false
            }]
        }, {
            headerIndex: 30,
            isCollapsed: false
        }],
        height: 55,
        navigationBehavior: "block",
        selectionBehavior: "block-spanning",
        themeOverride: {
            bgCell: "rgba(0, 100, 255, 0.1)"
        }
    }));

    const isEditableCalc = (cap, group) => {
        if (group === 'bfd') return !!cap.isFirstApForCompanyInGroup;
        if (group === 'bfdadjnt') return !!cap.cta10_change_in_ownership_rules;
        if (group === 'bfdadjt') return !!cap.trade_uncommercial_or_non_statutory;
        if (group === 'bfdadjofa') return !!cap.trade_uncommercial_or_non_statutory || !!cap.cta10_change_in_ownership_rules;
    }

    const columnIsEditable = (col, startDate, ap_end_date, group) => {
        if (table_columns && table_columns?.length > 0 && savedCAPData && savedCAPData?.length > 0) {
            const company_name = table_columns[col]?.title
            const cap = savedCAPData?.find(cap => cap?.company_name === company_name && processDateJS(cap?.start_date)?.isSame(processDateJS(startDate)) && processDateJS(cap?.end_date)?.isSame(processDateJS(ap_end_date)))
            
            if (!cap) return false;
            return isEditableCalc (cap, group);

            return !!(processDateJS(period_start)?.isSame(processDateJS(savedData?.model_first_period_start)));
        }
        return false;
    }

    const save_data = () => {
        if (isError) {
            throw 'There were errors in the table';
        }

        setIsPosting(true);

        const all_caps = getGroupData()?.company_accounting_periods || [];
        let newAdjustedCaps = []
        const oldAdjustedCaps = getSelectedPeriod()['adjusted_caps'];
        table_columns?.forEach((c_val, c_index) => {
            if (c_index > 0) {
                let AP = {company_name: c_val?.title}
                table_rows?.forEach((r_val, r_index) => {
                    if (r_val?.metric_id) {
                        AP[r_val.metric_id] = table_data[r_index][c_index - 1];
                    }
                })
                newAdjustedCaps?.push(AP)
            }
        })
        // update values in any edited caps
        const new_caps = oldAdjustedCaps?.map(old_cap => { // changed all_caps to oldAdjustedCaps 
            const new_cap = newAdjustedCaps?.find(cap => cap?.company_name === old_cap?.company_name && processDateJS(cap?.start_date)?.isSame(processDateJS(old_cap?.start_date)) && processDateJS(cap?.end_date)?.isSame(processDateJS(old_cap?.end_date)) )
            if (new_cap) {
                const finalCap = {
                    ...old_cap,
                    'bfd_non_trading_loan_relationships': new_cap?.bfd_non_trading_loan_relationships,
                    'bfd_non_trading_derivatives': new_cap?.bfd_non_trading_derivatives,
                    'bfd_trading_loan_relationships': new_cap?.bfd_trading_loan_relationships,
                    'bfd_trading_derivatives': new_cap?.bfd_trading_derivatives,
                    'bfd_other_financing': new_cap?.bfd_other_financing,
                    'bfd_subtotal': new_cap?.bfd_subtotal,
                    'bfdadj_non_trading_loan_relationships': new_cap?.bfdadj_non_trading_loan_relationships,
                    'bfdadj_non_trading_derivatives': new_cap?.bfdadj_non_trading_derivatives,
                    'bfdadj_trading_loan_relationships': new_cap?.bfdadj_trading_loan_relationships,
                    'bfdadj_trading_derivatives': new_cap?.bfdadj_trading_derivatives,
                    'bfdadj_other_financing': new_cap?.bfdadj_other_financing,
                    'bfdadj_subtotal': new_cap?.bfdadj_subtotal
                };

                convertBfdLcValuesToGBP(finalCap);

                return finalCap;
            }
            return old_cap;
        });


        const newData = getGroupData();
            //company_accounting_periods: new_caps
        
        newData.periods_of_account[getPoaIndex()].adjusted_caps = new_caps;

        if (auth?.isReadOnlyUser(getGroupName())) {
            setGroupData(newData);
            return;
        }
        const promise = sqldatabaseService?.postResponse(newData);
        return toast.promise(promise, {
            loading: 'Saving BFD data...',
            success: () => {
                setGroupData(newData);
                setIsPosting(false);   
                return 'Saved BFD data!'
            },
            error: 'Something went wrong saving BFD data!',
        })
    }

    
    return (
        <div className="ap-container">

                <div className="ap-container mt-4">
                    <CompanyPanel />
                </div>
                <p></p>

            <Panel title="Brought Forward Disallowances" className="ap-mb-spacing-4">
                <p>The reactivations for a Period of Account are limited by the disallowances brought forward to the start of the period. These amounts may need to be adjusted as set out below.
                <br/>As reactivations can only be made in the first relevant accounting period of a company in the Period of Account, only the first accounting period for each company is included in the table below. The table includes flags highlighting whether the inputs provided to date indicate that an adjustment could be required. Only these entries are unlocked for editing.</p>
                <p><b>Brought forward amounts</b><br/>
                <p>Amounts brought forward to the Period of Account are automatically rolled forward from the closing balances where the company was included in the CIR Analyser in a prior Period of Account. Other balances need to be entered manually. Under Para 26(3), Schedule 7A, TIOPA 2010, these need to take account of the below:</p>
                <ul type='a'>
                <li>Disallowed amounts brought forward at the start of the accounting period (Amount A)
                <CustomTooltipIcon size="big" tooltipText={<>
                            <p>Under Para 26(3), Schedule 7A, TIOPA 2010, the starting amount of disallowances available for reactivation at the start of the first relevant accounting period of the company to the Period of Account.</p>
                            <p>Refer to Para 26, Schedule 7A, TIOPA 2010 for further details.</p>
                        </>}/>
                </li>
                <li>Disallowances and reactivations occuring in a prior period of account in that accounting period (Amounts B & C)
                <CustomTooltipIcon size="big" tooltipText={<>
                            <p>Under Para 26(3), Schedule 7A, TIOPA 2010, the amount of disallowances available for reactivation at the start of an accounting period are adjusted to take account of disallowances and reactivations occuring in a prior worldwide group for that accounting period (amounts D & E).</p>
                            <p>The effect of this is that, where a company is acquired in the period, the amounts brought forward must reflect the disallowances of the company when it left the previous worldwide group.</p>
                            <p>Refer to Para 26, Schedule 7A, TIOPA 2010 for further details.</p>
                        </>}/>
                </li>
                <li>Disallowances and reactivations occuring in a prior worldwide group for that accounting period (Amounts D & E)
                <CustomTooltipIcon size="big" tooltipText={<>
                            <p>Under Para 26(3), Schedule 7A, TIOPA 2010, the amount of disallowances available for reactivation at the start of an accounting period are adjusted to take account of disallowances and reactivations occuring in a prior period of account in that accounting period (amounts B & C).</p>
                            <p>The effect of this is that, where this is the first Period of Account for which the model is used for an existing group company, the amounts brought forward should reflect any disallowances or allocations in a prior period of account.</p>
                            <p>Refer to Para 26, Schedule 7A, TIOPA 2010 for further details.</p>
                        </>}/>
                </li>
                </ul></p>
                <p><b>Brought forward adjustments</b><br/>
                <p>Disallowances may not available in an accounting period where one of the conditions in s378 TIOPA 2010 is met and/or where there is a change in ownership of the company. The conditions in s378(3),(5) only impact the utilisation in future periods and therefore are adjusted in the Carried Forward Disallowances page.</p>
                <ul type='a'>
                <li>Trade becoming uncommercial or non-statutory (Relevant trade amounts only)
                <CustomTooltipIcon size="big" tooltipText={<>
                    <p>Under 378(4), where a company is allocated a disallowance under the CIR rules in respect of an amount that would otherwise be brought into account in calculating the profits or losses of a trade carried on by that company, and in a subsequent accounting period, the trade becomes uncommercial and non-statutory, the allocated disallowance cannot be carried forward for potential reactivation to the period in which the trade became uncommercial or non-statutory or any accounting period following that period.</p>
                    <p>Refer to s378(4) TIOPA 2010 for further details.</p>
                    </>}/>
                </li>
                <li>Change in Ownership of the company (Non-trade amounts only)
                <CustomTooltipIcon size="big" tooltipText={<>
                    <p>Care must be taken in considering the carry forward of attributes for CIR purposes, where the change in ownership rules in CTA 2010 apply.</p>
                        <p>Disallowed CIR amounts which have been allocated to individual companies may be lost as a result of the application of the change in ownership rules applicable to "relevant non-trading debits" as defined in s730 CTA 2010.</p>
                        <p> This includes the restrictions where there is a significant increase in the capital of the company.</p>
                        <p>A restriction may also arise where a company with a disallowed amount carried forward is acquired alongside other group companies, and either that company or any other co-acquired company has a major change in business.</p>
                        <p>The potential restrictions which may arise as a consequence of a change in ownership are complex and should be carefully considered. Refer to s730 CTA 2010 for further details.</p>
                        </>}/>
                </li>
                </ul></p>
                <p className='err'>{isError}</p>
                <div id="portal" style={{ position: 'fixed', left: 0, top: 0, zIndex: 9999 }} />
                {isLoading ? 
                    <Loading
                        loadingType="linear"
                        indeterminate={true}
                        compact={false}
                        className="page-loader"
                    ></Loading> :
                    <div>
                        <DataEditor
                            columns={table_columns}
                            getCellContent={([col, row]) => renderTable([col, row], table_rows, table_data)}
                            rows={table_rows?.length}
                            rowGrouping={rowGrouping}
                            validateCell={validateCell}
                            freezeColumns={1}
                            smoothScrollX
                            smoothScrollY
                            onCellEdited={([col, row], newValue) => {
                                const metric = table_rows[row];
                                if (!metric) {
                                    return;
                                }
                                let value = newValue?.data;
                                // Column 0 is the metric name
                                // Companies always start from index 1
                                let localData = [...table_data];
                                localData[row][col-1] = !isNaN(value) ? value : 0;
                                // update totals
                                let bfd_total = 0
                                for (let i = 6; i < 11; i++) {
                                    bfd_total += localData[i][col-1] || 0
                                }
                                let bfdadj_total = 0
                                for (let i = 13; i < 18; i++) {
                                    bfdadj_total += localData[i][col-1] || 0
                                }
                                localData[11][col-1] = bfd_total
                                localData[18][col-1] = bfdadj_total
                                set_table_data(localData);
                            }}
                        />
                        <div className='container-fluid mt-4'>
                    <div className='d-flex justify-content-between align-items-center'> 
                        <div>
                            <BackButton disabled={isPosting}/>
                        </div>
                        <div className='d-flex gap-2'>
                            <Button hiddenInReadonly loading={isPosting} onClick={save_data}>Save</Button>
                            <NextButton loading={isPosting} preNavigation={save_data} />
                        </div>
                    </div>
                    </div>
                </div>
                }
            </Panel>
        </div>
    );
};

export default EditBFD;
