import { Button, Modal } from "@appkit4/react-components";
import { formatCurrency } from "../../utils/formatters";
import DynamicTable from "./dynamicTable";
import toast from "../../components/DismissibleToast";
import CustomTooltipIcon from "../../components/customTooltipIcon/customTooltipIcon";

export function generateTableRow(title, state, error, valueType, tooltipText) {
    return {
        title,
        state,
        error,
        ...(tooltipText && {tooltipText}),
        ...(valueType && { valueType })
    }
}

export function sumArray(inputArray) {
    let total = 0;
    for (const array of inputArray) {
        if (!Array.isArray(array)) continue;
        for (const row of array) {
            total += Number(row?.value);
        }
    }

    return total;
};

export function renderPrimaryAccordionTitle(title, total, toolTipText = null) {
    return (
        <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
            <div style={{display: 'flex', alignItems: 'center'}}>
                <h4>{title}</h4>
                {toolTipText && <CustomTooltipIcon tooltipText={<>{toolTipText?.map(line => <p>{line}</p>)}</>} />}
            </div>
            <h4 className='red-text'>{"Total: " + total?.toLocaleString()}</h4>
        </div>
    );
};

export function renderSecondaryAccordionTitle(title, total, toolTipText = null) {
    return (
        <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
            <div style={{display: 'flex', alignItems: 'center'}}>
                <p>{title}</p>
                {toolTipText && <CustomTooltipIcon tooltipText={<>{toolTipText?.map(line => <p>{line}</p>)}</>} />}
            </div>
            <p className='green-text'>{"Total: " + total?.toLocaleString()}</p>
        </div>
    );
};

export function renderTableKeyList(tables, keys = []) {
    return keys?.map(k => {
        return (
            <>
                {/* <div style={{display: 'flex', alignItems: "center"}}> */}
                <h3>
                    <span>{tables[k]?.title}</span>
                    {tables[k]?.tooltipText && <CustomTooltipIcon size="big" tooltipText={<>{tables[k]?.tooltipText?.map(line => <p>{line}</p>)}</>}/>}
                </h3>
                {/* </div> */}
                {DynamicTable(tables[k]?.state[0], tables[k]?.state[1], tables[k]?.error[1], tables[k]?.valueType)}
            </>
        )
    })
};

export function renderSummary(currency, rate, total) {
    if (currency === ('' || null)){ currency = 'GBP'} 
    return <ul className="list" style={{ listStyleType: 'none', padding: 0 }}>
        <li key={0}><h4>FX rate: 1 (GBP) = <span className='red-text'>{formatCurrency(rate)} {currency}</span></h4></li>
        <br></br>
        {currency === ('GBP' || '') ?
            total >= 0 ?
                <>
                    <li key={1}><h4>Overall Total: <span className='red-text'>{formatCurrency(Math.round(total / rate))} GBP</span></h4></li>
                </> :
                <>
                    <li key={1}><h4>Overall Total (Before floor of zero): <span className='red-text'>{formatCurrency(Math.round(total / rate))} GBP</span></h4></li>
                    <li key={2}><h4>Overall Total (Minimum of zero): <span className='red-text'>{formatCurrency(Math.max(Math.round(total / rate), 0))} GBP</span></h4></li>
                </> :
            total >= 0 ?
                <>
                    <li key={1}><h4>Overall Total: <span className='red-text'>{formatCurrency(Math.round(total))} {currency}</span></h4></li>
                    <li key={2}><h4>Overall Total: <span className='red-text'>{formatCurrency(Math.round(total / rate))} GBP</span></h4></li>
                </> :
                <>
                    <li key={1}><h4>Overall Total (Before floor of zero): <span className='red-text'>{formatCurrency(Math.round(total))} {currency}</span></h4></li>
                    <li key={2}><h4>Overall Total (Minimum of zero): <span className='red-text'>{formatCurrency(Math.max(Math.round(total), 0))} {currency}</span></h4></li>
                    <br></br>
                    <li key={3}><h4>Overall Total (Before floor of zero): <span className='red-text'>{formatCurrency(Math.round(total / rate))} GBP</span></h4></li>
                    <li key={4}><h4>Overall Total (Minimum of zero): <span className='red-text'>{formatCurrency(Math.max(Math.round(total / rate), 0))} GBP</span></h4></li>
                </>
        }
    </ul>;
}

export const WarningModal = ({ visible, setIsVisible, callBackFunc }) => {
    const cancel = () => {
        setIsVisible(false);
    }
    return <Modal
        visible={visible}
        title={"Changed Values won't be saved!"}
        ariaLabel={"Changed Values won't be saved!"}
        onCancel={cancel}
        modalStyle={{ width: '33.75rem' }}
        footerStyle={{ 'paddingTop': '8px', 'marginTop': '-8px', 'minHeight': '64px' }}
        header={""}
        icons={""}
        footer={
            <>
                <Button onClick={cancel} kind="secondary">Continue editing</Button>
                <Button onClick={() => {
                    cancel();
                    callBackFunc(false);
                }}>Exit without saving</Button>
            </>
        }
        bodyStyle={{ minHeight: '92px' }}
    >
    </Modal>;
}

export const handleCancel = (isModalChanged, isReadOnly, setVisible, setShowWarningModal) => {
    if (isModalChanged) {
        if (isReadOnly) {
            toast.error("You are in read only mode. Changes not saved.");
            setVisible(false)
        } else {
            setShowWarningModal(true);
        }
    } else {
        setVisible(false);
    }
}
