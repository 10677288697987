import { Panel, Loading, ButtonGroup } from '@appkit4/react-components';
import { Switch, InputNumber, DataEditor, Button } from "../components/ReadonlyAwareInputs";
import { useEffect, useState, useCallback, useContext } from 'react';
import { BackButton, NextButton } from '../components/ProgressNav';
import RevCalcHeader from './ReviewCalcHeader';
import ReviewCalcNav from './revCalcNavigation';
import { getGroupData, setGroupData, getSelectedPeriod, getPoaIndex, getGroupName } from '../services/GroupContext';
import toast from '../components/DismissibleToast';
import "@glideapps/glide-data-grid/dist/index.css";
import {
    GridCellKind,
} from "@glideapps/glide-data-grid";
import dayjs from "dayjs";
import utc from 'dayjs/plugin/utc';
import { calculate_reactivation_subtotals, getReviewCalc4Values } from '../services/calculations/calc4';
import { useAuth } from '../services/AuthProvider';
import { processDateJS } from '../utils/dateProcessor';
import sqldatabaseService from '../services/sqldatabase/sqldatabase.service';
import CompanyPanel from '../components/CompanyPanel';
import { getBFDTotal } from '../services/calculations/ComponentTotals';
dayjs.extend(utc)

const ReviewCalc4 = () => {
    const auth = useAuth();

    const [isLoading, setIsLoading] = useState(false)
    const [isPosting, setIsPosting] = useState(false);
    const [isError, setIsError] = useState('')

    const savedData = getGroupData();
    const savedPOAData = getSelectedPeriod();

    const [shouldZeroRemainingAllocation, setShouldZeroRemainingAllocation] = useState(false);

    const [comp_reactivation_table_data, set_comp_reactivation_table_data] = useState([]);
    const [comp_reactivation_table_columns, set_comp_reactivation_table_columns] = useState([]);
    const [comp_reactivation_totals_column, set_comp_reactivation_totals_column] = useState([]);
    const comp_reactivation_table_rows = [
        { metric_id: "interest_reactivation_cap_gbp", display_name: "Company Interest reactivation cap", mandatory: true, type: 'number' },
        { metric_id: "amount_available_for_reactivation_gbp", display_name: "Amount available for reactivation", mandatory: true, type: 'number_total' },
        { metric_id: "allocated_reactivation", display_name: "Reactivation allocation", mandatory: true, editable: true, type: 'number_total' }
    ];

    const compReactivationsAvailableRowIndex = comp_reactivation_table_rows.findIndex( metric => metric.metric_id === "amount_available_for_reactivation_gbp" );

    const compReactivationsAllocatedRowIndex = comp_reactivation_table_rows.findIndex( metric => metric.metric_id === "allocated_reactivation" );
    if ( compReactivationsAvailableRowIndex === -1 || compReactivationsAllocatedRowIndex === -1){
        throw new Error("Error: comp table is missing Available or Allocated reactivation metrics");
    }

    const [remainingCompReactivation, setRemainingCompReactivation] = useState(0);

    const [ap_reactivation_table_data, set_ap_reactivation_table_data] = useState([]);
    const [ap_reactivation_table_columns, set_ap_reactivation_table_columns] = useState([]);
    const [ap_reactivation_remaining_row, set_ap_reactivation_remaining_row] = useState([]);
    const ap_reactivation_table_rows = [
        { metric_id: "start_date", display_name: "Accounting Period Start Date", mandatory: true, type: 'date' },
        { metric_id: "end_date", display_name: "Accounting Period End Date", mandatory: true, type: 'date' },
        { metric_id: "allocated_reactivation", display_name: "Reactivation allocated to the company", mandatory: true, type: 'number' },
        { metric_id: "election_override_automatic_allocations", display_name: "Apply s380(3) election, to override the automatic allocation of reactivated amounts", mandatory: true, editable: true, type: 'boolean' },

        { metric_id: "ntlr_reactivation_amount_gbp", display_name: "NTLR tax-interest expense - available for reactivation", mandatory: true, type: 'number' },
        { metric_id: "cpr_non_trading_loan_relationships_gbp", display_name: "NTLR reactivated amount", mandatory: true, type: 'edit_number' },
        { metric_id: "ntdc_reactivation_amount_gbp", display_name: "NTDC tax-interest expense - available for reactivation", mandatory: true, type: 'number' },
        { metric_id: "cpr_non_trading_derivatives_gbp", display_name: "NTDC reactivated amount", mandatory: true, type: 'edit_number' },
        { metric_id: "tlr_reactivation_amount_gbp", display_name: "TLR tax-interest expense - available for reactivation", mandatory: true, type: 'number' },
        { metric_id: "cpr_trading_loan_relationships_gbp", display_name: "TLR reactivated amount", mandatory: true, type: 'edit_number' },
        { metric_id: "tdc_reactivation_amount_gbp", display_name: "TDC tax-interest expense - available for reactivation", mandatory: true, type: 'number' },
        { metric_id: "cpr_trading_derivatives_gbp", display_name: "TDC reactivated amount", mandatory: true, type: 'edit_number' },
        { metric_id: "ofa_reactivation_amount_gbp", display_name: "OFA tax-interest expense - available for reactivation", mandatory: true, type: 'number' },
        { metric_id: "cpr_other_financing_gbp", display_name: "OFA reactivated amount", mandatory: true, type: 'edit_number' },
        { metric_id: "remaining_reactivation", display_name: "Remaining reactivation to be allocated", mandatory: true, type: 'reactivation_total'},
    ];

    const allocationComponentsKeys = [
        "cpr_non_trading_loan_relationships_gbp",
        "cpr_non_trading_derivatives_gbp",
        "cpr_trading_loan_relationships_gbp",
        "cpr_trading_derivatives_gbp",
        "cpr_other_financing_gbp",
    ]

    const allocationComponentsKeyIndexMap = allocationComponentsKeys.reduce((obj, key) => {
        obj[key] = ap_reactivation_table_rows.findIndex((metric)=> metric.metric_id === key);
        return obj;
    }, {} );

    for (let key in allocationComponentsKeyIndexMap){
        if (allocationComponentsKeyIndexMap[key] === -1){
            throw new Error(`Error: ap table is missing ${key} metric which is a component of reactivation`);
        }
    }

    const apReactivationsAllocatedRowIndex = ap_reactivation_table_rows.findIndex( metric => metric.metric_id === "allocated_reactivation" );
    const apReactivationsRemainingRowIndex = ap_reactivation_table_rows.findIndex( metric => metric.metric_id === "remaining_reactivation" );
    const apReactivationsElectionRowIndex = ap_reactivation_table_rows.findIndex( metric => metric.metric_id === "election_override_automatic_allocations" );
        if ( apReactivationsAllocatedRowIndex === -1 || apReactivationsRemainingRowIndex === -1 || apReactivationsElectionRowIndex === -1 ){
            throw new Error("Error: ap table is missing Election, Allocated or Remaining reactivation metric");
        }

    const totalBFD = getBFDTotal('bfd', savedPOAData);
    const totalBFDADJ = getBFDTotal('bfdadj', savedPOAData);
    const groupTotalBFDDisallowances = totalBFD + totalBFDADJ;

    let availReactivations = 0;
    if (comp_reactivation_table_data[compReactivationsAvailableRowIndex]){
        availReactivations = (comp_reactivation_table_data[compReactivationsAvailableRowIndex]).reduce((sum,a) => sum + a, 0)
    }

    useEffect(() => {
        if (!comp_reactivation_table_data.length) return;

        // Calculate Totals column
        const tmp_comp_reactivation_totals_column = [...comp_reactivation_totals_column];
        comp_reactivation_table_rows.forEach((metric, index) => {
            if (metric.type !== "number_total")
                return;

            if (metric.metric_id === "amount_available_for_reactivation_gbp"){
                if (shouldZeroRemainingAllocation){
                    tmp_comp_reactivation_totals_column[index] = 0;
                    return;
                }
            }

            tmp_comp_reactivation_totals_column[index] = comp_reactivation_table_data[index].reduce((acc, val) => acc + (val || 0), 0);
        })
        set_comp_reactivation_totals_column(tmp_comp_reactivation_totals_column);

        // Set remaining reactivation
        setRemainingCompReactivation(
            Math.min( tmp_comp_reactivation_totals_column[compReactivationsAvailableRowIndex], Math.round(savedPOAData.group_interest_reactivation_cap) )
            - tmp_comp_reactivation_totals_column[compReactivationsAllocatedRowIndex]
        );

        const allValid = comp_reactivation_table_data[compReactivationsAllocatedRowIndex].every((value, index) => {
            value = (value || 0);
            return value >= 0 && value <= (comp_reactivation_table_data[compReactivationsAvailableRowIndex][index] || 0);
        })

        if (!allValid){
            setIsError('Table Error: Reactivation allocation must be a number greater than 0 and less than the amount available for reactivation')
        } else {
            setIsError('')
        }

        // Carry Comp allocations down to AP table
        let temp_ap_reactivation_data = [...ap_reactivation_table_data];
        temp_ap_reactivation_data[apReactivationsAllocatedRowIndex].forEach((_, index) => {
            temp_ap_reactivation_data[apReactivationsAllocatedRowIndex][index] = comp_reactivation_table_data[compReactivationsAllocatedRowIndex][index];
        })

        ap_reactivation_table_data?.[0]?.forEach((_,col) => {
            if (ap_reactivation_table_data[apReactivationsElectionRowIndex][col] === false) {
                const cap = savedPOAData?.adjusted_caps?.find((cap) => cap?.company_name === ap_reactivation_table_columns[col+1]?.title
                    && processDateJS(cap?.start_date)?.isSame(processDateJS(temp_ap_reactivation_data[0][col]))
                )

                if (cap) {
                    const allocations = calculate_reactivation_subtotals(cap, ap_reactivation_table_data[apReactivationsAllocatedRowIndex][col]);
                    temp_ap_reactivation_data = applyAPAllocation(temp_ap_reactivation_data, col, allocations);
                }
            }
        })

        set_ap_reactivation_table_data(temp_ap_reactivation_data);

    }, [comp_reactivation_table_data, shouldZeroRemainingAllocation])

    useEffect(()=>{ //JW- Why is this here and not in the OnEdit section below?
        if (!ap_reactivation_table_data?.length)
            return;

        let temp_ap_reactivation_remaining_row = [...ap_reactivation_remaining_row];
        ap_reactivation_table_data[apReactivationsRemainingRowIndex].forEach((_, index) => {
            temp_ap_reactivation_remaining_row[index] = shouldZeroRemainingAllocation
            ? 0
            : getRemainingAPAllocation(ap_reactivation_table_data, index);

        })
        set_ap_reactivation_remaining_row(temp_ap_reactivation_remaining_row);
    }, [ap_reactivation_table_data])

    // get calculated values
    useEffect(() => {
        const savedData = getGroupData();
        if (getPoaIndex() > -1 ) {
            const poa = getReviewCalc4Values(savedData?.periods_of_account[getPoaIndex()], savedData);
            if (poa) {
                savedData.periods_of_account[getPoaIndex()] = poa;
                setGroupData(savedData);
                setupTableData(savedData)

                setShouldZeroRemainingAllocation( poa.shouldZeroRemainingAllocation ?? false );
            }
        }
        else {
            toast.error(`could not find POA data`, {
                duration: 30 * 1000 // 30s
              })
        }
    }, [])

    const setupTableData = (calculationResult) => {
        const savedPOAData = calculationResult?.periods_of_account[getPoaIndex() > -1 ? getPoaIndex() : 0]
        let group_reactivation_amount = 0;
        savedPOAData?.adjusted_caps?.sort((firstCompany,secondCompany) => firstCompany.company_name.localeCompare(secondCompany.company_name)).forEach((cap) => {
            group_reactivation_amount += cap?.reactivation_amount_gbp || 0; 
        })
        let temp_comp_reactivation_table_columns = [{ title: "", width: 200 }, { title: "Group Total", width: 200 }];
        savedPOAData?.adjusted_caps?.forEach((company, index) => {
            const prevComp = index > 0 ? savedPOAData?.adjusted_caps[index - 1]?.company_ID : null;
            if (index === 0 || company?.company_ID !== prevComp) {
                const companyName = calculationResult?.companies?.filter((c) => c?.company_ID === company?.company_ID)[0]['company_name']
                temp_comp_reactivation_table_columns?.push({ title: companyName, width: 200 })
                // also set default allocated_reactivation value
                savedPOAData.adjusted_caps[index].allocated_reactivation = (savedPOAData?.adjusted_caps[index]?.allocated_reactivation && !isNaN(savedPOAData?.adjusted_caps[index]?.allocated_reactivation)) ? savedPOAData?.adjusted_caps[index]?.allocated_reactivation : savedPOAData?.adjusted_caps[index]?.amount_available_for_reactivation_gbp;
            }
        })
        set_comp_reactivation_table_columns(temp_comp_reactivation_table_columns);


        let tempTableDataBuilder = [];
        comp_reactivation_table_rows?.forEach((rowHeader) => {
            let tempRowDataBuilder = [];
            savedPOAData?.adjusted_caps?.forEach((company, index) => {
                const prevComp = index > 0 ? savedPOAData?.adjusted_caps[index - 1]?.company_ID : null;
                if (index === 0 || company?.company_ID !== prevComp) {
                    if (rowHeader?.type?.includes('blank')) {
                        tempRowDataBuilder?.push("")
                    }
                    else if (company?.hasOwnProperty(rowHeader?.metric_id)) {
                        tempRowDataBuilder?.push(company[rowHeader?.metric_id]);
                    }
                    else {
                        tempRowDataBuilder?.push('No value')
                    }
                }
            })
            tempTableDataBuilder?.push(tempRowDataBuilder);
        })
        set_comp_reactivation_table_data(tempTableDataBuilder);
        set_comp_reactivation_totals_column(new Array(tempTableDataBuilder[0].length).fill(0));

        let temp_ap_reactivation_table_columns = [{ title: "", width: 200 }];
        savedPOAData?.adjusted_caps?.forEach((company, index) => {
            const prevComp = index > 0 ? savedPOAData?.adjusted_caps[index - 1]?.company_ID : null;
            if (index === 0 || company?.company_ID !== prevComp) {
                const companyName = calculationResult?.companies?.filter((c) => c?.company_ID === company?.company_ID)[0]['company_name']
                temp_ap_reactivation_table_columns?.push({ title: companyName, width: 200 })
            }
        })
        set_ap_reactivation_table_columns(temp_ap_reactivation_table_columns)
        tempTableDataBuilder = [];
        ap_reactivation_table_rows?.forEach((rowHeader) => {
            let tempRowDataBuilder = [];
            savedPOAData?.adjusted_caps?.forEach((company, index) => {
                const prevComp = index > 0 ? savedPOAData?.adjusted_caps[index - 1]?.company_ID : null;
                if (index === 0 || company?.company_ID !== prevComp) {
                    if (rowHeader?.type?.includes('blank')) {
                        tempRowDataBuilder?.push("")
                    }
                    else if (company?.hasOwnProperty(rowHeader?.metric_id)) {
                        tempRowDataBuilder?.push(company[rowHeader?.metric_id]);
                    }
                    else {
                        if (rowHeader.metric_id === "election_override_automatic_allocations"){
                            tempRowDataBuilder?.push(false);
                        } else {
                            tempRowDataBuilder?.push('No value');
                        }
                    }
                }
            })
            tempTableDataBuilder?.push(tempRowDataBuilder);
        })
        set_ap_reactivation_table_data(tempTableDataBuilder);
        set_ap_reactivation_remaining_row(new Array(tempTableDataBuilder.length).fill(0));
    }

    const getCompCellContent = useCallback((col, row) => {
        const metric = comp_reactivation_table_rows[row];
        if (metric?.type === 'blank') {
            return {
                kind: GridCellKind.Text,
                allowOverlay: false,
                data: " ",
                displayData: " ",
                span: [0, savedData?.length]
            };
        }

        if (metric?.type === 'header' || metric?.type === 'subheader' || metric?.type === 'note') {
            return {
                kind: GridCellKind.Text,
                allowOverlay: false,
                data: metric?.display_name,
                displayData: metric?.display_name,
                span: [0, savedData?.length]
            };
        }

        if (col === -2) { // Metric name
            return {
                kind: GridCellKind.Text,
                data: metric?.display_name,
                allowOverlay: false,
                displayData: metric?.display_name,
                allowWrapping: true
            };
        } else if (col === -1) { // Totals column
            if (metric?.type?.includes('number_total')) {
                return {
                    kind: GridCellKind.Number,
                    data: comp_reactivation_totals_column[row],
                    displayData: comp_reactivation_totals_column[row] ? Math?.round(comp_reactivation_totals_column[row])?.toLocaleString() : '0',
                };
            }
            return {
                kind: GridCellKind.Text,
                allowOverlay: false,
                data: " ",
                displayData: " ",
                span: [0, savedData?.length]
            };
        }

        // Default values
        let cellType = GridCellKind.Text;
        let value = comp_reactivation_table_data[row][col];
        let displayValue = value ? String(value) : '0';
        let editable = metric?.editable ?? false;

        if (metric?.type?.includes('date')) {
            value = displayValue = value ? processDateJS(value)?.format('DD/MM/YYYY') : '';
        } else if (metric?.type?.includes('number')) {
            cellType = GridCellKind.Number;
            displayValue = value ? Math?.round(value)?.toLocaleString() : '0';
        } else if (metric?.type?.includes('boolean')) {
            cellType = GridCellKind.Boolean
            if (value === 1 || value === true) {
                value = displayValue = true;
            } else if (value === 0 || value === false) {
                value = displayValue = false;
            } else {
                value = displayValue = false;
            }
        }

        if (metric.metric_id === "amount_available_for_reactivation_gbp"){
            if (shouldZeroRemainingAllocation){
                value = 0;
                displayValue = "0";
            }
        }

        return {
            kind: cellType,
            data: value,
            allowOverlay: editable,
            displayData: displayValue,
            themeOverride: {
                ...(metric?.style === 'bold' && { baseFontStyle: '600 15px' }),
                ...(editable ? { bgCell: "#FFFFFF"} : { bgCell: "#ffd5b7"})
            }
        };
    }, [comp_reactivation_table_data, comp_reactivation_table_rows, comp_reactivation_totals_column]);


    const getAPCellContent = useCallback((col, row) => {
        const metric = ap_reactivation_table_rows[row];
        if (metric?.type === 'blank') {
            return {
                kind: GridCellKind.Text,
                allowOverlay: false,
                data: " ",
                displayData: " ",
                span: [0, savedData?.length]
            };
        }

        if (metric?.type === 'header' || metric?.type === 'subheader' || metric?.type === 'note') {
            return {
                kind: GridCellKind.Text,
                allowOverlay: false,
                data: metric?.display_name,
                displayData: metric?.display_name,
                span: [0, savedData?.length]
            };
        }

        // Metric name
        if (col === -1) {
            return {
                kind: GridCellKind.Text,
                data: metric?.display_name,
                allowOverlay: false,
                displayData: metric?.display_name,
                allowWrapping: true
            };
        }

        // set up default values
        let cellType = GridCellKind.Text;
        let value = ap_reactivation_table_data[row][col];
        let displayValue = value ? String(value) : '0';
        let editable = metric?.editable ?? false;

        if (metric?.metric_id === "remaining_reactivation") {
            cellType = GridCellKind.Number;
            value = ap_reactivation_remaining_row[col];
            displayValue = ap_reactivation_remaining_row[col] ? Math?.round(ap_reactivation_remaining_row[col])?.toLocaleString() : '0';
            editable = false;
        }

        // if cell is only editable on selection of a checkbox
        if (metric?.type?.includes('edit_number')) {
            cellType = GridCellKind.Number;
            editable = ap_reactivation_table_data[ apReactivationsElectionRowIndex ][col];
            displayValue = value ? Math?.round(value)?.toLocaleString() : '0';
        }
        else if (metric?.type?.includes('date')) {
            value = displayValue = value ? processDateJS(value)?.format('DD/MM/YYYY') : '';
        } else if (metric?.type?.includes('number')) {
            cellType = GridCellKind?.Number;
            displayValue = value ? Math.round(value)?.toLocaleString() : '0';
        } else if (metric?.type?.includes('boolean')) {
            cellType = GridCellKind.Boolean
            if (value === 1 || value === true) {
                value = displayValue = true;
            } else if (value === 0 || value === false) {
                value = displayValue = false;
            } else {
                value = displayValue = false;
            }
        }
        return {
            kind: cellType,
            data: value,
            allowOverlay: editable,
            displayData: displayValue,
            themeOverride: {
                ...(metric?.style === 'bold' && { baseFontStyle: '600 15px' }),
                ...(editable ? { bgCell: "#FFFFFF"} : { bgCell: "#ffd5b7"}),
            },
        };
    }, [ap_reactivation_table_data, ap_reactivation_table_rows, ap_reactivation_remaining_row]);

    const [rowGrouping, setRowGrouping] = useState(() => ({
        groups: [{
            headerIndex: 0,
            isCollapsed: false
        }, {
            headerIndex: 10,
            isCollapsed: true,
            subGroups: [{
                headerIndex: 15,
                isCollapsed: false
            }, {
                headerIndex: 20,
                isCollapsed: false
            }]
        }, {
            headerIndex: 30,
            isCollapsed: false
        }],
        height: 55,
        navigationBehavior: "block",
        selectionBehavior: "block-spanning",
        themeOverride: {
            bgCell: "rgba(0, 100, 255, 0.1)"
        }
    }));

    const onCompCellEdit = (col, row, value) => {
        const metric = comp_reactivation_table_rows[row];
        if (!metric) return;

        let temp_comp_reactivation_data = [...comp_reactivation_table_data];

        if (metric?.type === 'date') {
            const dayjsDate = processDateJS(value)?.format('DD/MM/YYYY');
            if (!dayjsDate?.isValid()) {
                toast.error('Date must be entered in dd/mm/yyyy format');
                return;
            }
            value = dayjsDate?.toDate();
        } else if (metric?.type?.includes("number")){
            value = isNaN(value) ? 0 : Math.round(value);
        }

        temp_comp_reactivation_data[row][col] = value || 0;

        set_comp_reactivation_table_data(temp_comp_reactivation_data);
    }

    const onAPCellEdit = (col, row, value) => {
        const metric = ap_reactivation_table_rows[row];
        if (!metric) {
            return;
        }

        let temp_ap_reactivation_data = [...ap_reactivation_table_data];
        if (metric?.type === 'date') {
            const dayjsDate = processDateJS(value)?.format('DD/MM/YYYY');
            if (!dayjsDate?.isValid()) {
                toast.error('Date must be entered in dd/mm/yyyy format')
                return
            }
            value = dayjsDate?.toDate()
        } else if (metric?.type === 'boolean') {
            temp_ap_reactivation_data[ apReactivationsElectionRowIndex ][col] = value;
        } else if (metric?.type?.includes('number')){
            value = isNaN(value) ? 0 : Math.round(value);
        }

        temp_ap_reactivation_data[row][col] = value;

        if (metric?.metric_id === 'election_override_automatic_allocations' && value === false) {
            const cap = savedPOAData?.adjusted_caps?.find((cap) => cap?.company_name === ap_reactivation_table_columns[col+1]?.title
                && processDateJS(cap?.start_date)?.isSame(processDateJS(temp_ap_reactivation_data[0][col]))
            )
            if (cap) {
                const allocations = calculate_reactivation_subtotals(cap, ap_reactivation_table_data[apReactivationsAllocatedRowIndex][col]);
                temp_ap_reactivation_data = applyAPAllocation(temp_ap_reactivation_data, col, allocations);
            }
        }

        set_ap_reactivation_table_data(temp_ap_reactivation_data)
    }

    const getMetricTheme = (metric) => {
        if (!metric) {
            return {};
        }

        if (metric?.type === 'blank') {
            return {
                bgCell: "#F4F3F7"
            }
        }

        if (metric?.type === 'header') {
            return {
                baseFontStyle: "600 18px",
            }
        }

        if (metric?.type === 'subheader') {
            return {
                baseFontStyle: "600 15px",
            }
        }

        if (metric?.type === 'note') {
            return {
                baseFontStyle: "600 12px",
            }
        }
    }

    const resetAllocation = () => {
        let temp_comp_table_data = [...comp_reactivation_table_data];
        let temp_ap_table_data = [...ap_reactivation_table_data];

        temp_comp_table_data[0]?.forEach((_, index) => {
            temp_comp_table_data[compReactivationsAllocatedRowIndex][index] = 0;

            const zeroedAllocation =  allocationComponentsKeys.reduce((obj, key) => {
                obj[key] = 0;
                return obj;
            }, {})

            temp_ap_table_data = applyAPAllocation( temp_ap_table_data, index, zeroedAllocation );
        })

        set_comp_reactivation_table_data(temp_comp_table_data);
        set_ap_reactivation_table_data(temp_ap_table_data);
    }

    const applyAPAllocation = (temp_ap_reactivation_data, col, newValues) => {

        Object.keys(allocationComponentsKeyIndexMap).forEach( k => {
            temp_ap_reactivation_data[allocationComponentsKeyIndexMap[k]][col] = newValues[k];
        })

        return temp_ap_reactivation_data;
    }

    const getRemainingAPAllocation = (ap_table_data, col) => {
        let total = ap_table_data[ apReactivationsAllocatedRowIndex ][col];

        Object.keys(allocationComponentsKeyIndexMap).forEach((key) => {
            total -= ap_table_data[ allocationComponentsKeyIndexMap[key] ][col];
        });

        return total;
    }

    const save = async () => {
        if (isError) {
            throw 'There is an error. Please fix before proceeding...';
        }

        setIsPosting(true);

        const poaEndDate = savedData.periods_of_account[getPoaIndex()].period_end;

        if (!savedData.periods_of_account[getPoaIndex()].unused_allowances_for_group) {
            savedData.periods_of_account[getPoaIndex()].unused_allowances_for_group = [];
        }

        // Remove existing entry in unused_allowances_for_group
        savedData.unused_allowances_for_group = savedData.unused_allowances_for_group.filter(
            entry => entry.period_end !== poaEndDate
        );

        // Calculate new entry values
        const aggregateNetTaxInterestExpense = savedData.periods_of_account[getPoaIndex()].aggregate_net_tax_interest_expense || 0;
        const groupInterestAllowance = savedData.periods_of_account[getPoaIndex()].group_interest_allowance || 0;
        const groupReactivation = Math.min(
            Math.round(savedPOAData.group_interest_reactivation_cap),
            Math.round(comp_reactivation_totals_column[compReactivationsAvailableRowIndex])
        );

        const unusedAllowance = shouldZeroRemainingAllocation
            ? 0
            : Math.max(
                groupInterestAllowance - aggregateNetTaxInterestExpense - groupReactivation,
                0
            );

        const existingIndex = savedData.unused_allowances_for_group.findIndex(
            (entry) => entry.period_start === savedData.periods_of_account[getPoaIndex()].period_start
        );
        
        // Create the new entry
        const newUnusedAllowanceEntry = {
            aggregate_net_tax_interest_expense: aggregateNetTaxInterestExpense,
            interest_allowance: groupInterestAllowance,
            reactivations_prior_first_period: 0,
            unused_allowance: unusedAllowance,
            unused_generated: unusedAllowance,
            period_end: poaEndDate,
            period_start: savedData.periods_of_account[getPoaIndex()].period_start,
        };
        
        // If an entry already exists, replace it 
        if (existingIndex !== -1) {
            savedData.unused_allowances_for_group[existingIndex] = newUnusedAllowanceEntry;
        
        // If not, push the new entry
        } else {
            savedData.unused_allowances_for_group.push(newUnusedAllowanceEntry);
        }

        // Get full data of displayed caps
        // copied from calc4
        const displayed_caps = savedPOAData.adjusted_caps?.filter((cap, index) => {
            const prevComp = index > 0 ? savedPOAData?.adjusted_caps[index - 1]?.company_ID : null;
            return ( index === 0 || cap.company_ID !== prevComp);
        })

        // Write data to new caps
        savedData.periods_of_account[getPoaIndex()].adjusted_caps = savedPOAData.adjusted_caps.map( ap => {
            const capIndex = displayed_caps.findIndex( (cap, index) =>
                cap.company_ID === ap.company_ID
                && processDateJS(cap.end_date)?.isSame(processDateJS(ap.end_date))
                && processDateJS(cap.start_date)?.isSame(processDateJS(ap.start_date))
            )

            return capIndex !== -1 ? {
                ...ap,
                ...comp_reactivation_table_rows.reduce((acc ,metric, metricIndex) => {
                    acc[metric.metric_id] = comp_reactivation_table_data[metricIndex][capIndex];
                    return acc;
                }),
                ...ap_reactivation_table_rows.reduce((acc, metric, metricIndex) => {
                    acc[metric.metric_id] = ap_reactivation_table_data[metricIndex][capIndex];
                    return acc;
                }),
            } : ap
        })

        savedData.periods_of_account[getPoaIndex()].shouldZeroRemainingAllocation = shouldZeroRemainingAllocation;
        savedData.periods_of_account[getPoaIndex()].group_reactivations = groupReactivation;
        savedData.periods_of_account[getPoaIndex()].group_interest_reactivation_available = availReactivations;

        if (auth?.isReadOnlyUser(getGroupName())) {
            setGroupData(savedData);
            return;
        }

        const promise = sqldatabaseService?.postResponse(savedData)
        return toast.promise(promise, {
            loading: 'Saving...',
            success: () => {
                setGroupData(savedData);
                setIsPosting(false);
                return `Changes saved to database`;
            },
            error: () => {
                setIsPosting(false);
                return 'Failed to save changes to database';
            },
        });
    }

    useEffect(()=>{
        if (shouldZeroRemainingAllocation)
            resetAllocation();

    },[shouldZeroRemainingAllocation]);

    const validateReactivationAllocation = () => {
        return Math.round(remainingCompReactivation)?.toLocaleString() === "0"
            && ap_reactivation_remaining_row?.every( apRemainingReactivation => apRemainingReactivation === 0);
    }

    if (isLoading)
        return (
            <Loading
                loadingType="linear"
                indeterminate={true}
                compact={false}
                className="page-loader"
            ></Loading>
        );
    return (
        <div className="ap-container">
            <CompanyPanel /><br/>
            <Panel>
                <RevCalcHeader groupname = "Reactivation of interest" />
                <ReviewCalcNav currentStep={3}/>
                <p>Where a group has an 'amount available for reactivation' in respect of a return period, this amount needs to be allocated among the companies in the group, subject to each company's interest reactivation cap.</p>
                <p className='col-7'>Group Interest Reactivation Cap: <strong>{Math.round(savedPOAData.group_interest_reactivation_cap)?.toLocaleString()}</strong></p>
                <p className='col-7'>Group total disallowances brought forward before application of company interest reactivation cap: <strong>{Math.round(groupTotalBFDDisallowances)?.toLocaleString()}</strong></p>
                <p className='col-7'>Group total amount available for reactivation: <strong>{availReactivations.toLocaleString()}</strong></p>
                <p className='col-7'>Reactivations to be allocated: <strong>{Math.min(Math.round(savedPOAData.group_interest_reactivation_cap), Math.round(comp_reactivation_totals_column[compReactivationsAvailableRowIndex]))?.toLocaleString()}</strong></p>
                <h2>Company - Reactivation Allocation</h2>
                <p>Where an amount is available for reactivation in respect of the Period of Account of a Group, this needs to be allocated among the companies within the group, subject to each company's reactivation limit.</p>
                <p className='err'>{isError}</p>
                <Switch
                    className='mb-2'
                    checked={shouldZeroRemainingAllocation}
                    onChange={()=> {
                        setShouldZeroRemainingAllocation( !shouldZeroRemainingAllocation );
                        }
                    }
                >
                Toggle this box if you intend to finalise the calculation without a reporting company or make an abbreviated return. In these cases, no reactivations can be made in the period, and all allocations must be set to zero.
                </Switch>
                <div id="portal" style={{ position: 'fixed', left: 0, top: 0, zIndex: 9999 }} />
                <DataEditor
                        columns={comp_reactivation_table_columns}
                        getCellContent={([col, row]) => getCompCellContent( col=col-2, row )}
                        rows={comp_reactivation_table_rows?.length}
                        rowGrouping={rowGrouping}
                        onCellEdited={([col, row], event) => onCompCellEdit( col=col-2, row, event?.data)}
                        getRowThemeOverride={ i => getMetricTheme(comp_reactivation_table_rows[i])}
                    />
                <div className='row'>
                    <div className='col-3'>Remaining reactivation to be allocated</div>
                    <InputNumber
                        className='col-auto'
                        value={remainingCompReactivation?.toLocaleString() ?? 0} //Updated formatting to ensure that we have financial data format.
                        readonly
                    ></InputNumber>
                    <ButtonGroup className='col-2' compact={false}>
                        <Button onClick={resetAllocation}>
                            Reset
                        </Button>
                    </ButtonGroup>
                </div>
                <h2>Accounting Period - Reactivation allocation</h2>
                <p className='mb-0'>Where a company has an allocated reactivation amount in respect of the Period of Account of a Group, the reactivated tax-interest amounts are brought into account in the earliest relevant accounting period.
                <br/>By default, tax-interest expense amounts must be brought into account in the following order:</p>
                <ol className='mb-2'>
                    <li>Non-trading debits in respect of loan relationships</li>
                    <li>Non-trading debits in respect of derivative contracts</li>
                    <li>Debits in respect of loan relationships treated as expenses of trade</li>
                    <li>Debits in respect of derivative contracts treated as expenses of trade</li>
                    <li>Finance leases, debt factoring and service concession arrangements</li>
                </ol>
                <p>A company may make an election for this order not to apply for an accounting period, in which case the company must specify the particular tax-interest expense amounts that are to be left out of account:</p>
                <DataEditor
                        columns={ap_reactivation_table_columns}
                        getCellContent={([col, row]) => getAPCellContent( col=col-1, row )}
                        rows={ap_reactivation_table_rows?.length}
                        rowGrouping={rowGrouping}
                        onCellEdited={([col, row], event) => onAPCellEdit( col=col-1, row, event?.data )}
                        getRowThemeOverride={i => getMetricTheme(ap_reactivation_table_rows[i])}
                    />

                <div className='container-fluid mt-4'>
                    <div className='d-flex justify-content-between align-items-center'>
                        <div>
                            <BackButton
                                disabled={isPosting}
                            />
                        </div>
                        <div className='d-flex gap-2'>
                            <Button
                                loading={isPosting}
                                onClick={save}
                                disabled={isError?.length > 0 || !validateReactivationAllocation()}
                            >Save</Button>
                            <NextButton
                                loading={isPosting}
                                preNavigation={save}
                                disabled={isError?.length > 0 || !validateReactivationAllocation()}
                            >Next</NextButton>
                        </div>
                    </div>
                </div>

            </Panel>
        </div>
    );
};

export default ReviewCalc4;
