import React, { useState } from 'react';
import { Modal, Select } from '@appkit4/react-components';
import { Button, Input } from "../../components/ReadonlyAwareInputs";
import { AccessRoles } from '../../utils/AccessRoles';
import toast from '../../components/DismissibleToast';

const NewUserModal = ({ visible, setVisible, addUser, existingUsers }) => {
    const [email, setEmail] = useState('');
    const [role, setRole] = useState('');
    const [isError, setIsError] = useState('')

    const isEmail = (email) => {
        var emailFormat = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;
        return (!!email?.match(emailFormat));
    };

    return (
        <Modal
            visible={visible}
            title={"Add new user"}
            ariaLabel={"Add new user"}
            onCancel={() => setVisible(false)}
            maskCloseable={false}
            modalStyle={{ width: '33.75rem' }}
            footerStyle={{ paddingTop: '8px', marginTop: '-8px', minHeight: '64px' }}
            footer={
                <>
                    <Button neverReadonly onClick={() => setVisible(false)} kind="secondary">Cancel</Button>
                    <Button kind="primary" disabled={isError !== ''} onClick={() => {
                        if (!isEmail(email)) {
                            toast.error(`'${email}' is not a valid email`)
                            return;
                        }

                        if (!role) {
                            toast.error(`No role selected`)
                            return;
                        }

                        addUser(email, role);
                        setVisible(false)
                        setEmail('');
                        return;
                        /* Handle save logic */
                    }}>Add user</Button>
                </>
            }
        >

            <div>
                <div className="row mb-3">
                    <Input
                        type={"text"}
                        title={"Enter the user's email"}
                        allowClear={true}
                        value={email}
                        onChange={(value) => {
                            setEmail(value);
                            if (!isEmail(value)) {
                                setIsError('Email format is not valid.')
                            }
                            else if (existingUsers?.findIndex(element => element?.email === value) !== -1) {
                                setIsError('This user already has access to group.')
                            }
                            else {
                                setIsError('')
                            }
                        }}
                        error={isError !== ''}
                        //errorNode={<p>{isError}</p>}
                    >
                    </Input>
                </div>

                <div className="row mb-3">
                    <Select
                        data={AccessRoles()}
                        dropdownRenderMode='portal'
                        placeholder={"Select the user's role"}
                        onSelect={e => setRole(e)}>
                    </Select>
                </div>
            </div>
        </Modal>
    );
};

export default NewUserModal;
