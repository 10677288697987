// const fetchCountries = async () => {
//     try {
//       const response = await fetch('https://restcountries.com/v3.1/all');
//       const data = await response.json();

//       const countries = data.map(country => {
//         const code = country.cca2 || ''; // 2-letter ISO code
//         const name = country.name.common || ''; // Name of the country
//         return `${code} - ${name}`;
//       });
//     } catch (error) {
//       console.error('Error fetching countries:', error);
//     }
// };

const countries = [
    "GB - United Kingdom",
    "US - United States",
    "LU - Luxembourg",
    "IE - Ireland",
    "JE - Jersey",
    "GG - Guernsey",
    ...["AD - Andorra",
    "TF - French Southern and Antarctic Lands",
    "LA - Laos",
    "CA - Canada",
    "NG - Nigeria",
    "VU - Vanuatu",
    "CZ - Czechia",
    "MW - Malawi",
    "ML - Mali",
    "IS - Iceland",
    "NO - Norway",
    "VC - Saint Vincent and the Grenadines",
    "GP - Guadeloupe",
    "CL - Chile",
    "BM - Bermuda",
    "KW - Kuwait",
    "DM - Dominica",
    "ME - Montenegro",
    "VI - United States Virgin Islands",
    "CM - Cameroon",
    "LK - Sri Lanka",
    "CN - China",
    "BD - Bangladesh",
    "SE - Sweden",
    "GD - Grenada",
    "TR - Turkey",
    "GN - Guinea",
    "TZ - Tanzania",
    "RW - Rwanda",
    "SG - Singapore",
    "MA - Morocco",
    "BL - Saint Barthélemy",
    "IQ - Iraq",
    "BN - Brunei",
    "IM - Isle of Man",
    "KP - North Korea",
    "IR - Iran",
    "CW - Curaçao",
    "PY - Paraguay",
    "AL - Albania",
    "TJ - Tajikistan",
    "BO - Bolivia",
    "AT - Austria",
    "KN - Saint Kitts and Nevis",
    "UM - United States Minor Outlying Islands",
    "CO - Colombia",
    "XK - Kosovo",
    "BZ - Belize",
    "GW - Guinea-Bissau",
    "MH - Marshall Islands",
    "MM - Myanmar",
    "PF - French Polynesia",
    "BR - Brazil",
    "HR - Croatia",
    "SO - Somalia",
    "AF - Afghanistan",
    "AI - Anguilla",
    "CK - Cook Islands",
    "EH - Western Sahara",
    "NZ - New Zealand",
    "ER - Eritrea",
    "KH - Cambodia",
    "BS - Bahamas",
    "BY - Belarus",
    "NF - Norfolk Island",
    "TV - Tuvalu",
    "GS - South Georgia",
    "MR - Mauritania",
    "NC - New Caledonia",
    "BG - Bulgaria",
    "MZ - Mozambique",
    "NU - Niue",
    "EE - Estonia",
    "IT - Italy",
    "MT - Malta",
    "SI - Slovenia",
    "IN - India",
    "PE - Peru",
    "BI - Burundi",
    "LT - Lithuania",
    "HN - Honduras",
    "TO - Tonga",
    "SA - Saudi Arabia",
    "SR - Suriname",
    "QA - Qatar",
    "SH - Saint Helena, Ascension and Tristan da Cunha",
    "GI - Gibraltar",
    "MP - Northern Mariana Islands",
    "MU - Mauritius",
    "BB - Barbados",
    "RE - Réunion",
    "IO - British Indian Ocean Territory",
    "SY - Syria",
    "EG - Egypt",
    "ST - São Tomé and Príncipe",
    "KI - Kiribati",
    "TL - Timor-Leste",
    "LS - Lesotho",
    "SB - Solomon Islands",
    "LY - Libya",
    "KR - South Korea",
    "LI - Liechtenstein",
    "NI - Nicaragua",
    "EC - Ecuador",
    "MV - Maldives",
    "DZ - Algeria",
    "KG - Kyrgyzstan",
    "FI - Finland",
    "AQ - Antarctica",
    "KE - Kenya",
    "CU - Cuba",
    "MS - Montserrat",
    "PL - Poland",
    "AX - Åland Islands",
    "ET - Ethiopia",
    "TG - Togo",
    "BA - Bosnia and Herzegovina",
    "UY - Uruguay",
    "GU - Guam",
    "CV - Cape Verde",
    "TD - Chad",
    "VA - Vatican City",
    "PW - Palau",
    "HT - Haiti",
    "YE - Yemen",
    "SZ - Eswatini",
    "ZW - Zimbabwe",
    "GR - Greece",
    "IL - Israel",
    "MF - Saint Martin",
    "AG - Antigua and Barbuda",
    "CY - Cyprus",
    "SX - Sint Maarten",
    "MC - Monaco",
    "FJ - Fiji",
    "UA - Ukraine",
    "MQ - Martinique",
    "HK - Hong Kong",
    "PT - Portugal",
    "BT - Bhutan",
    "NP - Nepal",
    "FR - France",
    "AE - United Arab Emirates",
    "LC - Saint Lucia",
    "DO - Dominican Republic",
    "RS - Serbia",
    "BW - Botswana",
    "CI - Ivory Coast",
    "GH - Ghana",
    "KM - Comoros",
    "AZ - Azerbaijan",
    "CF - Central African Republic",
    "PS - Palestine",
    "BQ - Caribbean Netherlands",
    "TW - Taiwan",
    "PN - Pitcairn Islands",
    "SM - San Marino",
    "SJ - Svalbard and Jan Mayen",
    "DJ - Djibouti",
    "WF - Wallis and Futuna",
    "DK - Denmark",
    "PG - Papua New Guinea",
    "MG - Madagascar",
    "BV - Bouvet Island",
    "HU - Hungary",
    "TK - Tokelau",
    "TT - Trinidad and Tobago",
    "GM - Gambia",
    "CC - Cocos (Keeling) Islands",
    "CG - Republic of the Congo",
    "AR - Argentina",
    "CD - DR Congo",
    "GL - Greenland",
    "JO - Jordan",
    "BE - Belgium",
    "CH - Switzerland",
    "ID - Indonesia",
    "LB - Lebanon",
    "MY - Malaysia",
    "KY - Cayman Islands",
    "SK - Slovakia",
    "AM - Armenia",
    "CX - Christmas Island",
    "MN - Mongolia",
    "PM - Saint Pierre and Miquelon",
    "JP - Japan",
    "ZA - South Africa",
    "PH - Philippines",
    "FM - Micronesia",
    "DE - Germany",
    "LV - Latvia",
    "JM - Jamaica",
    "MO - Macau",
    "NR - Nauru",
    "FO - Faroe Islands",
    "GY - Guyana",
    "BF - Burkina Faso",
    "SD - Sudan",
    "RU - Russia",
    "YT - Mayotte",
    "AU - Australia",
    "LR - Liberia",
    "MX - Mexico",
    "TN - Tunisia",
    "AW - Aruba",
    "KZ - Kazakhstan",
    "OM - Oman",
    "GF - French Guiana",
    "NE - Niger",
    "TM - Turkmenistan",
    "SL - Sierra Leone",
    "WS - Samoa",
    "SN - Senegal",
    "GE - Georgia",
    "NA - Namibia",
    "SS - South Sudan",
    "TH - Thailand",
    "BH - Bahrain",
    "HM - Heard Island and McDonald Islands",
    "FK - Falkland Islands",
    "VN - Vietnam",
    "GT - Guatemala",
    "MD - Moldova",
    "MK - North Macedonia",
    "UZ - Uzbekistan",
    "RO - Romania",
    "UG - Uganda",
    "SV - El Salvador",
    "ZM - Zambia",
    "GA - Gabon",
    "GQ - Equatorial Guinea",
    "ES - Spain",
    "NL - Netherlands",
    "VG - British Virgin Islands",
    "BJ - Benin",
    "PK - Pakistan",
    "PA - Panama",
    "TC - Turks and Caicos Islands",
    "AO - Angola",
    "AS - American Samoa",
    "VE - Venezuela",
    "CR - Costa Rica",
    "PR - Puerto Rico",
    "SC - Seychelles"
].sort()
];

export default countries;