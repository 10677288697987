import axios from 'axios';
import authService from './auth/auth.service';
import { LOCAL_KEYS } from './GroupContext';

const GET = async (url, params, isReadOnly = false) => {
    //const auth = useContext(AuthContext)
    try {
        const user = JSON.parse(localStorage.getItem(LOCAL_KEYS.user))
        const headers = {
            Authorization: `Bearer ${user?.access_token}`,
            'x-readonly-mode': isReadOnly ? 'true' : 'false'
        }
        const config = {
            withCredentials: true,
            headers: headers,
            params: params,
        }
        const { data } = await axios.get(url, config);
        localStorage.setItem(LOCAL_KEYS.lastRequest, Date.now());
        if (data?.hasOwnProperty('scope')) {
            const user = JSON.parse(localStorage.getItem(LOCAL_KEYS.user));
            user.scope = data?.scope;
            localStorage.setItem(LOCAL_KEYS.user, JSON.stringify(user));
        }
        return {status: 200, data: data?.data};
    } catch (err) {
        console.error(err)
        if (err?.response?.status === 401) {
            triggerLogin()
            return {status: 400, err: 'User is not authenticated'};
        }
        else {
            return {status: err?.response?.status ?? 400, err: err?.response?.data};
        }
    }
};

const POST = async (url, body, type) => {
    //const auth = useContext(AuthContext)
    try {
        const user = JSON.parse(localStorage.getItem(LOCAL_KEYS.user))
        const headers = {
            withCredentials: true,
            headers: {
                Authorization: `Bearer ${user?.access_token}`,
                "Content-Type": type || "application/json"
            },
        }
        const { data } = await axios.post(url, body, headers);
        localStorage.setItem(LOCAL_KEYS.lastRequest, Date.now());
        return {status: 200, data: data?.data};
    } catch (err) {
        if (err?.response?.status === 401) {
            triggerLogin()
            return {status: 400, err: 'User is not authenticated'};
        }
        if (err?.response?.status === 403) {
            // could not get a group lock - set readonly to true
            localStorage.setItem(LOCAL_KEYS.userReadonly, true);
            return {status: 400, err: err?.response?.data};
        }
        else {
            return {status: 400, err: err?.response?.data};
        }
    }
};

const triggerLogin = () => {
    authService?.login()
        .then((data) => {
            window.location.href = data;
        })
        .catch((error) => {
            console.log(error);
        });
}

export { GET, POST };