import { Accordion, AccordionItem, Button, Modal } from "@appkit4/react-components"
import NewGroupModal from "./NewGroup";
import { useState } from "react";
import toast from "../../../components/DismissibleToast";
import ProcessCompanyAPModal from "./ProcessCompanyAP";
import sqldatabaseService, { getWBSLogs } from "../../../services/sqldatabase/sqldatabase.service";
import DeleteGroupModal from "./DeleteGroup";
import { exportWBSLogs } from "../../../utils/wbsOutput";
import {saveAs} from "file-saver";
import { exportGroups } from "./outputGroups";
const ExcelJS = require('exceljs');


const AdminPanel = ({visible, setVisible, userGroupList, fetchGroups}) => {
    const [activeKeys, setActiveKeys] = useState("");
    const [resetCopyGroupModal, setResetCopyGroupModal] = useState(false);
    const [resetDeleteGroupModal, setResetDeleteGroupModal] = useState(false);
    const [resetProcessGrpCompAPsModal, setResetProcessGrpCompAPsmodal] = useState(false);
    const [wbsLogsLoading, setWbsLogsLoading] = useState(false);
    const [groupsLoading, setGroupsLoading] = useState(false);

    const onClickAccordion = (activeKeys) => {
        setActiveKeys(activeKeys)
        if(!activeKeys.includes('1')) {
            setResetCopyGroupModal(true);
        }

        if(!activeKeys.includes('2')) {
            setResetProcessGrpCompAPsmodal(true);
        }
    }

    const handleExport = async () => {
        setWbsLogsLoading(true); 
        try {
            const response = await getWBSLogs();

            const workbook = new ExcelJS.Workbook();
            exportWBSLogs(response.data, workbook);

            const excelFileName = "CIR Analyser - WBS Logs.xlsx";
            const buffer = await workbook.xlsx.writeBuffer();
            const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
            const blob = new Blob([buffer], { type: fileType });
            saveAs(blob, excelFileName);
        } catch (e) {
            console.error(e);
            toast.error("Failed to download logs, check the console for more information");
        } finally {
            setWbsLogsLoading(false); 
        }
    };
    
    const downloadGroupList = async () => {
        setGroupsLoading(true); 
        try {
            const response = await sqldatabaseService.getAllGroupReport();

            if (response?.status !== 200) {
                // set group lists that user does / doesn't have access to
                const message = (typeof response === "string" ? response : (response?.err?.message ?? response?.message)) || "Unknown Error"
                toast.error(`Failed to get group list :${message}`);
                return;
            }

            const workbook = new ExcelJS.Workbook();
            exportGroups(response.data, workbook);

            const excelFileName = `CIR Analyser - Group List ${(new Date()).toLocaleString().replace(",", " -")}.xlsx`;
            const buffer = await workbook.xlsx.writeBuffer();
            const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
            const blob = new Blob([buffer], { type: fileType });

            saveAs(blob, excelFileName);
        } catch (e) {
            console.error(e);
            toast.error("Failed to download groups, check the console for more information");
        } finally {
            setGroupsLoading(false); 
        }
    }


    return <>
        <Modal
            visible={visible}
            title={"Admin Panel"}
            ariaLabel={"Admin Panel"}
            onCancel={() => setVisible(false)}
            maskCloseable={false}
            modalStyle={{width: '70rem'}}
            footerStyle={{ 'paddingTop': '8px', 'marginTop': '-8px', 'minHeight': '100px' }}
            header={""}
            icons={""}
            >
            <Accordion multiple={false} onClick={onClickAccordion} activeKeys={activeKeys}>
                <AccordionItem title={'Copy Group'} itemKey="1">
                    <NewGroupModal
                        groupList={userGroupList}
                        fetchGroups={fetchGroups}
                        resetForm={resetCopyGroupModal}
                        setResetForm={setResetCopyGroupModal}
                    >
                    </NewGroupModal>
                </AccordionItem>
                <AccordionItem title={'Delete Group'} itemKey="2">
                    <DeleteGroupModal
                        groupList={userGroupList}
                        fetchGroups={fetchGroups}
                        resetForm={resetDeleteGroupModal}
                        setResetForm={setResetDeleteGroupModal}
                    >
                    </DeleteGroupModal>
                </AccordionItem>
                <AccordionItem title={'Process Group Company APs'} itemKey="3">
                    <ProcessCompanyAPModal
                        groupList={userGroupList}
                        fetchGroups={fetchGroups}
                        resetForm={resetProcessGrpCompAPsModal}
                        setResetForm={setResetProcessGrpCompAPsmodal}
                    >
                    </ProcessCompanyAPModal>
                </AccordionItem>
                <AccordionItem title={'WBS Logs'} itemKey="4">
                    <Button onClick={handleExport} disabled={wbsLogsLoading}>
                        {wbsLogsLoading ? "Downloading..." : "Download WBS Logs"}
                    </Button>
                </AccordionItem>
                <AccordionItem title={'Group List'} itemKey="5">
                    <Button onClick={downloadGroupList} loading={groupsLoading}>
                        {groupsLoading ? "Downloading..." : "Download Group List"}
                    </Button>
                </AccordionItem>
            </Accordion>
        </Modal>
    </>
}

export default AdminPanel;
