import { Modal, Loading, Accordion, AccordionItem } from '@appkit4/react-components'; 
import { Button, Input, InputNumber } from "../../components/ReadonlyAwareInputs";
import { useEffect, useState } from 'react';
import { setEBITDATotals } from '../../services/calculations/GroupDataCalcs';
import toast from '../../components/DismissibleToast';
import { WarningModal, generateTableRow, handleCancel, renderSummary, renderPrimaryAccordionTitle, renderTableKeyList, sumArray } from './groupDataUtil';
import { formatCurrency } from '../../utils/formatters';



const EBITDA = ({ setVisible, currentPeriod, setCurrentPeriod, save, errorState: [isError, setIsError], isParentError, isReadOnly }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [ebitdaSubTotal, setEbitdaSubTotal] = useState(0);

    const tables = {
        //Group's Profit Before Tax
        'group_profit_before_tax_s416_1': generateTableRow("Group's Profit Before Tax from P&L - s416(1)", useState([{ description: '', value: '' }]), useState(false), null, ["Income less expenses, excluding tax income/expenses."]),
        'exclude_rd_credits_s104a_s416_2a': generateTableRow('Exclude R&D expenditure credits within s104A CTA 2009 - s416(2A)', useState([{ description: '', value: '' }]), useState(false), 'negative', ["This should be a negative number reducing Group-EBITDA. The reduction only applies to RDEC credits within the meaning of s104A CTA 2009."]),
        'eliminate_derivative_fv_amounts_s420_3': generateTableRow('Adjustment to eliminate all amounts in respect of derivatives subject to fair value accounting to which Regulation 7, 8 or 9 of the Disregard Regulations would apply - s420(3)', useState([{ description: '', value: '' }]), useState(false), null, ["Under s420 TIOPA 2010, it is necessary to exclude derivative contract amounts that would have been Disregarded under Disregard Regulations 7, 8 or 9 on making the relevant assumptions.", "Amongst other assumptions, for the purpose of this section it is necessary to assume that all worldwide group members are subject to Corporation Tax and that the Disregard Regulations have been entered into by the group (regardless of whether any UK group entity has made an election)."]),
        'include_replacement_derivative_amounts_s420_5': generateTableRow('Adjustment to include all replacement derivative contract amounts for derivatives subject to fair value accounting to which Regulation 7, 8 or 9 of the Disregard Regulations would apply - s420(5)', useState([{ description: '', value: '' }]), useState(false), null, ["Under s420 TIOPA 2010, it is also necessary to include derivative contract amounts that would have been brought into account under Disregard Regulations 7, 8 or 9 on making the relevant assumptions (see above)."]),
        
        // Depreciation and Amortisation adjustment - Capital Expenditure Adjustment (s417):
        'relevant_assets_depreciation_s417_2a': generateTableRow('Depreciation of relevant assets - s417(2)(a)', useState([{ description: '', value: '' }]), useState(false), 'positive', ["The adjustment to be made here should reflect all amounts of depreciation that would be treated as capital for tax purposes, including depreciation in respect of finance leases as set out in CFM96440.", "The definition of a finance lease is included in s494 TIOPA 2010 and is limited to leases that fall to be treated as finance leases or right-of-use leases that would fall to be treated as finance leases if the company were required to determine whether it was so treated. As such, care is required in respect of right-of-use leases, as it will be necessary to consider if these would be classified as operating leases or finance leases in an accounting standard with such a distinction."]),
        'relevant_assets_amortization_s417_2a': generateTableRow('Amortisation of relevant assets - s417(2)(a)', useState([{ description: '', value: '' }]), useState(false), 'positive', ["The adjustment to be made here should reflect all amounts of amortisation that would be treated as capital for tax purposes, including amortisation of intangible assets."]),
        'relevant_assets_impairment_s417_2a': generateTableRow('Impairment of relevant assets - s417(2)(a)', useState([{ description: '', value: '' }]), useState(false), null, ["The adjustment to be made here should reflect all amounts in respect of impairments of capital assets, including intangible assets."]),
        'capital_expenditure_relevant_assets_s417_2b': generateTableRow('Expenditure of a capital nature that relates to relevant assets that is incurred and recognised in the relevant period of account - s417(2)(b)', useState([{ description: '', value: '' }]), useState(false), 'positive', ["Expenditure of a capital nature is determined based on whether the expenditure would be capital for tax purposes.", "The adjustment to be reflected here should include any amounts in respect of expenditure on capital assets which is reflected in the profit and loss account."]),
        'provision_future_capital_expenditure_s417_2c': generateTableRow('Amounts recognised in the relevant period of account by way of provision in respect of future expenditure of a capital nature that relates to relevant assets - s417(2)(c)', useState([{ description: '', value: '' }]), useState(false), 'positive', ["Expenditure of a capital nature is determined based on whether the expenditure would be capital for tax purposes.", "The adjustment to be reflected here should include any amounts in respect of provision made for future expenditure on capital assets which is reflected in the profit and loss account."]),
        'reversal_capital_expenditure_s417_1_3': generateTableRow('Reversal of any capital expenditure recognised in an earlier period of account - s417(1)/(3)', useState([{ description: '', value: '' }]), useState(false), 'negative', ["The adjustment to be made here should reflect the reversal of any amounts previously recognised as expenditure of a capital nature."]),
        'capital_income_in_pbt_s417_1_4': generateTableRow('Income of a capital nature brought into account in determining profit before tax - s417(1)/(4)', useState([{ description: '', value: '' }]), useState(false), 'negative', ["Income of a capital nature is determined based on whether the income would be capital for tax purposes.", "The adjustment to be reflected here should include any amounts in respect of capital income recognised in the profit and loss account."]),
        'capital_expenditure_adjustment_other': generateTableRow('Capital expenditure adjustment (Other)', useState([{ description: '', value: '' }]), useState(false), null, ["To the extent that there are other adjustments to be made in respect of capital income or expenditure, these can be entered here."]),
        
        //Capital fair value movement adjustment (s418):
        'fair_value_movements_s418': generateTableRow('Relevant fair value movements (fair value losses are positive / fair value gains are negative) - s418', useState([{ description: '', value: '' }]), useState(false), null, ["Relevant fair value movements can arise where the carrying value of a relevant asset is measured, for the purposes of the financial statements of the group, using fair value accounting and an amount representing a change in the carrying value of the asset is brought into account in determining the group's profit before tax."]),
        
        //Capital disposals adjustment (s419):
        'losses_disposals_relevant_assets_s419': generateTableRow("Amounts in group's profit before tax that represent losses on disposals of relevant assets (positive) - s419", useState([{ description: '', value: '' }]), useState(false), 'positive', ["Disposal adjustments are required where a relevant asset is disposed of in the period.", "Any loss on disposal of relevant assets reflected in the profit and loss account should be included here."]),
        'profits_disposals_relevant_assets_s419': generateTableRow("Amounts in group's profit before tax that represent profits on disposals of relevant assets (negative) - s419", useState([{ description: '', value: '' }]), useState(false), 'negative', ["Disposal adjustments are required where a relevant asset is disposed of in the period.", "Any profit on disposal of relevant assets reflected in the profit and loss account should be included here."]),
        'recalculated_profit_group_ebitda_s419_2_8': generateTableRow('Recalculated profit amount - as adjusted by the Group-EBITDA (chargeable gains) election in s422 if made (positive) - s419(2)-(8)', useState([{ description: '', value: '' }]), useState(false), 'positive', ["The recalculated profits amount is broadly intended to bring back into account any profits above the original cost of the asset.", "Where a Group-EBITDA (Chargeable Gains) election is made, the operation of the recalculated profits amount is adjusted to align it more closely with chargeable gains rules for tax purposes (albeit making certain assumptions, including that SSE would not apply)."]),
        
        //Adjustments in respect of the interest allowance (alternative calculation) election (s423-426):
        'remove_employer_pension_contributions_s424_2': generateTableRow("Adjustment to remove amounts in respect of employers' pension contributions from PBT - s424(2)", useState([{ description: '', value: '' }]), useState(false), 'negative', ["Where an Interest Allowance (Alternative Calculation) election is made, it is necessary under s424 TIOPA 2010 to remove income or expense in respect of registered pension schemes and replace them with relief available under UK pension rules. This only applies to registered pension schemes (and therefore likely only applies to UK schemes)."]),
        'include_uk_tax_employer_pensions_s424_3': generateTableRow("Adjustment to include UK tax deductions for employers' pension contributions - s424(3)", useState([{ description: '', value: '' }]), useState(false), 'positive', ["Where an Interest Allowance (Alternative Calculation) election is made, it is necessary under s424 TIOPA 2010 to remove income or expense in respect of registered pension schemes and replace them with relief available under UK pension rules. This only applies to registered pension schemes (and therefore likely only applies to UK schemes)."]),
        'remove_unpaid_remuneration_expenses_s424a_2': generateTableRow("For periods of account beginning on or after 1 January 2019, adjustment to remove expenses in relation to employees' remuneration not paid within 9 months from PBT - s424A(2)", useState([{ description: '', value: '' }]), useState(false), 'positive', ["Where an Interest Allowance (Alternative Calculation) election is made, it is necessary under s424A TIOPA 2010 to remove expenses in respect of employees remuneration that are not paid within 9 months of year end and only deduct them in the period that they are ultimately paid. This applies to both UK and non-UK group companies."]),
        'deduct_unpaid_remuneration_when_paid_s424a_3': generateTableRow("Adjustment to deduct unpaid employees' remuneration previously been added back when it is paid - s424A(3)", useState([{ description: '', value: '' }]), useState(false), 'negative', ["Where an Interest Allowance (Alternative Calculation) election is made, it is necessary under s424A TIOPA 2010 to remove expenses in respect of employees remuneration that are not paid within 9 months of year end and only deduct them in the period that they are ultimately paid. This applies to both UK and non-UK group companies."]),
        'remove_employee_share_acquisitions_s425_2': generateTableRow('Adjustment to remove amounts in relation to employee share acquisitions arrangements from PBT - s425(2)', useState([{ description: '', value: '' }]), useState(false), 'positive', ["Where an Interest Allowance (Alternative Calculation) election is made, it is necessary under s425 TIOPA 2010 to remove income and expense in respect of employee share schemes and replace them with the deductions available under Part 11 and Part 12 CTA 2009. This applies to non-UK companies in the same way as if they were chargeable to Corporation Tax, so can apply to non-UK group companies."]),
        'deductions_part11_part12_cta2009_s425_3': generateTableRow('Adjustment to reflect deductions that would be allowed under Part 11 and Part 12 of CTA 2009 were all members of the group within the charge to corporation tax - s425(3)', useState([{ description: '', value: '' }]), useState(false), 'negative', ["Where an Interest Allowance (Alternative Calculation) election is made, it is necessary under s425 TIOPA 2010 to remove income and expense in respect of employee share schemes and replace them with the deductions available under Part 11 and Part 12 CTA 2009. This applies to non-UK companies in the same way as if they were chargeable to Corporation Tax, so can apply to non-UK group companies."]),
        'adjust_pbt_accounting_policy_changes_s426_2': generateTableRow('Adjustment to PBT in respect of changes in accounting policy to align with the tax treatment that would arise for all items (including those impacting interest amounts) assuming the group was within the charge to corporation tax - s426(2)', useState([{ description: '', value: '' }]), useState(false), null, ["Where an Interest Allowance (Alternative Calculation) election is made, it is necessary under s426 TIOPA 2010 to adjust profit before tax such that the group accounts are subject to such adjustments as would be made under various change of accounting policy provisions included in s426(3)."]),
        
        //Adjustments in respect of the interest allowance (non-consolidated investment) election.
        'exclude_income_financial_liabilities_s427_3a': generateTableRow('Adjustment to PBT to exclude relevant income amounts relating to financial liabilities owed by any member of an associated worldwide group headed by a company specified in the election - s427(3)(a)', useState([{ description: '', value: '' }]), useState(false), 'negative', ["Where an Interest Allowance (Non-Consolidated Investment) election is made, it is necessary under s427 TIOPA 2010 to make certain adjustments to Group-EBITDA aimed at eliminating the effect of the non-consolidated investment and replacing it with the appropriate proportion of the underying Interest and EBITDA of the non-consolidated investment. An adjustment should be made here to remove from Group EBITDA any income arising to the principal worldwide group from financial liabilities owed by any member of an associated worldwide group for which an election has been made."]),
        'exclude_interest_related_profit_loss_s427_3b': generateTableRow('Adjustment to PBT to exclude any profit or loss attributable to an interest held by any member of the principal worldwide group in any member of an associated worldwide group headed by a company specified in the election - s427(3)(b)', useState([{ description: '', value: '' }]), useState(false), null, ["Where an Interest Allowance (Non-Consolidated Investment) election is made, it is necessary under s427 TIOPA 2010 to make certain adjustments to Group-EBITDA aimed at eliminating the effect of the non-consolidated investment and replacing it with the appropriate proportion of the underying Interest and EBITDA of the non-consolidated investment. An adjustment should be made here to remove from Group EBITDA any profit or loss arising to the principal worldwide group from the interest in any member of an associated worldwide group for which an election has been made."]),
        'adjust_group_ebitda_associated_groups_s427_6': generateTableRow('Adjustment to increase / decrease Group-EBITDA by the appropriate proportion of the Group-EBITDA of each associated worldwide group headed by a company specified in the election - s427(6)', useState([{ description: '', value: '' }]), useState(false), null, ["Where an Interest Allowance (Non-Consolidated Investment) election is made, it is necessary under s427 TIOPA 2010 to make certain adjustments to Group-EBITDA aimed at eliminating the effect of the non-consolidated investment and replacing it with the appropriate proportion of the underying Interest and EBITDA of the non-consolidated investment. An adjustment should be made here to include within Group EBITDA the relevant proportion of the Group EBITDA of any associated worldwide group for which an election has been made. In making the adjustments to reflect the election, it may be necessary to separately compute the Group EBITDA of the associated worldwide group."]),
        
        //Adjustments in respect of the interest allowance (consolidated partnership) election.
        'exclude_consolidated_partnerships_amounts_s430_2a': generateTableRow('Adjustment to PBT to exclude all amounts (including relevant interest income and expense amounts) recognised in respect of specified consolidated partnerships - s430(2)(a)', useState([{ description: '', value: '' }]), useState(false), null, ["Where an interest allowance (consolidated partnerships) election is made, it is necessary under s430 TIOPA 2010 to make certain adjustments to Group-EBITDA to remove the consolidated amounts in respect of the consolidated partnerships and replace them with amounts that would have arisen under the equity method. An adjustment should be made here to include within Group EBITDA the amount that would be reflected in the financial statements of the worldwide group in respect of the specified consolidated partnership, if it were to be accounted for using the equity method."]),
        'include_equity_method_partnerships_s430_2b': generateTableRow('Adjustment to PBT to include amounts that would arise if the specified consolidated partnerships were accounted for under the equity method - s430(2)(b)', useState([{ description: '', value: '' }]), useState(false), null, ["Where an interest allowance (consolidated partnerships) election is made, it is necessary under s430 TIOPA 2010 to make certain adjustments to Group-EBITDA to remove the consolidated amounts in respect of the consolidated partnerships and replace them with amounts that would have arisen under the equity method. An adjustment should be made here to remove from Group EBITDA any amounts representing profit or loss in respect of a specified consolidated partnership."]),
        
        //Adjustments in respect of qualifying infrastructure companies
        'exclude_qualifying_infrastructure_companies_s442_3': generateTableRow('Adjustment required such that Group-EBITDA is calculated as though the group did not include Qualifying Infrastructure companies. This should include interest amounts as adjustments to interest amounts are only made in caluclating ANGIE/QNGIE - s442(3)', useState([{ description: '', value: '' }]), useState(false), null, ["Where a company has made a qualifying infrastructure election it is necessary under s442 TIOPA 2010 to calculate ANGIE, QNGIE and Group EBITDA as though the group did not include the qualifying infrastructure companies. The legislation in s442 does not provide a specific mechanic for this elimination, but it is necessary to make adjustments in order to remove any Net Group Interest Income or Expense associated with the QIC, and also to make adjustments to reflect any amounts of interest income or expense arising in other group companies which are due to or from the QIC.", " Careful consideration of the adjustments to be made is therefore necessary. To the extent that an adjustment has already been made to NGIE, the adjustment to be made here should include all amounts in respect of the Group EBITDA of the QIC companies, including amounts that would otherwise be included in NGIE."]),
        
        //Other
        'specialist_regimes_other_adjustments': generateTableRow('Other adjustments required as a result of specialist regimes', useState([{ description: '', value: '' }]), useState(false), null, ["Use this row to enter any additional adjustments that are required as a result of the application of specialist regimes or elections"]),
        'other_adjustments': generateTableRow('Other adjustments', useState([{ description: '', value: '' }]), useState(false), null, ["Use this row to enter any additional adjustements that may be required"]),
    };

    
    const groupsProfitBeforeTax = [
        tables['group_profit_before_tax_s416_1']?.state[0],
        tables['exclude_rd_credits_s104a_s416_2a']?.state[0],
        tables['eliminate_derivative_fv_amounts_s420_3']?.state[0],
        tables['include_replacement_derivative_amounts_s420_5']?.state[0],
    ]
    
    const deprAmortCapExAdj = [
        tables['relevant_assets_depreciation_s417_2a']?.state[0],
        tables['relevant_assets_amortization_s417_2a']?.state[0],
        tables['relevant_assets_impairment_s417_2a']?.state[0],
        tables['capital_expenditure_relevant_assets_s417_2b']?.state[0],
        tables['provision_future_capital_expenditure_s417_2c']?.state[0],
        tables['reversal_capital_expenditure_s417_1_3']?.state[0],
        tables['capital_income_in_pbt_s417_1_4']?.state[0],
        tables['capital_expenditure_adjustment_other']?.state[0],
    ]
    
    const capFairValueMoveAdj = [
        tables['fair_value_movements_s418']?.state[0]
    ]
    
    const capDisposalsAdj = [
        tables['losses_disposals_relevant_assets_s419']?.state[0],
        tables['profits_disposals_relevant_assets_s419']?.state[0],
        tables['recalculated_profit_group_ebitda_s419_2_8']?.state[0],
    ]
    
    const intAllowanceAltCalcAdj = [
        tables['remove_employer_pension_contributions_s424_2']?.state[0],
        tables['include_uk_tax_employer_pensions_s424_3']?.state[0],
        tables['remove_unpaid_remuneration_expenses_s424a_2']?.state[0],
        tables['deduct_unpaid_remuneration_when_paid_s424a_3']?.state[0],
        tables['remove_employee_share_acquisitions_s425_2']?.state[0],
        tables['deductions_part11_part12_cta2009_s425_3']?.state[0],
        tables['adjust_pbt_accounting_policy_changes_s426_2']?.state[0],
    ]
    
    const intAllowanceNonConsAdj = [
        tables['exclude_income_financial_liabilities_s427_3a']?.state[0],
        tables['exclude_interest_related_profit_loss_s427_3b']?.state[0],
        tables['adjust_group_ebitda_associated_groups_s427_6']?.state[0],
    ]
    
    const intAllowanceConsPartAdj = [
        tables['exclude_consolidated_partnerships_amounts_s430_2a']?.state[0],
        tables['include_equity_method_partnerships_s430_2b']?.state[0],
    ]
    
    const infraCoAdj = [
        tables['exclude_qualifying_infrastructure_companies_s442_3']?.state[0],
    ]
    
    const otherAdj = [
        tables['specialist_regimes_other_adjustments']?.state[0],
        tables['other_adjustments']?.state[0],
    ]


    const [activeKeys, setActiveKeys] = useState("");
    const onClickAccordion = (activeKeys) => {
        setActiveKeys(activeKeys)
    }
    const [ngieETotal, setNgieTotal] = useState(currentPeriod?.local_curr_net_group_interest_expense ? currentPeriod?.local_curr_net_group_interest_expense : 0);
    const calculateInterimEBITDA = () => {
        let total = Math.round(
            sumArray(groupsProfitBeforeTax) +
            sumArray(deprAmortCapExAdj) +
            sumArray(capFairValueMoveAdj) +
            sumArray(capDisposalsAdj) +
            sumArray(intAllowanceAltCalcAdj) +
            sumArray(intAllowanceConsPartAdj) +
            sumArray(intAllowanceNonConsAdj) +
            sumArray(infraCoAdj) +
            sumArray(otherAdj)) +
            ngieETotal;
        return total;
    }


    useEffect (() => {
        let total = 
            sumArray(groupsProfitBeforeTax) +
            sumArray(deprAmortCapExAdj) +
            sumArray(capFairValueMoveAdj) +
            sumArray(capDisposalsAdj) +
            sumArray(intAllowanceAltCalcAdj) +
            sumArray(intAllowanceConsPartAdj) +
            sumArray(intAllowanceNonConsAdj) +
            sumArray(infraCoAdj) +
            sumArray(otherAdj);
        setEbitdaSubTotal(total);
      }, [groupsProfitBeforeTax, deprAmortCapExAdj, capFairValueMoveAdj, capDisposalsAdj, intAllowanceAltCalcAdj, intAllowanceConsPartAdj, intAllowanceNonConsAdj, infraCoAdj, otherAdj])

    const submit = () => {
        const newPeriod = {
            ...currentPeriod,

            ebitda:  Object.keys(tables)?.reduce((acc, k) => {
                    acc[k] = tables[k]?.state[0];
                    return acc;
                }, {})
        }
        
        setEBITDATotals(newPeriod);

        setCurrentPeriod(newPeriod);
        const promise = save(newPeriod);
        toast.promise(promise, {
            loading: 'Saving Group EBITDA data...',
            success: () => {
                setVisible(false);
                return 'Saved Group EBITDA data!'
            },
            error: 'Something went wrong with saving Group EBITDA data!',
        })
    }

    useEffect(() => {
        setIsError( Object.keys(tables)?.some( k => tables[k]?.error[0]) )
    }, Object.keys(tables)?.map(k => tables[k]?.error[0]));

    useEffect(() => {
        if (!currentPeriod) {
            return
        }

        const ebitda = currentPeriod?.ebitda;
        if (!ebitda) {
            return
        }

        Object.keys(tables)?.forEach(key => {
            tables[key]?.state[1](ebitda[key])
        })

    }, [currentPeriod])

    const [isModalChanged, setIsModalChanged] = useState(false);

    useEffect(() => {
        let currTotal = calculateInterimEBITDA();
        if (currentPeriod?.angie_amount_before_any_adjustments) {
            currTotal += currentPeriod?.angie_amount_before_any_adjustments;
        }
        if (currTotal > 0 && currentPeriod?.local_curr_group_ebitda !== currTotal) {
            setIsModalChanged(true);
        } else {
            setIsModalChanged(false);
        }
    }, [calculateInterimEBITDA()]);

    const [showWarningModal, setShowWarningModal] = useState(false);

    if (isLoading) {
        return (
            <Loading
                loadingType="linear"
                indeterminate={true}
                compact={false}
                className="page-loader"
            ></Loading>
        );
    }

    return (
        <>
            <Modal
                visible={true}
                title={`Group EBITDA`}
                onCancel={() => handleCancel(isModalChanged, isReadOnly, setVisible, setShowWarningModal)}
                maskCloseable={false}
                modalStyle={{ width: '90%', maxHeight: '90%' }}
                bodyStyle={{ maxHeight: '90%' }}
            >
            <table className="table">
                <tbody>
                <tr>
                    <td>      
                    <div className="row">
                        <p className="col-1">Selected input value currency:</p>
                        <Input
                        className="col"
                        disabled
                        value={currentPeriod?.currency}
                        />
                    </div>
                    </td>
                    <td>
                    <div className="row">
                        <p className="col-4">
                        Entered FX rate: 1 GBP = X Local Currency (if required):
                        </p>
                        <InputNumber
                        className="col"
                        value={currentPeriod?.fx_rate}
                        disabled
                        />
                    </div>              
                    </td>            
                </tr>
                </tbody>
            </table>
            
            <div className='row group-data-modal-top-input-header'>
                <p>
                    Changes to the currency and exchange rate can be made in the Net group-interest expense section
                </p>
            </div>
        
            {isError && <div className='err'>Description missing - a description must be provided for every value.</div>}


            <Accordion multiple={false} onClick={onClickAccordion} activeKeys={activeKeys}>

                <AccordionItem title={renderPrimaryAccordionTitle("Group's Profit Before Tax from P&L", sumArray(groupsProfitBeforeTax))} itemKey="1">        
                    {renderTableKeyList(tables, [
                        'group_profit_before_tax_s416_1',
                        'exclude_rd_credits_s104a_s416_2a',
                        'eliminate_derivative_fv_amounts_s420_3',
                        'include_replacement_derivative_amounts_s420_5',
                    ])}
                </AccordionItem>

                <AccordionItem title={renderPrimaryAccordionTitle('NGIE', Math.round(ngieETotal), ["This amount is taken from the NGIE calculation - to amend it, return to the NGIE page"])} itemKey="2"></AccordionItem>

                <AccordionItem title={renderPrimaryAccordionTitle("Depreciation and Amortisation adjustment - Capital Expenditure Adjustment (s417)", sumArray(deprAmortCapExAdj))} itemKey="3">
                    {renderTableKeyList(tables, [
                        'relevant_assets_depreciation_s417_2a',
                        'relevant_assets_amortization_s417_2a',
                        'relevant_assets_impairment_s417_2a',
                        'capital_expenditure_relevant_assets_s417_2b',
                        'provision_future_capital_expenditure_s417_2c',
                        'reversal_capital_expenditure_s417_1_3',
                        'capital_income_in_pbt_s417_1_4',
                        'capital_expenditure_adjustment_other',
                    ])}
                </AccordionItem>

                <AccordionItem title={renderPrimaryAccordionTitle("Capital fair value movement adjustment (s418):", sumArray(capFairValueMoveAdj))} itemKey="4">    
                    {renderTableKeyList(tables, [
                        'fair_value_movements_s418',
                    ])}
                </AccordionItem>

                <AccordionItem title={renderPrimaryAccordionTitle('Capital disposals adjustment (s419):', sumArray(capDisposalsAdj))} itemKey="5">
                    {renderTableKeyList(tables, [
                        'losses_disposals_relevant_assets_s419',
                        'profits_disposals_relevant_assets_s419',
                        'recalculated_profit_group_ebitda_s419_2_8',
                    ])}
                </AccordionItem>

                <AccordionItem title={renderPrimaryAccordionTitle('Adjustments in respect of the interest allowance (alternative calculation) election (s423-426):', sumArray(intAllowanceAltCalcAdj))} itemKey="6">        
                    {renderTableKeyList(tables, [
                        'remove_employer_pension_contributions_s424_2',
                        'include_uk_tax_employer_pensions_s424_3',
                        'remove_unpaid_remuneration_expenses_s424a_2',
                        'deduct_unpaid_remuneration_when_paid_s424a_3',
                        'remove_employee_share_acquisitions_s425_2',
                        'deductions_part11_part12_cta2009_s425_3',
                        'adjust_pbt_accounting_policy_changes_s426_2',
                    ])}
                </AccordionItem>

                <AccordionItem title={renderPrimaryAccordionTitle('Adjustments in respect of the interest allowance (non-consolidated investment) election', sumArray(intAllowanceNonConsAdj))} itemKey="7">        
                    {renderTableKeyList(tables, [
                        'exclude_income_financial_liabilities_s427_3a',
                        'exclude_interest_related_profit_loss_s427_3b',
                        'adjust_group_ebitda_associated_groups_s427_6',
                    ])}
                </AccordionItem>

                <AccordionItem title={renderPrimaryAccordionTitle('Adjustments in respect of the interest allowance (consolidated partnership) election', sumArray(intAllowanceConsPartAdj))} itemKey="8">        
                    {renderTableKeyList(tables, [
                        'exclude_consolidated_partnerships_amounts_s430_2a',
                        'include_equity_method_partnerships_s430_2b',
                    ])}
                </AccordionItem>

                <AccordionItem title={renderPrimaryAccordionTitle('Adjustments in respect of qualifying infrastructure companies', sumArray(infraCoAdj))} itemKey="9">        
                    {renderTableKeyList(tables, [
                        'exclude_qualifying_infrastructure_companies_s442_3'
                    ])}
                </AccordionItem>

                <AccordionItem title={renderPrimaryAccordionTitle('Other', sumArray(otherAdj))} itemKey="10">        
                    {renderTableKeyList(tables, [
                        'specialist_regimes_other_adjustments',
                        'other_adjustments',
                    ])}
                </AccordionItem>
            </Accordion>


            <h3>Summary</h3> 
            { currentPeriod?.currency == 'GBP' ? 
                <>
                <h4>Overall Total: <span className='red-text'>{formatCurrency(Math.round(calculateInterimEBITDA()))} GBP</span></h4>
                </> 
                :
                <>
                <h4>FX rate: 1 (GBP) = <span className='red-text'>{formatCurrency(currentPeriod?.fx_rate)} {currentPeriod?.currency}</span></h4>
                <h4>Overall Total: <span className='red-text'>{formatCurrency(Math.round(calculateInterimEBITDA()))} {currentPeriod?.currency}</span></h4>
                <h4>Overall Total: <span className='red-text'>{formatCurrency(Math.round(calculateInterimEBITDA() / currentPeriod?.fx_rate))} GBP</span></h4>
                </> 
            }          


            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'end', paddingTop: '30px', gap: 10 }}>
                <Button neverReadonly onClick={() => handleCancel(isModalChanged, isReadOnly, setVisible, setShowWarningModal)} kind="secondary">Cancel</Button>
                <Button hiddenInReadonly disabled={isError || isParentError} onClick={submit}>Submit</Button>
            </div>
            </Modal>

            <WarningModal
                visible={showWarningModal}
                setIsVisible={setShowWarningModal}
                callBackFunc={setVisible}
            >
            </WarningModal>
        </>
    );
};

export default EBITDA;
