import { Button, Modal } from '@appkit4/react-components'
import React, { useEffect, useState } from 'react'
import sqlService from '../services/sqldatabase/sqldatabase.service';
import toast from './DismissibleToast';



function RequestAccessModal({requestAccessModal,setRequestAccessModal,requestGroup}) {

    const [mailList ,setMailList] = useState([])

    useEffect(()=>{
        if(requestGroup){
            const getUsers = async () => {
                const response = await sqlService?.getGroupSupervisors(requestGroup);
                if (response?.status === 200) {
                    setMailList(response?.data)
                }
                else {
                    toast.error('Could not fetch users')
                }
            }
            getUsers();
        }
    },[requestGroup])
  
  return (
    <Modal
            title={`Request Access to ${requestGroup||'this group'}`}
            ariaLabel={`Request Access to ${requestGroup||'this group'}`}
            visible={requestAccessModal}
            closable={false}
            modalStyle={{ width: '33.75rem' }}
            footerStyle={{ paddingTop: '8px', marginTop: '-8px', minHeight: '64px',display:'block' }}
            footer={
                <div style={{width: '100%'}}>
                    <div style={{marginTop:"10px"}}>
                        <small style={{fontSize:"0.8rem"}}>Issues gaining access? Contact the <a href="mailto:uk_cir_app_support@pwc.com">CIR team</a></small>
                    </div>
                    <div style={{display:'flex',gap:'5px',justifyContent:'flex-end'}}>
                        <Button kind="primary" onClick={()=>{setRequestAccessModal(false)}}>Close</Button>
                    </div>
                </div>
            }
        >
            <div>
                <p>{mailList?.length?'Please contact one of the following team members to request access:':'There is no supervisors yet assigned to this group.'}</p>
                <div style={{marginTop:"10px"}}>
                    {mailList?.map((user,i)=>(
                        <a href={`mailto:${user?.email}`} key={i}>{user?.email}</a>
                    ))}
                </div>
            </div>
        </Modal>
  )
}

export default RequestAccessModal