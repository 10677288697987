import React, { useState, useEffect, useContext, useCallback } from 'react';
import { Modal } from '@appkit4/react-components';
import { Button, CalendarPicker } from "../../components/ReadonlyAwareInputs";
import { getGroupData, setGroupData, getSelectedPeriod, getGroupName } from '../../services/GroupContext';
import toast from '../../components/DismissibleToast';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import minMax from 'dayjs/plugin/minMax'
import { AuthContext } from '../../services/AuthProvider';
import sqldatabaseService from '../../services/sqldatabase/sqldatabase.service';
import { get_relevant_periods } from '../../utils/PeriodUtils';
import { processDate, processDateJS, getDefaultPeriodEnd, getToday } from '../../utils/dateProcessor';
import { generateCAPId } from '../../utils/capProcessor';
dayjs.extend(minMax)
dayjs.extend(utc)

const APModal = ({ visible, setVisible, selectedCompany, companies, companyAPs, onSave, group_data }) => {
    const [is_error, set_is_error] = useState(false)
    const [isPosting, setIsPosting] = useState(false);
    const selectedPeriod = getSelectedPeriod();
    const { period_start, period_end } = selectedPeriod;

    const [table_data, set_table_data] = useState([{ company_ID: selectedCompany, start_date: new Date(period_start), end_date: new Date(period_start) }])
    const auth = useContext(AuthContext)
    const companyName = selectedCompany ? companies?.filter((c) => c?.company_ID === selectedCompany)[0]?.company_name : 'no Company';
    const [start_date_error, set_start_date_error] = useState([]);
    const [end_date_error, set_end_date_error] = useState([]);
    const [is_table_valid, set_is_table_valid] = useState(true);
    const [are_dates_valid, set_are_dates_valid] = useState(true);

    const startDateCirErrorMessage = 'start date is after CIR end date';
    const endDateCirErrorMessage = 'end date is before CIR start date';
    const startDateEndDateErrorMessage = "AP start date is after it's end date";
    const endDateStartDateErrorMessage = "AP end date is before it's start date";
    const periodExceedsYear = "The Period Exceeds 12 Months";

    useEffect(() => {
        // No point running this when modal isn't open
        if (!visible) {
            return;
        }

        if (!selectedCompany) {
            return;
        }

        const saved_data = getGroupData();
        if (!saved_data?.hasOwnProperty('company_accounting_periods')) {
            saved_data['company_accounting_periods'] = []
        }

        const ap_list = companyAPs?.map((cap) => {
            const updatedCap = {
                ...cap,
                company_ID: selectedCompany,
                start_date: processDateJS(cap?.start_date) ?? undefined,
                end_date: processDateJS(cap?.end_date) ?? undefined,
            }
            return updatedCap
        });

        if (ap_list[0] !== undefined) {
            set_table_data(ap_list);
            const startDateError = ap_list?.map((ap) => {
                return {
                    isError: processDateJS(ap?.start_date)?.isAfter(selectedPeriod?.period_end),
                    message: startDateCirErrorMessage
                }
            });
            set_start_date_error(startDateError);
            const endDateError = ap_list?.map((ap) => {
                return {
                    isError: processDateJS(ap?.end_date)?.isBefore(selectedPeriod?.period_start),
                    message: endDateCirErrorMessage
                };
            });
            ap_list?.forEach((ap, index) => {
                if (processDateJS(ap?.start_date)?.isAfter(processDateJS(ap?.end_date))) {
                    startDateError[index] = {
                        isError: true,
                        message: startDateEndDateErrorMessage
                    }
                }
            })
            set_end_date_error(endDateError);
        } else {
            set_table_data([{ company_ID: selectedCompany, start_date: processDateJS(period_start), end_date: processDateJS(period_end) }])
        }
    }, [selectedCompany, visible]);

    const isPeriodMoreThanYear = useCallback((startDate, endDate) => processDateJS(endDate)?.add(1, "day").diff(processDateJS(startDate), "days") > 371, [])

    const getErrorMessage = useCallback((ap_startDate, ap_endDate, selectedPeriodType, poa_selectedDate) => {
        if (isPeriodMoreThanYear(ap_startDate, ap_endDate)) {
            return periodExceedsYear;
        }
        else if ( selectedPeriodType === "start" && processDateJS(ap_startDate)?.isAfter(poa_selectedDate)) {
            return startDateCirErrorMessage;
        }
        else if (selectedPeriodType === "end" && processDateJS(ap_endDate)?.isBefore(poa_selectedDate)) {
            return endDateCirErrorMessage;
        }
    }, [isPeriodMoreThanYear])

    useEffect(() => {

        const startDateError = table_data?.map((ap) => {
            return {
                isError: isPeriodMoreThanYear(ap?.start_date, ap?.end_date) || processDateJS(ap?.start_date)?.isAfter(selectedPeriod?.period_end),
                message: getErrorMessage(ap?.start_date, ap?.end_date, "start", selectedPeriod?.period_start)
            }
        });
        set_start_date_error(startDateError);
        const endDateError = table_data?.map((ap) => {
            return {
                isError: isPeriodMoreThanYear(ap?.start_date, ap?.end_date) || processDateJS(ap?.end_date)?.isBefore(selectedPeriod?.period_start),
                message: getErrorMessage(ap?.start_date, ap?.end_date, "end", selectedPeriod?.period_start),
            };
        });
        table_data.forEach((ap, index) => {
            if (processDateJS(ap?.start_date)?.isAfter(processDateJS(ap?.end_date))) {
                startDateError[index] = {
                    isError: true,
                    message: startDateEndDateErrorMessage
                }
            }
        })
        set_end_date_error(endDateError);

        let datesValid = true;
        if (startDateError?.some(value => value?.isError === true) || endDateError?.some(value => value?.isError === true)) {
            datesValid = false;
        }
        set_are_dates_valid(datesValid);

        let tableValid = true;
        if (table_data?.length === 0) {
            tableValid = false;
        }

        for (const row of table_data) {
            const start_date = row?.start_date;
            if (!start_date) {
                tableValid = false;
                break;
            }
            if (!processDateJS(start_date)) {
                tableValid = false;
                break;
            }
            const end_date = row?.end_date;
            if (!end_date) {
                tableValid = false;
                break;
            }
            if (!processDateJS(end_date)) {
                tableValid = false;
                break;
            }
        }

        if (tableValid) {
            const caps = table_data?.sort((a, b) => { return a?.start_date - b?.start_date })
            outer: for (let i = 0; i < caps?.length; i++) {
                const start = processDateJS(caps[i]?.start_date);
                const end = processDateJS(caps[i]?.end_date);
                if (start === null || end === null) {
                    tableValid = false;
                    break;
                }

                for (let j = i + 1; j < caps?.length; j++) {
                    const nextStart = processDateJS(caps[j]?.start_date);
                    const nextEnd = processDateJS(caps[j]?.end_date);
                    if (nextStart === null || nextEnd === null) {
                        tableValid = false;
                        break outer;
                    }
                    
                    if (nextStart?.isSameOrBefore(end) && nextEnd?.isSameOrAfter(start)) {
                        tableValid = false;
                        break outer;
                    }
                }
            }
        }

        set_is_table_valid(tableValid);

        let isError = false;
        if (!datesValid || !tableValid || startDateError?.includes((val) => val?.isError) || endDateError?.includes((val) => val?.isError)) {
            isError = true;
        }
        set_is_error(isError);
    }, [table_data]);

    const removeDuplicates = (caps) => {
        const seen = new Set();
        return caps?.filter(cap => {
            const datePair = generateCAPId(cap);
            if (seen?.has(datePair)) {
                return false;
            } else {
                seen?.add(datePair);
                return true;
            }
        });
    };

    const clearDateErrors = () => {
        set_start_date_error([]);
        set_end_date_error([]);
    }

    const tableChange = (index, value, name) => {
        // Ensure dayjs objects aren't getting saved
        if (name === 'start_date' || name === 'end_date') {
            value = processDate(value);
        }

        const rowsInput = [...table_data];
        rowsInput[index][name] = value;

        // on changing end date of a row
        // start each following row from the end date of its previous AP
        if(name === 'end_date'){
            for(let i = index+1;i<rowsInput?.length;i++){
                // Check if AP is from OneSource
                if (!rowsInput[i].from_onesource) {
                    // calculate the duration for each AP
                    const duration = processDateJS(rowsInput[i]['end_date'])?.diff(processDateJS(rowsInput[i]['start_date']), 'days')
                    // set the start_date for each following AP depending on the past AP end_date + 1 day
                    rowsInput[i]['start_date'] = processDateJS(rowsInput[i-1]['end_date']).add(1,"day")
                    // set the end_date for each following AP depending on the AP start_date + AP duration
                    rowsInput[i]['end_date'] = processDateJS(rowsInput[i]['start_date']).add(duration,"day")
                }
            }
        }

        set_table_data(rowsInput);
    }

    const poaEndDate = processDateJS(selectedPeriod['period_end'])
    const poaStartDate = processDateJS(selectedPeriod['period_start'])
    const addTableRows = () => {
        const tableDataSorted = table_data.length?[...table_data]:[{ company_ID: selectedCompany, start_date: new Date(period_start), end_date: new Date(period_start) }]
        tableDataSorted?.sort((a,b)=>a?.start_date-b?.start_date)
        const lastAPEndDate = tableDataSorted[tableDataSorted?.length-1]?.end_date
        const firstAPStartDate = tableDataSorted[0]?.start_date


        if(  (lastAPEndDate < poaEndDate) ){
            const previousData = table_data?.sort((a, b) => {
                return a?.start_date - b?.start_date
            })

            const lastPoA = previousData[previousData?.length - 1];
            const lastPoAEnd = lastPoA?.end_date || processDateJS(period_start)?.subtract(1, 'day'); // Have to subtract one day


            const new_period_start = processDateJS(lastPoAEnd)?.add(1, 'day');

            const new_period_end = dayjs.min(new_period_start?.add(365, 'day'), processDateJS(period_end))

            const startDateError = [...start_date_error];
            startDateError?.push({
                isError: false,
                message: startDateCirErrorMessage
            });
            set_start_date_error(startDateError);

            const endDateError = [...end_date_error];
            endDateError?.push({
                isError: processDateJS(new_period_end)?.isBefore(processDateJS(new_period_start)) ?? false,
                message: endDateStartDateErrorMessage
            });
            set_end_date_error(endDateError);

            set_table_data(([...previousData,
            {
                "company_ID": selectedCompany,
                "start_date": processDate(new_period_start),
                "end_date": processDate(new_period_end)
            }]));
        }
    }

    const deleteTableRows = (e, index) => {
        const rows = [...table_data];
        rows?.splice(index, 1);
        set_table_data(rows);

        const startDateError = [...start_date_error];
        startDateError?.splice(index, 1);
        set_start_date_error(startDateError);

        const endDateError = [...end_date_error];
        endDateError?.splice(index, 1);
        set_end_date_error(endDateError);
    }

    const save = () => {
        if (!is_table_valid) {
            toast?.error('There were validation errors in the table')
            return;
        }

        const old_caps = companyAPs;

        const initialCompApIDs = old_caps.map((cap) => {
            return generateCAPId(cap);
        })

        let caps = group_data?.company_accounting_periods || [];
        
        const currPoaCompanyAPs = table_data?.map(ap => {
            const capID = generateCAPId(ap);
            delete ap['refreshing']
            return {
                ...ap,
                start_date: processDate(ap?.start_date),
                end_date: processDate(ap?.end_date),
                ...(initialCompApIDs.includes(capID) ? {} : {creation_date: getToday()})
            };
        });

        // remove any caps which have been deleted
        old_caps?.forEach(old_cap => {
            if (!currPoaCompanyAPs?.find(ap=>ap.capID === generateCAPId(old_cap))) {
                caps = caps?.filter(cap=>generateCAPId(old_cap)!==generateCAPId(cap));
            }
        })
        // add any new / edited caps without creating duplicates
        const new_caps = removeDuplicates(caps?.concat(currPoaCompanyAPs));

        const newData = {
            ...getGroupData(),
            company_accounting_periods: new_caps
        }
        onSave(newData);

        toast.promise(
            new Promise((resolve, reject) => {
                try {
                    setIsPosting(false);
                    setVisible(false);
                    clearDateErrors();
                    resolve('Saved Company AP data!');
                } catch (error) {
                    reject('Something went wrong saving Company AP data');
                }
            }),
            {
                pending: 'Saving Company AP data...',
                success: 'Saved Company AP data!',
                error: 'Something went wrong saving Company AP data',
            }
        );
    }

    const APinPrevPeriod = (APStartDate) => {
        const savedData = getGroupData()
        return processDateJS(APStartDate)?.isBefore(processDateJS(selectedPeriod['period_start'])) && !processDateJS(selectedPeriod['period_start'])?.isSame(processDateJS(savedData?.model_first_period_start))
    }

    return (
        <Modal
            visible={visible}
            title={`Company Accounting Period for ${companyName}`}
            ariaLabel={"Company Accounting Periods"}
            onCancel={() => {
                clearDateErrors();
                setVisible(false);
            }}
            maskCloseable={false}
            modalStyle={{ width: '33.75rem' }}
            footerStyle={{ paddingTop: '8px', marginTop: '-8px', minHeight: '64px' }}
            footer={
                <>
                <Button
                    hiddenInReadonly
                    disabled={isPosting}
                    onClick={() => {
                        clearDateErrors();
                        setVisible(false);
                    }} kind="secondary">Cancel</Button>
                <Button
                    hiddenInReadonly
                    loading={isPosting}
                    kind="primary"
                    disabled={!(is_table_valid && are_dates_valid)}
                    onClick={save}>Save</Button>
                </>
            }
            bodyStyle={{ minHeight: '200px' }}>

            <div>
                <table className="table" style={{ width: "100%" }}>
                    <thead>
                        <tr>
                            <th className="col" key={1}>Start Date</th>
                            <th className="col" key={2}>End Date</th>
                            <th><Button disabled={table_data[table_data?.length - 1]?.end_date >= poaEndDate} add className="btn btn-outline-success" onClick={addTableRows} ><span className="Appkit4-icon icon-plus-outline"></span></Button></th>
                        </tr>
                    </thead>
                    <tbody>
                        {table_data?.map((data, index) => {
                            return (
                                <tr key={`${data.start_date} ${data.from_onesource}`}>
                                    <td style={{ verticalAlign: end_date_error[index]?.isError || start_date_error[index]?.isError ? 'top' : 'middle', backgroundColor: data?.refreshing?.colour }} key={1}>
                                        <CalendarPicker
                                            format='DD/MM/YYYY'
                                            placeholder='dd/mm/yyyy'
                                            className="col"
                                            fieldWidth={150}
                                            value={data['start_date']}
                                            error={start_date_error[index]?.isError}
                                            useCustomValidation
                                            customErrorNode={start_date_error[index]?.message}
                                            minDate={data['end_date'] ? processDateJS(data['end_date'])?.subtract(12, 'month')?.add(1, 'day') : undefined}
                                            maxDate={ processDateJS(data['end_date']) || poaEndDate }
                                            onChange={(val, isValid) => {
                                                tableChange(index, val, 'start_date')}
                                            }
                                            disabled={index>0}
                                        />
                                    </td>
                                    <td style={{ verticalAlign: end_date_error[index]?.isError || start_date_error[index]?.isError ? 'top' : 'middle' }} key={2}>
                                        <CalendarPicker
                                            format='DD/MM/YYYY'
                                            placeholder='dd/mm/yyyy'
                                            className="col"
                                            fieldWidth={150}
                                            value={data['end_date']}
                                            error={end_date_error[index]?.isError}
                                            useCustomValidation
                                            customErrorNode={end_date_error[index]?.message}
                                            minDate={data['start_date'] ? processDateJS(data['start_date']) : poaStartDate}
                                            maxDate={data['start_date'] ? getDefaultPeriodEnd(data['start_date']) : undefined}
                                            onChange={(val, isValid) => {
                                                tableChange(index, val, 'end_date')
                                            }}
                                            disabled={APinPrevPeriod(data['start_date'])}
                                        />
                                    </td>
                                    <td style={{
                                        verticalAlign: end_date_error[index]?.isError || start_date_error[index]?.isError ? 'top' : 'middle',
                                        paddingTop: end_date_error[index]?.isError || start_date_error[index]?.isError ? '0.3rem' : ''
                                    }}>{<Button className="btn btn-outline-danger" onClick={(e) => deleteTableRows(e, index)} disabled={APinPrevPeriod(data['start_date'])}><span className="Appkit4-icon icon-close-outline"></span></Button>}</td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>

            </div>
        </Modal>
    );
};

export default APModal;
