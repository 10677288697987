export function formatFullReturn(group_period_sheet, group_level_elections_sheet, uk_group_companies_sheet, group_level_amounts_sheet, allocated_restrictions_sheet, allocated_reactivations_sheet){

    const group_bolds = ['B2','B7','B11','B15','B18','B24','B29','B33','B37','B40','B46','C24','C29'];
    for(let j = 0; j < group_bolds?.length; j++){
        group_period_sheet.getCell(group_bolds[j]).font = {bold:true}
    }
    group_period_sheet.getCell('D12').numFmt = 'dd/mm/yyyy'
    group_period_sheet.getCell('D13').numFmt = 'dd/mm/yyyy'
    group_period_sheet.mergeCells('B2:D2');
    group_period_sheet.mergeCells('B4:D5');
    group_period_sheet.mergeCells('B7:C7');
    group_period_sheet.mergeCells('B9:D9');
    group_period_sheet.mergeCells('B11:D11');
    group_period_sheet.mergeCells('B33:D33');
    group_period_sheet.mergeCells('B37:D37');
    group_period_sheet.mergeCells('B40:D40');
    group_period_sheet.mergeCells('B46:C46');
    const group_borders = ['B2', 'B4', 'B7', 'B9', 'B15', 'B18'];
    for(let j = 0; j < group_borders?.length; j++){
        group_period_sheet.getCell(group_borders[j]).border= {
            top: {style:'medium'},
            left: {style:'medium'},
            bottom: {style:'medium'},
            right: {style:'medium'}
            }
    };

    group_period_sheet.getCell('B11:D11').border= {top: {style:'medium'},left: {style:'medium'},right: {style:'medium'}, bottom: {style:'thin'}};
    group_period_sheet.getCell('B12').border= {left: {style:'medium'}};
    group_period_sheet.getCell('D12').border= {right: {style:'medium'}, bottom: {style:'thin'}, left: {style:'thin'}};
    group_period_sheet.getCell('B13').border= {bottom: {style:'medium'},left: {style:'medium'}};
    group_period_sheet.getCell('C13').border= {bottom: {style:'medium'}};
    group_period_sheet.getCell('D13').border= {bottom: {style:'medium'},right: {style:'medium'}, left: {style:'thin'}};

    group_period_sheet.getCell('B15').border= {top: {style:'medium'},left: {style:'medium'}};
    group_period_sheet.getCell('C15').border= {top: {style:'medium'}, bottom: {style:'thin'}, left: {style:'thin'}};
    group_period_sheet.getCell('D15').border= {top: {style:'medium'},right: {style:'medium'}, left: {style:'thin'}, bottom: {style:'thin'}};
    group_period_sheet.getCell('B16').border= {bottom: {style:'medium'},left: {style:'medium'}};
    group_period_sheet.getCell('C16').border= {bottom: {style:'medium'}, left: {style:'thin'}};
    group_period_sheet.getCell('D16').border= {bottom: {style:'medium'},right: {style:'medium'}, left: {style:'thin'}};

    group_period_sheet.getCell('B18').border= {top: {style:'medium'},left: {style:'medium'}};
    group_period_sheet.getCell('C18').border= {top: {style:'medium'}, bottom: {style:'thin'}, left: {style:'thin'}};
    group_period_sheet.getCell('D18').border= {top: {style:'medium'},right: {style:'medium'}, bottom: {style:'thin'}, left: {style:'thin'}};
    group_period_sheet.getCell('B19').border= {left: {style:'medium'}}
    group_period_sheet.getCell('C19').border= {bottom: {style:'thin'}, left: {style:'thin'}}
    group_period_sheet.getCell('D19').border= {right: {style:'medium'}, left: {style:'thin'}, bottom: {style:'thin'}};
    group_period_sheet.getCell('B20').border= {left: {style:'medium'}}
    group_period_sheet.getCell('C20').border= {bottom: {style:'thin'}, left: {style:'thin'}}
    group_period_sheet.getCell('D20').border= {right: {style:'medium'}, left: {style:'thin'}, bottom: {style:'thin'}};
    group_period_sheet.getCell('B21').border= {left: {style:'medium'}, bottom: {style:'thin'}}
    group_period_sheet.getCell('C21').border= {bottom: {style:'thin'}, left: {style:'thin'}}
    group_period_sheet.getCell('D21').border= {right: {style:'medium'},left: {style:'thin'}, bottom: {style:'thin'}};
    group_period_sheet.getCell('B22').border= {bottom: {style:'medium'},left: {style:'medium'}};
    group_period_sheet.getCell('C22').border= {bottom: {style:'medium'}, left: {style:'thin'}};
    group_period_sheet.getCell('D22').border= {bottom: {style:'medium'},right: {style:'medium'}, left: {style:'thin'}};

    group_period_sheet.getCell('B24').border= {top: {style:'medium'},left: {style:'medium'}, bottom: {style:'thin'}};
    group_period_sheet.getCell('C24').border= {top: {style:'medium'},right: {style:'medium'}, bottom: {style:'thin'},left: {style:'thin'}};
    group_period_sheet.getCell('B25').border= {left: {style:'medium'}, bottom: {style:'thin'}};
    group_period_sheet.getCell('C25').border= {right: {style:'medium'}, bottom: {style:'thin'},left: {style:'thin'}};
    group_period_sheet.getCell('B26').border= {left: {style:'medium'}, bottom: {style:'thin'}};
    group_period_sheet.getCell('C26').border= {right: {style:'medium'}, bottom: {style:'thin'},left: {style:'thin'}};
    group_period_sheet.getCell('B27').border= {bottom: {style:'medium'},left: {style:'medium'}};
    group_period_sheet.getCell('C27').border= {bottom: {style:'medium'},right: {style:'medium'}};

    group_period_sheet.getCell('B29').border= {top: {style:'medium'},left: {style:'medium'}, bottom: {style:'thin'}};
    group_period_sheet.getCell('C29').border= {top: {style:'medium'},right: {style:'medium'}, bottom: {style:'thin'}};
    group_period_sheet.getCell('B30').border= {left: {style:'medium'}, right: {style:'thin'}, bottom: {style:'thin'}};
    group_period_sheet.getCell('C30').border= {right: {style:'medium'}, bottom: {style:'thin'}};
    group_period_sheet.getCell('B31').border= {bottom: {style:'medium'},left: {style:'medium'}, right: {style:'thin'}};
    group_period_sheet.getCell('C31').border= {bottom: {style:'medium'},right: {style:'medium'}};

    group_period_sheet.getCell('B33:D33').border= {top: {style:'medium'},left: {style:'medium'},right: {style:'medium'}, bottom: {style:'thin'}};
    group_period_sheet.getCell('B34').border= {left: {style:'medium'}, bottom: {style:'thin'}};
    group_period_sheet.getCell('C34').border= {bottom: {style:'thin'}}
    group_period_sheet.getCell('D34').border= {right: {style:'medium'}, bottom: {style:'thin'}};
    group_period_sheet.getCell('B35').border= {bottom: {style:'medium'},left: {style:'medium'},right: {style:'medium'}};
    group_period_sheet.getCell('C35').border= {bottom: {style:'medium'}, right: {style:'medium'}};
    group_period_sheet.getCell('D35').border= {bottom: {style:'medium'},right: {style:'medium'}};

    group_period_sheet.getCell('B37:D37').border= {top: {style:'medium'},left: {style:'medium'},right: {style:'medium'}, bottom: {style:'thin'}};
    group_period_sheet.getCell('B38').border= {bottom: {style:'medium'},left: {style:'medium'},right: {style:'medium'}};
    group_period_sheet.getCell('C38').border= {bottom: {style:'medium'}, right: {style:'medium'}};
    group_period_sheet.getCell('D38').border= {bottom: {style:'medium'},right: {style:'medium'}};

    group_period_sheet.getCell('B40:D40').border= {top: {style:'medium'},left: {style:'medium'},right: {style:'medium'}, bottom: {style:'thin'}};
    group_period_sheet.getCell('B41').border= {left: {style:'medium'},right: {style:'thin'}, bottom: {style:'thin'}};
    group_period_sheet.getCell('C41').border= {right: {style:'thin'}, bottom: {style:'thin'}};
    group_period_sheet.getCell('D41').border= {right: {style:'medium'}, bottom: {style:'thin'}};
    group_period_sheet.getCell('B42').border= {left: {style:'medium'},right: {style:'thin'}, bottom: {style:'thin'}};
    group_period_sheet.getCell('C42').border= {right: {style:'thin'}, bottom: {style:'thin'}};
    group_period_sheet.getCell('D42').border= {right: {style:'medium'}, bottom: {style:'thin'}};
    group_period_sheet.getCell('B43').border= {left: {style:'medium'},right: {style:'thin'}, bottom: {style:'thin'}};
    group_period_sheet.getCell('C43').border= {right: {style:'thin'}, bottom: {style:'thin'}};
    group_period_sheet.getCell('D43').border= {right: {style:'medium'}, bottom: {style:'thin'}};
    group_period_sheet.getCell('B44').border= {left: {style:'medium'},right: {style:'thin'}, bottom: {style:'medium'}};
    group_period_sheet.getCell('C44').border= {right: {style:'thin'}, bottom: {style:'medium'}};
    group_period_sheet.getCell('D44').border= {right: {style:'medium'}, bottom: {style:'medium'}};

    group_period_sheet.getCell('B46:C46').border= {top: {style:'medium'},left: {style:'medium'},right: {style:'medium'}, bottom: {style:'thin'}};
    group_period_sheet.getCell('B47').border= {bottom: {style:'medium'},left: {style:'medium'}};
    group_period_sheet.getCell('C47').border= {bottom: {style:'medium'},right: {style:'medium'}};

    group_period_sheet.getColumn(1).width = 2;
    group_period_sheet.getColumn(2).width = 90;
    group_period_sheet.getColumn(3).width = 90;
    group_period_sheet.getColumn(4).width = 60;
    group_period_sheet.getColumn(5).width = 2;
    group_period_sheet.getCell('B4').alignment = { vertical: 'top', horizontal: 'left' };
    group_period_sheet.getRow(35).height = 100;
    group_period_sheet.getRow(38).height = 100;

    const group_Greys = ['B7', 'C7', 'B9', 'C9', 'D9', 'B11', 'C11', 'D11', 'B15', 'B18', 'B24', 'B33', 'C33', 'D33', 'B37', 'C37', 'D37','B40', 'C40', 'D40','B46', 'C46'];
    for (let i = 0; i < group_Greys.length; i++){
        group_period_sheet.getCell(group_Greys[i]).fill = {
            type: 'pattern',
            pattern:'solid',
            fgColor:{argb:'FFC0C0C0'}
            };        
    }

    const group_Lemons = ['B2', 'C2', 'D2', 'B4', 'B22','B27','C27', 'B34', 'C34','D34','C47'];
    for (let i = 0; i < group_Lemons.length; i++){
        group_period_sheet.getCell(group_Lemons[i]).fill = {
            type: 'pattern',
            pattern:'solid',
            fgColor:{argb:'FFFFFF99'}
            };        
    }

    const group_Golds = ['D12', 'D13', 'D15', 'D16', 'D18','D19','D20', 'D21', 'D22','C25','C26','C30','C31','B35','C35','D35','B38','C38','D38','C42','D42','C43','D43','C44','D44','B47'];
    for (let i = 0; i < group_Golds.length; i++){
        group_period_sheet.getCell(group_Golds[i]).fill = {
            type: 'pattern',
            pattern:'solid',
            fgColor:{argb:'FFFFFFCC'}
            };        
    }

    const gle_bolds = ['B2','B4','B6','B7','B8','B10','B12','B21','B25','B29','B33', 'C12', 'D12']
    for(let j = 0; j < gle_bolds?.length; j++){
        group_level_elections_sheet.getCell(gle_bolds[j]).font = {bold:true}
    }
    group_level_elections_sheet.mergeCells('C7:D7');
    group_level_elections_sheet.mergeCells('C8:D8');
    group_level_elections_sheet.mergeCells('C10:D10');

    group_level_elections_sheet.getCell('B2').border= {left: {style:'medium'}, top: {style:'medium'}, bottom: {style:'medium'}};
    group_level_elections_sheet.getCell('C2').border= {top: {style:'medium'}, bottom: {style:'medium'}};
    group_level_elections_sheet.getCell('D2').border= {right: {style:'medium'}, top: {style:'medium'}, bottom: {style:'medium'}};

    group_level_elections_sheet.getCell('B4').border= {left: {style:'medium'}, top: {style:'medium'}, bottom: {style:'medium'}};
    group_level_elections_sheet.getCell('C4').border= {top: {style:'medium'}, bottom: {style:'medium'}};
    group_level_elections_sheet.getCell('D4').border= {right: {style:'medium'}, top: {style:'medium'}, bottom: {style:'medium'}};

    group_level_elections_sheet.getCell('B6').border= {left: {style:'medium'}, top: {style:'medium'}, bottom: {style:'thin'}};
    group_level_elections_sheet.getCell('C6').border= {top: {style:'medium'}, bottom: {style:'thin'}};
    group_level_elections_sheet.getCell('D6').border= {right: {style:'medium'}, top: {style:'medium'}, bottom: {style:'thin'}};
    group_level_elections_sheet.getCell('B7').border= {left: {style:'medium'}, bottom: {style:'thin'},right: {style:'thin'}};
    group_level_elections_sheet.getCell('D7').border= {right: {style:'medium'}, bottom: {style:'thin'}};
    group_level_elections_sheet.getCell('B8').border= {left: {style:'medium'}, bottom: {style:'thin'},right: {style:'thin'}};
    group_level_elections_sheet.getCell('D8').border= {right: {style:'medium'}, bottom: {style:'thin'}};
    group_level_elections_sheet.getCell('B9').border= {left: {style:'medium'}, bottom: {style:'thin'},right: {style:'thin'}};
    group_level_elections_sheet.getCell('C9').border= {bottom: {style:'thin'}};
    group_level_elections_sheet.getCell('D9').border= {right: {style:'medium'}, bottom: {style:'thin'}};
    group_level_elections_sheet.getCell('B10').border= {left: {style:'medium'}, bottom: {style:'medium'},right: {style:'thin'}};
    group_level_elections_sheet.getCell('C10').border= {bottom: {style:'medium'},right: {style:'thin'}};
    group_level_elections_sheet.getCell('D10').border= {right: {style:'medium'}, bottom: {style:'medium'}};

    group_level_elections_sheet.getCell('B12').border= {left: {style:'medium'}, top: {style:'medium'}, bottom: {style:'thin'},right: {style:'thin'}};
    group_level_elections_sheet.getCell('C12').border= {top: {style:'medium'}, bottom: {style:'thin'},right: {style:'thin'}};
    group_level_elections_sheet.getCell('D12').border= {right: {style:'medium'}, top: {style:'medium'}, bottom: {style:'thin'}};
    group_level_elections_sheet.getCell('B13').border= {left: {style:'medium'}, bottom: {style:'thin'},right: {style:'thin'}};
    group_level_elections_sheet.getCell('C13').border= {bottom: {style:'thin'},right: {style:'thin'}};
    group_level_elections_sheet.getCell('D13').border= {right: {style:'medium'}, bottom: {style:'thin'}};
    group_level_elections_sheet.getCell('B14').border= {left: {style:'medium'}, bottom: {style:'thin'},right: {style:'thin'}};
    group_level_elections_sheet.getCell('C14').border= {bottom: {style:'thin'},right: {style:'thin'}};
    group_level_elections_sheet.getCell('D14').border= {right: {style:'medium'}, bottom: {style:'thin'}};
    group_level_elections_sheet.getCell('B15').border= {left: {style:'medium'}, bottom: {style:'thin'},right: {style:'thin'}};
    group_level_elections_sheet.getCell('C15').border= {bottom: {style:'thin'},right: {style:'thin'}};
    group_level_elections_sheet.getCell('D15').border= {right: {style:'medium'}, bottom: {style:'thin'}};
    group_level_elections_sheet.getCell('B16').border= {left: {style:'medium'}, bottom: {style:'thin'},right: {style:'thin'}};
    group_level_elections_sheet.getCell('C16').border= {bottom: {style:'thin'},right: {style:'thin'}};
    group_level_elections_sheet.getCell('D16').border= {right: {style:'medium'}, bottom: {style:'thin'}};
    group_level_elections_sheet.getCell('B17').border= {left: {style:'medium'}, bottom: {style:'thin'},right: {style:'thin'}};
    group_level_elections_sheet.getCell('C17').border= {bottom: {style:'thin'},right: {style:'thin'}};
    group_level_elections_sheet.getCell('D17').border= {right: {style:'medium'}, bottom: {style:'thin'}};
    group_level_elections_sheet.getCell('B18').border= {left: {style:'medium'}, bottom: {style:'medium'},right: {style:'thin'}};
    group_level_elections_sheet.getCell('C18').border= {bottom: {style:'medium'},right: {style:'thin'}};
    group_level_elections_sheet.getCell('D18').border= {right: {style:'medium'}, bottom: {style:'medium'}};

    group_level_elections_sheet.getCell('B21').border= {left: {style:'medium'}, top: {style:'medium'}};
    group_level_elections_sheet.getCell('C21').border= {top: {style:'medium'}};
    group_level_elections_sheet.getCell('D21').border= {right: {style:'medium'}, top: {style:'medium'}};
    group_level_elections_sheet.getCell('B22').border= {left: {style:'medium'}, bottom: {style:'thin'}};
    group_level_elections_sheet.getCell('C22').border= {bottom: {style:'thin'}};
    group_level_elections_sheet.getCell('D22').border= {right: {style:'medium'}, bottom: {style:'thin'}};
    group_level_elections_sheet.getCell('B23').border= {left: {style:'medium'}, bottom: {style:'medium'}};
    group_level_elections_sheet.getCell('C23').border= {bottom: {style:'medium'}};
    group_level_elections_sheet.getCell('D23').border= {right: {style:'medium'}, bottom: {style:'medium'}};

    group_level_elections_sheet.getCell('B25').border= {left: {style:'medium'}, top: {style:'medium'}};
    group_level_elections_sheet.getCell('C25').border= {top: {style:'medium'}};
    group_level_elections_sheet.getCell('D25').border= {right: {style:'medium'}, top: {style:'medium'}};
    group_level_elections_sheet.getCell('B26').border= {left: {style:'medium'}, bottom: {style:'thin'}};
    group_level_elections_sheet.getCell('C26').border= {bottom: {style:'thin'}};
    group_level_elections_sheet.getCell('D26').border= {right: {style:'medium'}, bottom: {style:'thin'}};
    group_level_elections_sheet.getCell('B27').border= {left: {style:'medium'}, bottom: {style:'medium'}};
    group_level_elections_sheet.getCell('C27').border= {bottom: {style:'medium'}};
    group_level_elections_sheet.getCell('D27').border= {right: {style:'medium'}, bottom: {style:'medium'}};
    
    group_level_elections_sheet.getCell('B29').border= {left: {style:'medium'}, top: {style:'medium'}};
    group_level_elections_sheet.getCell('C29').border= {top: {style:'medium'}};
    group_level_elections_sheet.getCell('D29').border= {right: {style:'medium'}, top: {style:'medium'}};
    group_level_elections_sheet.getCell('B30').border= {left: {style:'medium'}, bottom: {style:'thin'}};
    group_level_elections_sheet.getCell('C30').border= {bottom: {style:'thin'}};
    group_level_elections_sheet.getCell('D30').border= {right: {style:'medium'}, bottom: {style:'thin'}};
    group_level_elections_sheet.getCell('B31').border= {left: {style:'medium'}, bottom: {style:'medium'}};
    group_level_elections_sheet.getCell('C31').border= {bottom: {style:'medium'}};
    group_level_elections_sheet.getCell('D31').border= {right: {style:'medium'}, bottom: {style:'medium'}};

    group_level_elections_sheet.getCell('B33').border= {left: {style:'medium'}, top: {style:'medium'}, bottom: {style:'thin'}};
    group_level_elections_sheet.getCell('C33').border= {right: {style:'medium'}, top: {style:'medium'}, bottom: {style:'thin'}};
    group_level_elections_sheet.getCell('B34').border= {left: {style:'medium'}, bottom: {style:'medium'}};
    group_level_elections_sheet.getCell('C34').border= {right: {style:'medium'}, bottom: {style:'medium'}};

    group_level_elections_sheet.getColumn(1).width = 2;
    group_level_elections_sheet.getColumn(2).width = 165;
    group_level_elections_sheet.getColumn(3).width = 20;
    group_level_elections_sheet.getColumn(4).width = 20;
    group_level_elections_sheet.getColumn(5).width = 2;
    group_level_elections_sheet.getRow(9).height = 2;
    group_level_elections_sheet.getRow(23).height = 80;
    group_level_elections_sheet.getRow(27).height = 80;
    group_level_elections_sheet.getRow(31).height = 80;

    group_level_elections_sheet.getCell('B23').alignment = { vertical: 'top', horizontal: 'left' };
    group_level_elections_sheet.getCell('B27').alignment = { vertical: 'top', horizontal: 'left' };
    group_level_elections_sheet.getCell('B31').alignment = { vertical: 'top', horizontal: 'left' };
    
    group_level_elections_sheet.getCell('C13').alignment = { horizontal: 'center' };
    group_level_elections_sheet.getCell('C14').alignment = { horizontal: 'center' };
    group_level_elections_sheet.getCell('C15').alignment = { horizontal: 'center' };
    group_level_elections_sheet.getCell('C16').alignment = { horizontal: 'center' };
    group_level_elections_sheet.getCell('C17').alignment = { horizontal: 'center' };
    group_level_elections_sheet.getCell('C18').alignment = { horizontal: 'center' };
    group_level_elections_sheet.getCell('D13').alignment = { horizontal: 'center' };
    group_level_elections_sheet.getCell('D14').alignment = { horizontal: 'center' };
    group_level_elections_sheet.getCell('D15').alignment = { horizontal: 'center' };
    group_level_elections_sheet.getCell('D16').alignment = { horizontal: 'center' };
    group_level_elections_sheet.getCell('D17').alignment = { horizontal: 'center' };
    group_level_elections_sheet.getCell('D18').alignment = { horizontal: 'center' };

    const gle_Greys = ['B2', 'C2', 'D2', 'B4', 'C4', 'D4', 'B7', 'B8', 'B9', 'B10', 'B12', 'B21', 'B25', 'B29', 'B33', 'C21', 'D21',  'C25', 'D25', 'C29', 'D29', 'C33'];
    for (let i = 0; i < gle_Greys.length; i++){
        group_level_elections_sheet.getCell(gle_Greys[i]).fill = {
            type: 'pattern',
            pattern:'solid',
            fgColor:{argb:'FFC0C0C0'}
            };        
    }

    const gle_Lemons = ['B6', 'C6', 'D6', 'C34'];
    for (let i = 0; i < gle_Lemons.length; i++){
        group_level_elections_sheet.getCell(gle_Lemons[i]).fill = {
            type: 'pattern',
            pattern:'solid',
            fgColor:{argb:'FFFFFF99'}
            };        
    }

    const gle_Golds = ['C7', 'D7', 'C8', 'D8', 'C9','D9','C10', 'D10', 'C13','D13','C14','D14','C15','C16','C17','D17','C18','B23','C23','D23','B27','C27','D27','B31','C31','D31','B34'];
    for (let i = 0; i < gle_Golds.length; i++){
        group_level_elections_sheet.getCell(gle_Golds[i]).fill = {
            type: 'pattern',
            pattern:'solid',
            fgColor:{argb:'FFFFFFCC'}
            };        
    }

    uk_group_companies_sheet.getColumn(1).width = 2;
    uk_group_companies_sheet.getColumn(2).width = 8;
    uk_group_companies_sheet.getColumn(3).width = 115;
    uk_group_companies_sheet.getColumn(4).width = 20;
    uk_group_companies_sheet.getColumn(5).width = 15;
    uk_group_companies_sheet.getColumn(6).width = 15;
    uk_group_companies_sheet.getColumn(7).width = 5;
    uk_group_companies_sheet.getColumn(8).width = 36;
    uk_group_companies_sheet.getColumn(9).width = 36;
    uk_group_companies_sheet.getColumn(10).width = 36;
    uk_group_companies_sheet.getColumn(11).width = 2;
    uk_group_companies_sheet.getRow(1).height = 3;
    uk_group_companies_sheet.getRow(3).height = 5;
    uk_group_companies_sheet.getRow(5).height = 5;
    uk_group_companies_sheet.getRow(11).height = 5;
    uk_group_companies_sheet.getRow(14).height = 35;

    uk_group_companies_sheet.mergeCells("B2:J2");
    uk_group_companies_sheet.mergeCells("B4:J4");
    uk_group_companies_sheet.mergeCells("B6:F6");
    uk_group_companies_sheet.mergeCells("B7:C7");
    uk_group_companies_sheet.mergeCells("B8:C8");
    uk_group_companies_sheet.mergeCells("B9:C9");
    uk_group_companies_sheet.mergeCells("B10:C10");
    uk_group_companies_sheet.mergeCells("D7:F7");
    uk_group_companies_sheet.mergeCells("D8:F8");
    uk_group_companies_sheet.mergeCells("D9:F9");
    uk_group_companies_sheet.mergeCells("D10:F10");
    uk_group_companies_sheet.mergeCells("C12:F13");
    uk_group_companies_sheet.mergeCells("B12:B13");
    uk_group_companies_sheet.mergeCells("H12:J12");
    uk_group_companies_sheet.mergeCells("H13:J13");

    uk_group_companies_sheet.getCell('B2').border= {left: {style:'medium'}, top: {style:'medium'}, bottom: {style:'medium'}, right: {style:'medium'}};
    uk_group_companies_sheet.getCell('B4').border= {left: {style:'medium'}, top: {style:'medium'}, bottom: {style:'medium'}, right: {style:'medium'}};
    uk_group_companies_sheet.getCell('B6').border= {left: {style:'medium'}, top: {style:'medium'}, bottom: {style:'medium'}, right: {style:'medium'}};
    uk_group_companies_sheet.getCell('B7').border= {left: {style:'medium'}, bottom: {style:'thin'}, right: {style:'thin'}};
    uk_group_companies_sheet.getCell('D7').border= {right: {style:'thin'}};
    uk_group_companies_sheet.getCell('B8').border= {left: {style:'medium'}, bottom: {style:'thin'}, right: {style:'thin'}};
    uk_group_companies_sheet.getCell('D8').border= {right: {style:'thin'}};
    uk_group_companies_sheet.getCell('B9').border= {left: {style:'medium'}, bottom: {style:'thin'}, right: {style:'thin'}};
    uk_group_companies_sheet.getCell('D9').border= {right: {style:'thin'}};
    uk_group_companies_sheet.getCell('B10').border= {left: {style:'medium'}, bottom: {style:'medium'}, right: {style:'thin'}};
    uk_group_companies_sheet.getCell('D10').border= {right: {style:'thin'}, bottom: {style:'medium'},};
    uk_group_companies_sheet.getCell('B12').border= {left: {style:'medium'}, bottom: {style:'medium'}, top: {style:'medium'}};
    uk_group_companies_sheet.getCell('C12').border= {right: {style:'medium'}, bottom: {style:'medium'}, top: {style:'medium'}};
    uk_group_companies_sheet.getCell('H12').border= {left: {style:'medium'}, right: {style:'medium'}, top: {style:'medium'}};
    uk_group_companies_sheet.getCell('H13').border= {left: {style:'medium'}, right: {style:'medium'}, bottom: {style:'medium'}};
    for (let row = 14; row < 315; row++){
        uk_group_companies_sheet.getCell('B' + row).border= {left: {style:'medium'}, bottom: {style:'thin'}, right: {style:'thin'}};
        uk_group_companies_sheet.getCell('B' + row).alignment = { horizontal: 'center' };
        uk_group_companies_sheet.getCell('C' + row).border= {bottom: {style:'thin'}, right: {style:'thin'}};
        uk_group_companies_sheet.getCell('D' + row).border= {bottom: {style:'thin'}, right: {style:'thin'}};
        uk_group_companies_sheet.getCell('D' + row).alignment = { horizontal: 'center' };
        uk_group_companies_sheet.getCell('E' + row).border= {bottom: {style:'thin'}, right: {style:'thin'}};
        uk_group_companies_sheet.getCell('E' + row).alignment = { horizontal: 'center' };
        uk_group_companies_sheet.getCell('F' + row).border= {bottom: {style:'thin'}, right: {style:'medium'}};
        uk_group_companies_sheet.getCell('F' + row).alignment = { horizontal: 'center' };
        uk_group_companies_sheet.getCell('H' + row).border= {left: {style:'medium'}, bottom: {style:'thin'}, right: {style:'thin'}};
        uk_group_companies_sheet.getCell('I' + row).border= {bottom: {style:'thin'}, right: {style:'thin'}};
        uk_group_companies_sheet.getCell('J' + row).border= {bottom: {style:'thin'}, right: {style:'medium'}};
    }
    
    uk_group_companies_sheet.getCell('B315').border= {left: {style:'medium'}, bottom: {style:'medium'}, right: {style:'thin'}};
    uk_group_companies_sheet.getCell('C315').border= {bottom: {style:'medium'}, right: {style:'thin'}};
    uk_group_companies_sheet.getCell('D315').border= {bottom: {style:'medium'}, right: {style:'thin'}};
    uk_group_companies_sheet.getCell('E315').border= {bottom: {style:'medium'}, right: {style:'thin'}};
    uk_group_companies_sheet.getCell('F315').border= {bottom: {style:'medium'}, right: {style:'medium'}};
    uk_group_companies_sheet.getCell('H315').border= {left: {style:'medium'}, bottom: {style:'medium'}, right: {style:'thin'}};
    uk_group_companies_sheet.getCell('I315').border= {bottom: {style:'medium'}, right: {style:'thin'}};
    uk_group_companies_sheet.getCell('J315').border= {bottom: {style:'medium'}, right: {style:'medium'}};
    uk_group_companies_sheet.getCell('C320').border= {left: {style:'medium'}, top: {style:'medium'}};
    uk_group_companies_sheet.getCell('D320').border= {top: {style:'medium'}};
    uk_group_companies_sheet.getCell('E320').border= {right: {style:'medium'}, top: {style:'medium'}};
    uk_group_companies_sheet.getCell('C321').border= {left: {style:'medium'}, bottom: {style:'medium'}};
    uk_group_companies_sheet.getCell('D321').border= {bottom: {style:'medium'}};
    uk_group_companies_sheet.getCell('E321').border= {right: {style:'medium'}, bottom: {style:'medium'}};
    uk_group_companies_sheet.getCell('H317').border= {left: {style:'medium'}, top: {style:'medium'}};
    uk_group_companies_sheet.getCell('I317').border= {top: {style:'medium'}};
    uk_group_companies_sheet.getCell('J317').border= {right: {style:'medium'}, top: {style:'medium'}};

    uk_group_companies_sheet.getCell('H318').border= {left: {style:'medium'}, bottom: {style:'thin'}, right: {style:'thin'}};
    uk_group_companies_sheet.getCell('I318').border= {right: {style:'thin'}, bottom: {style:'thin'}};
    uk_group_companies_sheet.getCell('J318').border= {right: {style:'medium'}}
    uk_group_companies_sheet.getCell('H319').border= {left: {style:'medium'}, bottom: {style:'thin'}, right: {style:'thin'}};
    uk_group_companies_sheet.getCell('I319').border= {right: {style:'thin'}, bottom: {style:'thin'}};
    uk_group_companies_sheet.getCell('J319').border= {right: {style:'medium'}};    
    uk_group_companies_sheet.getCell('H320').border= {left: {style:'medium'}, bottom: {style:'thin'}};
    uk_group_companies_sheet.getCell('I320').border= { bottom: {style:'thin'}};
    uk_group_companies_sheet.getCell('J320').border= {right: {style:'medium'}, bottom: {style:'thin'}};
    uk_group_companies_sheet.getCell('H321').border= {left: {style:'medium'},bottom: {style:'thin'}, right: {style:'thin'}};
    uk_group_companies_sheet.getCell('I321').border= { bottom: {style:'thin'}, right: {style:'thin'}};
    uk_group_companies_sheet.getCell('J321').border= {right: {style:'medium'}, bottom: {style:'thin'}};
    uk_group_companies_sheet.getCell('H322').border= {left: {style:'medium'},bottom: {style:'medium'}, right: {style:'thin'}};
    uk_group_companies_sheet.getCell('I322').border= { bottom: {style:'medium'}, right: {style:'thin'}};
    uk_group_companies_sheet.getCell('J322').border= {right: {style:'medium'}, bottom: {style:'medium'}};
    uk_group_companies_sheet.getCell('B14').alignment = {wrapText : true};
    uk_group_companies_sheet.getCell('D9').value = { formula: "='(1)_Group,_period,_return_type_'!D22"};
    uk_group_companies_sheet.getCell('H319').value = { formula: '=SUM(H16:H315)'};
    uk_group_companies_sheet.getCell('I319').value = { formula: '=SUM(I16:I315)'};
    uk_group_companies_sheet.getCell('H322').value = { formula: '=MAX((H319-I319),0)'};
    uk_group_companies_sheet.getCell('I322').value = { formula: '=MAX((I319-H319),0)'};
    uk_group_companies_sheet.getCell('J322').value = { formula: '=MAX(SUM(J16:J315),0)'};

    uk_group_companies_sheet.getCell('B14').alignment = {wrapText:true,  horizontal: 'center'}
    uk_group_companies_sheet.getCell('E14').alignment = {wrapText:true,  horizontal: 'center'}


    const ugc_bolds = ['B2','B4','B7','B8','B9','B10','C12','C14','D14','E14','F14','D15','E15','F15','H12','H13','H14','I14','J14','C320','H317','H318','I318','H321','I321','J321'];
    for(let j = 0; j < ugc_bolds?.length; j++){
        uk_group_companies_sheet.getCell(ugc_bolds[j]).font = {bold:true}
    }

    const ugc_Greys = ['B2','B4', 'B7', 'B8', 'B9', 'B10', 'B12', 'C12', 'H12', 'H13', 'C320', 'D320', 'E320', 'H317', 'I317', 'J317' ];
    for (let i = 0; i < ugc_Greys.length; i++){
        uk_group_companies_sheet.getCell(ugc_Greys[i]).fill = {
            type: 'pattern',
            pattern:'solid',
            fgColor:{argb:'FFC0C0C0'}
            };        
    }

    let buildGoldsList = [];
    let startLRow = 16
    for (let i = 0; i < 300; i++){
        let row = startLRow + i
        buildGoldsList.push('C' + row);
        buildGoldsList.push('D' + row);
        buildGoldsList.push('E' + row);
        buildGoldsList.push('F' + row);
    }
    
    const ugc_Lemons = ['B6','C15','D15','E15','F15','H15','I15','J15','C321'];
    for (let i = 0; i < ugc_Lemons.length; i++){
        uk_group_companies_sheet.getCell(ugc_Lemons[i]).fill = {
            type: 'pattern',
            pattern:'solid',
            fgColor:{argb:'FFFFFF99'}
            };        
    }

    const ugc_Golds_others = ['E321'];
    const ugc_Golds = ugc_Golds_others.concat(buildGoldsList);
    for (let i = 0; i < ugc_Golds.length; i++){
        uk_group_companies_sheet.getCell(ugc_Golds[i]).fill = {
            type: 'pattern',
            pattern:'solid',
            fgColor:{argb:'FFFFFFCC'}
            };        
    }

    let buildBluesList = [];
    let startBRow = 15
    for (let i = 0; i < 301; i++){
        let row = startBRow + i
        buildBluesList.push('H' + row);
        buildBluesList.push('I' + row);
        buildBluesList.push('J' + row)
    }
    const ugc_Blues_others = ['H1', 'D321', 'H319', 'I319', 'H322', 'I322', 'J322'];
    const ugc_Blues = ugc_Blues_others.concat(buildBluesList)
    for (let i = 0; i < ugc_Blues.length; i++){
        uk_group_companies_sheet.getCell(ugc_Blues[i]).fill = {
            type: 'pattern',
            pattern:'solid',
            fgColor:{argb:'FFCCFFFF'} 
            };        
    }

    for (let i = 0; i < 316; i++){
        let cell = 'H' + (startLRow + i);
        uk_group_companies_sheet.getCell(cell).numFmt = '#,##0';
        cell = 'I' + (startLRow + i);
        uk_group_companies_sheet.getCell(cell).numFmt = '#,##0';
        cell = 'J' + (startLRow + i);
        uk_group_companies_sheet.getCell(cell).numFmt = '#,##0';
    }


    group_level_amounts_sheet.getColumn(1).width = 2;
    group_level_amounts_sheet.getColumn(2).width = 105;
    group_level_amounts_sheet.getColumn(3).width = 50;
    group_level_amounts_sheet.getColumn(4).width = 5;
    group_level_amounts_sheet.getRow(1).height = 5;
    group_level_amounts_sheet.getRow(30).height = 5;

    const gla_bolds = ['B2','B4','B7','B8','B9','B10','B12','B13','B15','C15','C36'];
    for(let j = 0; j < gla_bolds?.length; j++){
        group_level_amounts_sheet.getCell(gla_bolds[j]).font = {bold:true}
    }

    group_level_amounts_sheet.mergeCells('B2:C2');
    group_level_amounts_sheet.mergeCells('B4:C4');
    group_level_amounts_sheet.mergeCells('B6:C6');
    group_level_amounts_sheet.mergeCells('B12:C12');
    group_level_amounts_sheet.mergeCells('B13:C13');
    group_level_amounts_sheet.mergeCells('B37:C37');

    group_level_amounts_sheet.getCell('B2').border= {left: {style:'medium'}, top: {style:'medium'}, bottom: {style:'medium'}, right: {style:'medium'}};
    group_level_amounts_sheet.getCell('B4').border= {left: {style:'medium'}, top: {style:'medium'}, bottom: {style:'medium'}, right: {style:'medium'}};
    group_level_amounts_sheet.getCell('B6').border= {left: {style:'medium'}, top: {style:'medium'}, right: {style:'medium'}, bottom: {style:'thin'}};
    group_level_amounts_sheet.getCell('B7').border= {left: {style:'medium'}, bottom: {style:'thin'}, right: {style:'thin'}};
    group_level_amounts_sheet.getCell('C7').border= {right: {style:'medium'}, bottom: {style:'thin'}, right: {style:'medium'}};
    group_level_amounts_sheet.getCell('B8').border= {left: {style:'medium'}, bottom: {style:'thin'}, right: {style:'thin'}};
    group_level_amounts_sheet.getCell('C8').border= {right: {style:'medium'}, bottom: {style:'thin'}, right: {style:'medium'}};
    group_level_amounts_sheet.getCell('B9').border= {left: {style:'medium'}, bottom: {style:'thin'}, right: {style:'thin'}};
    group_level_amounts_sheet.getCell('C9').border= {right: {style:'medium'}, bottom: {style:'thin'}, right: {style:'medium'}};
    group_level_amounts_sheet.getCell('B10').border= {left: {style:'medium'}, bottom: {style:'medium'}, right: {style:'thin'}};
    group_level_amounts_sheet.getCell('C10').border= {right: {style:'medium'}, bottom: {style:'medium'}, right: {style:'medium'}};
    group_level_amounts_sheet.getCell('B12').border= {left: {style:'medium'}, top: {style:'medium'}, right: {style:'medium'}};
    group_level_amounts_sheet.getCell('B13').border= {left: {style:'medium'}, bottom: {style:'medium'}, right: {style:'medium'}};
    group_level_amounts_sheet.getCell('B15').border= {left: {style:'medium'}, top: {style:'medium'}};
    group_level_amounts_sheet.getCell('C15').border= {right: {style:'medium'}, top: {style:'medium'}};
    group_level_amounts_sheet.getCell('B16').border= {left: {style:'medium'}, bottom: {style:'thin'}};
    group_level_amounts_sheet.getCell('C16').border= {right: {style:'medium'}, bottom: {style:'thin'}};
    group_level_amounts_sheet.getCell('B17').border= {right: {style:'thin'}, left: {style:'medium'}, bottom: {style:'thin'}};
    group_level_amounts_sheet.getCell('C17').border= {right: {style:'medium'}, bottom: {style:'thin'}};
    group_level_amounts_sheet.getCell('B18').border= {right: {style:'thin'}, left: {style:'medium'}, bottom: {style:'thin'}};
    group_level_amounts_sheet.getCell('C18').border= {right: {style:'medium'}, bottom: {style:'thin'}};
    group_level_amounts_sheet.getCell('B19').border= {right: {style:'thin'}, left: {style:'medium'}, bottom: {style:'thin'}};
    group_level_amounts_sheet.getCell('C19').border= {right: {style:'medium'}, bottom: {style:'thin'}};
    group_level_amounts_sheet.getCell('B20').border= {right: {style:'thin'}, left: {style:'medium'}, bottom: {style:'thin'}};
    group_level_amounts_sheet.getCell('C20').border= {right: {style:'medium'}, bottom: {style:'thin'}};
    group_level_amounts_sheet.getCell('B21').border= {left: {style:'medium'}, bottom: {style:'thin'}};
    group_level_amounts_sheet.getCell('C21').border= {right: {style:'medium'}, bottom: {style:'thin'}};
    group_level_amounts_sheet.getCell('B22').border= {right: {style:'thin'}, left: {style:'medium'}, bottom: {style:'thin'}};
    group_level_amounts_sheet.getCell('C22').border= {right: {style:'medium'}, bottom: {style:'thin'}};
    group_level_amounts_sheet.getCell('B23').border= {right: {style:'thin'}, left: {style:'medium'}, bottom: {style:'thin'}};
    group_level_amounts_sheet.getCell('C23').border= {right: {style:'medium'}, bottom: {style:'thin'}};
    group_level_amounts_sheet.getCell('B24').border= {right: {style:'thin'}, left: {style:'medium'}, bottom: {style:'thin'}};
    group_level_amounts_sheet.getCell('C24').border= {right: {style:'medium'}, bottom: {style:'thin'}};
    group_level_amounts_sheet.getCell('B25').border= {right: {style:'thin'}, left: {style:'medium'}, bottom: {style:'thin'}};
    group_level_amounts_sheet.getCell('C25').border= {right: {style:'medium'}, bottom: {style:'thin'}};
    group_level_amounts_sheet.getCell('B26').border= {right: {style:'thin'}, left: {style:'medium'}, bottom: {style:'thin'}};
    group_level_amounts_sheet.getCell('C26').border= {right: {style:'medium'}, bottom: {style:'thin'}};
    group_level_amounts_sheet.getCell('B27').border= {right: {style:'thin'}, left: {style:'medium'}, bottom: {style:'thin'}};
    group_level_amounts_sheet.getCell('C27').border= {right: {style:'medium'}, bottom: {style:'thin'}};
    group_level_amounts_sheet.getCell('B28').border= {left: {style:'medium'}, bottom: {style:'thin'}};
    group_level_amounts_sheet.getCell('C28').border= {right: {style:'medium'}, bottom: {style:'thin'}};
    group_level_amounts_sheet.getCell('B29').border= {right: {style:'thin'}, left: {style:'medium'}, bottom: {style:'thin'}};
    group_level_amounts_sheet.getCell('C29').border= {right: {style:'medium'}, bottom: {style:'thin'}};
    group_level_amounts_sheet.getCell('B30').border= {left: {style:'medium'}, bottom: {style:'thin'}};
    group_level_amounts_sheet.getCell('C30').border= {right: {style:'medium'}, bottom: {style:'thin'}};
    group_level_amounts_sheet.getCell('B31').border= {right: {style:'thin'}, left: {style:'medium'}, bottom: {style:'thin'}};
    group_level_amounts_sheet.getCell('C31').border= {right: {style:'medium'}, bottom: {style:'thin'}};
    group_level_amounts_sheet.getCell('B32').border= {right: {style:'thin'}, left: {style:'medium'}, bottom: {style:'thin'}};
    group_level_amounts_sheet.getCell('C32').border= {right: {style:'medium'}, bottom: {style:'thin'}};
    group_level_amounts_sheet.getCell('B33').border= {right: {style:'thin'}, left: {style:'medium'}, bottom: {style:'thin'}};
    group_level_amounts_sheet.getCell('C33').border= {right: {style:'medium'}, bottom: {style:'thin'}};
    group_level_amounts_sheet.getCell('B34').border= {right: {style:'thin'}, left: {style:'medium'}, bottom: {style:'medium'}};
    group_level_amounts_sheet.getCell('C34').border= {right: {style:'medium'}, bottom: {style:'medium'}};
    group_level_amounts_sheet.getCell('B36').border= {left: {style:'medium'}, top: {style:'medium'}, bottom: {style:'thin'}, right: {style:'medium'}};
    group_level_amounts_sheet.getCell('B37').border= {left: {style:'medium'}, bottom: {style:'thin'}};
    group_level_amounts_sheet.getCell('C37').border= {right: {style:'medium'}, bottom: {style:'thin'}}
    group_level_amounts_sheet.getCell('B38').border= {left: {style:'medium'}, bottom: {style:'medium'}};
    group_level_amounts_sheet.getCell('C38').border= {right: {style:'medium'}, bottom: {style:'medium'}};
    group_level_amounts_sheet.getCell('C17').alignment = { horizontal: 'right' };
    group_level_amounts_sheet.getCell('C19').alignment = { horizontal: 'right' };
    group_level_amounts_sheet.getCell('C18').numFmt = '#,##0';
    group_level_amounts_sheet.getCell('C20').numFmt = '#,##0';
    group_level_amounts_sheet.getCell('C22').numFmt = '#,##0';
    group_level_amounts_sheet.getCell('C23').numFmt = '#,##0';
    group_level_amounts_sheet.getCell('C24').numFmt = '#,##0';
    group_level_amounts_sheet.getCell('C25').numFmt = '#,##0';
    group_level_amounts_sheet.getCell('C26').numFmt = '#,##0';
    group_level_amounts_sheet.getCell('C27').numFmt = '#,##0';
    group_level_amounts_sheet.getCell('C29').numFmt = '#,##0';
    group_level_amounts_sheet.getCell('C31').numFmt = '#,##0';
    group_level_amounts_sheet.getCell('C32').numFmt = '#,##0';
    group_level_amounts_sheet.getCell('C33').numFmt = '#,##0';
    group_level_amounts_sheet.getCell('C34').numFmt = '0.000%';
    group_level_amounts_sheet.getCell('C9').value = { formula: "='(1)_Group,_period,_return_type_'!D22"};


    const gla_Greys = ['B2','B4', 'B7', 'B8', 'B9', 'B10', 'B15','C15', 'B37'];
    for (let i = 0; i < gla_Greys.length; i++){
        group_level_amounts_sheet.getCell(gla_Greys[i]).fill = {
            type: 'pattern',
            pattern:'solid',
            fgColor:{argb:'FFC0C0C0'}
            };        
    }

    const gla_Lemons = ['B6', 'B12', 'B13','B38','C38'];
    for (let i = 0; i < gla_Lemons.length; i++){
        group_level_amounts_sheet.getCell(gla_Lemons[i]).fill = {
            type: 'pattern',
            pattern:'solid',
            fgColor:{argb:'FFFFFF99'}
            };        
    }

    const gla_Golds = ['C17','C19','B37'];
    for (let i = 0; i < gla_Golds.length; i++){
        group_level_amounts_sheet.getCell(gla_Golds[i]).fill = {
            type: 'pattern',
            pattern:'solid',
            fgColor:{argb:'FFFFFFCC'}
            };        
    }

    const gla_Blues = ['C18','C20','C22','C23','C24','C25','C26','C27','C29','C32','C33','C34', 'C37']
    for (let i = 0; i < gla_Blues.length; i++){
        group_level_amounts_sheet.getCell(gla_Blues[i]).fill = {
            type: 'pattern',
            pattern:'solid',
            fgColor:{argb:'FFE0FFFF'} 
            };       
    }

    allocated_restrictions_sheet.getColumn(1).width = 2;
    allocated_restrictions_sheet.getColumn(2).width = 7;
    allocated_restrictions_sheet.getColumn(3).width = 84;
    allocated_restrictions_sheet.getColumn(4).width = 17;
    allocated_restrictions_sheet.getColumn(5).width = 26;
    allocated_restrictions_sheet.getColumn(6).width = 26;
    allocated_restrictions_sheet.getColumn(7).width = 26;
    allocated_restrictions_sheet.getColumn(8).width = 32;
    allocated_restrictions_sheet.getColumn(9).width = 32;
    allocated_restrictions_sheet.getColumn(10).width = 32;
    allocated_restrictions_sheet.getRow(1).height = 5;
    allocated_restrictions_sheet.getRow(3).height = 5;
    allocated_restrictions_sheet.getRow(5).height = 5;
    allocated_restrictions_sheet.getRow(11).height = 5;
    allocated_restrictions_sheet.getRow(315).height = 5;
    allocated_restrictions_sheet.getRow(318).height = 5;
    allocated_restrictions_sheet.mergeCells('B2:J2');
    allocated_restrictions_sheet.mergeCells('B4:J4');
    allocated_restrictions_sheet.mergeCells('B6:E6');
    allocated_restrictions_sheet.mergeCells('B7:C7');
    allocated_restrictions_sheet.mergeCells('B8:C8');
    allocated_restrictions_sheet.mergeCells('B9:C9');
    allocated_restrictions_sheet.mergeCells('B10:C10');
    allocated_restrictions_sheet.mergeCells('D7:E7');
    allocated_restrictions_sheet.mergeCells('D8:E8');
    allocated_restrictions_sheet.mergeCells('D9:E9');
    allocated_restrictions_sheet.mergeCells('D10:E10');
    allocated_restrictions_sheet.mergeCells('H14:J14');
    allocated_restrictions_sheet.mergeCells('B319:F319');

    allocated_restrictions_sheet.getCell('B2').border= {left: {style:'medium'}, top: {style:'medium'}, bottom: {style:'medium'}, right: {style:'medium'}};
    allocated_restrictions_sheet.getCell('B4').border= {left: {style:'medium'}, top: {style:'medium'}, bottom: {style:'medium'}, right: {style:'medium'}};
    allocated_restrictions_sheet.getCell('B6').border= {left: {style:'medium'}, top: {style:'medium'}, bottom: {style:'thin'}, right: {style:'medium'}};
    allocated_restrictions_sheet.getCell('B7').border= {left: {style:'medium'}, bottom: {style:'thin'}, right: {style:'thin'}};
    allocated_restrictions_sheet.getCell('D7').border= {bottom: {style:'thin'}, right: {style:'medium'}};
    allocated_restrictions_sheet.getCell('B8').border= {left: {style:'medium'}, bottom: {style:'thin'}, right: {style:'thin'}};
    allocated_restrictions_sheet.getCell('D8').border= {bottom: {style:'thin'}, right: {style:'medium'}};
    allocated_restrictions_sheet.getCell('B9').border= {left: {style:'medium'}, bottom: {style:'thin'}, right: {style:'thin'}};
    allocated_restrictions_sheet.getCell('D9').border= {bottom: {style:'thin'}, right: {style:'medium'}};
    allocated_restrictions_sheet.getCell('B10').border= {left: {style:'medium'}, bottom: {style:'medium'}, right: {style:'thin'}};
    allocated_restrictions_sheet.getCell('D10').border= {bottom: {style:'medium'}, right: {style:'medium'}};
    allocated_restrictions_sheet.getCell('B12').border= {left: {style:'medium'}, top: {style:'medium'}, bottom: {style:'thin'}, right: {style:'thin'}};
    allocated_restrictions_sheet.getCell('C12').border= {top: {style:'medium'}, bottom: {style:'thin'}, right: {style:'thin'}};
    allocated_restrictions_sheet.getCell('D12').border= {top: {style:'medium'}, bottom: {style:'thin'}, right: {style:'thin'}};
    allocated_restrictions_sheet.getCell('E12').border= {top: {style:'medium'}, bottom: {style:'thin'}};
    allocated_restrictions_sheet.getCell('F12').border= {top: {style:'medium'}, bottom: {style:'thin'}};
    allocated_restrictions_sheet.getCell('G12').border= {top: {style:'medium'}, bottom: {style:'thin'}, right: {style:'thin'}};
    allocated_restrictions_sheet.getCell('H12').border= {top: {style:'medium'}, bottom: {style:'thin'}, right: {style:'thin'}};
    allocated_restrictions_sheet.getCell('I12').border= {top: {style:'medium'}, bottom: {style:'thin'}, right: {style:'thin'}};
    allocated_restrictions_sheet.getCell('J12').border= {top: {style:'medium'}, bottom: {style:'thin'}, right: {style:'medium'}};
    allocated_restrictions_sheet.getCell('B13').border= {left: {style:'medium'}, right: {style:'thin'}};
    allocated_restrictions_sheet.getCell('D13').border= {right: {style:'thin'}};
    allocated_restrictions_sheet.getCell('E13').border= {bottom: {style:'thin'},right: {style:'thin'}};
    allocated_restrictions_sheet.getCell('F13').border= {bottom: {style:'thin'},right: {style:'thin'}};
    allocated_restrictions_sheet.getCell('G13').border= {bottom: {style:'thin'},right: {style:'thin'}};
    allocated_restrictions_sheet.getCell('H13').border= {bottom: {style:'thin'},right: {style:'thin'}};
    allocated_restrictions_sheet.getCell('I13').border= {bottom: {style:'thin'},right: {style:'thin'}};
    allocated_restrictions_sheet.getCell('J13').border= {bottom: {style:'thin'},right: {style:'medium'}};
    allocated_restrictions_sheet.getCell('B14').border= {left: {style:'medium'}, right: {style:'thin'}, bottom: {style:'thin'}};
    allocated_restrictions_sheet.getCell('C14').border= {bottom: {style:'thin'}};
    allocated_restrictions_sheet.getCell('D14').border= {right: {style:'thin'}, bottom: {style:'thin'}};
    allocated_restrictions_sheet.getCell('E14').border= {bottom: {style:'thin'},right: {style:'thin'}};
    allocated_restrictions_sheet.getCell('F14').border= {bottom: {style:'thin'},right: {style:'thin'}};
    allocated_restrictions_sheet.getCell('G14').border= {bottom: {style:'thin'},right: {style:'thin'}};
    allocated_restrictions_sheet.getCell('H14').border= {bottom: {style:'thin'}};
    allocated_restrictions_sheet.getCell('I14').border= {bottom: {style:'thin'}};
    allocated_restrictions_sheet.getCell('J14').border= {bottom: {style:'thin'},right: {style:'medium'}};
    for (let row = 15; row < 318; row++){
        allocated_restrictions_sheet.getCell('B' + row).border= {left: {style:'medium'}, bottom: {style:'thin'}, right: {style:'thin'}};
        allocated_restrictions_sheet.getCell('C' + row).border= {bottom: {style:'thin'}, right: {style:'thin'}};
        allocated_restrictions_sheet.getCell('D' + row).border= {bottom: {style:'thin'}, right: {style:'thin'}};
        allocated_restrictions_sheet.getCell('E' + row).border= {bottom: {style:'thin'}, right: {style:'thin'}};
        allocated_restrictions_sheet.getCell('F' + row).border= {bottom: {style:'thin'}, right: {style:'thin'}};
        allocated_restrictions_sheet.getCell('G' + row).border= {bottom: {style:'thin'}, right: {style:'thin'}};
        allocated_restrictions_sheet.getCell('H' + row).border= {bottom: {style:'thin'}, right: {style:'thin'}};
        allocated_restrictions_sheet.getCell('I' + row).border= {bottom: {style:'thin'}, right: {style:'thin'}};
        allocated_restrictions_sheet.getCell('J' + row).border= {bottom: {style:'thin'}, right: {style:'medium'}};
    }
    allocated_restrictions_sheet.getCell('B317' ).border= {left: {style:'medium'}, bottom: {style:'medium'}, right: {style:'thin'}};
    allocated_restrictions_sheet.getCell('C317').border= {bottom: {style:'medium'}, right: {style:'thin'}};
    allocated_restrictions_sheet.getCell('D317').border= {bottom: {style:'medium'}, right: {style:'thin'}};
    allocated_restrictions_sheet.getCell('E317').border= {bottom: {style:'medium'}, right: {style:'thin'}};
    allocated_restrictions_sheet.getCell('F317').border= {bottom: {style:'medium'}, right: {style:'thin'}};
    allocated_restrictions_sheet.getCell('G317').border= {bottom: {style:'medium'}, right: {style:'thin'}};
    allocated_restrictions_sheet.getCell('H317').border= {bottom: {style:'medium'}, right: {style:'thin'}};
    allocated_restrictions_sheet.getCell('I317').border= {bottom: {style:'medium'}, right: {style:'thin'}};
    allocated_restrictions_sheet.getCell('J317').border= {bottom: {style:'medium'}, right: {style:'medium'}};
    allocated_restrictions_sheet.getCell('B319').border= {left: {style:'medium'}, top: {style:'medium'}, bottom: {style:'thin'}, right: {style:'medium'}};
    allocated_restrictions_sheet.getCell('B320').border= {left: {style:'medium'}, bottom: {style:'medium'}, right: {style:'thin'}};
    allocated_restrictions_sheet.getCell('C320').border= {bottom: {style:'medium'}, right: {style:'thin'}};
    allocated_restrictions_sheet.getCell('D320').border= {bottom: {style:'medium'}, right: {style:'thin'}};
    allocated_restrictions_sheet.getCell('E320').border= {bottom: {style:'medium'}, right: {style:'thin'}};
    allocated_restrictions_sheet.getCell('F320').border= {bottom: {style:'medium'}, right: {style:'medium'}};
    allocated_restrictions_sheet.getCell('J316').value = { formula: '=SUM(H15:J314)'};
    allocated_restrictions_sheet.getCell('J317').value = { formula: "='(4)_Group_level_amounts_'!C18"};
    allocated_restrictions_sheet.getCell('D9').value = { formula: "='(1)_Group,_period,_return_type_'!D22"};
    allocated_restrictions_sheet.getCell('B12').alignment = {wrapText : true};
    allocated_restrictions_sheet.getCell('D12').alignment = {wrapText : true};

    for (let i = 0; i < 316; i++){
        let cell = 'H' + (startLRow + i);
        allocated_restrictions_sheet.getCell(cell).numFmt = '#,##0';
        cell = 'I' + (startLRow + i);
        allocated_restrictions_sheet.getCell(cell).numFmt = '#,##0';
        cell = 'J' + (startLRow + i);
        allocated_restrictions_sheet.getCell(cell).numFmt = '#,##0';
    }

    const aRes_Greys = ['B2','B4', 'B7', 'B8', 'B9', 'B10', 'B12','C12','D12','E12','F12','G12','H12','I12','J12','E13','F13','G13','H13','I13','J13','B319'];
    for (let i = 0; i < aRes_Greys.length; i++){
        allocated_restrictions_sheet.getCell(aRes_Greys[i]).fill = {
            type: 'pattern',
            pattern:'solid',
            fgColor:{argb:'FFC0C0C0'}
            };        
    }

    const aRes_Lemons = ['B6', 'C13', 'D13', 'C14', 'D14','H14','I14','J14','I317','F320'];
    for (let i = 0; i < aRes_Lemons.length; i++){
        allocated_restrictions_sheet.getCell(aRes_Lemons[i]).fill = {
            type: 'pattern',
            pattern:'solid',
            fgColor:{argb:'FFFFFF99'}
            };        
    }

    buildGoldsList = [];
    for (let i = 0; i < 300; i++){
        let row = startLRow + i;
        buildGoldsList.push('E' + row);
        buildGoldsList.push('F' + row);
        buildGoldsList.push('G' + row);
    }
    const aRes_Golds_others = ['B320','C320'];
    const aRes_Golds = aRes_Golds_others.concat(buildGoldsList);
    for (let i = 0; i < aRes_Golds.length; i++){
        allocated_restrictions_sheet.getCell(aRes_Golds[i]).fill = {
            type: 'pattern',
            pattern:'solid',
            fgColor:{argb:'FFFFFFCC'}
            };        
    }
    buildBluesList = [];
    for (let i = 0; i < 300; i++){
        let row = startBRow + i
        buildBluesList.push('H' + row);
        buildBluesList.push('I' + row);
        buildBluesList.push('J' + row)
    }
    const aRes_Blues_others = ['J316','J317','D320','E320'];
    const aRes_Blues = aRes_Blues_others.concat(buildBluesList)
    for (let i = 0; i < aRes_Blues.length; i++){
        allocated_restrictions_sheet.getCell(aRes_Blues[i]).fill = {
            type: 'pattern',
            pattern:'solid',
            fgColor:{argb:'FFCCFFFF'} 
            };        
    }
    const aRes_bolds = ['B2','B4','B7','B8','B9','B10','B12','C12','D12','E12','F12','G12','H12','I12','J12','E13','F13','G13','H13','I13','J13','B319'];
    for(let j = 0; j < aRes_bolds?.length; j++){
        allocated_restrictions_sheet.getCell(aRes_bolds[j]).font = {bold:true}
    }

    allocated_reactivations_sheet.getColumn(1).width = 2;
    allocated_reactivations_sheet.getColumn(2).width = 7;
    allocated_reactivations_sheet.getColumn(3).width = 107;
    allocated_reactivations_sheet.getColumn(4).width = 52;
    allocated_reactivations_sheet.getColumn(5).width = 52;
    allocated_reactivations_sheet.getColumn(6).width = 10;
    allocated_reactivations_sheet.getRow(1).height = 5;
    allocated_reactivations_sheet.getRow(3).height = 5;
    allocated_reactivations_sheet.getRow(5).height = 5;
    allocated_reactivations_sheet.getRow(11).height = 5;
    allocated_reactivations_sheet.getRow(314).height = 5;
    allocated_reactivations_sheet.getRow(317).height = 5;
    allocated_reactivations_sheet.mergeCells('B2:E2');
    allocated_reactivations_sheet.mergeCells('B4:E4');
    allocated_reactivations_sheet.mergeCells('B6:D6');
    allocated_reactivations_sheet.mergeCells('B7:C7');
    allocated_reactivations_sheet.mergeCells('B8:C8');
    allocated_reactivations_sheet.mergeCells('B9:C9');
    allocated_reactivations_sheet.mergeCells('B10:C10');
    allocated_reactivations_sheet.getCell('B2').border= {left: {style:'medium'}, top: {style:'medium'}, bottom: {style:'medium'}, right: {style:'medium'}};
    allocated_reactivations_sheet.getCell('B4').border= {left: {style:'medium'}, top: {style:'medium'}, bottom: {style:'medium'}, right: {style:'medium'}};
    allocated_reactivations_sheet.getCell('B6').border= {left: {style:'medium'}, top: {style:'medium'}, bottom: {style:'thin'}, right: {style:'medium'}};
    allocated_reactivations_sheet.getCell('B7').border= {left: {style:'medium'}, bottom: {style:'thin'}, right: {style:'thin'}};
    allocated_reactivations_sheet.getCell('D7').border= {bottom: {style:'thin'}, right: {style:'medium'}};
    allocated_reactivations_sheet.getCell('B8').border= {left: {style:'medium'}, bottom: {style:'thin'}, right: {style:'thin'}};
    allocated_reactivations_sheet.getCell('D8').border= {bottom: {style:'thin'}, right: {style:'medium'}};
    allocated_reactivations_sheet.getCell('B9').border= {left: {style:'medium'}, bottom: {style:'thin'}, right: {style:'thin'}};
    allocated_reactivations_sheet.getCell('D9').border= {bottom: {style:'thin'}, right: {style:'medium'}};
    allocated_reactivations_sheet.getCell('B10').border= {left: {style:'medium'}, bottom: {style:'medium'}, right: {style:'thin'}};
    allocated_reactivations_sheet.getCell('D10').border= {bottom: {style:'medium'}, right: {style:'medium'}};
    allocated_reactivations_sheet.getCell('D12').alignment = {wrapText : true};
    allocated_reactivations_sheet.getCell('E12').alignment = {wrapText : true};
    allocated_reactivations_sheet.getCell('B12').border= {top: {style:'medium'}, left: {style:'medium'}, bottom: {style:'thin'}, right: {style:'thin'}};
    allocated_reactivations_sheet.getCell('C12').border= {top: {style:'medium'}, bottom: {style:'thin'}, right: {style:'thin'}};
    allocated_reactivations_sheet.getCell('D12').border= {top: {style:'medium'}, bottom: {style:'thin'}, right: {style:'thin'}};
    allocated_reactivations_sheet.getCell('E12').border= {top: {style:'medium'},bottom: {style:'thin'}, right: {style:'medium'}};
    for (let row = 13; row < 314; row++){
        allocated_reactivations_sheet.getCell('B' + row).border= {left: {style:'medium'}, bottom: {style:'thin'}, right: {style:'thin'}};
        allocated_reactivations_sheet.getCell('C' + row).border= {bottom: {style:'thin'}, right: {style:'thin'}};
        allocated_reactivations_sheet.getCell('D' + row).border= {bottom: {style:'thin'}, right: {style:'thin'}};
        allocated_reactivations_sheet.getCell('E' + row).border= {bottom: {style:'thin'}, right: {style:'medium'}};
    }
    allocated_reactivations_sheet.getCell('B314').border= {bottom: {style:'thin'}, left: {style:'medium'}, top: {style:'thin'}};
    allocated_reactivations_sheet.getCell('C314').border= {bottom: {style:'thin'}, top: {style:'thin'}};
    allocated_reactivations_sheet.getCell('D314').border= {bottom: {style:'thin'}, top: {style:'thin'}};
    allocated_reactivations_sheet.getCell('E314').border= {right: {style:'medium'}, bottom: {style:'thin'}, top: {style:'thin'}};
    allocated_reactivations_sheet.getCell('B315').border= {bottom: {style:'thin'}, left: {style:'medium'}, top: {style:'thin'}};
    allocated_reactivations_sheet.getCell('C315').border= {bottom: {style:'thin'}, top: {style:'thin'}};
    allocated_reactivations_sheet.getCell('D315').border= {bottom: {style:'thin'}, top: {style:'thin'}, right: {style:'thin'}};
    allocated_reactivations_sheet.getCell('E316').border= {right: {style:'medium'}, bottom: {style:'thin'}, left: {style:'thin'}, top: {style:'thin'}};
    allocated_reactivations_sheet.getCell('B316').border= {bottom: {style:'medium'}, left: {style:'medium'}, bottom: {style:'thin'}, right: {style:'thin'}};
    allocated_reactivations_sheet.getCell('C316').border= {bottom: {style:'medium'}, bottom: {style:'thin'}, right: {style:'thin'}};
    allocated_reactivations_sheet.getCell('D316').border= {bottom: {style:'medium'}, bottom: {style:'thin'}, right: {style:'thin'}};
    allocated_reactivations_sheet.getCell('E316').border= {bottom: {style:'medium'},bottom: {style:'thin'}, right: {style:'medium'}};
    allocated_reactivations_sheet.mergeCells('B315:C315');
    allocated_reactivations_sheet.mergeCells('B316:C316');
    allocated_reactivations_sheet.mergeCells('B318:E318');
    allocated_reactivations_sheet.getCell('B318').border= {left: {style:'medium'}, top: {style:'medium'}, bottom: {style:'thin'}, right: {style:'medium'}};
    allocated_reactivations_sheet.getCell('B319').border= {left: {style:'medium'}, bottom: {style:'medium'}, right: {style:'thin'}};
    allocated_reactivations_sheet.getCell('C319').border= {bottom: {style:'medium'}, right: {style:'thin'}};
    allocated_reactivations_sheet.getCell('D319').border= {bottom: {style:'medium'}, right: {style:'thin'}};
    allocated_reactivations_sheet.getCell('E319').border= {bottom: {style:'medium'}, right: {style:'medium'}};
    allocated_reactivations_sheet.getCell('E315').border= {bottom: {style:'medium'}, right: {style:'medium'}};
    allocated_reactivations_sheet.getCell('E315').value = { formula: '=SUM(E14:E313)'};
    allocated_reactivations_sheet.getCell('E316').value = { formula: "='(4)_Group_level_amounts_'!C20"};
    allocated_reactivations_sheet.getCell('D9').value = { formula: "='(1)_Group,_period,_return_type_'!D22"};
    allocated_reactivations_sheet.getCell('B12').alignment = {wrapText : true};

    const aReact_Greys = ['B2','B4', 'B7', 'B8', 'B9', 'B10', 'B318'];
    for (let i = 0; i < aReact_Greys.length; i++){
        allocated_reactivations_sheet.getCell(aReact_Greys[i]).fill = {
            type: 'pattern',
            pattern:'solid',
            fgColor:{argb:'FFC0C0C0'}
            };        
    }

    const aReact_Golds = ['B319','C319'];
    for (let i = 0; i < aReact_Golds.length; i++){
        allocated_reactivations_sheet.getCell(aReact_Golds[i]).fill = {
            type: 'pattern',
            pattern:'solid',
            fgColor:{argb:'FFFFFFCC'}
            };        
    }

    const aReact_Lemons = ['B6', 'B13', 'C13', 'E13', 'D316','E319'];
    for (let i = 0; i < aReact_Lemons.length; i++){
        allocated_reactivations_sheet.getCell(aReact_Lemons[i]).fill = {
            type: 'pattern',
            pattern:'solid',
            fgColor:{argb:'FFFFFF99'}
            };        
    }
    buildBluesList = [];
    startBRow = 14;
    for (let i = 0; i < 300; i++){
        let row = startBRow + i
        buildBluesList.push('D' + row);
        buildBluesList.push('E' + row);
    }
    const aReact_Blues_others = ['E315','E316','D319'];
    const aReact_Blues = aReact_Blues_others.concat(buildBluesList)
    for (let i = 0; i < aReact_Blues.length; i++){
        allocated_reactivations_sheet.getCell(aReact_Blues[i]).fill = {
            type: 'pattern',
            pattern:'solid',
            fgColor:{argb:'FFCCFFFF'} 
            };        
    }
    for (let i = 0; i < 316; i++){
        let cell = 'D' + (startLRow + i);
        allocated_reactivations_sheet.getCell(cell).numFmt = '#,##0';
        cell = 'E' + (startLRow + i);
        allocated_reactivations_sheet.getCell(cell).numFmt = '#,##0';
    }
    const aReact_bolds = ['B2','B4','B7','B8','B9','B10','B12','C12','D12','E12','B315', 'B316', 'B318'];
    for(let j = 0; j < aReact_bolds?.length; j++){
        allocated_reactivations_sheet.getCell(aReact_bolds[j]).font = {bold:true}
    }
    


return [group_period_sheet, group_level_elections_sheet, uk_group_companies_sheet, group_level_amounts_sheet, allocated_restrictions_sheet, allocated_reactivations_sheet]
}
