/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Modal, Accordion, AccordionItem } from '@appkit4/react-components'; 
import { Button, Input, InputNumber } from "../../components/ReadonlyAwareInputs";
import { setANGIETotals, setQNGIETotals } from '../../services/calculations/GroupDataCalcs';
import toast from '../../components/DismissibleToast';
import { WarningModal, generateTableRow, renderPrimaryAccordionTitle, renderSummary, renderTableKeyList, sumArray, handleCancel } from './groupDataUtil';
import { formatCurrency } from "../../utils/formatters";

const ANGIE = ({ setVisible, currentPeriod, setCurrentPeriod, save, errorState: [isError, setIsError], isParentError, isReadOnly }) => {

  const tables = {
    //Upward Adjustments
    'capitalised_expense_in_statements': generateTableRow('Relevant expense amounts capitalised in group financial statements into a non-financial asset or liability - s413(3)(a)', useState([{ description: '', value: '' }]), useState(false), 'positive', ["Under s413(3)(a) any relevant expense amounts that are capitalised in the period in respect of non-financial assets or liabilities are included as an upward adjustment to ANGIE such that they are included in ANGIE in the period in which they are capitalised. This applies to all non-financial assets or liabilities (i.e. not just relevant assets)."]),
    'capitalised_income_written_off': generateTableRow('Amounts of capitalised income written off in respect of a non-financial asset or liability that are included in NGIE by virtue of s410(5) - s413(3)(b)', useState([{ description: '', value: '' }]), useState(false), 'positive', ["Under s413(3)(b) any relevant income amounts that were included in NGIE as a result of the \"Depreciation/amortisation/write-off of relevant income amounts capitalised into non-relevant assets\" adjustment above (due to s410(5)) are reversed in respect of all non-financial assets and liabilities. Combined with the adjustment above, this ensures that the only amounts included in ANGIE in respect of non-financial assets or liabilities (in the absense of a Interest Allowance (alternative calculation) election) are the amounts capitalised in the period."]),
    'expense_amount_recognised_in_equity_or_shareholders': generateTableRow('Relevant expense amounts recognised in equity or shareholders funds in group accounts (not in P&L/OCI) and is/would be brought into account for corporate tax purposes - s413(3)(c)', useState([{ description: '', value: '' }]), useState(false), 'positive', ["Under s413(3)(c) and s413(6) certain amounts that arise in equity (as opposed to the income statement or OCI) but that would be brought into account for tax purposes under a relevant enactment are included in ANGIE (regardless of whether the company in question is actually subject to Corporation Tax). Relevant enactments at the time this was last updated were:", " 1) s321 / 605 CTA 2009 - which only remains relevant for loan relationships and derivative contracts issued in periods of account commencing before 1 January 2016; and", "2) Regulatory capital instruments under s320B CTA 2009 (e.g. AT1 instruments issued by banks)."]),
    'upward_amount_under_s330za': generateTableRow('Amounts treated as deductible, for periods of account ending on or after 6 April 2020 subject to transitional provisions, under s330ZA CTA 2009 by a UK group company that have not previously been included in ANGIE - s413(3)(ca)', useState([{ description: '', value: '' }]), useState(false), 'positive', ["As a result of Finance (No 2) Act 2023, for all periods of account ending on or after 6 April 2020 there are adjustments in respect of s330ZA and s607ZA CTA 2009. An election can be made to ensure that amounts are brought into account for prior periods.", "Under s413(3)(ca)-(cc) amounts that are brought into account under s330ZA / s607ZA CTA 2009 on commencement of a UK property business are included as upward adjustments. Similarly there are upward adjustments required in respect of certain income amounts that are expected to be brought into account under s330ZA / s607A in future periods."]),
    'post_2020_transitional_amounts_s607za_cta2009': generateTableRow('Amounts that would be brought in, for periods of account ending on or after 6 April 2020 subject to transitional provisions, under s607ZA CTA 2009 by a UK group company had a Disregard Regulation election been made and that have not previously been included in ANGIE - s413(3)(cb)', useState([{ description: '', value: '' }]), useState(false), 'positive', ["As a result of Finance (No 2) Act 2023, for all periods of account ending on or after 6 April 2020 there are adjustments in respect of s330ZA and s607ZA CTA 2009. An election can be made to ensure that amounts are brought into account for prior periods.", "Under s413(3)(ca)-(cc) amounts that are brought into account under s330ZA / s607ZA CTA 2009 on commencement of a UK property business are included as upward adjustments. Similarly there are upward adjustments required in respect of certain income amounts that are expected to be brought into account under s330ZA / s607A in future periods."]),
    'post_2020_income_amounts_s330za_transitional': generateTableRow('Income amounts under a loan relationship, for periods of account ending on or after 6 April 2020 subject to transitional provisions, that are not brought into account and are expected to be brought into account under s330ZA for another accounting period - s413(c)(cc)', useState([{ description: '', value: '' }]), useState(false), 'positive', ["As a result of Finance (No 2) Act 2023, for all periods of account ending on or after 6 April 2020 there are adjustments in respect of s330ZA and s607ZA CTA 2009. An election can be made to ensure that amounts are brought into account for prior periods.", "Under s413(3)(ca)-(cc) amounts that are brought into account under s330ZA / s607ZA CTA 2009 on commencement of a UK property business are included as upward adjustments. Similarly there are upward adjustments required in respect of certain income amounts that are expected to be brought into account under s330ZA / s607A in future periods."]),
    'post_2020_derivative_income_s607za': generateTableRow('Income amounts under a derivative contract, for periods of account ending on or after 6 April 2020 subject to transitional provisions, that are not brought into account by a UK group company, but would be expected to be brought into account under s607ZA for another accounting period had a Disregard Regulation election been made - s413(3)(cc)', useState([{ description: '', value: '' }]), useState(false), 'positive', ["As a result of Finance (No 2) Act 2023, for all periods of account ending on or after 6 April 2020 there are adjustments in respect of s330ZA and s607ZA CTA 2009. An election can be made to ensure that amounts are brought into account for prior periods.", "Under s413(3)(ca)-(cc) amounts that are brought into account under s330ZA / s607ZA CTA 2009 on commencement of a UK property business are included as upward adjustments. Similarly there are upward adjustments required in respect of certain income amounts that are expected to be brought into account under s330ZA / s607A in future periods."]),
    'upward_amount_under_s330_cta_election': generateTableRow('Amounts brought into account under a s330 CTA 2009 election made after 11 July 2023 that have not previously been included in ANGIE - s413(3)(cd)', useState([{ description: '', value: '' }]), useState(false), 'positive', ["This adjustment was introduced as a result of Finance (No 2) Act 2023 and applies in relation to s330 CTA 2009 elections made on or after the day in which the Act was passed. Where a s330 CTA 2009 election is made to bring into account pre-trading loan relationship debits on the day the company commences to trade, ANGIE is aligned. As such, any amounts not previously included in ANGIE but that are deductible under s330 CTA 2009 elections made on or after the day in which the Act was passed are included as an upward adjustment to ANGIE."]),
    'relevant_debt_release_tax_exempt_income': generateTableRow('Income in group P&L that is a relevant income amount arising from certain debt releases or similar transactions that would benefit from a Corporation Tax exemption under s322(2), 323A and, for periods of account beginning on or after 1 January 2019, s358 or s359', useState([{ description: '', value: '' }]), useState(false), 'positive', ["Under s413(3)(d) relevant income amounts that are prevented from being brought into account under s322(2), s323A, s358 or s359 CTA 2009 (or would be if the company in question was subject to UK Corporation Tax) are excluded from ANGIE. These provisions generally relate to exemptions from releases of debts and/or substantial modifications. If the release was in respect of a debt issued by a non-UK tax resident entity, it will be necessary to consider whether the relevant conditions would be met."]),
    
    // Downward Adjustments
    'capitalised_income_in_statements': generateTableRow('Relevant income amounts capitalised in group financial statements into a non-financial asset or liability - s413(4)(a)', useState([{ description: '', value: '' }]), useState(false), 'negative', ["Under s413(4)(a) any relevant income amounts that are capitalised in the period in respect of non-financial assets or liabilities are included as a downward adjustment to ANGIE such that they are included in ANGIE in the period in which they are capitalised. This applies to all non-financial assets or liabilities (i.e. not just relevant assets)."]),
    'capitalised_expense_written_off': generateTableRow('Amounts included in net group-interest expense by virtue of s.410(3) (capitalised expense written off) in respect of a non-financial asset or liability - s413(4)(b)', useState([{ description: '', value: '' }]), useState(false), 'negative', ["Under s413(4)(b) any relevant expense amounts that were included in NGIE as a result of the \"Depreciation/amortisation/write-off of relevant income amounts capitalised into non-relevant assets\" adjustment above (due to s410(3)) are reversed in respect of all non-financial assets and liabilities. Combined with the adjustment above, this ensures that the only amounts included in ANGIE in respect of non-financial assets or liabilities (in the absense of a Interest Allowance (alternative calculation) election) are the amounts capitalised in the period."]),
    'income_amount_recognised_in_equity_or_shareholders': generateTableRow('Relevant income amounts recognised in equity or shareholders funds in group accounts (not in P&L/OCI) and is/would be brought into account for corporate tax purposes - s413(4)(c)', useState([{ description: '', value: '' }]), useState(false), 'negative', ["Under s413(4)(c) and s413(6) certain amounts that arise in equity (as opposed to the income statement or OCI) but that would be brought into account for tax purposes under a relevant enactment are included in ANGIE (regardless of whether the company in question is actually subject to Corporation Tax). Relevant enactments at the time this was last updated were:", " 1) s321 / 605 CTA 2009 - which only remains relevant for loan relationships and derivative contracts issued in periods of account commencing before 1 January 2016; and", " 2) Regulatory capital instruments under s320B CTA 2009 (e.g. AT1 instruments issued by banks)."]),
    'downward_amount_under_s330ZA': generateTableRow('Expense amounts under a loan relationship, for periods of account ending on or after 6 April 2020 subject to transitional provisions, that are not brought into account and are expected to be brought into account under s330ZA for another accounting period - s413(4)(ca)', useState([{ description: '', value: '' }]), useState(false), 'negative', ["As a result of Finance (No 2) Act 2023, for all periods of account ending on or after 6 April 2020 there are adjustments in respect of s330ZA and s607ZA CTA 2009. An election can be made to ensure that amounts are brought into account for prior periods.", "Under s413(4)(ca) amounts that are expected to be brought into account under s330ZA / s607ZA CTA 2009 on commencement of a UK property business are included as downward adjustments (such that they are not brought in twice)."]),
    'post_2020_derivative_expense_s607za': generateTableRow('Expense amounts under a derivative contract, for periods of account ending on or after 6 April 2020 subject to transitional provisions, that are not brought into account by a UK group company, but would be expected to be brought into account under s607ZA for another accounting period had a Disregard Regulation election been made - s413(4)(ca)', useState([{ description: '', value: '' }]), useState(false), 'negative', ["As a result of Finance (No 2) Act 2023, for all periods of account ending on or after 6 April 2020 there are adjustments in respect of s330ZA and s607ZA CTA 2009. An election can be made to ensure that amounts are brought into account for prior periods.", "Under s413(4)(ca) amounts that are expected to be brought into account under s330ZA / s607ZA CTA 2009 on commencement of a UK property business are included as downward adjustments (such that they are not brought in twice)."]),
    'downward_amount_under_s330_cta_election': generateTableRow('Amounts prevented from being brought into account under a s330 CTA 2009 election made after 11 July 2023 - s413(4)(cb)', useState([{ description: '', value: '' }]), useState(false), 'negative', ["This adjustment was introduced a result of Finance (No 2) Act 2023 and applies in relation to s330 CTA 2009 elections made on or after the day in which the Act was passed. Where a s330 CTA 2009 election is made to bring into account pre-trading loan relationship debits on the day the company commences to trade, ANGIE is aligned. As such, any amounts included in the group accounts but that are not deductible as a result of s330(1)(b) CTA 2009 are included as a downward adjustment to ANGIE."]),
    'expense_amount_excluded_for_s322_2_or_cta2009': generateTableRow('Expense in group P&L that is a relevant expense amount arising from certain debt releases or similar transactions that would benefit from a Corporation Tax exemption under s323A and, for periods of account beginning on or after 1 January 2019, s354 - s413(4)(d)', useState([{ description: '', value: '' }]), useState(false), 'negative', ["Under s413(4)(d) relevant expense amounts that are prevented from being brought into account under s323A or s354 CTA 2009 (or would be if the company in question was subject to UK Corporation Tax) are excluded from ANGIE. These provisions generally relate to exemptions from releases of loan receivables and/or reversals of credits arising as a result of substantial modifications. If the income arises in respect of a non-UK tax resident entity, it will be necessary to consider whether the relevant conditions would be met."]),
    'expense_amount_for_dividends_on_preference_shares': generateTableRow('Expense in group P&L that is a relevant expense amount and is for dividends payable on preference shares (if accounted for as financial liability) - s413(4)(e)', useState([{ description: '', value: '' }]), useState(false), 'negative', ["Note that there is no equivalent adjustment in respect of preference share income amounts."]),

    //Group Mismatch Adjustments
    'mismatches_regulation_2_adjustments': generateTableRow('Adjustments in respect of certain loan relationships existing on 1 April 2017 which were subject to fair value accounting or designated fair value hedge accounting in the group accounts - Regulation 2', useState([{ description: '', value: '' }]), useState(false), null, ["These mismatches typically arise as a result of loan relationships that a group company was party to on 1 April 2017 and was accounted for in the company accounts at amortised cost, but in the group accounts at fair value or as a hedged item in a fair value hedge."]),
    'mismatches_regulation_3_adjustments': generateTableRow('Adjustments in respect of loan relationships derecognised from the consolidated financial statements prior to 1 April 2017 without s361/362 CTA 2009 applying - Regulation 3', useState([{ description: '', value: '' }]), useState(false), null, ["These mismatches typically arise where a loan relationship was derecognised in the group accounts prior to 1 April 2017 in circumstances in which s361/s362 CTA 2009 did not apply and where the gain was previously recognised in the financial statements of the group (but may be recognised in the individual entity accounts in a post 1 April 2017 period)."]),
    'mismatches_regulation_4_adjustments': generateTableRow('Adjustments in respect of asset backed pension arrangements treated as acceptable structured finance arrangements giving rise to deemed interest deductions - Regulation 4', useState([{ description: '', value: '' }]), useState(false), null, ["These mismatches typically arise as a result of asset backed pension contributions that would be acceptable structured finance arrangements and result in deemed interest deductions."]),
    
    //Interest Allowance (Alternative Calculation) Election
    'capitalised_upward_interest_adjustment': generateTableRow('Reversal of s413(3)(a) adjustment above in respect of GAAP-taxable assets, such that amounts capitalised into GAAP-taxable assets or liabilities are not included in ANGIE - s423(2)(a)', useState([{ description: '', value: '' }]), useState(false), 'negative', ["Where an interest allowance alternative calculation election has been made, it treats s413(3)(a) as if it does not apply. It is therefore necessary to reverse the adjustment made to ANGIE in order to reflect relevant expense amounts that are capitalised into non-financial assets or liabilities - that adjustment should be made here, effectively as a downward adjustment, reducing ANGIE"]),
    'capitalised_downward_interest_adjustment': generateTableRow('Reversal of s413(3)(b) adjustment above in respect of GAAP-taxable assets, such that amounts of capitalised income written off in respect of GAAP-taxable assets (that are not relevant assets) remain in NGIE - s423(2)(b)', useState([{ description: '', value: '' }]), useState(false), 'negative', ["Where an interest allowance alternative calculation election has been made, it treats s413(3)(b) as if it does not apply. It is therefore necessary to reverse the upward adjustment made to ANGIE in order to reflect any relevant income amounts that were included in NGIE relating to the write-off of relevant income amounts capitalised into non-relevant assets. This adjustment is therefore a downward adjustment, reducing ANGIE, and effectively ensures that such amounts remain in NGIE."]),
    'reversal_gaap_tax_assets_s423_2_a': generateTableRow('Reversal of s413(4)(a) adjustment above in respect of GAAP-taxable asets, such that income amounts capitalised into GAAP-taxable assets or liabilities are not included in ANGIE - s423(2)(a)', useState([{ description: '', value: '' }]), useState(false), null, ["Where an interest allowance alternative calculation election has been made, it treats s413(4)(a) as if it does not apply. It is therefore necessary to reverse the adjustment made to ANGIE in order to reflect relevant income amounts that are capitalised in the period in respect of non-financial assets or liabilities - that adjustment should be made here, effectively as an upward adjustment, increasing ANGIE"]),
    'gaap_assets_written_off_s423_2_b': generateTableRow('Reversal of s413(4)(b) adjustment above in respect of GAAP-taxable assets, such that amounts written off in respect of GAAP-taxable assets (that are not relevant assets) remain in NGIE - s423(2)(b)', useState([{ description: '', value: '' }]), useState(false), null, ["Where an interest allowance alternative calculation election has been made, it treats s413(4)(b) as if it does not apply. It is therefore necessary to reverse the adjustment made to ANGIE in order to reflect relevant expense amounts that were included in NGIE as a result of the write-off of relevant expense amounts capitalised into non-relevant assets. This adjustment is therefore an upward adjustment, increasing ANGIE, and effectively ensures that such amounts remain in NGIE."]),
    'gaap_relevant_expense_written_off_s423_2a': generateTableRow('For periods beginning on or after 1 January 2019, adjustment to include the amounts written off relating to relevant expense amounts in respect of a GAAP-taxable asset that is a relevant asset - s423(2A)(a)', useState([{ description: '', value: '' }]), useState(false), 'negative', ["Where an interest allowance alternative calculation election has been made, s413 instead applies as if the definition of \"upward adjustment\" includes amounts written off the carrying value of a relevant asset, to the extent that they are attributable to relevant expense amounts. This adjustment is therefore an upward adjustment, increasing ANGIE."]),
    'gaap_relevant_income_written_off_s423_2b': generateTableRow('For periods beginning on or after 1 January 2019, adjustment to include the amounts written off relating to relevant income amounts in respect of a GAAP-taxable asset that is a relevant asset - s423(2A)(b)', useState([{ description: '', value: '' }]), useState(false), 'negative', ["Where an interest allowance alternative calculation election has been made, s413 instead applies as if the definition of \"downward adjustment\" includes amounts written off the carrying value of a relevant asset, to the extent that they are attributable to relevant income amounts. This adjustment is therefore a downward adjustment, reducing ANGIE."]),
    'gaap_asset_carrying_value_relevant_expense_s423_2aa_a': generateTableRow('For periods beginning on or after 1 April 2023, adjustment to include the amount of the carrying value of a GAAP-taxable asset appropriated (including under s173 TCGA 1992) which is attributable to a relevant expense amount - s423(2AA)(a)', useState([{ description: '', value: '' }]), useState(false), 'negative', ["For periods of account that begin or or after 1 April 2023, where an interest allowance alternative calculation election has been made, s413 instead applies as if the definition of \"upward adjustment\" includes the amount of the carring value of a GAAP-taxable asset that is attributable to a relevant expense amount when appropriated. This adjustment is therefore an upward adjustment, increasing ANGIE."]),
    'gaap_asset_carrying_value_relevant_income_s423_2aa_b': generateTableRow('Adjustment to downward adjustments to include the amount of the carrying value of a GAAP-taxable asset appropriated under s173 TCGA 1992 which is attributable to a relevant income amount - s423(2AA)(b)', useState([{ description: '', value: '' }]), useState(false), 'negative', ["For periods of account that begin on or after 1 April 2023, where an interest allowance alternative calculation election has been made, s413 instead applies as if the definition of \"downward adjustment\" includes the amount of the carring value of a GAAP-taxable asset that is attributable to a relevant income amount when appropriated. This adjustment is therefore a downward adjustment, reducing ANGIE."]),
    
    //Other
    'specialist_regimes_adjustments': generateTableRow('Other adjustments required as a result of specialist regimes', useState([{ description: '', value: '' }]), useState(false), null, ["Use this row to enter any additional adjustments that are required as a result of the application of specialist regimes or elections"]),
    'miscellaneous_adjustments': generateTableRow('Other adjustments for which there is no space above', useState([{ description: '', value: '' }]), useState(false), null, ["Use this row to enter any additional adjustements that may be required"]),
    
    //Interest Allowance (non-consolidated investment election)
    'angie_proportion_associated_groups_s427_4': generateTableRow('Adjustment to include the appropriate proportion of the ANGIE of each associated worldwide group headed by a company specified in the election - s427(4)', useState([{ description: '', value: '' }]), useState(false), 'positive', ["Where an Interest Allowance (Non-Consolidated Investment) election is made, it is necessary under s427 TIOPA 2010 to adjust NGIE to increase ANGIE by an amount equivalent to the ANGIE of the associated worldwide group. It is, however, also necessary to adjust the ANGIE of the associated worldwide group in order to remove any relevant expense amounts which reflect amounts owed to the principal worldwide group. See s428 TIOPA 2010 for more details. This adjustment should be an upward adjustment to ANGIE."]),
    
    //Adjustments for Qualifying Infrastructure Companies		
    'exclude_qic_income_exempt_s440_s442_2a': generateTableRow('Adjustment to exclude relevant income amounts of a QIC that were exempted under s440 and are included in the group financial statements - s442(2)(a)', useState([{ description: '', value: '' }]), useState(false), 'positive', ["Where an election has been made to treat a company as a qualifying infrastructure company, it is necessary to make an adjustment to remove any relevant income amounts that the company would otherwise have, to the extent that such amounts are exempt under the QIC rules, from the calculations. The adjustment should be an upwards adjustment, increasing ANGIE."]),
    'exclude_qic_expense_exempt_s438_s442_2a': generateTableRow('Adjustment to exclude relevant expense amounts of a QIC that were exempted under s438 (including as applied by s439) and are included in the group financial statements - s442(2)(a)', useState([{ description: '', value: '' }]), useState(false), 'negative', ["Where an election has been made to treat a company as a qualifying infrastructure company, it is necessary to make an adjustment to remove any relevant expense amounts that the company would otherwise have, to the extent that such amounts are exempt under the QIC rules, from the calculations. The adjustment should be downwards adjustment, reducing ANGIE."]),
  };


  
  const upwardAdjustments = [
    tables['capitalised_expense_in_statements']?.state[0],
    tables['capitalised_income_written_off']?.state[0],
    tables['expense_amount_recognised_in_equity_or_shareholders']?.state[0],
    tables['upward_amount_under_s330za']?.state[0],
    tables['post_2020_transitional_amounts_s607za_cta2009']?.state[0],
    tables['post_2020_income_amounts_s330za_transitional']?.state[0],
    tables['post_2020_derivative_income_s607za']?.state[0],
    tables['upward_amount_under_s330_cta_election']?.state[0],
    tables['relevant_debt_release_tax_exempt_income']?.state[0],
  ]
  const [upwardAdjustmentsTotal, setUpwardAdjustmentsTotal] = useState(0);

  const downwardAdjustments = [
    tables['capitalised_income_in_statements']?.state[0],
    tables['capitalised_expense_written_off']?.state[0],
    tables['income_amount_recognised_in_equity_or_shareholders']?.state[0],
    tables['downward_amount_under_s330ZA']?.state[0],
    tables['post_2020_derivative_expense_s607za']?.state[0],
    tables['downward_amount_under_s330_cta_election']?.state[0],
    tables['expense_amount_excluded_for_s322_2_or_cta2009']?.state[0],
    tables['expense_amount_for_dividends_on_preference_shares']?.state[0],
  ]
  const [downwardAdjustmentsTotal, setDownwardAdjustmentsTotal] = useState(0);

  const mismatchAdjustments = [
    tables['mismatches_regulation_2_adjustments']?.state[0],
    tables['mismatches_regulation_3_adjustments']?.state[0],
    tables['mismatches_regulation_4_adjustments']?.state[0],
  ]
  const [mismatchAdjustmentsTotal, setMismatchAdjustmentsTotal] = useState(0);

  const interestAllowanceAltCalcElection = [
    tables['capitalised_upward_interest_adjustment']?.state[0],
    tables['capitalised_downward_interest_adjustment']?.state[0],
    tables['reversal_gaap_tax_assets_s423_2_a']?.state[0],
    tables['gaap_assets_written_off_s423_2_b']?.state[0],
    tables['gaap_relevant_expense_written_off_s423_2a']?.state[0],
    tables['gaap_relevant_income_written_off_s423_2b']?.state[0],
    tables['gaap_asset_carrying_value_relevant_expense_s423_2aa_a']?.state[0],
    tables['gaap_asset_carrying_value_relevant_income_s423_2aa_b']?.state[0],
  ]
  const [interestAltCalcTotal, setInterestAltCalcTotal] = useState(0);

  const otherAdjustments = [
    tables['specialist_regimes_adjustments']?.state[0],
    tables['miscellaneous_adjustments']?.state[0]
  ]
  const [otherAdjustmentsTotal, setOtherAdjustments] = useState(0);

  const adjustmentsForQualifyingInfraCompanies = [
    tables['exclude_qic_income_exempt_s440_s442_2a']?.state[0],
    tables['exclude_qic_expense_exempt_s438_s442_2a']?.state[0]
  ]
  const [infraAdjustmentsTotal, setInfraAdjustmentsTotal] = useState(0);

  const [totalAdjustments, setTotalAdjustments] = useState(0);
  const [totalWithoutNGIE, setTotalWithoutNGIE] = useState(0);
  const [ngieETotal, setNgieTotal] = useState(currentPeriod?.local_curr_net_group_interest_expense ? currentPeriod?.local_curr_net_group_interest_expense : 0);
  const [angieAmountBeforeAnyAdjustments, setAngieAmountBeforeAnyAdjustments] = useState(0);

  useEffect(() => {
    setIsError( Object.keys(tables)?.some( k => tables[k]?.error[0]) )
  }, [Object.keys(tables)?.map(k => tables[k]?.error[0])])

  const [activeKeys, setActiveKeys] = useState("");
  const onClickAccordion = (activeKeys) => {
    setActiveKeys(activeKeys)
  }
  useEffect(() => {
    let total = sumArray(upwardAdjustments);
    setUpwardAdjustmentsTotal(total);
  }, upwardAdjustments)

  useEffect(() => {
    let total = sumArray(downwardAdjustments);
    setDownwardAdjustmentsTotal(total === 0 ? 0 : total);
  }, downwardAdjustments)

  useEffect(() => {
    let total = sumArray(mismatchAdjustments);
    setMismatchAdjustmentsTotal(total);
  }, [mismatchAdjustments])

  useEffect(() => {
    setInterestAltCalcTotal(sumArray(interestAllowanceAltCalcElection));
  }, [interestAllowanceAltCalcElection]);

  useEffect(() => {
    setOtherAdjustments(sumArray(otherAdjustments));
  }, [otherAdjustments]);

  useEffect(() => {
    setInfraAdjustmentsTotal(sumArray(adjustmentsForQualifyingInfraCompanies));
  }, [adjustmentsForQualifyingInfraCompanies]);

  useEffect(() => {
    let total = upwardAdjustmentsTotal + downwardAdjustmentsTotal + mismatchAdjustmentsTotal + interestAltCalcTotal + otherAdjustmentsTotal + sumArray([tables['angie_proportion_associated_groups_s427_4']?.state[0]]) + infraAdjustmentsTotal;
    let totalBeforeAnyAdjustments = upwardAdjustmentsTotal + downwardAdjustmentsTotal + mismatchAdjustmentsTotal + interestAltCalcTotal + otherAdjustmentsTotal;
    setTotalWithoutNGIE(totalBeforeAnyAdjustments)
    if (currentPeriod?.local_curr_net_group_interest_expense) {
      total += currentPeriod?.local_curr_net_group_interest_expense;
      totalBeforeAnyAdjustments += currentPeriod?.local_curr_net_group_interest_expense;
    }
    
    setTotalAdjustments(Math.round(total));
    setAngieAmountBeforeAnyAdjustments(Math.round(totalBeforeAnyAdjustments));
  }, [upwardAdjustmentsTotal, downwardAdjustmentsTotal, mismatchAdjustmentsTotal, interestAltCalcTotal, otherAdjustmentsTotal, , tables['angie_proportion_associated_groups_s427_4'].state[0], infraAdjustmentsTotal])

  useEffect(() => {
    const angie = currentPeriod?.angie;
    if (!angie) {
      return;
    }

    Object.keys(tables)?.forEach(key => {
      tables[key]?.state[1](angie[key])
    })
  }, []);

  const submit = () => {
    const newPeriod = {
      ...currentPeriod,

      angie: Object.keys(tables)?.reduce((acc, k) => {
        acc[k] = tables[k]?.state[0];
        return acc;
      }, {})
    }
    setANGIETotals(newPeriod);

    if (newPeriod?.qngie) {
      setQNGIETotals(newPeriod);
    }

    setCurrentPeriod(newPeriod);
    const promise = save(newPeriod);
    toast.promise(promise, {
      loading: 'Saving NGIE data...',
      success: () => {
        setVisible(false);
        return 'Saved NGIE data!'
      },
      error: 'Something went wrong with saving NGIE data:',
    })
  }

  const [isModalChanged, setIsModalChanged] = useState(false);

  useEffect(() => {
    const total = totalAdjustments;
    if (currentPeriod?.local_curr_adjusted_net_group_interest_expense !== total) {
      setIsModalChanged(true);
    } else {
      setIsModalChanged(false);
    }
  }, [totalAdjustments]);

  const [showWarningModal, setShowWarningModal] = useState(false);

  return (
    <>
      <Modal
        visible={true}
        title={`Adjusted net group-interest expense`}
        onCancel={() => handleCancel(isModalChanged, isReadOnly, setVisible, setShowWarningModal)}
        maskCloseable={false}
        modalStyle={{ width: '90%', maxHeight: '90%' }}
        bodyStyle={{ maxHeight: '90%' }}
      >
        <table className="table">
          <tbody>
            <tr>
              <td>
                <div className="row">
                  <p className="col-1">Selected input value currency:</p>
                  <Input
                    className="col"
                    disabled
                    value={currentPeriod?.currency}
                  />
                </div>
              </td>
              <td>
                <div className="row">
                  <p className="col-4">
                    Entered FX rate: 1 GBP = X Local Currency (if required):
                  </p>
                  <InputNumber
                    className="col"
                    value={currentPeriod?.fx_rate}
                    disabled
                  />
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        
        <div className='row group-data-modal-top-input-header'>
          <p>
            Changes to the currency and exchange rate can be made in the Net group-interest expense section
          </p>
        </div>
        
        {isError && <div className='err'>Description missing - a description must be provided for every value.</div>}


        <Accordion multiple={false} onClick={onClickAccordion} activeKeys={activeKeys}>
          <AccordionItem title={renderPrimaryAccordionTitle('NGIE', Math.round(ngieETotal), ["This amount is taken from the NGIE calculation - to amend it, return to the NGIE page"])} itemKey="1">

          </AccordionItem>
          <AccordionItem title={renderPrimaryAccordionTitle('Upward Adjustments', upwardAdjustmentsTotal)} itemKey="2">
            {renderTableKeyList(tables, [
              'capitalised_expense_in_statements',
              'capitalised_income_written_off',
              'expense_amount_recognised_in_equity_or_shareholders',
              'upward_amount_under_s330za',
              'post_2020_transitional_amounts_s607za_cta2009',
              'post_2020_income_amounts_s330za_transitional',
              'post_2020_derivative_income_s607za',
              'upward_amount_under_s330_cta_election',
              'relevant_debt_release_tax_exempt_income',
            ])}
          </AccordionItem>
          
          <AccordionItem title={renderPrimaryAccordionTitle('Downward Adjustments', downwardAdjustmentsTotal)} itemKey="3">
            {renderTableKeyList(tables, [
              'capitalised_income_in_statements',
              'capitalised_expense_written_off',
              'income_amount_recognised_in_equity_or_shareholders',
              'downward_amount_under_s330ZA',
              'post_2020_derivative_expense_s607za',
              'downward_amount_under_s330_cta_election',
              'expense_amount_excluded_for_s322_2_or_cta2009',
              'expense_amount_for_dividends_on_preference_shares',
            ])}
          </AccordionItem>

          <AccordionItem title={renderPrimaryAccordionTitle('Group Mismatch Adjustments (SI 2017/1224)', mismatchAdjustmentsTotal, ["Certain adjustments were brought in under The Corporate Interest Restriction (Financial Statements: Group Mismatches) Regulations 2017 (SI 2017 / 1224) that adjusted the financial statements of the group in relation to Group-Interest amounts. As the regulations only apply to Group-Interest amounts, the adjustments have been included for calculation purposes as an adjustment to ANGIE. The precise details are complex - refer to SI 2017 / 1224 to determine whether they might apply"])} itemKey="4">
            {renderTableKeyList(tables, [
              'mismatches_regulation_2_adjustments',
              'mismatches_regulation_3_adjustments',
              'mismatches_regulation_4_adjustments',
            ])}
          </AccordionItem>

          <AccordionItem title={renderPrimaryAccordionTitle('Interest Allowance (Alternative Calculation) Election', interestAltCalcTotal)} itemKey="5">
            {renderTableKeyList(tables, [
              'capitalised_upward_interest_adjustment',
              'capitalised_downward_interest_adjustment',
              'reversal_gaap_tax_assets_s423_2_a',
              'gaap_assets_written_off_s423_2_b',
              'gaap_relevant_expense_written_off_s423_2a',
              'gaap_relevant_income_written_off_s423_2b',
              'gaap_asset_carrying_value_relevant_expense_s423_2aa_a',
              'gaap_asset_carrying_value_relevant_income_s423_2aa_b',
            ])}
          </AccordionItem>

          <AccordionItem title={renderPrimaryAccordionTitle('Other', otherAdjustmentsTotal)} itemKey="6">
            {renderTableKeyList(tables, [
              'specialist_regimes_adjustments',
              'miscellaneous_adjustments',
            ])}
          </AccordionItem>

          <AccordionItem title={renderPrimaryAccordionTitle('Interest Allowance (non-consolidated investment election)', sumArray([tables['angie_proportion_associated_groups_s427_4']?.state[0]]))} itemKey="7">
            {renderTableKeyList(tables, [
              'angie_proportion_associated_groups_s427_4'
            ])}
          </AccordionItem>

          <AccordionItem title={renderPrimaryAccordionTitle('Adjustments for Qualifying Infrastructure Companies', infraAdjustmentsTotal)} itemKey="8">
            {renderTableKeyList(tables, [
              'exclude_qic_income_exempt_s440_s442_2a',
              'exclude_qic_expense_exempt_s438_s442_2a'
            ])}
          </AccordionItem>
        </Accordion>

        {/* {renderDynamicTable(tableOtherAdjustmentsCalcNGIE, setTableOtherAdjustmentsCalcNGIE, setIsError)} */}

        <h3>Summary</h3>
        {renderSummary(currentPeriod?.currency, currentPeriod?.fx_rate, totalAdjustments)}

        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'end', paddingTop: '30px', gap: 10 }}>
          <Button neverReadonly onClick={() => handleCancel(isModalChanged, isReadOnly, setVisible, setShowWarningModal)} kind="secondary">Cancel</Button>
          <Button hiddenInReadonly disabled={isError || isParentError} onClick={submit}>Submit</Button>
        </div>
      </Modal>

      <WarningModal
        visible={showWarningModal}
        setIsVisible={setShowWarningModal}
        callBackFunc={setVisible}
      >
      </WarningModal>
    </>
  );
};

export default ANGIE;
