import dayjs from "dayjs";
import { generateCAPId, generateCAPIdByCompName } from "./capProcessor";
import { getDefaultCompanyLeaveDate, getOriginatingPoaMinStartDateJS, processDateJS } from "./dateProcessor";
import { cap_adjusted_values } from "./CapCurrencyValues";
import { getGroupData, getPoaIndex } from "../services/GroupContext";

const nonTradingKeys = [
    {
        cfd: 'cfd_non_trading_loan_relationships_gbp',
        cfdadj: 'cfdadj_non_trading_loan_relationships_gbp',
        bfd: 'bfd_non_trading_loan_relationships_gbp',
        bfdadj: 'bfdadj_non_trading_loan_relationships_gbp',
        cpr: 'cpr_non_trading_loan_relationships_gbp',
        cpd: 'cpd_non_trading_loan_relationships_gbp'
    },
    {
        cfd: 'cfd_non_trading_derivatives_gbp',
        cfdadj: 'cfdadj_non_trading_derivatives_gbp',
        bfd: 'bfd_non_trading_derivatives_gbp',
        bfdadj: 'bfdadj_non_trading_derivatives_gbp',
        cpr: 'cpr_non_trading_derivatives_gbp',
        cpd: 'cpd_non_trading_derivatives_gbp'
    }
];

const tradingKeys = [
    {
        cfd: 'cfd_trading_loan_relationships_gbp',
        cfdadj: 'cfdadj_trading_loan_relationships_gbp',
        bfd: 'bfd_trading_loan_relationships_gbp',
        bfdadj: 'bfdadj_trading_loan_relationships_gbp',
        cpr: 'cpr_trading_loan_relationships_gbp',
        cpd: 'cpd_trading_loan_relationships_gbp'
    },
    {
        cfd: 'cfd_trading_derivatives_gbp',
        cfdadj: 'cfdadj_trading_derivatives_gbp',
        bfd: 'bfd_trading_derivatives_gbp',
        bfdadj: 'bfdadj_trading_derivatives_gbp',
        cpr: 'cpr_trading_derivatives_gbp',
        cpd: 'cpd_trading_derivatives_gbp'
    }
];

const ofaKeys = [
    {
        cfd: 'cfd_other_financing_gbp',
        cfdadj: 'cfdadj_other_financing_gbp',
        bfd: 'bfd_other_financing_gbp',
        bfdadj: 'bfdadj_other_financing_gbp',
        cpr: 'cpr_other_financing_gbp',
        cpd: 'cpd_other_financing_gbp'
    }
];

const allKeys = [...nonTradingKeys, ...tradingKeys, ...ofaKeys];

const subtotalKeysGBP = {
    cfd: 'cfd_subtotal_gbp',
    cfdadj: 'cfdadj_subtotal_gbp',
    bfd: 'bfd_subtotal_gbp',
    bfdadj: 'bfdadj_subtotal_gbp',
    cpr: 'cpr_subtotal_gbp',
    cpd: 'cpd_total_gbp'
};

const tradingNonTradingConditionForEditBFD = (adjustedCap) => ({
    trading: !!adjustedCap?.trade_uncommercial_or_non_statutory,
    nonTrading: false
})

const tradingNonTradingConditionForEditCFD = (adjustedCap) => ({
    trading: !!adjustedCap?.trade_small_or_negligible,
    nonTrading: !!adjustedCap?.investment_business_ceased
})

const setBFDADJasNegativeBFD = (adjustedCap, conditionsMet, localCurrency = false) => {
    if(conditionsMet.trading) {
        tradingKeys.forEach(keys => {
            const adjKey = localCurrency ? keys.bfdadj.replace('_gbp', '') : keys.bfdadj;
            const bfdKey = localCurrency ? keys.bfd.replace('_gbp', '') : keys.bfd;
            adjustedCap[adjKey] = (Number(0 - adjustedCap[bfdKey]) || 0);
        });
    }

    if(conditionsMet.nonTrading) {
        nonTradingKeys.forEach(keys => {
            const adjKey = localCurrency ? keys.bfdadj.replace('_gbp', '') : keys.bfdadj;
            const bfdKey = localCurrency ? keys.bfd.replace('_gbp', '') : keys.bfd;
            adjustedCap[adjKey] = (Number(0 - adjustedCap[bfdKey]) || 0);
        });
    }

    if(Object.values(conditionsMet).includes(true)) {
        ofaKeys.forEach(keys => {
            const adjKey = localCurrency ? keys.bfdadj.replace('_gbp', '') : keys.bfdadj;
            const bfdKey = localCurrency ? keys.bfd.replace('_gbp', '') : keys.bfd;
            adjustedCap[adjKey] = (Number(0 - adjustedCap[bfdKey]) || 0);
        });
    
        recalculateTotal(adjustedCap, 'bfdadj');
    }
}

const convertLCToGBP = (adjustedCap, gbpKey) => {
    const lcKey = gbpKey.replace('_gbp', '');
    adjustedCap[gbpKey] = (Number(adjustedCap[lcKey]) || 0) / (Number(adjustedCap.fx_rate) || 1);
}

const recalculateAllTotals = (adjustedCap, localCurrency = false) => {
    Object.keys(subtotalKeysGBP).forEach(key => {
        recalculateTotal(adjustedCap, key, localCurrency);
    });
}

const initiateAdjustedCapProps = (grpData, poa, compAP) => {
    const company = grpData?.companies?.find(c => c.company_ID === compAP?.company_ID);

    const companyJoinDate = processDateJS(company?.date_join || getOriginatingPoaMinStartDateJS());
    const companyLeaveDate = processDateJS(company?.date_left || getDefaultCompanyLeaveDate());

    const group_period_start = processDateJS(poa?.period_start);
    const group_period_end = processDateJS(poa?.period_end);

    const relevantPeriodStart = processDateJS(dayjs.max(companyJoinDate, group_period_start));
    const relevantPeriodEnd = processDateJS(dayjs.min(companyLeaveDate, group_period_end));

    const start_date = processDateJS(compAP?.start_date);
    const end_date = processDateJS(compAP?.end_date);

    const inclusion_period_start = dayjs.max(processDateJS(relevantPeriodStart), start_date);
    const inclusion_period_end = dayjs.min(processDateJS(relevantPeriodEnd), end_date);

    const grpPeriodLength = group_period_end?.diff(group_period_start, "day") + 1;
    const relPeriodLength = relevantPeriodEnd?.diff(relevantPeriodStart, 'day') + 1;
    const proportion_of_poa_as_group_company = relPeriodLength / grpPeriodLength;

    const inclusion_period_length = inclusion_period_end?.diff(inclusion_period_start, "day") + 1;

    const ap_length = end_date?.diff(start_date, "day") + 1;

    let adjCapPosInPoA = null
    if (processDateJS(inclusion_period_start).isSame(processDateJS(companyJoinDate)) || processDateJS(inclusion_period_start).isSame(processDateJS(group_period_start)))
    {
        if (processDateJS(inclusion_period_end).isSame(processDateJS(companyLeaveDate)) || processDateJS(inclusion_period_end).isSame(processDateJS(group_period_end)))
        {adjCapPosInPoA = 'StartEnd'}
        else
        {adjCapPosInPoA = 'Start'}
    }
    else 
    {
        if (processDateJS(inclusion_period_end).isSame(companyLeaveDate) || processDateJS(inclusion_period_end).isSame(processDateJS(group_period_end)))
        {adjCapPosInPoA = 'End'}
        else
        {adjCapPosInPoA = 'Middle'}
    }
   
    let AdjCapPosinCompanyAccountingPeriod = null
    if (processDateJS(inclusion_period_start).isSame(processDateJS(start_date)) && processDateJS(inclusion_period_end).isSame(processDateJS(end_date))) {
        AdjCapPosinCompanyAccountingPeriod = 'StartEnd'
    }
    else if (processDateJS(inclusion_period_start).isSame(processDateJS(start_date))) {
        AdjCapPosinCompanyAccountingPeriod = 'Start'
    }
    else if (processDateJS(inclusion_period_end).isSame(processDateJS(end_date))) {
        AdjCapPosinCompanyAccountingPeriod = 'End'
    }
    else {
        AdjCapPosinCompanyAccountingPeriod = 'Middle'
    }

    return {
            company_ID: company?.company_ID,
            company_name: company?.company_name,
            group_period_start,
            group_period_end,
            inclusion_period_start,
            inclusion_period_end,
            start_date: compAP?.start_date,
            end_date: compAP?.end_date,
            proportion_of_poa_as_group_company,
            inclusion_period_length,
            ap_length,
            inclusion_factor: inclusion_period_length / ap_length,
            AdjCapPosinCompanyAccountingPeriod,
            adjCapPosInPoA
    }
}

export function recalculateTotal (adjustedCap, property, localCurrency = false) {
    const subtotalKey = localCurrency ? subtotalKeysGBP[property].replace('_gbp', '') : subtotalKeysGBP[property];
    adjustedCap[subtotalKey] = 0;

    allKeys.forEach((keys) => {
        const propKey = localCurrency ? keys[property].replace('_gbp', '') : keys[property];
        adjustedCap[subtotalKey] += (Number(adjustedCap[propKey]) || 0);
    });
}

export function calculateCFDADJ (adjustedCap) {
    if (!adjustedCap) return;

    const conditionsMet = tradingNonTradingConditionForEditCFD(adjustedCap);

    if (conditionsMet.trading) {
        tradingKeys.forEach((keys) => {
            const bfd = Number(adjustedCap[keys.bfd]) || 0;
            const bfdadj = Number(adjustedCap[keys.bfdadj]) || 0;
            const cpd = Number(adjustedCap[keys.cpd]) || 0;
            const cpr = Number(adjustedCap[keys.cpr]) || 0;

            adjustedCap[keys.cfdadj] = Number(-(bfd + bfdadj + cpd - cpr));
        })
    }
    
    if (conditionsMet.nonTrading) {
        nonTradingKeys.forEach((keys) => {
            const bfd = Number(adjustedCap[keys.bfd]) || 0;
            const bfdadj = Number(adjustedCap[keys.bfdadj]) || 0;
            const cpd = Number(adjustedCap[keys.cpd]) || 0;
            const cpr = Number(adjustedCap[keys.cpr]) || 0;

            adjustedCap[keys.cfdadj] = Number(-(bfd + bfdadj + cpd - cpr));
        })
    }

    if(Object.values(conditionsMet).includes(true)) {        
        ofaKeys.forEach((keys) => {
            const bfd = Number(adjustedCap[keys.bfd]) || 0;
            const bfdadj = Number(adjustedCap[keys.bfdadj]) || 0;
            const cpd = Number(adjustedCap[keys.cpd]) || 0;
            const cpr = Number(adjustedCap[keys.cpr]) || 0;

            adjustedCap[keys.cfdadj] = Number(-(bfd + bfdadj + cpd - cpr));
        });

        recalculateTotal(adjustedCap, 'cfdadj');
    }
};


export function calculateCFD (adjustedCap) {
    if (!adjustedCap) return;
    
    allKeys.forEach((keys) => {
        const bfd = Number(adjustedCap[keys.bfd]) || 0;
        const bfdadj = Number(adjustedCap[keys.bfdadj]) || 0;
        const cpd = Number(adjustedCap[keys.cpd]) || 0;
        const cpr = Number(adjustedCap[keys.cpr]) || 0;
        const cfdadj = Number(adjustedCap[keys.cfdadj]) || 0;

        adjustedCap[keys.cfd] = Number(bfd + bfdadj + cpd - cpr + cfdadj);
    });
    
    recalculateTotal(adjustedCap, 'cfd');
}

export function setBFDasPrevCFD (adjustedCap, priorAdjustedCap) {
    allKeys.forEach(keys => {
        adjustedCap[keys.bfd] = (Number(priorAdjustedCap[keys.cfd]) || 0);
        
        adjustedCap[subtotalKeysGBP.bfd] += adjustedCap[keys.bfd];
    })
    
    recalculateTotal(adjustedCap, 'bfd');
}

export function setBFDADJForEditBFD (adjustedCap) {
    const conditionsMet = tradingNonTradingConditionForEditBFD(adjustedCap);

    setBFDADJasNegativeBFD(adjustedCap, conditionsMet, true);
}

export function setBFDADJForEditCFD (adjustedCap) {
    const conditionsMet = tradingNonTradingConditionForEditCFD(adjustedCap);

    setBFDADJasNegativeBFD(adjustedCap, conditionsMet)
}

export function convertGBPToLC (adjustedCap) {
    allKeys.forEach(keys => {
        Object.values(keys).forEach(gbpKey => {
            const lcKey = gbpKey.replace('_gbp', '');
            adjustedCap[lcKey] = (Number(adjustedCap[gbpKey]) || 0) * (Number(adjustedCap.fx_rate) || 1);
        })
    });

    recalculateAllTotals(adjustedCap, true);
}

export function convertBfdLcValuesToGBP (adjustedCap) {
    allKeys.forEach(keys => {
        convertLCToGBP(adjustedCap, keys.bfd);
        convertLCToGBP(adjustedCap, keys.bfdadj);
    })
}

export function convertAllLcValuesToGBP (adjustedCap) {
    allKeys.forEach(keys => {
        Object.values(keys).forEach(gbpKey => {
            convertLCToGBP(adjustedCap, gbpKey);
        })
    });

    recalculateAllTotals(adjustedCap);
}

export function updateCaps(newCaps, oldCaps) {
    if (!newCaps || !oldCaps) {
        return oldCaps || newCaps;
    }

    return newCaps.map(newCap => {
        const oldCap = oldCaps.find(oldCap => {
            return generateCAPId(oldCap) === generateCAPId(newCap);
        })

        return { ...oldCap, ...newCap };
    })
}

export function updateCapsByName(newCaps, oldCaps) {
    if (!newCaps || !oldCaps) {
        return oldCaps || newCaps;
    }

    return newCaps.map(newCap => {
        const oldCap = oldCaps.find(oldCap => {
            return generateCAPIdByCompName(oldCap) === generateCAPIdByCompName(newCap);
        })

        return { ...oldCap, ...newCap };
    })
}

export function carryOverAdjustedCaps(newCaps, oldCaps) {
    return newCaps.map(newCap => {
        const oldCap = oldCaps?.find(oldCap => {
            return generateCAPId(newCap) === generateCAPId(oldCap);
        });
        const savedData = getGroupData();
        const poa = savedData.periods_of_account[getPoaIndex()];
        const calculatedCap = oldCap ?? newCap;
        return {
            ...calculatedCap,
            ...initiateAdjustedCapProps(savedData, poa, calculatedCap)
        };
    });
}

// Mostly copied from initiateAdjustedCapProps above
export function calculateCapProps(grpData, poa, compAP) {
    const company = grpData?.companies?.find(c => c.company_ID === compAP?.company_ID);

    const companyJoinDate = processDateJS(company?.date_join || getOriginatingPoaMinStartDateJS());
    const companyLeaveDate = processDateJS(company?.date_left || getDefaultCompanyLeaveDate());

    const group_period_start = processDateJS(poa?.period_start);
    const group_period_end = processDateJS(poa?.period_end);

    const relevantPeriodStart = processDateJS(dayjs.max(companyJoinDate, group_period_start));
    const relevantPeriodEnd = processDateJS(dayjs.min(companyLeaveDate, group_period_end));

    const start_date = processDateJS(compAP?.start_date);
    const end_date = processDateJS(compAP?.end_date);

    const inclusion_period_start = dayjs.max(processDateJS(relevantPeriodStart), start_date);
    const inclusion_period_end = dayjs.min(processDateJS(relevantPeriodEnd), end_date);

    const ap_length = end_date?.diff(start_date, "day") + 1;
    
    const start_outside_poa = processDateJS(compAP?.start_date)?.isBefore(processDateJS(inclusion_period_start));
    const end_outside_poa = processDateJS(compAP?.end_date)?.isAfter(processDateJS(inclusion_period_end));
    
    const pre_app_start_date = start_date;
    const pre_app_end_date = inclusion_period_start.subtract(1, "day");
    
    // cannot be negative as inclusion period start is always after start_date
    const pre_app_length = pre_app_end_date.diff(pre_app_start_date, "days") + 1; 
    
    const pre_app_inclusion_factor = pre_app_length / ap_length;

    return {
        ...compAP,
        start_outside_poa,
        end_outside_poa,
        pre_app_inclusion_factor,
    }
}

export function applyInclusionFactorAdjustments (adjCap, correspondingCompanyAP) {
    if (adjCap?.inclusion_factor && correspondingCompanyAP) {
        Object.entries(adjCap)?.forEach(([key, entry]) => {
            if (cap_adjusted_values?.includes(key)) {
                if (adjCap?.hasOwnProperty(key) && adjCap[key] && adjCap[key]?.manual_adjustment === true) {
                    adjCap.adjustment_status = 'Manual Adjustment';
                }
                else {
                    const compAPVal = (correspondingCompanyAP && Object.keys(correspondingCompanyAP).includes(key)) ? correspondingCompanyAP[key] : 0;
                    adjCap[key] = {value: (Number(compAPVal) || 0) * adjCap.inclusion_factor, manual_adjustment: false};
                }
            }
            else {
                adjCap[key] = entry
            }
        })
    }

    return adjCap;
}

export function getCompanyAPsforPoA(compAPs, poa) {
    return compAPs.filter(cap => 
        (processDateJS(cap?.date_left)?.isAfter(processDateJS(poa?.period_start)) ?? true) &&
        (processDateJS(cap?.end_date)?.isAfter(processDateJS(poa?.period_start)) || 
         processDateJS(cap?.end_date)?.isSame(processDateJS(poa?.period_start), 'day')) &&
        (processDateJS(cap?.date_join)?.isBefore(processDateJS(poa?.period_end)) ?? true) &&
        (processDateJS(cap?.start_date)?.isBefore(processDateJS(poa?.period_end)) || 
         processDateJS(cap?.start_date)?.isSame(processDateJS(poa?.period_end), 'day'))
    );
}
