import { GET } from '../requestHandler'

const DREDGER_URL = process.env.REACT_APP_DREDGER_API
const UTR_REGEX = /^\d{5}-?\d{5}$/;

const get_onesource_groups = async (email) => {
    // return await GET(`${DREDGER_URL}/groups/${email}`);
    return await GET(`${DREDGER_URL}/groups`);
    
};

const get_group_companies = async (group_id, userEmail) => {
    if (!isFinite(group_id)) {
        throw new Error('group_id passed is not an integer')
    }

    // const result = await GET(`${DREDGER_URL}/group-companies/${group_id}/${userEmail}`);
    const result = await GET(`${DREDGER_URL}/group-companies/${group_id}`);
    if (result?.status !== 200) {
        throw Error(result?.err);
    }

    return result?.data?.map(r => {
        return {
            onesource_group_id: Number(group_id),
            company_ID: Number(r?.company_ID),
            company_name: r?.company_name,
            tax_office: r?.tax_office,
            utr: (r?.scfref_no && r?.scffile_no) ? `${r?.scfref_no}-${r?.scffile_no}` : undefined,
            from_onesource: true,
            uk_group_company: true,
        }
    });
}

/**
 * 
 * @param {[company_ID: number, company_name: string]} companies 
 * @returns 
 */
const get_company_data = async (companies, period_start, period_end, userEmail) => {
    if (!Array.isArray(companies)) {
        throw new Error('expected an array input')
    }

    const entity_ids = companies?.map(c => c?.company_ID)
    if (!entity_ids || entity_ids?.length === 0) {
        throw new Error('no entity ids provided')
    }

    const response = await GET(`${DREDGER_URL}/company-periods/${entity_ids}/${period_start}/${period_end}`)

    if (response?.status !== 200) {
        throw new Error(response?.err);
    }

    const company_data = response?.data?.map(r => {
        return {
            ...r,
            company_ID: Number(r?.company_ID)
        }
    });

    // Delete the keys keys that are not relevant to the company_accounting_periods section 
    const cleaned_company_data = company_data
        ?.filter(entry => {
            // Siva: TODO 15/05/2024 - Confirm if this behaviour is appropriate. 
            // Possible edge case we would need to support is if there are CIR data points but no start/end date, we would want to allow the user to fill in the missing dates   

            if (!entry?.hasOwnProperty('start_date')) {
                return false;
            }

            if (!entry?.hasOwnProperty('end_date')) {
                return false;
            }

            return true;
        })
        ?.map(entry => {
            //delete entry.tax_office;
            delete entry?.scfref_no;
            delete entry?.scffile_no;
            delete entry?.company_name;

            // Ensure these fields map to Date objects instead of being a String 
            entry.start_date = new Date(entry?.start_date);
            entry.end_date = new Date(entry?.end_date);

            // Siva: Some OneSource values for fx_rate is zero. In these cases, remove the fx_rate as it
            // will mess with downstream calculations.
            if (Number(entry?.fx_rate) <= 0) {
                delete entry?.fx_rate;
            }

            // Siva: Some OneSource values for SCFREF_NO and SCFFILE_NO are not length 5. We analysed the database and most of these are 
            // before 2017, thus not relevant to CIR. However, to be safe, added a check for completeness that will remove values that 
            // break the format 
            if (entry?.utr && !entry?.utr?.match(UTR_REGEX)) {
                delete entry?.utr;
            }

            entry.from_onesource = true;
            entry.onesource_sync = true;

            return entry;
        });

    return { company_accounting_periods: cleaned_company_data };

}

const get_company_periods_only = async (companies, period_start, period_end, userEmail) => {
    if (!Array.isArray(companies)) {
        throw new Error('expected an array input')
    }

    const entity_ids = companies?.map(c => c?.company_ID)
    if (!entity_ids || entity_ids?.length === 0) {
        throw new Error('no entity ids provided')
    }

    const response = await GET(`${DREDGER_URL}/company-periods-only/${entity_ids}/${period_start}/${period_end}`)
    if (response?.status !== 200) {
        throw new Error(response?.err);
    }

    const company_data = response?.data?.map(r => {
        return {
            ...r,
            company_ID: Number(r?.company_ID)
        }
    });

    // Delete the keys keys that are not relevant to the company_accounting_periods section 
    const cleaned_company_data = company_data
        ?.filter(entry => {
            // Siva: TODO 15/05/2024 - Confirm if this behaviour is appropriate. 
            // Possible edge case we would need to support is if there are CIR data points but no start/end date, we would want to allow the user to fill in the missing dates   

            if (!entry?.hasOwnProperty('start_date')) {
                return false;
            }

            if (!entry?.hasOwnProperty('end_date')) {
                return false;
            }

            return true;
        })
        ?.map(entry => {
            //delete entry.tax_office;
            delete entry?.scfref_no;
            delete entry?.scffile_no;
            delete entry?.company_name;

            // Ensure these fields map to Date objects instead of being a String 
            entry.start_date = new Date(entry?.start_date);
            entry.end_date = new Date(entry?.end_date);

            // Siva: Some OneSource values for fx_rate is zero. In these cases, remove the fx_rate as it
            // will mess with downstream calculations.
            if (Number(entry?.fx_rate) <= 0) {
                delete entry?.fx_rate;
            }

            // Siva: Some OneSource values for SCFREF_NO and SCFFILE_NO are not length 5. We analysed the database and most of these are 
            // before 2017, thus not relevant to CIR. However, to be safe, added a check for completeness that will remove values that 
            // break the format 
            if (entry?.utr && !entry?.utr?.match(UTR_REGEX)) {
                delete entry?.utr;
            }

            entry.from_onesource = true;
            entry.onesource_sync = true;

            return entry;
        });

    return cleaned_company_data;
}

const get_company_id_by_name = async (company_name, userEmail) => {
    // const entity_id_response = await GET(`${DREDGER_URL}/entity-name/${company_name}${userEmail}`)
    const entity_id_response = await GET(`${DREDGER_URL}/entity-name/${company_name}`)
    // The GET call wraps error in another object, with status code 400. Let's find the real status code
    if (entity_id_response?.status !== 200) {
        throw new Error(`Failed to find entity: ${entity_id_response?.err?.error}`)
    }

    const company = entity_id_response?.data;
    return {
        onesource_group_id: Number(company?.onesource_group_id),
        company_ID: Number(company?.company_ID),
        company_name: company?.company_name,
        tax_office: company?.tax_office,
        utr: (company?.scfref_no && company?.scffile_no) ? `${company?.scfref_no}-${company?.scffile_no}` : undefined,
        from_onesource: true,
        uk_group_company: true,
    };
}

export { get_onesource_groups, get_group_companies, get_company_data, get_company_id_by_name, get_company_periods_only };