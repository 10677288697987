import { Panel, Loading } from '@appkit4/react-components'; 
import { Button, Input, CalendarPicker, InputNumber } from "../../components/ReadonlyAwareInputs";
import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { BackButton } from '../../components/ProgressNav';
import { getGroupData, setGroupData, setSelectedPeriodData, setPoaIndex, getGroupName, getPoaIndex, getPeriodIsReadonly } from '../../services/GroupContext';
import toast from '../../components/DismissibleToast';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import sqlService from '../../services/sqldatabase/sqldatabase.service'
import { AuthContext } from '../../services/AuthProvider';
import { getDefaultPeriodEnd, getMaxPeriodEnd, processDate, processDateJS, getOriginatingPoaMinStartDateJS } from '../../utils/dateProcessor';
import { getDefaultPeriod } from '../new-group/NewGroup';
import CustomTableHeader from '../../components/tableHeader/tableHeader';
import OpenPeriodModal from './OpenPeriodModal';
import { getUltParent} from '../../components/CompanyPanel'
import { formatNegativeNumber } from '../../utils/formatters';

dayjs.extend(utc)

export const validReturnTypes = {
    full: "Full",
    abbreviated: "Abbreviated"
}

export const defaultReturnStatus = {
    isFinalised: false,
    withoutReportingCompany: false,
    type: "",
    isSubmitted: false,
    submittedDate: undefined,
    reviewConfirmed: false,
    reviewedBy: "",
    isRevised: false,
    revisedNotes: [],
}

export const formatReturnStatus = (returnStatus) => {
    if (!returnStatus)
        return "";

    return (returnStatus.isRevised ? "Revised " : "")
        + (! returnStatus.isFinalised ? "Draft" : [
            "Finalised",
            returnStatus.type,
            "-",
            returnStatus.withoutReportingCompany ? "no reporting company" : returnStatus.isSubmitted ? "Submitted" : "Not Submitted"
        ].filter(v => v).join(" "));
}

const GroupPOA = () => {
    const [isLoading, setIsLoading] = useState(true)
    const [isPosting, setIsPosting] = useState(false);
    const auth = useContext(AuthContext)

    const [tableData, setTableData] = useState([])
    const [minPOAStart, setMinPOAStart] = useState([processDate(getOriginatingPoaMinStartDateJS())])
    const [maxPOAEnd, setMaxPOAEnd] = useState([])
    const [isError, setIsError] = useState(false)

    const [isPoaStartDateValid, setIsPoaStartDateValid] = useState();
    const [isPoaEndDateValid, setIsPoaEndDateValid] = useState();
    const [arePoaDatesValid, setArePoaDatesValid] = useState();
    
    const isReadOnly = auth?.isReadOnlyUser(getGroupName());

    const navigate = useNavigate();
    const [canSelect, setCanSelect] = useState(false);

    const [showOpenPeriodModal, setShowOpenPeriodModal] = useState(false);
    const [openPeriodIndex, setOpenPeriodIndex] = useState(-1);


    const submit = async () => {
        if (!isTableValid()) {
            toast.error('There are validation errors on the table!')
            return;
        }

        setIsPosting(true);

        const newJson = {
            ...getGroupData(),
            periods_of_account: tableData,
        }
       
        setGroupData(newJson);

        const poaIndex = getPoaIndex();
        let PoAPosition = poaIndex === 0 ? "start" : "inBetween";
        if ( poaIndex === tableData?.length-1 ) PoAPosition = "last";
        setSelectedPeriodData({ PoAPosition }, true);

        if (isReadOnly) {
            navigate('/app/groupcompanies');
            return;
        }

        const promise = sqlService?.postResponse(getGroupData());
        toast.promise(promise, {
            loading: 'Saving to the database...',
            success: (data) => {
                setIsPosting(false);
                navigate('/app/groupcompanies')
                return 'Saved to the database'
            },
            error: 'Error while saving',
        })

    }

    // Initial page load
    useEffect(() => {
        const savedData = getGroupData();
        if (!savedData) {
            return;
        }

        let periodOfAccounts = savedData?.periods_of_account;
        if (!periodOfAccounts) {
            // If PoA data is empty, populate it with the 
            periodOfAccounts = [getDefaultPeriod(savedData?.model_first_period_start, savedData?.model_first_period_end)];
        }

        // When data is loaded back in from the DB, it will be a string. Need to parse it as a Date
        // fill any missing fields with defaults
        periodOfAccounts = periodOfAccounts?.map(poa => (
            {
                ...poa,
                period_start: poa?.period_start ? new Date(poa?.period_start) : savedData?.model_first_period_start,
                period_end: poa?.period_end ? new Date(poa?.period_end) : savedData?.model_first_period_end,
                return_status: poa?.return_status && typeof poa?.return_status === "object" ? poa?.return_status : {...defaultReturnStatus},
                ratio_type: ((poa?.apply_group_ratio_election ?? null) === null) ? 'Not Selected' : (poa?.apply_group_ratio_election ? 'Group Ratio' : 'Fixed Ratio'),
                disallowance_reactivation: poa?.group_disallowance > 0 ? poa?.group_disallowance : poa.group_interest_reactivation_available * -1,
                group_excess_debt_cap: poa?.group_excess_debt_cap ?? '',
                unused_interest_allowance: poa?.group_unused_allowance ?? '',
                unused_allowance: poa?.unused_allowance ?? ''
            }
        ))

        setIsPoaStartDateValid(periodOfAccounts?.map(() => true));
        setIsPoaEndDateValid(periodOfAccounts?.map(() => true));

        setTableData(periodOfAccounts);
        setIsLoading(false);
    }, [ showOpenPeriodModal ])

    // Update table validation
    useEffect(() => {
        const tempMaxPOAEnd = [...maxPOAEnd];
        const tempMinPOAStart = [...minPOAStart];
        let validSelect = true;

        for (var x = 0; x < tableData?.length; x++) {
            const row = tableData[x];
            if (!row) {
                continue;
            }

            const period_start = row?.period_start;
            if (period_start) {
                tempMaxPOAEnd[x] = getMaxPeriodEnd(period_start).toDate();
            }
            
            if (x > 0 && tableData[x - 1]['period_end']) {
                var mindate = new Date(tableData[x - 1]['period_end'])

                tempMinPOAStart[x] = mindate;
            }

            if (new Date(row?.period_start) < new Date(getOriginatingPoaMinStartDateJS())) {  
                validSelect = false;  
                break;  
            }  
        }
 
        setMinPOAStart(tempMinPOAStart);
        setMaxPOAEnd(tempMaxPOAEnd);
        isTableValid();
        setCanSelect(validSelect);
        
    }, [ tableData ]);

    useState(() => {
        setArePoaDatesValid(isPoaStartDateValid?.map((isValid, index) => isValid && isPoaEndDateValid[index]));
    }, [isPoaStartDateValid, isPoaEndDateValid]);

    const isTableValid = () => {
        for (const row of tableData) {
            const period_start = row?.period_start;
            if (!period_start || period_start === 'Invalid Date') {
                setIsError(true);
                return false;
            }

            const period_end = row?.period_end;
            if (!period_end || period_end === 'Invalid Date') {
                setIsError(true);
                return false;
            }

            if (processDateJS(period_end)?.isBefore(period_start)) {
                setIsError(true);
                return false;
            }

            if (getMaxPeriodEnd(period_start)?.isBefore(period_end)) {
                setIsError(true);
                return false;
            }
        }

        setIsError(false);
        return true;
    }

    const tableChange = (index, value, name) => {
        const rowsInput = [...tableData];
        const row = rowsInput[index];

        if (name === 'period_start' || name === 'period_end') {
            value = processDate(value);

            // Auto-populate periods if possible
            if (row?.period_start && !row?.period_end) {
                const defaultDate = new Date(row?.period_start);
                defaultDate?.setFullYear(defaultDate?.getFullYear() + 1);
                defaultDate?.setDate(defaultDate?.getDate() - 1);

                rowsInput[index]['period_end'] = defaultDate;
            }

            if (index > 0 && rowsInput[index - 1]['period_end'] && !row?.period_start) {
                var mindate = new Date(rowsInput[index - 1]['period_end'])
                mindate?.setDate(mindate?.getDate() + 1);

                tableData[index]['period_start'] = mindate;
            }
        }
        
        rowsInput[index][name] = value;

        if (name === 'period_end' && index < tableData?.length - 1) {
            const nextStartDate = new Date(value);
            nextStartDate?.setDate(nextStartDate?.getDate() + 1);
            rowsInput[index + 1]['period_start'] = nextStartDate;
        }

        setTableData(rowsInput);
    }

    const addTableRows = () => {
        const previousData = tableData?.sort((a, b) => {
            return a?.period_start?.getTime() - b?.period_start?.getTime()
        })

        const lastPoA = previousData[previousData?.length - 1];

        const new_period_start = processDateJS(lastPoA?.period_end)?.add(1, 'day');
        const new_period_end = getDefaultPeriodEnd(new_period_start);
        setTableData(([...previousData,
        {
            "period_start": new_period_start?.toDate(),
            "period_end": new_period_end?.toDate(),
            "return_status": {...defaultReturnStatus},
            "ratio_type": 'Not Selected',
            "disallowance_reactivation": "",
            "group_excess_debt_cap": "",
            "unused_interest_allowance": "",
            "unused_allowance": ""
        }]));

        setIsPoaStartDateValid(prevValidators => [...prevValidators, true]);
        setIsPoaEndDateValid(prevValidators => [...prevValidators, true]);
    }

    const deleteTableRows = (index) => {
        const rows = [...tableData];
        rows?.splice(index, 1);

        if (index < rows?.length) {
            if (index === 0) {
                const nextMinStartDate = new Date(minPOAStart[0])
                rows[0]['period_start'] = nextMinStartDate;
            }
            else {
                const prevEndDate = new Date(rows[index - 1]['period_end']);
                prevEndDate?.setDate(prevEndDate?.getDate() + 1);
                rows[index]['period_start'] = prevEndDate;
            }
        }

        setTableData(rows);

        setIsPoaStartDateValid(prevState => prevState?.splice(index,1));
        setIsPoaEndDateValid(prevState => prevState?.splice(index,1));
    }

    if (isLoading) {
        return (
            <Loading
                loadingType="linear"
                indeterminate={true}
                compact={false}
                className="page-loader"
            ></Loading>
        );
    }

    return (
        <div className="ap-container">
            <OpenPeriodModal
                visible={showOpenPeriodModal}
                setVisible={setShowOpenPeriodModal}
                periodIndexToOpen={openPeriodIndex}
            />
            <Panel className='mt-4'>
                <div className="row">
                    <label className="col-sm-2 col-form-label">Group Name</label>
                    <div className="col-sm-10">
                        <b>{getGroupData()?.group_name}</b>
                    </div>
                </div>
                <div className="row">
                    <label className="col-sm-2 col-form-label">Ultimate Parent</label>
                    <div className="col-sm-10">
                        <b>{getUltParent()}</b>
                    </div>
                </div>
            </Panel>
            <Panel className='mt-4'>
                <div style={{paddingTop:'20px'}}>
                    <table className="table" style={{width: "100%"}}>
                        <thead>
                            <tr style={{textAlign: 'left'}}>
                                <CustomTableHeader title="Period of Account Start Date" key={1} required={false} tooltipText={<p>This is the start of the period of account.</p>} />

                                <CustomTableHeader title="Period of Account End Date" key={2} required={false} tooltipText={<p>This is the end of the period of account.</p>} />

                                <CustomTableHeader title="Return Status" key={3} required={false} tooltipText={<p>This reflects the current status of the CIR return for the period of account. A full list of the potential statuses and their meaning is included in the User Guide.</p>} />

                                <CustomTableHeader title="Fixed / Group Ratio" key={4} required={false} tooltipText={<p>This identifies whether the fixed or the group ratio has been applied to the calculations for that period of account.</p>} />

                                <CustomTableHeader title="Disallowance / (Reactivation)" key={5} required={false} tooltipText={<p>This summarises the output of the calculation for that period of account. A positive figure reflects an overall disallowed amount for the period, and a negative figure reflects a reactivation for the period.</p>} />

                                <CustomTableHeader title="Excess Debt Cap Generated" key={6} required={false} tooltipText={<p>Where the CIR calculations reflect a disallowed amount for the period, and ANGIE (or QNGIE) is not the limiting factor, an Excess Debt Cap amount will be generated. The excess debt cap is a cumulative measure such that the excess debt cap generated in the immediately preceding period is effectively the total excess debt cap brought forward. Refer to s400 TIOPA 2010.</p>} />

                                <CustomTableHeader title="Unused Allowance generated/(utilised)" key={7} required={false} tooltipText={<p>This reflects the amount of Interest Allowance generated in the period which is not utilised in the period, and may therefore be available to use in later periods to the extent that it has not already been used in a subsequent period or expired.</p>} />

                                {/* //TODO Unhide after release
                                <th className="col" key={8}>Unexpired Allowance</th>
                                <th className="col" key={9}>Potential Unused Allowance</th> */}
                                <th className="col" key={10}></th>
                                <th><Button add className="btn btn-outline-success" disabled={true} onClick={addTableRows} ><span className="Appkit4-icon icon-plus-outline"></span></Button></th>
                            </tr>
                        </thead>
                        <tbody>
                            {tableData?.map((data, index) => {
                                const isPoaReadOnly = getPeriodIsReadonly( data );
                                const isLastRow = index === tableData?.length - 1;
                                const canDelete = isLastRow && ! data['return_status'].isSubmitted && index !== 0;
                                return (
                                    <tr key={index}>
                                        <td key={1} style={{ verticalAlign: arePoaDatesValid && arePoaDatesValid[index] ? 'middle' : 'top' }}>
                                            <CalendarPicker 
                                                format='DD/MM/YYYY' placeholder='dd/mm/yyyy' className="col" fieldWidth={150} 
                                                value={data['period_start']} 
                                                minDate={minPOAStart[index]} 
                                                disabled={index !== 0 || isPoaReadOnly}
                                                onChange={(val, isValid) => {
                                                    tableChange(index, val, 'period_start');
                                                    setIsPoaStartDateValid(prevState => {
                                                        const newState = prevState;
                                                        newState[index] = isValid;
                                                        return newState;
                                                    })}
                                                }
                                            />
                                        </td>
                                        <td key={2} style={{ verticalAlign: arePoaDatesValid && arePoaDatesValid[index] ? 'middle' : 'top' }}>
                                            <CalendarPicker 
                                                format='DD/MM/YYYY' placeholder='dd/mm/yyyy' className="col" fieldWidth={150} 
                                                value={data['period_end']} 
                                                minDate={data['period_start']} 
                                                maxDate={maxPOAEnd[index]}
                                                disabled={isPoaReadOnly}
                                                onChange={(val, isValid) => {
                                                    tableChange(index, val, 'period_end');
                                                    setIsPoaEndDateValid(prevState => {
                                                        const newState = prevState;
                                                        newState[index] = isValid;
                                                        return newState;
                                                    })}
                                                }
                                            />
                                        </td>
                                        <td key={3} style={{ verticalAlign: arePoaDatesValid && arePoaDatesValid[index] ? 'middle' : 'top' }}>
                                            <Input readonly value={ formatReturnStatus(data['return_status']) } />
                                            {/* <Select className="col" data={['Full', 'Abbreviated', 'Not Submitted', 'Amended']} value={data['return_status']} onSelect={(evnt) => { tableChange(index, evnt, 'return_status') }} /> */}
                                        </td>
                                        <td key={4} style={{ verticalAlign: arePoaDatesValid && arePoaDatesValid[index] ? 'middle' : 'top' }}>
                                            <Input readonly value={data['ratio_type']} />
                                        </td>
                                        <td key={5} style={{ verticalAlign: arePoaDatesValid && arePoaDatesValid[index] ? 'middle' : 'top' }}>
                                            <InputNumber
                                                className="col"
                                                value={
                                                    data["status_reviewcalc2_completed"] ? 
                                                        
                                                            data['group_disallowance'] ?
                                                                data['group_disallowance'] :
                                                                formatNegativeNumber(data['group_reactivations'])
                                                         : 
                                                        ""
                                                }
                                                format=","
                                                readonly
                                                // onChange={(evnt) => { tableChange(index, evnt, 'group_reactivations') }}
                                            />
                                        </td>
                                        <td key={6} style={{ verticalAlign: arePoaDatesValid && arePoaDatesValid[index] ? 'middle' : 'top' }}>
                                            <InputNumber
                                                className="col"
                                                value={data["status_reviewcalc2_completed"] ? Math.round(data['group_excess_debt_cap']) : ""}
                                                min={0}
                                                format=","
                                                readonly
                                                onChange={(evnt) => { tableChange(index, evnt, 'group_excess_debt_cap') }}
                                            />
                                        </td>
                                        <td key={7} style={{ verticalAlign: arePoaDatesValid && arePoaDatesValid[index] ? 'middle' : 'top' }}>
                                            <InputNumber
                                                className="col"
                                                value={
                                                    data["status_reviewcalc2_completed"]  ? 
                                                            data['group_bf_interest_allowance'] ? 
                                                            formatNegativeNumber(data['group_bf_interest_allowance']) : 
                                                                data['unused_generated']
                                                         :
                                                        ""
                                                }
                                                min={0}
                                                format=","
                                                readonly
                                                // onChange={(evnt) => {
                                                //     tableChange(index, evnt, 'unused_generated')
                                                //     tableChange(index, evnt, 'group_bf_interest_allowance')
                                                // }}
                                                />
                                        </td>
    {/*                                     //TODO Unhide after release
                                        <td key={8} style={{ verticalAlign: arePoaDatesValid && arePoaDatesValid[index] ? 'middle' : 'top' }}>
                                            <InputNumber
                                                className="col"
                                                value={data["status_reviewcalc2_completed"] ? Math.round(data['unexpired_allowance']) : ""}
                                                min={0}
                                                format=","
                                                readonly
                                                onChange={(evnt) => { tableChange(index, evnt, 'unexpired_allowance') }}
                                            />
                                        </td>
                                        <td key={9} style={{ verticalAlign: arePoaDatesValid && arePoaDatesValid[index] ? 'middle' : 'top' }}>
                                            <InputNumber
                                                className="col"
                                                value={data["status_reviewcalc2_completed"] ? Math.round(data['unused_allowance']) : ""}
                                                min={0}
                                                format=","
                                                readonly
                                                onChange={(evnt) => { tableChange(index, evnt, 'unused_allowance') }}
                                            />
                                        </td>
                                        </td> */}
                                        <td key={10} style={{
                                            verticalAlign: arePoaDatesValid && arePoaDatesValid[index] ? 'middle' : 'top',
                                            paddingTop: arePoaDatesValid && arePoaDatesValid[index] ? '' : '0.3rem'
                                        }}>
                                            <Button
                                                className="btn"
                                                disabled={isError|| !canSelect}
                                                neverReadonly
                                                loading={isPosting}
                                                onClick={() => {
                                                    setPoaIndex(index);
                                                    submit();
                                                    
                                                    //setSelectedPOA({ "POA_start": data['period_start'], "POA_end": data['period_end'] });
                                                    //toast('open a modal here to edit POA')
                                                }}
                                                >
                                                { isPoaReadOnly ? "View" : "Select" }
                                            </Button>
                                        </td>
                                        <td style={{
                                            verticalAlign: arePoaDatesValid && arePoaDatesValid[index] ? 'middle' : 'top',
                                            paddingTop: arePoaDatesValid && arePoaDatesValid[index] ? '' : '0.3rem'
                                        }}>

                                            { isPoaReadOnly
                                                ? (<Button
                                                    onClick={() => {
                                                        setShowOpenPeriodModal(true);
                                                        setOpenPeriodIndex(index);
                                                    }}
                                                    >Open</Button>)
                                                
                                                : (canDelete && (<Button 
                                                    className="btn btn-outline-danger" 
                                                    onClick={() => deleteTableRows(index)}
                                                    >
                                                    <span className="Appkit4-icon icon-close-outline"/>
                                                </Button>))
                                            }
                                        </td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                </div>
            </Panel>

            <div className='container-fluid mt-4'>
                <div className='d-flex justify-content-between align-items-center'> 
                    <div>
                        <BackButton disabled={isPosting}/>
                    </div>
                </div>
            </div>

            
        </div>
    );
};

export default GroupPOA;