import { Panel,  Loading,  ButtonGroup } from '@appkit4/react-components'; 
import { Button, InputNumber, DataEditor } from "../components/ReadonlyAwareInputs";
import { useEffect, useState, useCallback, useContext } from 'react';
import { BackButton, NextButton } from '../components/ProgressNav';
import RevCalcHeader from './ReviewCalcHeader';
import ReviewCalcNav from './revCalcNavigation';
import { getGroupData, setGroupData, getPoaIndex, getGroupName, getFormattedSelectedPeriod } from '../services/GroupContext';
import toast from '../components/DismissibleToast';
import sqlService from '../services/sqldatabase/sqldatabase.service';
import "@glideapps/glide-data-grid/dist/index.css";
import {
    GridCellKind,
} from "@glideapps/glide-data-grid";
import dayjs from "dayjs";
import utc from 'dayjs/plugin/utc';
import { calculate_disallowance_subtotals, getReviewCalc3Values } from '../services/calculations/calc3';
import { sumCalculationGroup } from '../services/calculations/EntityCalculations';
import { AuthContext } from '../services/AuthProvider';
import { processDate, processDateJS } from '../utils/dateProcessor';
import CompanyPanel from '../components/CompanyPanel';
dayjs.extend(utc)

const disallowed_allocation_table_rows = [
    { metric_id: "non_consenting_company", display_name: "Non-consenting company?", mandatory: true, editable: true, type: 'boolean' },
    { metric_id: "start_date", display_name: "Accounting Period Start Date", mandatory: true, type: 'date' },
    { metric_id: "end_date", display_name: "Accounting Period End Date", mandatory: true, type: 'date' },
    { metric_id: "net_tax_interest_expense_gbp", display_name: "Net tax-interest expense / (income)", mandatory: true, type: 'number_total' },
    { metric_id: "max_disallowance", display_name: "Disallowance allocation limit", mandatory: true, type: 'number' },
    { metric_id: "disallowance_gbp", display_name: "Disallowance allocation", mandatory: true, editable: true, type: 'number_total' }];

    const disallowed_allocation_ntie_row_index = disallowed_allocation_table_rows.findIndex( metric => metric.metric_id === "net_tax_interest_expense_gbp" );
    const disallowed_allocation_disallowance_row_index = disallowed_allocation_table_rows.findIndex( metric => metric.metric_id === "disallowance_gbp" );
    if ( disallowed_allocation_ntie_row_index === -1 || disallowed_allocation_disallowance_row_index === -1){
        throw new Error("Error: Disallowed allocation table is missing Available or Allocated reactivation metrics");
    }

const disallowed_deductions_table_rows = [
    { metric_id: "non_consenting_company", display_name: "Non-consenting company?", mandatory: true, type: 'boolean' },
    { metric_id: "start_date", display_name: "Accounting Period Start Date", mandatory: true, type: 'date' },
    { metric_id: "end_date", display_name: "Accounting Period End Date", mandatory: true, type: 'date' },
    { metric_id: "apply_s377_3_election", display_name: "Apply s377(3) election, to override the automatic order of disallowances", mandatory: true, editable: true, type: 'boolean' },
    { metric_id: "disallowance_gbp", display_name: "Total allocated disallowance for accounting period", mandatory: true, type: 'number' },
    { metric_id: "ntlr_subtotal_gbp", display_name: "Non trading loan relationship debits for the period", mandatory: true, type: 'number' },
    { metric_id: "ntlr_disallowance_gbp", display_name: "Disallowance allocated to non trading loan relationship debits", mandatory: true, type: 'edit_number' },
    { metric_id: "ntdc_subtotal_gbp", display_name: "Non trading derivative contract debits for the period", mandatory: true, type: 'number' },
    { metric_id: "ntdc_disallowance_gbp", display_name: "Disallowance allocated to non trading derivative contract debits", mandatory: true, type: 'edit_number' },
    { metric_id: "tlr_subtotal_gbp", display_name: "Trading loan relationship debits for the period", mandatory: true, type: 'number' },
    { metric_id: "tlr_disallowance_gbp", display_name: "Disallowance allocated to trading loan relationship debits", mandatory: true, type: 'edit_number' },
    { metric_id: "tdc_subtotal_gbp", display_name: "Trading derivative contract debits for the period", mandatory: true, type: 'number' },
    { metric_id: "tdc_disallowance_gbp", display_name: "Disallowance allocated to trading derivative contract debits", mandatory: true, type: 'edit_number' },
    { metric_id: "ofa_subtotal_gbp", display_name: "Other financing arrangements debits for the period", mandatory: true, type: 'number' },
    { metric_id: "ofa_disallowance_gbp", display_name: "Disallowance allocation to other financing arrangement debits", mandatory: true, type: 'edit_number' },
    { metric_id: "remaining_disallowance", display_name: "Remaining disallowance to be allocated", mandatory: true, type: 'number' }
];

const ReviewCalc3 = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [isPosting, setIsPosting] = useState(false);
    const [isErrorA, setIsErrorA] = useState("");
    const [isErrorB, setIsErrorB] = useState("");
    const [remainingDisallowanceError, setRemainingDisallowanceError] = useState(true);
    const auth = useContext(AuthContext);

    const [savedPOAData, setSavedPOAData] = useState();
    const [savedData, setSavedData] = useState();
    const [remainingDisallowance, setRemainingDisallowance] = useState(0);

    // Setup Tables
    const [disallowed_allocation_table_columns, set_disallowed_allocation_table_columns] = useState([]);
    const [disallowed_allocation_table_data, set_disallowed_allocation_table_data] = useState([]);
    const [disallowed_allocation_totals_column, set_disallowed_allocation_totals_column ] = useState([]);
    useEffect(() => { 
        disallowanceError(disallowed_allocation_table_data, 5, 4, setIsErrorA, true, 3) 
    }, [disallowed_allocation_table_data])


    const [disallowed_deductions_table_columns, set_disallowed_deductions_table_columns] = useState([]);
    const [disallowed_deductions_table_data, set_disallowed_deductions_table_data] = useState([]);
    const onAllocationOfDiallowancesEdited = (disallowedAllocationTotalsColumn) => { 
        if (!disallowedAllocationTotalsColumn.length) return;

        // Calculate Totals column
        const tmp_disallowed_allocation_totals_column = [...disallowedAllocationTotalsColumn];
        disallowed_allocation_table_rows.forEach((metric, index) => {
            if (metric.type !== "number_total")
                return;

            tmp_disallowed_allocation_totals_column[index] = disallowed_allocation_table_data[index].reduce((acc, val) => acc + val, 0);
        })
        set_disallowed_allocation_totals_column(tmp_disallowed_allocation_totals_column);

        setRemainingDisallowance(savedPOAData?.group_disallowance - tmp_disallowed_allocation_totals_column[disallowed_allocation_disallowance_row_index] );
    

        let temp_disallowed_deductions_data = [...disallowed_deductions_table_data];
        disallowed_deductions_table_data[0]?.forEach((_,index) => {
            temp_disallowed_deductions_data[0][index] = disallowed_allocation_table_data[0][index];
            temp_disallowed_deductions_data[4][index] = disallowed_allocation_table_data[5][index];

            const cap = savedPOAData?.adjusted_caps?.find((cap) => 
                cap?.company_name === disallowed_deductions_table_columns[index+1]?.title
                && processDateJS(cap?.start_date)?.isSame(processDateJS(disallowed_deductions_table_data[1][index])
            ))
            if (!cap) return;

            const newSubtotals = calculate_disallowance_subtotals(cap, Math.max(cap?.net_tax_interest_expense_gbp, 0), temp_disallowed_deductions_data[4][index]);
            if(!temp_disallowed_deductions_data[3][index]) {
                temp_disallowed_deductions_data[6][index] = newSubtotals?.ntlr_disallowance_gbp;
                temp_disallowed_deductions_data[8][index] = newSubtotals?.ntdc_disallowance_gbp;
                temp_disallowed_deductions_data[10][index] = newSubtotals?.tlr_disallowance_gbp;
                temp_disallowed_deductions_data[12][index] = newSubtotals?.tdc_disallowance_gbp;
                temp_disallowed_deductions_data[14][index] = newSubtotals?.ofa_disallowance_gbp;
            }
            temp_disallowed_deductions_data[15]?.forEach((_, col) => {
                temp_disallowed_deductions_data[15][col] = temp_disallowed_deductions_data[4][col]
                    - temp_disallowed_deductions_data[6][col]
                    - temp_disallowed_deductions_data[8][col]
                    - temp_disallowed_deductions_data[10][col]
                    - temp_disallowed_deductions_data[12][col]
                    - temp_disallowed_deductions_data[14][col]
            })
        })
        set_disallowed_deductions_table_data(temp_disallowed_deductions_data);

        let error = ''
        disallowanceError(disallowed_deductions_table_data, 6, 5, (msg) => {error = msg || error})
        disallowanceError(disallowed_deductions_table_data, 8, 7, (msg) => {error = msg || error})
        disallowanceError(disallowed_deductions_table_data, 10, 9, (msg) => {error = msg || error})
        disallowanceError(disallowed_deductions_table_data, 12, 11, (msg) => {error = msg || error})
        disallowanceError(disallowed_deductions_table_data, 14, 13, (msg) => {error = msg || error})
        setIsErrorB(error)
    }

    useEffect(() => {
        const savedData = getGroupData();
        if (getPoaIndex() === -1) {
            toast.error(`could not find POA data`, { duration: 30 * 1000 }) // 30s 
            return;
        }

        const poa = getReviewCalc3Values(savedData?.periods_of_account[getPoaIndex()], savedData);
        if (!poa) {
            console.error('calculations failed');
            return;
        }

        savedData.periods_of_account[getPoaIndex()] = poa;
        setGroupData(savedData);
        setupTableData(savedData);
    }, [])

    const setupTableData = (calculatedData) => {
        setSavedData(calculatedData)
        const savedPOAData = calculatedData?.periods_of_account[getPoaIndex() > -1 ? getPoaIndex() : 0]
        setSavedPOAData(savedPOAData);
 
        let disallowed_allocation_columns = [{ title: "", width: 200 }, { title: "Group Total", width: 200 }];
        savedPOAData?.adjusted_caps?.forEach((company) => {
            const companyName = calculatedData?.companies?.filter((c) => c?.company_ID === company?.company_ID)[0]?.company_name;
            disallowed_allocation_columns?.push({ title: companyName, width: 200 })
        })
 
        set_disallowed_allocation_table_columns(disallowed_allocation_columns)
 
        let tempTableDataBuilder = [];
        disallowed_allocation_table_rows?.forEach((rowHeader) => {
            let tempRowDataBuilder = [];
            let value = 0;
            savedPOAData?.adjusted_caps?.forEach((company) => {
                if (rowHeader?.type?.includes('blank')) {
                    tempRowDataBuilder?.push("")
                } else if (rowHeader?.metric_id === "max_disallowance") {
                    value = company?.non_consenting_company === true ? company?.non_consenting_max_disallowance_gbp : company?.consenting_max_disallowance_gbp;
                    tempRowDataBuilder?.push(value);
                } else if (company?.hasOwnProperty(rowHeader?.metric_id)) {
                    value = company[rowHeader?.metric_id];
                    if (rowHeader?.metric_id === "disallowance_gbp") {
                        value = company[rowHeader.metric_id]?.value;
                    }
                   
                    tempRowDataBuilder.push(value);
                } else {
                    tempRowDataBuilder?.push('no value found')
                }
            })
 
            tempTableDataBuilder?.push(tempRowDataBuilder);
        })
 
        set_disallowed_allocation_table_data(tempTableDataBuilder);
        set_disallowed_allocation_totals_column(new Array(tempTableDataBuilder[0].length).fill(""));
 
        let disallowed_deductions_table_columns = [{ title: "", width: 200 }];
        // change to use values from adjusted_caps
        savedPOAData?.adjusted_caps?.forEach((company) => {
            const companyName = calculatedData?.companies?.filter((c) => c?.company_ID === company?.company_ID)[0]['company_name']
            disallowed_deductions_table_columns?.push({ title: companyName, width: 200 })
        })
 
        set_disallowed_deductions_table_columns(disallowed_deductions_table_columns)
 
        tempTableDataBuilder = [];
        disallowed_deductions_table_rows?.forEach((rowHeader) => {
            let tempRowDataBuilder = [];
 
            savedPOAData?.adjusted_caps?.forEach((company) => {
                if ( ! company?.apply_s377_3_election){
                    company = calculate_disallowance_subtotals(company, Math.max(company?.net_tax_interest_expense_gbp, 0), company?.disallowance_gbp?.value)
                }
 
                if (rowHeader?.type?.includes('blank')) {
                    tempRowDataBuilder?.push("")
                } else if (rowHeader?.metric_id === 'disallowance_gbp' || rowHeader?.metric_id?.includes('subtotal')) {
                    const value = company[rowHeader?.metric_id]?.value;
                        tempRowDataBuilder?.push(value);
                } else if (company?.hasOwnProperty(rowHeader?.metric_id)) {
                    const value = company[rowHeader?.metric_id];
                        tempRowDataBuilder?.push(value);
                }
                else {
                    tempRowDataBuilder?.push('')
                }
            })
            tempTableDataBuilder?.push(tempRowDataBuilder);
        })
        tempTableDataBuilder[15]?.forEach((value, col) => {
            tempTableDataBuilder[15][col] = tempTableDataBuilder[4][col]
                - tempTableDataBuilder[6][col]
                - tempTableDataBuilder[8][col]
                - tempTableDataBuilder[10][col]
                - tempTableDataBuilder[12][col]
                - tempTableDataBuilder[14][col]
        })
 
        set_disallowed_deductions_table_data(tempTableDataBuilder)
 
        setIsLoading(false);
 
    }

    const getAllocationCellContent = useCallback((col, row) => {
        const savedData = getGroupData();
        const metric = disallowed_allocation_table_rows[row];
        if (metric?.type === 'blank') {
            return {
                kind: GridCellKind?.Text,
                allowOverlay: false,
                data: " ",
                displayData: " ",
                span: [0, savedData?.length]
            };
        }

        if (metric?.type === 'header' || metric?.type === 'subheader' || metric?.type === 'note') {
            return {
                kind: GridCellKind?.Text,
                allowOverlay: false,
                data: metric?.display_name,
                displayData: metric?.display_name,
                span: [0, savedData?.length]
            };
        }

        // Metric name
        if (col === -2) {
            return {
                kind: GridCellKind?.Text,
                data: metric?.display_name,
                allowOverlay: false,
                displayData: metric?.display_name,
                allowWrapping: true
            };
        }

        // Default values
        let cellType = GridCellKind?.Text;
        let value = disallowed_allocation_table_data[row][col] || 0;
        let displayValue = value ? String(value) : '0';
        let editable = metric?.hasOwnProperty('editable') ? metric?.editable : false;

        // Totals column
        if (col === -1) {
            if (metric?.type?.includes('number_total')) {
                return {
                    kind: GridCellKind?.Number,
                    data: disallowed_allocation_totals_column[row],
                    displayData: disallowed_allocation_totals_column[row] ? Math.round(disallowed_allocation_totals_column[row])?.toLocaleString() : '0',
                };
            }
            return {
                kind: GridCellKind.Text,
                allowOverlay: false,
                data: " ",
                displayData: " ",
                span: [0, savedData?.length]
            };
        }

        else if (metric?.type?.includes('date')) {
            value = displayValue = value ? (processDateJS(value)?.format('DD/MM/YYYY') ?? '') : '';
        } else if (metric?.type?.includes('number')) {
            cellType = GridCellKind?.Number;
            displayValue = typeof value === 'number' ? Math.round(value)?.toLocaleString() : '0';
        } else if (metric.type?.includes('boolean')) {
            cellType = GridCellKind.Boolean
            if (value === 1 || value === true) {
                value = displayValue = true;
            } else if (value === 0 || value === false) {
                value = displayValue = false;
            } else {
                value = displayValue = false;
            }
        }
        return {
            kind: cellType,
            data: value,
            allowOverlay: editable,
            displayData: displayValue ?? 0,
            themeOverride: {
                ...(metric?.style === 'bold' && { baseFontStyle: '600 15px' }),
                ...(editable ? { bgCell: "#FFFFFF"} : { bgCell: "#ffd5b7"})
            }
        };
    }, [disallowed_allocation_table_rows, disallowed_allocation_table_data, disallowed_allocation_totals_column]);

    const getDeductionsCellContent = useCallback((col, row) => {
        const metric = disallowed_deductions_table_rows[row];
        if (metric?.type === 'blank') {
            return {
                kind: GridCellKind.Text,
                allowOverlay: false,
                data: " ",
                displayData: " ",
                span: [0, savedData?.length]
            };
        }

        if (metric?.type === 'header' || metric?.type === 'subheader' || metric?.type === 'note') {
            return {
                kind: GridCellKind.Text,
                allowOverlay: false,
                data: metric?.display_name,
                displayData: metric?.display_name,
                span: [0, savedData?.length]
            };
        }

        // Metric name
        if (col === -1) {
            return {
                kind: GridCellKind.Text,
                data: metric?.display_name,
                allowOverlay: false,
                displayData: metric?.display_name,
                allowWrapping: true
            };
        }

        // set up default values
        let cellType = GridCellKind.Text;
        let value = disallowed_deductions_table_data[row][col];
        let displayValue = value ? String(value) : '';
        let editable = metric?.hasOwnProperty('editable') ? metric?.editable : false;

        // if cell is only editable on selection of a checkbox
        if (metric?.type?.includes('edit_number')) {
            editable = disallowed_deductions_table_data[3][col]
            displayValue = value ? Math.round(value)?.toLocaleString() : '0';
        }
        else if (metric?.type?.includes('date')) {
            value = displayValue = value ? processDateJS(value)?.format('DD/MM/YYYY') : '';
        } else if (metric?.type?.includes('number')) {
            cellType = GridCellKind?.Number;
            displayValue = value ? Math?.round(value)?.toLocaleString() : '0';
        } else if (metric?.type?.includes('boolean')) {
            cellType = GridCellKind.Boolean
            if (value === 1 || value === true) {
                value = displayValue = true;
            } else if (value === 0 || value === false) {
                value = displayValue = false;
            } else {
                value = displayValue = false;
            }
        }

        return {
            kind: cellType,
            data: value,
            allowOverlay: editable,
            displayData: displayValue,
            themeOverride: {
                ...(metric?.style === 'bold' && { baseFontStyle: '600 15px' }),
                ...(editable ? { bgCell: "#FFFFFF"} : { bgCell: "#ffd5b7"})
            }
        };
    }, [disallowed_deductions_table_rows, disallowed_deductions_table_data, disallowed_allocation_totals_column]);

    const [rowGrouping, setRowGrouping] = useState(() => ({
        groups: [{
            headerIndex: 0,
            isCollapsed: false
        }, {
            headerIndex: 10,
            isCollapsed: true,
            subGroups: [{
                headerIndex: 15,
                isCollapsed: false
            }, {
                headerIndex: 20,
                isCollapsed: false
            }]
        }, {
            headerIndex: 30,
            isCollapsed: false
        }],
        height: 55,
        navigationBehavior: "block",
        selectionBehavior: "block-spanning",
        themeOverride: {
            bgCell: "rgba(0, 100, 255, 0.1)"
        }
    }));

    const save = async () => {
        if (isErrorA || isErrorB) {
            throw 'There is an error. Please fix before proceeding...';
        }

        setIsPosting(true);


        const new_caps = savedPOAData?.adjusted_caps?.map((cap, index) => {
            cap.non_consenting_company = !!disallowed_allocation_table_data[0][index]
            cap.disallowance_gbp = {value: disallowed_allocation_table_data[5][index], automatic_allocation: disallowed_allocation_table_data[5][index] === Math.round(cap?.disallowance_automatic_allocation_gbp)};
            cap.apply_s377_3_election = disallowed_deductions_table_data[3][index]
            cap.ntlr_disallowance_gbp = disallowed_deductions_table_data[6][index]
            cap.ntdc_disallowance_gbp = disallowed_deductions_table_data[8][index]
            cap.tlr_disallowance_gbp = disallowed_deductions_table_data[10][index]
            cap.tdc_disallowance_gbp = disallowed_deductions_table_data[12][index]
            cap.ofa_disallowance_gbp = disallowed_deductions_table_data[14][index]

            return cap;
        })
        let newPOA = savedPOAData;
        newPOA.adjusted_caps = new_caps;
        if (getPoaIndex() === -1) {
            throw `could not find POA data`;
        }

        savedData.periods_of_account[getPoaIndex()] = newPOA;
        savedData.company_accounting_periods = savedData.company_accounting_periods.map((ap)=>{
            const correspondingCap = new_caps.find(cap=>cap.company_ID===ap.company_ID && processDateJS(cap.end_date)?.isSame(processDateJS(ap.end_date)) && processDateJS(cap.start_date)?.isSame(processDateJS(ap.start_date)))
            return correspondingCap ?
            {   
                ...ap,
                non_consenting_company:correspondingCap.non_consenting_company
            } :
            ap
        })

        const newJson = savedData;
        if (auth?.isReadOnlyUser(getGroupName())) {
            setGroupData(newJson);
            return;
        }

        const promise = sqlService?.postResponse(newJson)
        return toast.promise(promise, {
            loading: 'Saving...',
            success: (response) => {
                setGroupData(newJson);
                setIsPosting(false);
                return `Changes saved to database`
            },
            error: 'Failed to save changes to database',
        })
    }

    const disallowanceError = (table, valueRow, maxRow, setError, hasTotalRow, ntie) => {
        let hasError = false;
        table[valueRow]?.forEach((value, index) => {
            if (!hasTotalRow || index > 0) {
                const max = ntie ? Math.min(Math.round(table[maxRow][index] + 1), Math.round(table[ntie][index])) : Math.round(table[maxRow][index] + 1);
                if (value < 0 || value > Math.max(max, 0)) {
                    hasError = true
                }
            }
        })
        if (hasError) {
            setError('Table Error: Disallowance allocation must be a number greater than 0 and less than the disallowance allocation limit.')
        }
        else {
            setError('')
        }
    }

    const applyAutomaticAllocation = () => {
        let temp_disallowed_allocation_data = [...disallowed_allocation_table_data];
        let temp_disallowed_deductions_data = [...disallowed_deductions_table_data];
        
        temp_disallowed_allocation_data[5]?.forEach((cell, index) => {
            const cap = savedPOAData?.adjusted_caps?.find((cap) => cap?.company_name === disallowed_allocation_table_columns[index+2]?.title 
                && processDateJS(cap?.start_date)?.isSame(processDateJS(temp_disallowed_allocation_data[1][index])
            ))
            if (!cap) return;

            temp_disallowed_allocation_data[5][index] = Math.round(cap?.disallowance_automatic_allocation_gbp);
            temp_disallowed_deductions_data[4][index] = Math.round(cap?.disallowance_automatic_allocation_gbp);

            const newSubtotals = calculate_disallowance_subtotals(cap, Math.max(cap?.net_tax_interest_expense_gbp, 0), Math.round(cap?.disallowance_automatic_allocation_gbp));
            if(!temp_disallowed_allocation_data[3][index]) {
                temp_disallowed_deductions_data[6][index] = newSubtotals?.ntlr_disallowance_gbp;
                temp_disallowed_deductions_data[8][index] = newSubtotals?.ntdc_disallowance_gbp;
                temp_disallowed_deductions_data[10][index] = newSubtotals?.tlr_disallowance_gbp;
                temp_disallowed_deductions_data[12][index] = newSubtotals?.tdc_disallowance_gbp;
                temp_disallowed_deductions_data[14][index] = newSubtotals?.ofa_disallowance_gbp;
            }
        })

        set_disallowed_allocation_table_data(temp_disallowed_allocation_data);
        set_disallowed_deductions_table_data(temp_disallowed_deductions_data);
        onAllocationOfDiallowancesEdited(temp_disallowed_allocation_data);
    }

    const resetAllocation = () => {
        let temp_disallowed_allocation_data = [...disallowed_allocation_table_data];
        let temp_disallowed_deductions_data = [...disallowed_deductions_table_data];

        temp_disallowed_allocation_data[5]?.forEach((cell, index) => {

            const cap = savedPOAData?.adjusted_caps?.find((cap) => cap?.company_name === disallowed_allocation_table_columns[index+2]?.title 
                && processDateJS(cap?.start_date)?.isSame(processDateJS(temp_disallowed_allocation_data[1][index])
            ))
            if (!cap) return;

            temp_disallowed_allocation_data[5][index] = 0;

            if(!temp_disallowed_allocation_data[3][index]) {
                temp_disallowed_deductions_data[4][index] = 0;
                temp_disallowed_deductions_data[6][index] = 0;
                temp_disallowed_deductions_data[8][index] = 0;
                temp_disallowed_deductions_data[10][index] = 0;
                temp_disallowed_deductions_data[12][index] = 0;
                temp_disallowed_deductions_data[14][index] = 0;
            }
        })

        set_disallowed_allocation_table_data(temp_disallowed_allocation_data);
        set_disallowed_deductions_table_data(temp_disallowed_deductions_data);
        onAllocationOfDiallowancesEdited(temp_disallowed_allocation_data);
    }

    const onAllocationsEdit = (col, row, value) => {
        const metric = disallowed_allocation_table_rows[row];
        if (!metric) {
            return;
        }

        if (metric?.type === 'date') {
            const dayjsDate = dayjs.utc(value, 'DD/MM/YYYY', true);
            if (!dayjsDate?.isValid()) {
                toast.error('Date must be entered in dd/mm/yyyy format')
                return
            }
            value = processDate(dayjsDate);
        }

        const cap = savedPOAData?.adjusted_caps?.find((cap) => 
            cap?.company_name === disallowed_deductions_table_columns[col+1]?.title
            && processDateJS(cap?.start_date)?.isSame(processDateJS(disallowed_deductions_table_data[1][col])
        ))

        let temp_disallowed_allocation_data = [...disallowed_allocation_table_data]        
        temp_disallowed_allocation_data[row][col] = isNaN(value) ? 0 : Math.round(value)
        if (metric?.metric_id === 'non_consenting_company') {
            temp_disallowed_allocation_data[4][col] = temp_disallowed_allocation_data[0][col] 
                ? cap?.non_consenting_max_disallowance_gbp
                : cap?.consenting_max_disallowance_gbp;
        }

        set_disallowed_allocation_table_data(temp_disallowed_allocation_data);
        onAllocationOfDiallowancesEdited(temp_disallowed_allocation_data);
    }

    const onDisallowancesEdit = (col, row, value) => {
        const metric = disallowed_deductions_table_rows[row];
        if (!metric) {
            return;
        }

        if (metric?.type === 'date') {
            const dayjsDate = processDateJS(value)?.format('DD/MM/YYYY');
            if (!dayjsDate?.isValid()) {
                toast.error('Date must be entered in dd/mm/yyyy format')
                return
            }
            value = dayjsDate?.toDate()
        }
        // don't allow editing of non consenting company
        if (metric?.metric_id === 'non_consenting_company') {
            value = !value
        }
        let temp_disallowed_deductions_data = [...disallowed_deductions_table_data]

        temp_disallowed_deductions_data[row][col] = isNaN(value) ? 0 : Math?.round(value)

        if (metric?.metric_id === 'apply_s377_3_election') {
            temp_disallowed_deductions_data[row][col] = value;
            if (value === false) {
                const cap = savedPOAData?.adjusted_caps?.find((cap) => cap?.company_name === disallowed_deductions_table_columns[col+1]?.title && processDateJS(cap?.start_date)?.isSame(processDateJS(temp_disallowed_deductions_data[1][col])))
                if (cap) {
                    const newSubtotals = calculate_disallowance_subtotals(cap, Math.max(cap?.net_tax_interest_expense_gbp, 0), temp_disallowed_deductions_data[4][col]);
                    temp_disallowed_deductions_data[6][col] = newSubtotals?.ntlr_disallowance_gbp;
                    temp_disallowed_deductions_data[8][col] = newSubtotals?.ntdc_disallowance_gbp;
                    temp_disallowed_deductions_data[10][col] = newSubtotals?.tlr_disallowance_gbp;
                    temp_disallowed_deductions_data[12][col] = newSubtotals?.tdc_disallowance_gbp;
                    temp_disallowed_deductions_data[14][col] = newSubtotals?.ofa_disallowance_gbp;
                }
            }
        }

        temp_disallowed_deductions_data[15][col] = temp_disallowed_deductions_data[4][col]
            - temp_disallowed_deductions_data[6][col]
            - temp_disallowed_deductions_data[8][col]
            - temp_disallowed_deductions_data[10][col]
            - temp_disallowed_deductions_data[12][col]
            - temp_disallowed_deductions_data[14][col]
        set_disallowed_deductions_table_data(temp_disallowed_deductions_data)
    }

    const getMetricTheme = (metric) => {
        if (!metric){
            return {};
        }

        if (metric.type === 'blank') {
            return {
                bgCell: "#F4F3F7"
            }
        }

        if (metric.type === 'header') {
            return {
                baseFontStyle: "600 18px",
            }
        }

        if (metric.type === 'subheader') {
            return {
                baseFontStyle: "600 15px",
            }
        }

        if (metric.type === 'note') {
            return {
                baseFontStyle: "600 12px",
            }
        }
    }

    // update remaining disallowance error on any table changes
    useEffect(() => {
        // if there is an unallocated disallowance in the top table there is an error
        if (remainingDisallowance != 0) {
            setRemainingDisallowanceError(true);
        }
        else {
            // sum unallocated disallowances in bottom table
            let total = 0;
            disallowed_deductions_table_data[15]?.forEach((value) => {
                total += value;
            })
            // if there is any unallocated disallowance in the bottom table there is an error
            if (total != 0) {
                setRemainingDisallowanceError(true);
            }
            else {
                // otherwise all is good
                setRemainingDisallowanceError(false);
            }
        }
        
    }, [disallowed_deductions_table_data, remainingDisallowance])

    if (isLoading)
        return (
            <Loading
                loadingType="linear"
                indeterminate={true}
                compact={false}
                className="page-loader"
            ></Loading>
        );
    return (
        <div className="ap-container">
            <div className="ap-container mt-4">
                <CompanyPanel />
            </div>
            <Panel className='mt-4'>
                <RevCalcHeader groupname="Review Calculations -  Allocation of disallowances" />
                <ReviewCalcNav currentStep={2} />
                <h2>Disallowance of Tax Interest Expense</h2>
                <p>The Group disallowance is calculated by comparing the group's aggregate net tax-interest expense to the group's interest capacity. Where the expense exceeds the capacity, this results in a disallowance.</p>
                <div className='col-4'>Aggregate Net-Tax Interest Expense / (Income): <strong>{Math.round(savedPOAData?.aggregate_net_tax_interest_expense_income)?.toLocaleString()}</strong></div>
                <div className='col-4'>Interest Capacity: <strong>{Math.round(savedPOAData?.group_interest_capacity).toLocaleString()}</strong></div>
                <div className='col-4' style={{ padding: "0em 0em 1em 0em" }}>Disallowance: <strong>{Math.round(savedPOAData?.group_disallowance)?.toLocaleString()} </strong></div>
                <h2>Allocation of disallowances</h2>
                <p className='mb-0'>Where a disallowance is calculated in respect of a period of account, it is necessary to allocate the disallowance to one or more group companies and reflect this in the relevant tax computations. Disallowed amounts can be freely allocated to companies within the group, subject to the following restrictions:</p>
                <ul type='a'>
                    <li>Disallowances can only be allocated to companies which have an overall net tax interest expense for the period. No amount can be allocated to companies which have net tax interest income;</li>
                    <li>The maximum amount which can be allocated to a company is the net tax interest expense arising in the relevant accounting period;</li>
                    <li>If the company is a non-consenting company, the maximum that can be allocated is the company’s pro-rata share of the total disallowed amount; and</li>
                    <li>If there is no active reporting company nomination, disallowed amounts can only be allocated on a pro-rata basis.</li>
                </ul>
                <p className='err'>{isErrorA}</p>
                <div id="portal" style={{ position: 'fixed', left: 0, top: 0, zIndex: 9999 }} />
                <DataEditor
                    columns={disallowed_allocation_table_columns}
                    getCellContent={([col, row]) => getAllocationCellContent(col=col-2, row)}
                    rows={disallowed_allocation_table_rows?.length}
                    rowGrouping={rowGrouping}
                    onCellEdited={([col, row], event) => onAllocationsEdit(col=col-2, row, event?.data)}
                    getRowThemeOverride={(i) => getMetricTheme(disallowed_allocation_table_rows[i])}
                />
                <div className='row'>
                    <div className='col-3'>Remaining disallowance to be allocated</div>
                    <InputNumber
                        className='col-auto'
                        value={remainingDisallowance ? (remainingDisallowance)?.toLocaleString() : 0}
                        readonly
                    ></InputNumber>
                    <ButtonGroup className='col-2' compact={false}>
                        <Button onClick={applyAutomaticAllocation}>
                            Apply Automatic Allocation
                        </Button>
                        <Button onClick={resetAllocation}>
                            Reset
                        </Button>
                    </ButtonGroup>
                </div>
                <h2>Order of disallowed deductions</h2>
                <div>By default, tax-interest expense amounts must be left out of account in the following order:
                    <ol>
                        <li>Non-trading debits in respect of loan relationships</li>
                        <li>Non-trading debits in respect of derivative contracts</li>
                        <li>Debits in respect of loan relationships treated as expenses of trade</li>
                        <li>Debits in respect of derivative contracts treated as expenses of trade</li>
                        <li>Finance leases, debt factoring and service concession arrangements</li>
                    </ol>
                    A company may make an election for this order not to apply for an accounting period, in which case the company must specify the particular tax-interest expense amounts that are to be left out of account:</div>
                <p className='err'>{isErrorB}</p>
                <div id="portal" style={{ position: 'fixed', left: 0, top: 0, zIndex: 9999 }} />
                <DataEditor
                    columns={disallowed_deductions_table_columns}
                    getCellContent={([col, row]) => getDeductionsCellContent(col=col-1, row)}
                    rows={disallowed_deductions_table_rows?.length}
                    rowGrouping={rowGrouping}
                    onCellEdited={([col, row], event) => onDisallowancesEdit(col=col-1, row, event.data)}
                    getRowThemeOverride={i => getMetricTheme(disallowed_deductions_table_rows[i])}
                />

                <div className='container-fluid mt-4'>
                    <div className='d-flex justify-content-between align-items-center'> 
                        <div>
                            <BackButton disabled={isPosting}/>
                        </div>
                        <div className='d-flex gap-2'>
                            <Button onClick={save} loading={isPosting} disabled={isErrorA?.length > 0 || isErrorB?.length > 0 || remainingDisallowanceError}>Save</Button>
                            <div className='col-1'><NextButton loading={isPosting} disabled={isErrorA?.length > 0 || isErrorB?.length > 0 || remainingDisallowanceError} preNavigation={save}>Next</NextButton></div>
                        </div>
                    </div>
                </div>

            </Panel>
        </div>
    );
};

export default ReviewCalc3;