import { Loading, Panel, Table, Column } from '@appkit4/react-components'; 
import { Button, Checkbox } from "../components/ReadonlyAwareInputs";
import { useContext, useEffect, useState } from 'react';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { BackButton, NextButton } from '../components/ProgressNav.js';
import { getGroupData, setGroupData, getPoaIndex, getGroupName, getSelectedPeriod } from '../services/GroupContext';
import toast from '../components/DismissibleToast.js';
import sqldatabaseService from '../services/sqldatabase/sqldatabase.service.js';
import { AuthContext } from '../services/AuthProvider.js';
import CompanyPanel from '../components/CompanyPanel.js';
import { getGroupElectionsTableData } from './reporting-company/ReportingCompany.js';
import { formatNegativeNumber, formatNumber } from '../utils/formatters.js';

dayjs.extend(utc)

const Summary = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [isPosting, setIsPosting] = useState(false);
    const savedData = getGroupData();
    const poaIndex = getPoaIndex();
    const auth = useContext(AuthContext);
    const currPoaData = savedData?.periods_of_account[poaIndex]

    const group_disallowance                    = currPoaData?.group_disallowance ? Math.round(currPoaData?.group_disallowance) : 0;
    const reactivations_in_period               = Math.round(currPoaData?.group_reactivations);
    const calculation_method                    = currPoaData?.ratio_type;
    const excess_debt_cap_from_prior_period     = currPoaData?.excess_debt_cap_from_prior_period ? Math.round(currPoaData?.excess_debt_cap_from_prior_period): 0;
    const group_debt_cap                        = currPoaData?.group_debt_cap ? Math.round(currPoaData?.group_debt_cap) : 0;
    const group_ratio                           = currPoaData?.group_percentage && currPoaData?.aggregate_tax_ebitda ? Math.round(currPoaData?.group_percentage * currPoaData?.aggregate_tax_ebitda) : 0;
    const excess_debt_cap_generated_curr_period = Math.max(Math.round(group_debt_cap - group_ratio), 0);
    const disallowed_amounts                    = currPoaData?.group_disallowance ? Math.round(currPoaData?.group_disallowance) : 0;
    const carry_forward_limit                   = excess_debt_cap_from_prior_period + disallowed_amounts;
    const group_excess_debt_cap                 = currPoaData?.group_excess_debt_cap ? Math.round(currPoaData?.group_excess_debt_cap) : 0;
    const debt_cap_compared                     = Math.round(group_excess_debt_cap - excess_debt_cap_from_prior_period);
    const unused_interest_allowance_generated_curr_period = currPoaData?.group_bf_interest_allowance === 0 ? currPoaData?.unused_generated : formatNegativeNumber(currPoaData?.group_bf_interest_allowance);
    const interest_allowance_curr_period        = currPoaData?.group_interest_allowance ? Math.round(currPoaData?.group_interest_allowance) : 0;
    const antie_curr_period                     = currPoaData?.aggregate_net_tax_interest_expense ? Math.round(currPoaData?.aggregate_net_tax_interest_expense) : 0;
    const reactivations_curr_period             = currPoaData?.group_interest_reactivation_available ? Math.round(currPoaData?.group_interest_reactivation_available) : 0;
    const curr_year_interest_allowance_used     = Math.min(interest_allowance_curr_period, (antie_curr_period + reactivations_curr_period));
    const unusued_generated_curr_period         = Math.max((interest_allowance_curr_period - curr_year_interest_allowance_used), 0);
    const unusued_bf_curr_period                = currPoaData?.group_bf_interest_allowance ? Math.round(currPoaData?.group_bf_interest_allowance) : 0;
    let ANGIEORQNGIE                            =  0;
    if (calculation_method == "Group Ratio"){
        ANGIEORQNGIE = currPoaData?.qualifying_net_group_interest_expense ? Math.round(currPoaData?.qualifying_net_group_interest_expense) : 0
    }
    else if (calculation_method == "Fixed Ratio"){
        ANGIEORQNGIE = currPoaData?.adjusted_net_group_interest_expense ? Math.round(currPoaData?.adjusted_net_group_interest_expense) : 0
    }
    else {ANGIEORQNGIE = 0}

    let ANGIEORQNGIEWORD                          = "ANGIE or QNGIE";
    if (calculation_method == "Group Ratio"){
        ANGIEORQNGIEWORD = "QNGIE"
    }
    else if (calculation_method == "Fixed Ratio"){
        ANGIEORQNGIEWORD = "ANGIE"
    }
    else {ANGIEORQNGIEWORD = "ANGIE or QNGIE"}

    const groupElectionsTableFullData = getGroupElectionsTableData( savedData, getSelectedPeriod() );
    const groupElectionsTable = [
        "disregard-regulations",
        "group-ratio",
        "group-ratio-blended",
        "group-ebitda",
        "interest-alt-calc",
        "interest-non-con-investment",
        "interest-con-partnership",
        "period-not-ias-statements",
        "period-no-fin-statements",
    ].map(id => groupElectionsTableFullData[id]);

    const compListArray1 = savedData?.company_accounting_periods.filter(company => company?.elections_s456_s457 == true).map(function(company){return company.company_name}); // election_s456_s457
    const reducedCompList1 = compListArray1.filter((value, index) => compListArray1.indexOf(value) === index);
    const compListArray2 = savedData?.company_accounting_periods.filter(company => company?.election_s433_s434 == true).map(function(company){return company.company_name});  // election_s433_s434
    const compListElections1 = reducedCompList1.length > 1 ? reducedCompList1.join(", ") : reducedCompList1[0];
    const reducedCompList2 = compListArray2.filter((value, index) => compListArray2.indexOf(value) === index);
    const compListElections2 = reducedCompList2.length > 1 ? reducedCompList2.join(", ") : reducedCompList1[0];
    const companyElectionsTable = [
        ['Election for amortised cost basis for fair valued creditor relationships', 's456, 457', 'Irrevocable', compListElections1],
        ['Qualifying infrastructure company', 's433, 434', 'Revocable after 5 years', compListElections2],
        ['Qualifying infrastructure company - group election', 's435', 'Revocable after 5 years', 'Not recorded in system'],
        ['Qualifying infrastructure company - joint venture companies election', 's444-446', 'Revocable after 5 years', 'Not recorded in system']
    ]

    // const allocationDisallowancesData = savedData?.periods_of_account.map(period => period.adjusted_caps).flat().map(cap => ({name: cap.company_name, status: cap.non_consenting_company, disallowance: cap.cpd_total_gbp, defaultOrder: cap.apply_s377_3_election}));
    // const allocationReactivationsData = savedData?.periods_of_account.map(period => period.adjusted_caps).flat().map(cap => ({name: cap.company_name, bfd: cap.bfd_subtotal_gbp, cpr: cap.cpr_total_gbp, defaultOrder: cap.election_override_automatic_allocations}));

    useEffect(() => {
        setIsLoading(false);
    }, []);

    const save = () => {

        if (auth?.isReadOnlyUser(getGroupName())) {
            setGroupData(savedData);
            return;
        }

        setIsPosting(true);

        const promise = sqldatabaseService?.postResponse(savedData);
        return toast.promise(promise, {
            loading: 'Saving...',
            success: () => {
                setGroupData(savedData);
                setIsPosting(false);
                return 'Saved!'
            },
            error: 'Something went wrong saving!',
        })
    }

    if (isLoading)
        return (
            <Loading
                loadingType="linear"
                indeterminate={true}
                compact={false}
                className="page-loader"
            ></Loading>
        );
    return (
            <div className="ap-container">
             {/* Group Info */}
             <div className='ap-container mt-4'>
                <CompanyPanel />
            </div>

            <div className='col-8'>
                <div className='ap-container mt-4'>
                    <Panel title='Summary of results'>
                        <div className="row">
                            <label className="col-sm-4 col-form-label">Disallowance in period</label>
                            <div className="col-sm-8">
                                <b>{group_disallowance.toLocaleString()}</b>
                            </div>
                        </div>

                        <div className="row">
                            <label className="col-sm-4 col-form-label">Reactivations in period</label>
                            <div className="col-sm-8">
                                <b>{formatNumber(reactivations_in_period)}</b>
                            </div>

                        </div>

                        <div className="row">
                            <label className="col-sm-4 col-form-label align-self-center">Calculation method applied</label>
                            <div className="col-sm-8">
                                <b>{calculation_method}</b>
                            </div>
                        </div>
                    </Panel>
                </div>
            </div>

            <div className='ap-container mt-4'>
                <Panel >
                    <div className="row">
                        <label className="col-sm-7 col-form-label"></label>
                        <div className="col-sm-4">
                            <b><h3>GBP</h3></b>
                        </div>
                    </div>
                    <div className="row">
                        <label className="col-sm-7 col-form-label"><h4>Excess debt cap (s400)</h4></label>
                        <div className="col-sm-4">
                            <b></b>
                        </div>
                    </div>
                    <div className="row">
                        <label className="col-sm-7 col-form-label"><b>Increase/(decrease) in excess debt cap compared to prior period</b></label>
                        <div className="col-sm-4"><b>{debt_cap_compared.toLocaleString()}</b></div>
                    </div>

                    <div className="row">
                        <label className="col-sm-7 col-form-label"><p></p></label>
                        <div className="col-sm-4"></div>
                    </div>

                    <div className="row">
                        <label className="col-sm-7 col-form-label">Excess debt cap generated in immediately preceding period</label>
                        <div className="col-sm-4">{excess_debt_cap_from_prior_period.toLocaleString()}</div>
                    </div>
                    <div className="row">
                        <label className="col-sm-7 col-form-label">{ANGIEORQNGIEWORD}</label>
                        <div className="col-sm-4">{ANGIEORQNGIE.toLocaleString()}</div>
                    </div> 
                    <div className="row">
                        <label className="col-sm-7 col-form-label"><b>Current period debt cap (s400(1)-(2))</b></label>
                        <div className="col-sm-4"><b>{group_debt_cap.toLocaleString()}</b></div>
                    </div> 
                    <div className="row">
                        <label className="col-sm-7 col-form-label"><p></p></label>
                        <div className="col-sm-4"></div>
                    </div>
                        <div className="row">
                        <label className="col-sm-7 col-form-label">Current period debt cap (s400(1)-(2))</label>
                        <div className="col-sm-4">{group_debt_cap.toLocaleString()}</div>
                    </div>                   
                    <div className="row">
                        <label className="col-sm-7 col-form-label">Less: <i>30% or Group Ratio % of Aggregate Tax-EBITDA</i></label>
                        <div className="col-sm-4">({group_ratio.toLocaleString()})</div>
                    </div>
                    <div className="row">
                        <label className="col-sm-7 col-form-label"><b>Excess debt cap generated in current period (s400(3)-(5)) before the application of the carry forward limit</b></label>
                        <div className="col-sm-4"><b>{excess_debt_cap_generated_curr_period.toLocaleString()}</b></div>
                    </div>

                    <div className="row">
                        <label className="col-sm-7 col-form-label"><p></p></label>
                        <div className="col-sm-4"></div>
                    </div>
                    
                    <div className="row">
                        <label className="col-sm-7 col-form-label">Excess debt cap generated in immediately preceding period</label>
                        <div className="col-sm-4">{excess_debt_cap_from_prior_period.toLocaleString()}</div>
                    </div>
                    <div className="row">
                        <label className="col-sm-7 col-form-label">Disallowed amount for current period</label>
                        <div className="col-sm-4">{disallowed_amounts.toLocaleString()}</div>
                    </div>
                    <div className="row">
                        <label className="col-sm-7 col-form-label"><b>Carry forward limit</b></label>
                        <div className="col-sm-4"><b>{carry_forward_limit.toLocaleString()}</b></div>
                    </div>
                    <div className="row">
                        <label className="col-sm-7 col-form-label"><p></p></label>
                        <div className="col-sm-4"></div>
                    </div>
                    <div className="row">
                        <label className="col-sm-7 col-form-label"><b>Excess debt cap generated in the period after the application of the carry-forward limit</b></label>
                        <div className="col-sm-4"><b>{group_excess_debt_cap.toLocaleString()}</b></div>
                    </div>

                    <p></p>
                    <div className="row">
                        <label className="col-sm-7 col-form-label"><h4>Unused Interest Allowance (s394)</h4></label>
                        <div className="col-sm-10">
                            <b></b>
                        </div>
                    </div>
                    <div className="row">
                        <label className="col-sm-7 col-form-label"><b>Unused Interest Allowance generated/(utilised) in current period</b></label>
                        <div className="col-sm-4"><b>{unused_interest_allowance_generated_curr_period.toLocaleString()}</b></div>
                    </div>
                    <p></p>
                    <div className="row">
                        <label className="col-sm-7 col-form-label"><b>Interest allowance for the current period</b></label>
                        <div className="col-sm-4"><b>{interest_allowance_curr_period.toLocaleString()}</b></div>
                    </div>
                    <p></p>
                    <div className="row">
                        <label className="col-sm-7 col-form-label">Aggregate Net Tax Interest Expense for current period</label>
                        <div className="col-sm-4">{antie_curr_period.toLocaleString()}</div>
                    </div>
                    <div className="row">
                        <label className="col-sm-7 col-form-label">Reactivations in the current period</label>
                        <div className="col-sm-4">{reactivations_curr_period.toLocaleString()}</div>
                    </div>
                    <div className="row">
                        <label className="col-sm-7 col-form-label"><b>Current Year Interest allowance used in current period</b></label>
                        <div className="col-sm-4"><b>{curr_year_interest_allowance_used.toLocaleString()}</b></div>
                    </div>
                    <p></p>
                    <div className="row">
                        <label className="col-sm-7 col-form-label"><b>Unused Interest Allowance generated in current period</b></label>
                        <div className="col-sm-4"><b>{unusued_generated_curr_period.toLocaleString()}</b></div>
                    </div>
                    <div className="row">
                        <label className="col-sm-7 col-form-label"><b>Unused Interest Allowance b/f used in current period</b></label>
                        <div className="col-sm-4"><b>{unusued_bf_curr_period.toLocaleString()}</b></div>
                    </div>
                </Panel>
            </div>

            
            {/*   // TO BE RE-ADDED POST-RELEASE
            <div className='ap-container mt-4'>
                <Panel title='Periods of Account'>

                </Panel>
            </div>                
            */}
            <div className='ap-container mt-4'>
                <Panel title='List of Elections'>
                    <Table originalData={groupElectionsTable} hasTitle disableDefaultSort skipInitialSort compact>
                        <Column field='election'>Group Elections</Column>
                        <Column field='shortStatRef'>Statutory Reference </Column>
                        <Column field='duration'>Duration</Column>
                        <Column field='status' renderCell={(row, field)=> row[field] === true ? "Active": "Inactive" }>Status</Column>
                        <Column field='dateMade' renderCell={(row, field) => row[field] ? dayjs(row[field])?.year() : '' }>Year in which election was made (if provided)</Column>
                    </Table>
                    <p></p>
                    <Table originalData={companyElectionsTable} hasTitle disableDefaultSort skipInitialSort compact>
                        <Column field='0'>Company Elections</Column>
                        <Column field='1'>Statutory Reference </Column>
                        <Column field='2'>Duration</Column>
                        <Column field='3'>List of companies</Column>
                    </Table>
                </Panel>
            </div>

            {/* <div className='ap-container mt-4'>
                <Panel title='New Tables Section'>

                </Panel>
            </div> */}

        {/* <div className='ap-container mt-4'>
            <Panel title='Allocation of disallowances'>
                {allocationDisallowancesData.length > 0? <Table originalData={allocationDisallowancesData} hasTitle disableDefaultSort skipInitialSort compact>
                    <Column field='name'>Name of company</Column>
                    <Column field='status' style={{width: '25%'}} renderCell={(row) => row.status ? "Yes" : "No"}>Consent Status</Column>
                    <Column field='disallowance'>Disallowance allocated to company (GBP)</Column>
                    <Column field='defaultOrder' renderCell={(row) => row.defaultOrder ? "Yes" : "No"}>Election to override default order (s377(3)) applied?</Column>
                </Table> : <div>There were no disallowances in the period.</div>}
            </Panel>
        </div>


        <div className='ap-container mt-4'>
            <Panel title='Allocation of reactivations'>
                {allocationReactivationsData.length > 0? <Table originalData={allocationReactivationsData} hasTitle disableDefaultSort skipInitialSort compact>
                    <Column field='name'>Name of company</Column>
                    <Column field='bfd' style={{width: '25%'}}>Disallowances brought forward</Column>
                    <Column field='cpr'>Reactivations allocated to company (GBP)</Column>
                    <Column field='defaultOrder' renderCell={(row) => row.defaultOrder ? "Yes" : "No"}>Election to override default order (s377(3)) applied?</Column>
                </Table> : <div>There were no reactivations in the period.</div>}
            </Panel>
        </div> */}


            {/*   // TO BE RE-ADDED POST-RELEASE
            <div className='ap-container mt-4'>
                <Panel title='Allocation of disallowances'>

                </Panel>
            </div>                

            <div className='ap-container mt-4'>
                <Panel title='Allocation of reactivations'>

                </Panel>
            </div> 
            */}

            <div className='container-fluid mt-4'>
                <div className='d-flex justify-content-between align-items-center'> 
                    <div>
                        <BackButton disabled={isPosting}/>
                    </div>
                    <div className='d-flex gap-2'>
                        <Button hiddenInReadonly loading={isPosting} onClick={save} >Save</Button>
                        <NextButton loading={isPosting} preNavigation={save} />
                    </div>
                </div>   
            </div>
        </div>
    );
};

export default Summary;