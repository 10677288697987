import { Panel,  Loading,  Table, Column,  Accordion, AccordionItem,  Tooltip } from '@appkit4/react-components'; 
import { Button, Input, CalendarPicker, Checkbox } from "../components/ReadonlyAwareInputs";
import { useContext, useEffect, useState } from 'react';
import { getGroupData, setGroupData, getSelectedPeriod, getPoaIndex, getGroupName, getRepCo } from '../services/GroupContext';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { BackButton, NextButton } from '../components/ProgressNav.js';
import { processDateJS } from '../utils/dateProcessor';
import { processExcel } from '../utils/ExcelOutput.js';
import { processFullReturn } from '../utils/FullReturnOutput';
import { formatFullReturn } from '../utils/FullReturnFormatting';
import { processAbbreviatedReturn } from '../utils/AbbReturnOutput';
import { formatAbbreviatedReturn } from '../utils/AbbrReturnFormatting';
import {saveAs} from "file-saver";
import toast from '../components/DismissibleToast.js';
import { getGroupElectionsTableData } from './reporting-company/ReportingCompany.js';
import sqldatabaseService from '../services/sqldatabase/sqldatabase.service.js';
import { AuthContext } from '../services/AuthProvider.js';
import { defaultReturnStatus, formatReturnStatus, validReturnTypes } from './GroupPOA/GroupPOA.js';
import { addCharge } from '../services/Charging.service.js';

import { Packer } from "docx";
import { createWordReport } from '../utils/wordExport';
import CompanyPanel from '../components/CompanyPanel.js';
import CustomTableHeader from '../components/tableHeader/tableHeader.js';
import { useNavigate } from 'react-router-dom';
import CustomTooltipIcon from '../components/customTooltipIcon/customTooltipIcon.js';


const ExcelJS = require('exceljs');

dayjs.extend(utc)

const chargeTableData = [
    ["1 - 5", "£800"],
    ["6 - 15", "£1,100"],
    ["16 - 50", "£1,600"],
    ["51+", "£2,300",],
]

const Outputs = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [isPosting, setIsPosting] = useState(false);
    const selectedPeriod = getSelectedPeriod();
    const savedData = getGroupData();
    const poaIndex = getPoaIndex();
    const auth = useContext(AuthContext);

    const [readyToFinalise, setReadyToFinalise] = useState(false);
    const [returnStatus, setReturnStatus] = useState(defaultReturnStatus);
    const [isReturnTypeValid, setIsReturnTypeValid] = useState(false);
    const [isRevisionValid, setIsRevisionValid] = useState(false);
    const [readyToSubmit, setReadyToSubmit] = useState(false);

    const [wbsCode, setWbsCode] = useState("");
    const [wbsCodeError, setWbsCodeError] = useState(false);

    const [returnsEnabled, setReturnsEnabled] = useState(false);
    const navigate = useNavigate();
    
    useEffect(() => {
        const newWbsCodeError = !String(wbsCode).match(/^\d{11}/);
        setWbsCodeError(newWbsCodeError);
        setReturnsEnabled(
            wbsCode && !newWbsCodeError
            && returnStatus?.reviewConfirmed && returnStatus?.reviewedBy?.length > 0
            && returnStatus?.isFinalised && ! returnStatus?.isSubmitted
        )
    }, [wbsCode, returnStatus])
    
    useEffect(() => {
        if (!returnStatus) return;

        setIsReturnTypeValid(Object.values(validReturnTypes).includes(returnStatus.type));
        
        const revisedNotes = returnStatus?.revisedNotes;
        setIsRevisionValid( returnStatus?.isRevised ? revisedNotes[revisedNotes.length-1]?.length > 0 : true );
    }, [returnStatus])

    const fullElectionsTableData = getGroupElectionsTableData(savedData, selectedPeriod);
    const electionsTableData = [
        "group-ratio",
        "group-ratio-blended",
        "group-ebitda",
        "interest-alt-calc",
        "interest-non-con-investment",
        "interest-con-partnership"
    ].map(id => fullElectionsTableData[id])

    const isElectionThisYear = (table, property) => table[property].status 
        && dayjs(table[property].dateMade).isBefore(selectedPeriod.end_date);

    const shouldDisableReturnWithoutReportingCompany = [
        fullElectionsTableData['group-ratio'].status,
        fullElectionsTableData['group-ratio-blended'].status,
        isElectionThisYear( fullElectionsTableData, 'interest-alt-calc'),
        fullElectionsTableData['interest-non-con-investment'].status,
        isElectionThisYear( fullElectionsTableData, 'interest-con-partnership'),
        getSelectedPeriod().adjusted_caps.some( cap => !cap.non_consenting_company ),
    ].some( v => v === true );

    const RepCO = getRepCo();
    let RepCoStatus = ['No Active Reporting Company', 'No period selected'].includes(RepCO) ? RepCO : 'Reporting Company Appointed';
    const hasActiveCompany = Boolean(getSelectedPeriod().reporting_company ?? false);

    // create file in browser
    const jsonFileName = savedData?.group_name + " - Summary output - " + processDateJS(selectedPeriod?.period_start)?.format('DD/MM/YYYY') + " - " + processDateJS(selectedPeriod?.period_start)?.format('DD/MM/YYYY');
    const blob = new Blob([JSON.stringify(savedData, null, 2)], { type: "application/json" });

    function downloadJson(){
        saveAs(blob, jsonFileName + '.json');
    }

    // download an Excel file with the draft return - full
    async function downloadFullReturn() {        
        const workbook = new ExcelJS.Workbook();
        const group_period_sheet = workbook?.addWorksheet('(1)_Group,_period,_return_type_');
        const group_level_elections_sheet = workbook?.addWorksheet('(2)_Group_level_elections');
        const uk_group_companies_sheet = workbook?.addWorksheet('(3)_UK_group_companies_');
        const group_level_amounts_sheet = workbook?.addWorksheet('(4)_Group_level_amounts_');
        const allocated_restrictions_sheet = workbook?.addWorksheet('(5)_Allocated_restrictions_');
        const allocated_reactivations_sheet = workbook?.addWorksheet('(6)_Allocated_reactivations');

        const full_return_data = processFullReturn(savedData, poaIndex, selectedPeriod);

        const group_period_return_data = full_return_data[0];
        group_period_sheet?.addRows(group_period_return_data);

        const group_level_elections_data = full_return_data[1];
        group_level_elections_sheet?.addRows(group_level_elections_data);

        const uk_group_companies_data = full_return_data[2];
        uk_group_companies_sheet?.addRows(uk_group_companies_data);

        const group_level_amounts_data = full_return_data[3];
        group_level_amounts_sheet?.addRows(group_level_amounts_data);

        const allocated_restrictions_data = full_return_data[4];
        allocated_restrictions_sheet?.addRows(allocated_restrictions_data);

        const allocated_reactivations_data = full_return_data[5];
        allocated_reactivations_sheet?.addRows(allocated_reactivations_data);
        
        formatFullReturn(group_period_sheet, group_level_elections_sheet, uk_group_companies_sheet, group_level_amounts_sheet, allocated_restrictions_sheet, allocated_reactivations_sheet)

        const period_start = processDateJS(selectedPeriod?.period_start)?.format('DD/MM/YYYY')
        const period_end = processDateJS(selectedPeriod?.period_end)?.format('DD/MM/YYYY')
        const fullReportFilename = savedData?.group_name + " - Full return draft - " + period_start + " - " + period_end; 
        const excelFileName = fullReportFilename + ".xlsx"
        const buffer = await workbook.xlsx.writeBuffer();
        const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
        const blob = new Blob([buffer], {type: fileType});
        saveAs(blob, excelFileName);
      }

    async function donwloadAbbreviatedReturn(){
        const workbook = new ExcelJS.Workbook();
        const group_period_sheet = workbook?.addWorksheet('(1)_Group,_period,_return_type');
        const group_level_elections_sheet = workbook?.addWorksheet('(2)_Group_level_elections');
        const uk_group_companies_sheet = workbook?.addWorksheet('(3)_UK_group_companies_');

        const full_return_data = processAbbreviatedReturn(savedData, poaIndex, selectedPeriod);
        const group_period_return_data = full_return_data[0];
        group_period_sheet?.addRows(group_period_return_data);

        const group_level_elections_data = full_return_data[1];
        group_level_elections_sheet?.addRows(group_level_elections_data);

        const uk_group_companies_data = full_return_data[2];
        uk_group_companies_sheet?.addRows(uk_group_companies_data);

        formatAbbreviatedReturn(group_period_sheet, group_level_elections_sheet, uk_group_companies_sheet)

        const period_start = processDateJS(selectedPeriod?.period_start)?.format('DD/MM/YYYY')
        const period_end = processDateJS(selectedPeriod?.period_end)?.format('DD/MM/YYYY')
        const fullReportFilename = savedData?.group_name + " - Abbreviated return draft - " + period_start + " - " + period_end; 
        const excelFileName = fullReportFilename + ".xlsx"
        const buffer = await workbook.xlsx.writeBuffer();
        const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
        const blob = new Blob([buffer], {type: fileType});
        saveAs(blob, excelFileName);
    }

    async function excelDataDownload(){

        const workBook = new ExcelJS.Workbook();
        const CompanyPeriods = workBook?.addWorksheet('Company Accounting Periods (Regarded Amounts)', {views:[{state: 'frozen', xSplit: 1, ySplit:1}]})
        const CIR_Calculations = workBook?.addWorksheet('CIR Calculations')
        const CIR_Attributes = workBook?.addWorksheet('CIR Attributes')
        const Accounting_Periods = workBook?.addWorksheet('Accounting Periods (Full Period)', {views:[{state: 'frozen', xSplit: 1, ySplit:1}]})
        
        const [sheet1, sheet2, sheet3, sheet4] = processExcel(savedData);
        CompanyPeriods?.addRows(sheet1)
        CIR_Calculations?.addRows(sheet2)
        CIR_Attributes?.addRows(sheet3)
        Accounting_Periods?.addRows(sheet4)



        //Styling for Company accounting periods (Output 4)

        const apBolds = [1,13,15,30,32,46,48,63,65,79,81,93,95,98,103,105,135]
        apBolds.forEach(apBold => {
            Accounting_Periods.getRow(apBold).font = {bold:true};
        });

        const apItalics = [17,34,50,67,83]
        apItalics.forEach(apItalic => {
            Accounting_Periods.getRow(apItalic).font = {italic:true}
        })

        const apNumbForms = [16,18,19,20,21,22,23,24,25,26,27,28,29,30,33,35,36,37,38,39,40,41,42,43,44,45,46,49,51,52,53,54,55,56,57,58,59,60,61,62,63,66,68,69,70,71,72,73,74,75,76,77,78,79,82,84,85,86,87,88,89,90,91,92,93,95,99,100,101,102,103,106,107,108,109,110,111,112,113,114,115,116,117,118,119,120,121,122,123,124,125,126,127,128,129,130,131,132,133,134,135]
        apNumbForms.forEach(apNumbForm => {
            Accounting_Periods.getRow(apNumbForm).numFmt = "#,##0;(#,##0)";
        })

        Accounting_Periods.getColumn(1).width = 60
        Accounting_Periods.getColumn(1).alignment = {wrapText:true}
        Accounting_Periods.getRow(8).numFmt = '0%';

        for(let a = 0; a < sheet4?.length; a++){
            Accounting_Periods.getColumn(a+2).width = 15
            Accounting_Periods.getColumn(a+2).alignment = {horizontal: "right"}
        }


        //Styling for Company accounting periods (Output 1 - being Adjusted Caps)
        const boldsAdjCaps = [1,13,15,30,32,46,48,63,65,79,81,93,95,98,103,105,135,144,151]
        boldsAdjCaps.forEach(boldsAdjCap => {
            CompanyPeriods.getRow(boldsAdjCap).font = {bold:true};
        })

        const apItalicsAdjCaps = [17,34,50,67,83]
        apItalicsAdjCaps.forEach(apItalicsAdjCap => {
            CompanyPeriods.getRow(apItalicsAdjCap).font = {italic:true};
        })

        const apNumbFormAdjCaps = [16,18,19,20,21,22,23,24,25,26,27,28,29,30,33,35,36,37,38,39,40,41,42,43,44,45,46,49,51,52,53,54,55,56,57,58,59,60,61,62,63,66,68,69,70,71,72,73,74,75,76,77,78,79,82,84,85,86,87,88,89,90,91,92,93,95,99,100,101,102,103,106,107,108,109,110,111,112,113,114,115,116,117,118,119,120,121,122,123,124,125,126,127,128,129,130,131,132,133,134,135]
        apNumbFormAdjCaps.forEach(apNumbFormAdjCap => {
            CompanyPeriods.getRow(apNumbFormAdjCap).numFmt = "#,##0;(#,##0)";
        })

        CompanyPeriods.getColumn(1).width = 40
        CompanyPeriods.getColumn(1).alignment = {wrapText:true}
        CompanyPeriods.getRow(8).numFmt = '0%';

        for(let a = 0; a < sheet4?.length; a++){
            CompanyPeriods.getColumn(a+2).width = 15
            CompanyPeriods.getColumn(a+2).alignment = {horizontal: "right"}
        }

        //Styling for CIR Calculations

        CIR_Calculations.getColumn(1).width = 40
        CIR_Calculations.getColumn(2).width = 25
        CIR_Calculations.getColumn(3).width = 15
        CIR_Calculations.getColumn(2).alignment = {horizontal: "right"}
        CIR_Calculations.getColumn(1).alignment = {wrapText:true}

        CIR_Calculations.getRow(1).font = {underline:true, bold:true}
        CIR_Calculations.getRow(15).font = {underline:true,bold:true}
        CIR_Calculations.getRow(33).font = {underline:true,bold:true}

        const CIRCalsNumFormat = ['C3','C4','C6','C7','C8','C10','C11','C12','C17','C18','C21','C22','C24','C25','C26','C28','C29','C28','C39','C41','C43','C45','C47','C49']
        for(let j = 0;j<CIRCalsNumFormat?.length;j++){
            CIR_Calculations.getCell(CIRCalsNumFormat[j]).numFmt = "#,##0;(#,##0)";
        }

        const CIRCalsPercentFormat = ['C19']
        for(let j = 0;j<CIRCalsPercentFormat?.length;j++){
            CIR_Calculations.getCell(CIRCalsPercentFormat[j]).numFmt = '0.00%';
        }

        const bolds2 = [2,16,36,37]

        for(let j = 0;j<bolds2?.length;j++){
            CIR_Calculations.getRow(bolds2[j]).font = {bold:true}
        }

        //Styling for CIR Attributes
        CIR_Attributes.getColumn(1).width = 50
        CIR_Attributes.getColumn(2).width = 20
        CIR_Attributes.getColumn(3).width = 15
        CIR_Attributes.getColumn(4).width = 15
        CIR_Attributes.getColumn(2).alignment = {horizontal: "right"}
        CIR_Attributes.getColumn(1).alignment = {wrapText:true}
        CIR_Attributes.getCell("A31").alignment = {wrapText:false}
        CIR_Attributes.getCell("A32").alignment = {wrapText:false}
        CIR_Attributes.getCell("A33").alignment = {wrapText:false}
        CIR_Attributes.getCell("A34").alignment = {wrapText:false}
        CIR_Attributes.getCell("A21").font = {italics:true}
        CIR_Attributes.getRow(31).font = {color:{argb:"FF00001"}}
        CIR_Attributes.getRow(33).font = {color:{argb:"FF00001"}}
        CIR_Attributes.getRow(1).font = {underline:true, bold:true}
        CIR_Attributes.getRow(14).font = {underline:true,bold:true}


        const bolds3 = [2,8,10,11,15,18,22,26,28]

        for(let j = 0;j<bolds3.length;j++){
            CIR_Attributes.getRow(bolds3[j]).font = {bold:true}
        }

        const APNumbForm3 = [16,17,18,20,21,22,24,25,26,28]
        for(let j = 0;j<APNumbForm3?.length;j++){
            CIR_Attributes.getRow(APNumbForm3[j]).numFmt = "#,##0;(#,##0)";
        }

        const excelFileName = jsonFileName + ".xlsx"

        const buffer = await workBook.xlsx.writeBuffer();
        const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';

        const blob = new Blob([buffer], {type: fileType});
        saveAs(blob, excelFileName);

    }

    async function dowloadWordReport(){
        const doc = createWordReport(savedData, poaIndex);
        const period_start = processDateJS(selectedPeriod?.period_start)?.format('DD/MM/YYYY')
        const period_end = processDateJS(selectedPeriod?.period_end)?.format('DD/MM/YYYY')
        const fullReportFilename = savedData?.group_name + " - CIR Return Output - " + period_start + " - " + period_end; 
        const wordFileName = fullReportFilename + ".docx"

        try {
            const blob = await Packer.toBlob(doc);
            saveAs(blob, wordFileName);
        } catch (error) {
            console.error("Error generating document", error);
        }
    }

    const notImplemented = () => {
        toast("This feature is not fully implemented", { "icon": "⚠️" });
    }

    useEffect(() => {
        const saved_data = getGroupData();
        if (!saved_data) {
            return;
        }
        const poaIndex = getGroupData()?.periods_of_account?.findIndex(poa => processDateJS(poa?.period_start)?.isSame(processDateJS(selectedPeriod?.period_start)) && processDateJS(poa?.period_end)?.isSame(processDateJS(selectedPeriod?.period_end)));
        if (poaIndex === -1){
            return;
        }

        setWbsCode(saved_data?.wbsCode || "");

        const selectedDataPeriod = savedData?.periods_of_account[poaIndex];
        if (selectedDataPeriod?.return_status){
            setReturnStatus(selectedDataPeriod?.return_status);
        }
        
        setIsLoading(false);
    }, [])

    const save = (returnStatus, shouldSubmit=false) => {
        const savedData = getGroupData();

        if (poaIndex === -1)
            throw `Error: failed to find period of account`;

        setIsPosting(true);

        if (!wbsCodeError && wbsCode?.length > 0)
            savedData.wbsCode = wbsCode;

        let chargePromise =  null;
        if (shouldSubmit){
            chargePromise = addCharge(returnStatus.type, [], wbsCode, selectedPeriod, getGroupData());
            returnStatus = { ...returnStatus, isSubmitted: true };
        }
        
        savedData.periods_of_account[poaIndex].return_status = returnStatus;
        
        if (auth?.isReadOnlyUser(getGroupName())) {
            setGroupData(savedData);
            return;
        }

        const savePromise = sqldatabaseService?.postResponse(savedData);
        return toast.promise( Promise.all([ chargePromise, savePromise ]), {
            loading: 'Saving return data...',
            success: () => {
                setGroupData(savedData);
                setReturnStatus(returnStatus);
                setIsPosting(false);
                return 'Saved return data!'
            },
            error: 'Something went wrong saving return status!',
        })
    }


    if (isLoading)
        return (
            <Loading
                loadingType="linear"
                indeterminate={true}
                compact={false}
                className="page-loader"
            ></Loading>
        );
    return (
        <div >
            
            {/* Group Info */}
                     <div className='ap-container mt-4'>
                <CompanyPanel />
            </div>

            <div className="ap-container">
            
                <Panel title='Finalise Calculations' className='mt-4'>
                    <div className="row">
                        <label className="col-sm-3 col-form-label">I confirm that these calculations are ready to be finalised.</label>
                        <div className="col-sm-1">
                            <Checkbox
                                checked={readyToFinalise || returnStatus?.isFinalised}
                                onChange={setReadyToFinalise}
                            />
                        </div>
                        <div className="col-sm-5"/>
                        <Button
                            className="col-sm-3"
                            disabled={!readyToFinalise}
                            onClick={() => {
                                save({...returnStatus, isFinalised: true, withoutReportingCompany: !hasActiveCompany });
                            }}
                            >{hasActiveCompany ? "Finalise" : "Finalise without reporting company"}</Button>
                    </div>
                </Panel>          

                <Panel title='Reporting Company Status' className='mt-2'>

                <div className='ap-container mt-4'>
                <table className="table" style={{width: "100%"}}>
                        <thead>
                            <tr style={{textAlign: 'left'}}>
                                <CustomTableHeader title="Reporting Company Status" required={false} tooltipText={<p>In order to file a CIR return; generate, carry forward and utilise Unused Interest Allowance; reactivate previously disallowed interest expense; and to make certain elections it is necessary for the group to have a valid reporting company nomination in place.<br/><br/>It is not possible to prepare and submit a CIR return without a valid reporting company nomination, although the CIR rules still apply in a modified manner.<br/><br/>It is strongly recommended that a reporting company is nominated as soon as reasonably practical after the end of the Period of Account and in any case within 12 months of the end of the Period of Account.<br/><br/>Please refer to Para 1, Schedule7A, TIOPA 2010 for further information.</p>} />
                                <CustomTableHeader title="Reporting Company Name" required={false} tooltipText={<p>This is the name of the active reporting company for the period.</p>} />
                                <CustomTableHeader title="View Reporting Company Status" required={false} tooltipText={<p>Click on the button below to return to the Reporting Company page which contains further information about the reporting company and/or provides for the ability to record the nomination of a reporting company.<br/><br/>If the period is closed it will be necessary to re-open the period before a reporting company can be nominated.</p>} />
                            </tr>
                        </thead>
                        <tbody>
                        <td>{RepCoStatus}</td>
                        <td>{RepCO}</td>
                        <td style={{display: "flex", alignItems: "center"}}>
                            <Button
                                enabledWhenFinalised
                                onClick={() => navigate("/app/reportingcompany")}
                            >Go to Reporting Company</Button>
                        </td>
                        </tbody></table>    
                </div>
                </Panel>

                <Panel title='Generate Outputs' className='mt-2'>
                    <p>Please enter a WBS code below and confirm acceptance of the below recharges in order to generate app outputs:</p>
                    <p>Generating an original full return incurs a recharge based on the number of UK group companies, as per the table below, an addition to any visuals fees that may have been chargeable for One Source extractions.<br />A revised full return incurs a fixed recharge of £500.<br />Abbreviated returns, whether original or revised, incur a fixed recharge of £250, and generating modelling outputs incurs a £1,000 fixed charge.</p>
                    <Table originalData={chargeTableData} hasTitle disableDefaultSort skipInitialSort >
                        <Column field='0'>Number of UK group companies</Column>
                        <Column field='1'>Fixed recharge</Column>
                    </Table>

                    <label className="col-sm-2 col-form-label align-self-center">WBS code:</label>
                    <Input
                        title="WBS Code"
                        className="col-2"
                        value={wbsCode}
                        enabledWhenFinalised
                        error={ returnStatus.isFinalised && wbsCodeError }
                        onChange={(value) => {
                            value= String(value).trim().slice(0, 11);

                            setWbsCode(value);
                        }}
                    />

                    <label className="col-sm-2 col-form-label align-self-center">Reviewed by:</label>
                    <Input 
                        value={returnStatus?.reviewedBy || ""} 
                        onChange={(value) => setReturnStatus({...returnStatus, reviewedBy: value.trimStart()})} 
                        error={ returnStatus.isFinalised && returnStatus?.reviewedBy?.length === 0}
                        enabledWhenFinalised
                    />
                    <div className='d-flex flex-row justify-content'>
                        <Checkbox enabledWhenFinalised checked={returnStatus?.reviewConfirmed ?? false} onChange={(value) => { setReturnStatus({...returnStatus, reviewConfirmed: value, reviewedBy: returnStatus?.reviewedBy?.trimEnd()}) }}/>
                        <p>Please confirm that the CIR calculation has been appropriately reviewed before generating the return</p>
                    </div>


                    <div className='row space-between'>
                        <p className="col-8">Selecting this option generates standard reports, detailed excel outputs and visualisation inputs</p>
                        <Button
                            className="col-4"
                            neverReadonly 
                            onClick={() => {
                                downloadJson();
                                excelDataDownload();
                            }} 
                            icon="icon-download-light-outline"
                        >
                            Generate modelling outputs
                        </Button>
                    </div>
                    <div className='row space-between'>
                        <p className="col-8">Selecting this option generates full return templates, in addition to standard reports, detailed excel outputs and visualisation inputs. This option is only available if there is an active reporting company.</p>
                        <Button 
                            className="col-4"
                            enabledWhenFinalised
                            onClick={async () => { 
                                downloadJson();
                                excelDataDownload();
                                downloadFullReturn();
                                //dowloadWordReport();

                                const newStatus = { ...returnStatus, type: validReturnTypes.full };
                                setReturnStatus(newStatus); 
                                await save(newStatus);
                            }}
                            disabled={
                                !returnsEnabled || 
                                returnStatus?.withoutReportingCompany
                            }
                            icon="icon-download-light-outline"
                            >
                            Generate full return
                        </Button>
                    </div>
                    <div className='row space-between'>
                        <p className="col-8">Selecting this option generates abbreviated return templates, in addition to standard reports, detailed excel outputs and visualisation inputs. This option is only available if there is an active reporting company, and if there were no disallowances in the period.</p>
                        <Button 
                            className="col-4"
                            enabledWhenFinalised
                            onClick={async () => {
                                downloadJson();
                                excelDataDownload();
                                donwloadAbbreviatedReturn();

                                const newStatus = { ...returnStatus, type: validReturnTypes.abbreviated };
                                setReturnStatus(newStatus);
                                await save(newStatus);
                            }}
                            disabled={
                                !returnsEnabled || 
                                returnStatus?.withoutReportingCompany || 
                                selectedPeriod.group_reactivations !== 0 || 
                                selectedPeriod.group_disallowance !== 0
                            }
                            icon="icon-download-light-outline"
                            >
                            Generate abbreviated return
                        </Button>
                    </div>
                    <div className='row space-between'>
                        <p className="col-8">Selecting this option generates standard reports, detailed excel outputs and visualisation inputs. This option is only available if all companies are non-consenting, and none of the elections below have been made. This is the only option available if there is no active reporting company in the period.</p>
                        <Button
                            className="col-4" 
                            enabledWhenFinalised
                            onClick={() => {
                                downloadJson();
                                excelDataDownload();
                            }}
                            disabled={!returnsEnabled || !returnStatus?.withoutReportingCompany || shouldDisableReturnWithoutReportingCompany}
                            icon="icon-download-light-outline"
                            >
                            Generate return without reporting company
                        </Button>
                    </div>
                    
                    { returnStatus?.isRevised && <>
                        <label className="col-sm-2 col-form-label align-self-center">Revised return notes:</label>
                        <Input
                            enabledWhenFinalise
                            value={returnStatus?.revisedNotes?.[returnStatus?.revisedNotes?.length-1]} 
                            error={!isRevisionValid}
                            onChange={(value) => {
                            setReturnStatus({
                                revisedNotes: returnStatus.revisedNotes.splice(-1,1, value),
                                ...returnStatus, 
                            })
                        }}
                        />
                    </>}

                </Panel>
                
                <Panel className='mt-2'>
                    <Accordion>
                        <AccordionItem itemKey='1' title={'Elections which require an active reporting company'}>
                            <p>The following elections cannot be made in the return if there is no active reporting company, however any irrevocable election made in a previous CIR period should remain active as they cannot be revoked:</p>
                            <Table originalData={electionsTableData} hasTitle disableDefaultSort skipInitialSort >
                            <Column field='election'>Group Elections</Column>
                            <Column field='statReference'>Statutory Reference </Column>
                            <Column field='duration'>Duration</Column>
                            <Column field='status' renderCell={(row, field)=> row[field] === true ? "Active": "Inactive" }>Status</Column>
                            <Column field='dateMade' renderCell={(row, field) => row[field] ? dayjs(row[field])?.year() : '' }>Year in which election was made (if provided)</Column>
                            </Table>
                        </AccordionItem>
                    </Accordion>
                </Panel>

                <Panel title='Submission Confirmation' className='mt-2' hidden={returnStatus.withoutReportingCompany}>
                    <div className='row'>
                        <Input className='col-1' value={returnStatus?.type} readonly />
                        <Input className='col-1' value={returnStatus?.isSubmitted ? "Submitted": "Not submitted"} readonly />
                    </div>

                    <div className='row'>
                        <label className="col-sm-3 col-form-label">I confirm the return has been submitted.</label>
                        <div className="col-sm-1">
                            <Checkbox
                                enabledWhenFinalised
                                disabled={!isReturnTypeValid || !isRevisionValid}
                                checked={readyToSubmit || returnStatus?.isSubmitted}
                                onChange={setReadyToSubmit}
                            />
                        </div>
                        <div className='col-sm-5'/>
                        <Button
                            className="col-sm-3"
                            enabledWhenFinalised
                            disabled={!readyToSubmit || !isReturnTypeValid || !isRevisionValid || !processDateJS(returnStatus?.submittedDate)}
                            checked={returnStatus.isSubmitted}
                            onClick={() => {
                                save(returnStatus, true);
                            }}
                            >Mark period submitted</Button>
                    </div>

                    <Tooltip content={"TODO late submission, enquiries, and how these impact deadlines"}>
                        <div>
                            <label className="col-sm-2 col-form-label align-self-center">Date of submission:</label>
                            <CalendarPicker
                                value={processDateJS(returnStatus?.submittedDate)}
                                minDate={processDateJS(selectedPeriod?.period_end)}
                                enabledWhenFinalised
                                onChange={(date) => {
                                    setReturnStatus({...returnStatus, submittedDate: processDateJS(date)?.toString()})
                                }}
                            />
                        </div>
                    </Tooltip>
                </Panel>
            </div>
            <div className='container-fluid mt-4'>
                <div className='d-flex justify-content-between align-items-center'> 
                    <div>
                        <BackButton disabled={isPosting}/>
                    </div>
                    <div className='d-flex gap-2'>
                        <Button enabledWhenFinalised hiddenInReadonly loading={isPosting} onClick={() => save(returnStatus)}>Save</Button>
                        <NextButton preNavigation={() => save(returnStatus)} loading={isPosting} nextPage="/app/grouppoa">Return to Group POA</NextButton>
                    </div>
                </div>
            </div>

        </div>
    );
};

export default Outputs;