import { Button, Notice } from "@appkit4/react-components"
import { getCurrentPeriodIsReadonly, getUserIsReadonly } from "../services/GroupContext";
import { useNavigate } from "react-router-dom";

const ReadonlyBanner = () => {
  const navigate = useNavigate();

  // Don't show if readonly to prevent 2 banners
  if ( ! getCurrentPeriodIsReadonly() || getUserIsReadonly() ) {
    return <></>;
  }

  return (
    <Notice
      message={
        <div className="space-between notice-content-width">
          <p>This period is finalised. To enable editing, open the period from the Group POA page.</p>
          <Button onClick={() => navigate("/app/grouppoa")} >Go to Group POA</Button>
        </div>}
      status='icon-warning-fill'
      closeable={false}
      style={{ backgroundColor: "white", width: "100%" }}
    />
  )
}

export default ReadonlyBanner