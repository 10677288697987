import dayjs from "dayjs";
import { processDate, processDateJS } from "./dateProcessor";

export const get_relevant_companies = (period_start, period_end, group_data) => {
    let companies = group_data?.companies;
    const relevant_companies = companies?.filter(company => {
        if (!Boolean(company?.uk_group_company)) {
            return false;
        }
    
        const date_join = company?.date_join;
        if (date_join && processDateJS(date_join)?.isAfter(period_end)) {
            return false;
        }
    
        const date_left = company?.date_left;
        if (date_left && processDateJS(date_left)?.isSameOrBefore(period_start)) {
            return false;
        }

        return true;
    })

    return relevant_companies;
}

/**
 * Get a list of company accounting periods (cap) that are relevant for the specified CIR period. 
 * 
 * @param {*} period_start the start of the CIR period
 * @param {*} period_end the end of the CIR period
 * @param {*} group_data the entire groupData object (see GroupContext)
 * @param {*} company_ID If specified, only find relevant periods for a given company. If null, it will return periods for all companies in the group
 * @returns a list of relevant caps 
 */
export const get_relevant_periods = (period_start, period_end, group_data, company_ID) => {
    if (!period_start || !period_end) {
        return [];
    }

    const caps = group_data?.company_accounting_periods;

    // If no caps are defined in the group, let's exit early and return an empty array
    if (!caps || caps?.length === 0) {
        return [];
    }

    // Let's find companies relevant relevant to this CIR period
    let relevant_companies = get_relevant_companies(period_start, period_end, group_data);
    if (company_ID) {
        relevant_companies = relevant_companies?.filter(c => c?.company_ID === company_ID);

        // A specific company id was specified but couldn't find it - return empty array of CAPs
        if (!relevant_companies || relevant_companies?.length === 0) {
            return []
        }
    }

    // Now we know it's a valid company for this current CIR period, let's find all the CAPS that fall within this date range
    const relevant_caps = caps?.filter(cap => {
        if (!relevant_companies?.find(c => c?.company_ID === cap?.company_ID)) {
            return false;
        }

        const cap_start = processDateJS(cap?.start_date);
        const cap_end = processDateJS(cap?.end_date);

        // Overlap logic explained: https://stackoverflow.com/a/325964
        if (!(cap_start?.isSameOrBefore(period_end) && cap_end?.isSameOrAfter(period_start))) {
            return false;
        }

        return true;
    })?.map(cap => {
        return ({
            ...cap,
            start_date: cap?.start_date ? processDate(cap?.start_date) : undefined,
            end_date: cap?.end_date ? processDate(cap?.end_date) : undefined
        })
    })

    return relevant_caps;
}

/**
 * Checks if the company accounting period is overlapping with a previous period 
 * @param {*} cap_start_date the start date of the company accounting period
 * @param {*} model_first_period_start the model's first period start date
 * @returns true if there is an overlap 
 */
export const is_overlapping_cap = (cap_start_date, group_period_start, model_first_period_start) => {
    const period_start = processDateJS(group_period_start);
    return processDateJS(cap_start_date)?.isBefore(period_start) && !period_start?.isSame(model_first_period_start)
}