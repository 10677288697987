export function calculateIsCFDAdjEditable (isTradingOrOFA, isNonTradingOrOFA, adjustedCap) {
    return !!(
        (isTradingOrOFA && adjustedCap?.trade_small_or_negligible) ||
        (isNonTradingOrOFA && (adjustedCap?.investment_business_ceased || adjustedCap?.cta10_change_in_ownership_rules))
    );
}

export function calculateIsBFDAdjEditable (isTradingOrOFA, isNonTradingOrOFA, adjustedCap) {
    if(!adjustedCap) return false;

    const { 
        adjCapPosInPoA, 
        isFirstApForCompanyInPoa,
        isFirstApForCompanyInGroup, 
        trade_uncommercial_or_non_statutory, 
        cta10_change_in_ownership_rules
    } = adjustedCap;

    if (
        isFirstApForCompanyInPoa || 
        isFirstApForCompanyInGroup
    ) {
        return false;
    } 

    return !!(
        (isTradingOrOFA && trade_uncommercial_or_non_statutory) ||
        (isNonTradingOrOFA && cta10_change_in_ownership_rules)
    );
}