import { Panel,  Table, Column } from '@appkit4/react-components'; 
import { Button, Input, InputNumber, Checkbox } from "../components/ReadonlyAwareInputs";
import React, { useContext, useEffect, useState } from 'react';
import toast from '../components/DismissibleToast';
import { getGroupData, getGroupName, getFormattedSelectedPeriod, getSelectedPeriod } from '../services/GroupContext';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { metrics } from '../services/calculations/EntityCalculations';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import { AuthContext } from '../services/AuthProvider';
import { processDateJS } from '../utils/dateProcessor';
dayjs.extend(isSameOrBefore);
dayjs.extend(utc)

const nonEditableMetrics = [
    { group: 'tax_ebitda', metricID: 's407_tiopa_net_tax_interest_expense' }
]

export function applyAdjustedCapDTR(adj_cap) {
    const {fx_rate, ct_rate_override, corporation_tax_rate, s407_relief_dtr_adjustment_tax_rate_equal_uk} = adj_cap;

    const ct_rate = corporation_tax_rate?.value ?? corporation_tax_rate ?? 0

    let ct_override_percent = ct_rate_override?.value ?? ct_rate_override ?? 0;
    if (ct_override_percent > 100) {
        ct_override_percent = 100;
    } else if (ct_override_percent < 0) {
        ct_override_percent = 0;
    }
    const ct_override_rate = ct_override_percent/100;
   
    const dtr_adjustment_value = s407_relief_dtr_adjustment_tax_rate_equal_uk.value; 
    if ( (dtr_adjustment_value ?? null) === null)
        return;
    
    adj_cap.net_dtr_adjustment_lc = {
        manual_adjustment: s407_relief_dtr_adjustment_tax_rate_equal_uk.manual_adjustment,
        value: Math.round(
            ( Number(dtr_adjustment_value) || 0 )
            * 
            ( ct_override_rate && ct_override_rate !== 0
            ? ct_override_rate 
            : (ct_rate || 1)
        )
        )
    }
    
    adj_cap.net_dtr_adjustment_gbp = {
        value: adj_cap.net_dtr_adjustment_lc.value / fx_rate,
        manual_adjustment: s407_relief_dtr_adjustment_tax_rate_equal_uk.manual_adjustment,
    }
}


const getAmountEditability = (original_cap, company_period) => {
    const canEditPreAppAmount = company_period?.isFirstApForCompanyInGroup && original_cap?.start_outside_poa;
    const canEditCurrentAmount = original_cap?.end_outside_poa;
    const canEditSomething = canEditPreAppAmount || canEditCurrentAmount;

    return {
        canEditPreAppAmount,
        canEditCurrentAmount,
        canEditSomething,
    }
}

function getPriorPoas(company_period, selectedPeriod) {
    return getGroupData()?.periods_of_account?.filter((poa) => processDateJS(poa?.period_end)?.isAfter(processDateJS(company_period?.start_date))
        && processDateJS(poa?.period_start)?.isBefore(processDateJS(selectedPeriod?.period_start))
    ).map((period) => period.adjusted_caps?.find((cap) => cap?.start_date === company_period?.start_date
        && cap?.end_date === company_period?.end_date
        && cap?.company_ID === company_period?.company_ID
    )
    ).filter(v => v);
}

// calculate pre_app, prior, current and future CIR values
export const getAdjustedRow = (original_cap, company_period, selectedPeriod, row, {newPreApp, newCurrent}={}) => {
    const db_value = company_period[row?.metric_id];

    const prior_poas = getPriorPoas(company_period, selectedPeriod);
    const cap_has_prior_pre_app = prior_poas.some( p_poa => getAmountEditability(original_cap, p_poa)?.canEditPreAppAmount );

    const {canEditPreAppAmount, canEditCurrentAmount} = getAmountEditability(original_cap, company_period);

    const ap_total = row.accounting_period_total || 0;
    let [pre_app_cir_amount, prior_cir_amount, current_cir_amount, future_cir_amount] = [0,0,0,0];

    // Pre App
    const pre_app_default_allocation = ap_total * original_cap.pre_app_inclusion_factor;
    const pre_app_stored_value = original_cap.pre_app_amounts?.[row.metric_id]?.value;
    const pre_app_current_value = row?.pre_app_cir_amount?.value ?? row?.pre_app_cir_amount;
    if ( cap_has_prior_pre_app ){
        pre_app_cir_amount = pre_app_stored_value;
    } else if ( canEditPreAppAmount && row.manual_adjustment ){
        pre_app_cir_amount = newPreApp ?? pre_app_current_value ?? pre_app_stored_value ?? pre_app_default_allocation;
    } else {
        pre_app_cir_amount = pre_app_default_allocation;
    }
    
    // Prior
    prior_cir_amount =  original_cap.start_outside_poa
        ? prior_poas?.reduce((acc, matching_cap) => {
                const metric = matching_cap?.[row?.metric_id];
                return acc + (metric?.value ?? metric ?? 0);
            }, 0)
        :0;
    
    // Current
    const current_default_allocation = ap_total * company_period.inclusion_factor;
    const current_stored_value = db_value?.value ?? db_value;
    const current_current_value = row?.current_cir_amount?.value ?? row?.current_cir_amount;
    if (canEditCurrentAmount){
        if (row.manual_adjustment){
            current_cir_amount = newCurrent ?? current_current_value ?? current_stored_value ?? current_default_allocation;
        } else {
            current_cir_amount = current_default_allocation;
        }
    } else {
        current_cir_amount = ap_total - pre_app_cir_amount - prior_cir_amount;
    }
    
    // Future
    future_cir_amount = ap_total - pre_app_cir_amount - prior_cir_amount - current_cir_amount;

    return { ...row,
        pre_app_cir_amount,
        prior_cir_amount,
        current_cir_amount,
        future_cir_amount,
    };
}

// Init Table State
export const getCalculationGroupValues = (original_cap, company_period, selectedPeriod, calculation_group) => {
    const filtered_metrics = metrics?.filter(metric => metric?.calculation_group?.includes(calculation_group));

    if (!original_cap){
        toast.error(`Failed to find cap for ${calculation_group}`);
        return [];
    }
    return filtered_metrics?.map( metric => {
        const db_value = company_period[metric?.metric_id];

        const editable = !nonEditableMetrics.find(nonEditableMetric => nonEditableMetric.group === calculation_group && nonEditableMetric.metricID === metric?.metric_id);
    
        const tmpRow = {
            metric_id: metric?.metric_id,
            description: metric?.display_name,
            manual_adjustment: db_value?.manual_adjustment ?? false,
            editable,
            accounting_period_total: original_cap[metric?.metric_id] || 0,
        }

        return getAdjustedRow(original_cap, company_period, selectedPeriod, tmpRow);
    })
}

const EditAutomaticAllocations = ({ navBack, company_period, adjusted_caps, save }) => {
    const selectedPeriod = getSelectedPeriod();
    const savedData = getGroupData();

    const original_cap_index = savedData?.company_accounting_periods?.findIndex((cap) => cap?.company_ID === company_period?.company_ID && cap?.start_date === company_period?.start_date)
    const original_cap = savedData?.company_accounting_periods[original_cap_index];

    const prior_poas = getPriorPoas(company_period, selectedPeriod);
    const cap_has_prior_pre_app = prior_poas.some( p_poa => getAmountEditability(original_cap, p_poa)?.canEditPreAppAmount );

    const {canEditPreAppAmount, canEditCurrentAmount, canEditSomething} = getAmountEditability(original_cap, company_period);

    const [ntlr_data, set_ntlr_data] = useState( () => getCalculationGroupValues(original_cap, company_period, selectedPeriod, 'ntlr'));
    const [ntdc_data, set_ntdc_data] = useState( () => getCalculationGroupValues(original_cap, company_period, selectedPeriod, 'ntdc'));
    const [tlr_data, set_tlr_data] = useState( () => getCalculationGroupValues(original_cap, company_period, selectedPeriod, 'tlr'));
    const [tdc_data, set_tdc_data] = useState( () => getCalculationGroupValues(original_cap, company_period, selectedPeriod, 'tdc'));
    const [ofa_data, set_ofa_data] = useState( () => getCalculationGroupValues(original_cap, company_period, selectedPeriod, 'ofa'));
    const [tax_ebitda_data, set_tax_ebitda_data] = useState( () => getCalculationGroupValues(original_cap, company_period, selectedPeriod, 'tax_ebitda'));


    const render_pre_app_cir_input = (row, table_data, set_table_data) => {
        const val = Math.round(Number(row?.pre_app_cir_amount))
        return row?.editable ? 
            <InputNumber
                value={val}
                readonly={!row?.manual_adjustment}
                disabled={!canEditPreAppAmount}
                format=','
                onChange={(val) => {
                    const index = table_data?.findIndex(r => r?.metric_id === row?.metric_id);
                    if (index === -1) {
                        toast.error(`Failed to update current CIR amount for ${row?.description}`);
                        return
                    }
                    const newData = [...table_data];
                    newData[index] = getAdjustedRow(original_cap, company_period, selectedPeriod, row, {newPreApp:val});
                    set_table_data(newData);
                }}
            /> : 
            formatNumber(val.toString());
    }

    const render_current_cir_input = (row, table_data, set_table_data) => {
        const val = Math.round(Number(row?.current_cir_amount));
        return row?.editable ?
            <InputNumber
                value={val}
                readonly={!row?.manual_adjustment}
                disabled={!canEditCurrentAmount}
                format=','
                onChange={(val) => {
                    const index = table_data?.findIndex(r => r?.metric_id === row?.metric_id);
                    if (index === -1) {
                        toast.error(`Failed to update current CIR amount for ${row?.description}`);
                        return;
                    }
                    const newData = [...table_data];
                    newData[index] = getAdjustedRow(original_cap, company_period, selectedPeriod, row, {newCurrent:val});
                    set_table_data(newData);
                }}
            /> :
            formatNumber(val.toString());
    }

    const formatNumber = (value) => {
        if (!value && value !== 0) return '';
        // Convert value to a number, round to the nearest integer, and add commas for thousands and millions separator
        return Math.round(Number(value))?.toLocaleString('en-US');
    };

    const render_manual_adjustment = (row, set_table) => {
        if (!row?.hasOwnProperty('manual_adjustment')) {
            return <></>;
        }

        return row.editable && <Checkbox
            className='col'
            disabled={!canEditSomething || !row.editable}
            checked={row?.manual_adjustment}
            onChange={(checkedValue) => {
                const updatedRow = { ...row, manual_adjustment: checkedValue};

                set_table((previousTableData) => {
                    const newData = [...previousTableData];
                    const index = newData?.findIndex((currentRow) => currentRow?.metric_id === row?.metric_id);
                    if (index === -1) {
                        toast.error(`Failed to update manual adjustment for ${row?.description}`);
                        return previousTableData;
                    }

                    newData[index] = getAdjustedRow(original_cap, company_period, selectedPeriod, updatedRow);
                    return newData;
                });
            }}
        />
    }

    const calculateTotal = (table, key) => {
        let total = 0;
        for (const row of table) {
            const value = row[key]
            total = total + value;
        }
        return total;
    }

    const calculateCurrentCirAmountSubTotal = (table) => {
        return calculateTotal(table, 'current_cir_amount');
    }
    
    const calculateAccountingPeriodTotal = (table) => {
        return calculateTotal(table, 'accounting_period_total');
    }
    const calculatePreAppCirAmountTotal = (table) => {
        return calculateTotal(table, 'pre_app_cir_amount');
    }

    const calculateFutureCirAmountTotal = (table) => {
        return calculateTotal(table, 'future_cir_amount');
    }

    const calculatePriorCirAmountTotal = (table) => {
        return calculateTotal(table, 'prior_cir_amount');
    }

    useEffect(() => {
        const ntlr_table = getCalculationGroupValues(original_cap, company_period, selectedPeriod, 'ntlr');
        set_ntlr_data(ntlr_table)

        const ntdc_table = getCalculationGroupValues(original_cap, company_period, selectedPeriod, 'ntdc');
        set_ntdc_data(ntdc_table)

        const tlr_table = getCalculationGroupValues(original_cap, company_period, selectedPeriod, 'tlr');
        set_tlr_data(tlr_table);

        const tdc_table = getCalculationGroupValues(original_cap, company_period, selectedPeriod, 'tdc');
        set_tdc_data(tdc_table);

        const ofa_table = getCalculationGroupValues(original_cap, company_period, selectedPeriod, 'ofa');
        set_ofa_data(ofa_table);

        const tax_ebitda_table = getCalculationGroupValues(original_cap, company_period, selectedPeriod, 'tax_ebitda');
        set_tax_ebitda_data(tax_ebitda_table)
    }, [company_period])

    useEffect(() => {
        const currCirAmountTotal = 
            calculateCurrentCirAmountSubTotal(ntlr_data) +
            calculateCurrentCirAmountSubTotal(ntdc_data) +
            calculateCurrentCirAmountSubTotal(tlr_data) +
            calculateCurrentCirAmountSubTotal(tdc_data) +
            calculateCurrentCirAmountSubTotal(ofa_data);
            
        const accountingPeriodTotal =
            calculateAccountingPeriodTotal(ntlr_data) +
            calculateAccountingPeriodTotal(ntdc_data) +
            calculateAccountingPeriodTotal(tlr_data) +
            calculateAccountingPeriodTotal(tdc_data) +
            calculateAccountingPeriodTotal(ofa_data);
            
        const preAppCirAmountTotal = 
            calculatePreAppCirAmountTotal(ntlr_data) +
            calculatePreAppCirAmountTotal(ntdc_data) +
            calculatePreAppCirAmountTotal(tlr_data) +
            calculatePreAppCirAmountTotal(tdc_data) +
            calculatePreAppCirAmountTotal(ofa_data);
            
        const futureCirAmountTotal =
            calculateFutureCirAmountTotal(ntlr_data) +
            calculateFutureCirAmountTotal(ntdc_data) +
            calculateFutureCirAmountTotal(tlr_data) +
            calculateFutureCirAmountTotal(tdc_data) +
            calculateFutureCirAmountTotal(ofa_data);
            
        const priorCirAmountTotal = 
            calculatePriorCirAmountTotal(ntlr_data) +
            calculatePriorCirAmountTotal(ntdc_data) +
            calculatePriorCirAmountTotal(tlr_data) +
            calculatePriorCirAmountTotal(tdc_data) +
            calculatePriorCirAmountTotal(ofa_data);

        set_tax_ebitda_data(prevState => prevState.map(metric => {
                if(metric.metric_id === 's407_tiopa_net_tax_interest_expense') {
                    metric.current_cir_amount = currCirAmountTotal;
                    metric.accounting_period_total = accountingPeriodTotal;
                    metric.pre_app_cir_amount = preAppCirAmountTotal;
                    metric.future_cir_amount = futureCirAmountTotal;
                    metric.prior_cir_amount = priorCirAmountTotal;
                }
                return metric
            })
        )
    }, [ntlr_data, ntdc_data, tlr_data, tdc_data, ofa_data])

    const save_data = async () => {
        const all_data = [...ntlr_data, ...ntdc_data, ...tlr_data, ...tdc_data, ...ofa_data, ...tax_ebitda_data]

        const index = adjusted_caps?.findIndex(cap => {
            if (cap?.company_ID !== company_period?.company_ID) return false;
            if (cap?.start_date && new Date(cap?.start_date)?.toISOString() !== new Date(company_period?.start_date)?.toISOString()) return false;
            if (cap?.end_date && new Date(cap?.end_date)?.toISOString() !== new Date(company_period?.end_date)?.toISOString()) return false;

            return true;
        })

        if (index === -1) {
            return;
        }

        const data = adjusted_caps;
        const edited_cap = adjusted_caps[index];

        original_cap.pre_app_amounts = original_cap.pre_app_amounts ?? {};
        for (const row of all_data) {
            const key = row?.metric_id;
            edited_cap[key] = {total: row?.accounting_period_total, value: row?.current_cir_amount, manual_adjustment: row?.manual_adjustment}
            edited_cap.adjustment_status = row?.manual_adjustment ? 'Manual Adjustment' : edited_cap?.adjustment_status;
            original_cap.pre_app_amounts[key] = {value: row?.pre_app_cir_amount, manual_adjustment: row.manual_adjustment};
        }

        [
            ['ntlr', ntlr_data],
            ['ntdc', ntdc_data],
            ['tlr', tlr_data],
            ['tdc', tdc_data],
            ['ofa', ofa_data],
        ].forEach( ([calcGroup, values]) => {
            edited_cap[`${calcGroup}_subtotal`] = { 
                value: calculateCurrentCirAmountSubTotal(values),
                manual_adjustment: values.reduce((acc, row) => acc || row.manual_adjustment, false),
            };
        })

        applyAdjustedCapDTR(edited_cap);

        savedData.company_accounting_periods[original_cap_index] = original_cap;

        data[index] = edited_cap;
        await save(data, savedData.company_accounting_periods);
    }

    return (
        <div className="ap-container">
             <div className="container-fluid mt-4">
                <div className='d-flex justify-content-between align-items-center'> 
                    <Button neverReadonly onClick={() => {navBack(false)}}>Back</Button>
                    <div className='d-flex gap-2'>
                        <Button hiddenInReadonly onClick={() => save_data()}>Save</Button>
                        <Button onClick={async () => {
                            await save_data();
                            navBack(false);
                        }}>Save and Close</Button>
                    </div>
                </div>
            </div>
            <div>
                <Panel className='mt-4' title='Group Detail'>
                    <div className='row'>
                        <p className='col-4'>Group Name:</p>
                        <Input
                            className='col'
                            title={"Group Name"}
                            hideTitleOnInput
                            value={savedData['group_name']}
                            disabled={true}
                            required={true}
                        />
                    </div>
                    <div className='row'>
                        <p className='col-4'>Ultimate Parent:</p>
                        <Input
                            className='col'
                            title={"Group Name"}
                            hideTitleOnInput
                            value={savedData['ult_parent_name']}
                            disabled={true}
                            required={true}
                        />
                    </div>
                    <div className='row'>
                        <p className='col-4'>Selected Period</p>
                        <Input className='col'
                            value={getFormattedSelectedPeriod()}
                            required={true}
                            disabled
                            hideTitleOnInput
                        ></Input>
                    </div>
                    <div className='row'>
                        <p className='col-4'>Selected Company + Accounting period</p>
                        <Input className='col'
                            value={savedData?.companies?.filter((c) => c?.company_ID === company_period?.company_ID)[0]['company_name']}
                            required={true}
                            disabled
                            hideTitleOnInput
                        ></Input>
                    </div>
                </Panel>

                <Panel title='Company Detail' className='mt-4'>
                    <div className='row'>
                        <p className='col-4'>Company Name</p>
                        <Input
                            className='col'
                            title={"Group Name"}
                            hideTitleOnInput
                            value={savedData?.companies?.filter((c) => c?.company_ID === company_period?.company_ID)[0]['company_name']}
                            disabled={true}
                            required={true}
                        />
                    </div>
                    <div className='row'>
                        <p className='col-6'>Accounting Period (Start, End, Length):</p>
                        <Input
                            className='col-2'
                            title={"Start Date"}
                            hideTitleOnInput
                            value={processDateJS(company_period?.start_date)?.format('DD/MM/YYYY')}
                            disabled={true}
                            required={true}
                        />
                        <Input
                            className='col-2'
                            title={"Start Date"}
                            hideTitleOnInput
                            value={processDateJS(company_period?.end_date)?.format('DD/MM/YYYY')}
                            disabled={true}
                            required={true}
                        />
                        <Input
                            className='col-2'
                            title={"Group Name"}
                            hideTitleOnInput
                            value={company_period?.ap_length?.toLocaleString()}
                            disabled={true}
                            required={true}
                        />
                    </div>
                    <div className='row'>
                        <p className='col-6'>Group Period of Account (Start, End):</p>
                        <Input
                            className='col-2'
                            title={"Group Period Start Date"}
                            hideTitleOnInput
                            value={processDateJS(company_period?.group_period_start)?.format('DD/MM/YYYY')}
                            disabled={true}
                            required={true}
                        />
                        <Input
                            className='col-2'
                            title={"Group Period End Date"}
                            hideTitleOnInput
                            value={processDateJS(company_period?.group_period_end)?.format('DD/MM/YYYY')}
                            disabled={true}
                            required={true}
                        />
                    </div>
                    <div className='row'>
                        <p className='col-6'>Regarded Period (Start, End, Length):</p>
                        <Input
                            className='col-2'
                            title={"Start Date"}
                            hideTitleOnInput
                            value={processDateJS(company_period?.inclusion_period_start)?.format('DD/MM/YYYY')}
                            disabled={true}
                            required={true}
                        />
                        <Input
                            className='col-2'
                            title={"Start Date"}
                            hideTitleOnInput
                            value={processDateJS(company_period?.inclusion_period_end)?.format('DD/MM/YYYY')}
                            disabled={true}
                            required={true}
                        />
                        <Input
                            className='col-2'
                            title={"Group Name"}
                            hideTitleOnInput
                            value={company_period?.inclusion_period_length?.toLocaleString()}
                            disabled={true}
                            required={true}
                        />
                    </div>
                    <div className='row'>
                        <p className='col-4'>Disregarded period proportion: </p>
                        <Input
                            className='col'
                            title={"Group Name"}
                            hideTitleOnInput
                            value={(1 - company_period?.inclusion_factor)?.toLocaleString()}
                            disabled={true}
                            required={true}
                        />
                    </div>
                    <div className='row'>
                        <p className='col-4'>Computation Currency: </p>
                        <Input
                            className='col'
                            title={"Group Name"}
                            hideTitleOnInput
                            value={company_period?.currency}
                            disabled={true}
                            required={true}
                        />
                    </div>
                    <div className='row'>
                        <p className='col-4'>Exchange Rate (GBP1:CURX):  </p>
                        <Input
                            className='col'
                            title={"Group Name"}
                            hideTitleOnInput
                            value={company_period?.fx_rate}
                            disabled={true}
                            required={true}
                        />
                    </div>
                </Panel>
            </div>

            { [
                [ntlr_data, set_ntlr_data, "Non-trading loan relationships"],
                [ntdc_data, set_ntdc_data, "Non-trading derivative contracts"],
                [tlr_data, set_tlr_data, "Trading loan relationships"],
                [tdc_data, set_tdc_data, "Trading derivative contracts"],
                [ofa_data, set_ofa_data, "Other financing arrangements"],
                [tax_ebitda_data, set_tax_ebitda_data, "Tax-EBITDA section"],
            ].map(( [data, setData, fullName], index ) => {
                return <Panel style={{ marginTop: 20, padding: 5 }} key={index}>
                    <Table originalData={data} hasTitle style={{ marginTop: 20 }} sortActive={false}>
                        <Column field="description" >{fullName}</Column>
                        <Column field="manual_adjustment" renderCell={(row, field) => render_manual_adjustment(row, setData)}>Allow Manual Adjustment</Column>
                        <Column field="accounting_period_total" renderCell={(row, field) => formatNumber(row[field])} style={{ textAlign: 'center' }}>Accounting Period Total</Column>
                        {/* // TODO use conditional logic bellow to hide columns after calculations have been tested */}
                        <Column field="pre_app_cir_amount" renderCell={(row, field) => (canEditPreAppAmount || cap_has_prior_pre_app) ? render_pre_app_cir_input(row, data, setData) : ""}>Amount allocated to CIR periods outside of application</Column>
                        <Column field="prior_cir_amount" renderCell={(row, field) => original_cap.start_outside_poa ? formatNumber(row[field]) : ""}>Amount allocated to prior CIR periods</Column>
                        <Column field="current_cir_amount" renderCell={(row, field) => render_current_cir_input(row, data, setData)}>Amount allocated to current CIR period</Column>
                        <Column field="future_cir_amount" renderCell={(row, field) => original_cap.end_outside_poa ? formatNumber(row[field]) : ""}>Amount to be regarded in future CIR periods</Column>
                    </Table>
                    <div>
                        <h1>{fullName} - Subtotal: {calculateCurrentCirAmountSubTotal(data)?.toLocaleString()}</h1>
                    </div>
                </Panel>
            })
            }

            <div className="container-fluid mt-4">
                <div className='d-flex justify-content-between align-items-center'> 
                    <Button neverReadonly onClick={() => {navBack(false)}}>Back</Button>
                    <div className='d-flex gap-2'>
                        <Button hiddenInReadonly onClick={() => save_data()}>Save</Button>
                        <Button onClick={() => {
                            save_data(); 
                            navBack(false);
                        }}>Save and Close</Button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default EditAutomaticAllocations;