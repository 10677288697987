import { Panel,  Table, Loading,  Column,  Tooltip, Badge, Button } from '@appkit4/react-components'; 
import { Switch } from "../components/ReadonlyAwareInputs";
import { useContext, useEffect, useState } from 'react';
import { BackButton, NextButton } from '../components/ProgressNav';
import RevCalcHeader from './ReviewCalcHeader';
import ReviewCalcNav from './revCalcNavigation';
import { getGroupData, setGroupData, getPoaIndex, getGroupName, getSelectedPeriod, getFormattedSelectedPeriod } from '../services/GroupContext';
import toast from '../components/DismissibleToast';
import axios from 'axios';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import sqlService from '../services/sqldatabase/sqldatabase.service';
import { getReviewCalc2Values } from '../services/calculations/calc2';
import { AuthContext } from '../services/AuthProvider';
import { processDateJS } from '../utils/dateProcessor';
import { getBFDTotal } from '../services/calculations/ComponentTotals';
import { calculateUsedAllowances } from '../services/calculations/allowanceCalc';
import CustomTooltipIcon from '../components/customTooltipIcon/customTooltipIcon';
import CompanyPanel from '../components/CompanyPanel';
dayjs.extend(utc)


const BACKEND_URL = process.env.REACT_APP_BACKEND_API;

const titleTooltipFRMap = {
    "Fixed ratio %": ["This is 30% of Aggregate Tax-EBITDA. Refer to s397 TIOPA 2010 for further details."],
    "Aggregate Tax EBITDA": ["This is the total of all Tax-EBITDA amounts allocable to the period of account for all UK group companies. Where the total is negative, Aggregate Tax-EBITDA is nil. Refer to s405 TIOPA 2010 for further details."],
    "Fixed Ratio % of Tax EBITDA": ["This is 30% of Aggregate Tax-EBITDA. Refer to s397 TIOPA 2010 for further details."],
    "Adjusted Net Group Interest Expense (ANGIE)": ["ANGIE, as calculated based on the inputs to the Group data page."],
    "Excess debt cap brought forward": ["This is any excess debt cap which is brought forward from previous periods of account. Refer to s400 TIOPA 2010 for further details."],
    "Fixed Ratio Debt Cap (s400(1))": ["This is the ANGIE for the period plus any excess debt cap brought forward. Refer to s400 TIOPA 2010 for further details."],
    "Basic Interest Allowance (s397(1))": ["This is the lower of the Fixed ratio % of Tax-EBITDA and the Fixed ratio debt cap. Refer to s397 TIOPA 2010 for further details."],
    "Aggregate Net Tax Interest Income (ANTII)": ["This is the total of the Tax-interest expense and income amounts allocable to the period of account for all UK group companies. If there is Aggregate net tax-interest expense, aggregate net tax-interest income is nil. Refer to s390 TIOPA 2010 for further details."],
    "Interest allowance (s396(1))": ["This is the sum of the basic interest allowance and aggregate net tax-interest income. Refer to s396 TIOPA 2010 for further details."]
}

const titleTooltipGRMap = {
    "Qualifying Net Group Interest Expense (QNGIE)" : ["QNGIE, as calculated based on the inputs to the Group data page."],
    "Group EBITDA": ["Group-EBITDA, as calculated based on the inputs to the Group data page."],
    "Group Ratio %": ["This QNGIE / Group-EBITDA. The ratio is capped at 100%. and is also 100% if Group-EBITDA is nil or negative. The Group ratio % is overridden by the Blended group ratio, as entered on the Group data page, where relevant. Refer to s399 TIOPA 2010 for further details."],
    "Aggregate Tax EBITDA": ["This is the total of all Tax-EBITDA amounts allocable to the period of account for all UK group companies. Where the total is negative, Aggregate Tax-EBITDA is nil. Refer to s405 TIOPA 2010 for further details."],
    "Group Ratio % of Tax EBITDA": ["This is the Group ratio % of Aggregate Tax-EBITDA.  Refer to s397 TIOPA 2010 for further details."],
    "Excess debt cap brought forward": ["This is any excess debt cap which is brought forward from previous periods of account. Refer to s400 TIOPA 2010 for further details."],
    "Group ratio debt cap (s400(2))": ["This is the QNGIE for the period plus any excess debt cap brought forward."],
    "Basic Interest Allowance (s398(1))": ["This is the lower of the Group ratio % of Tax-EBITDA and the Group ratio debt cap. Refer to s398 TIOPA 2010 for further details."],
    "Aggregate Net Tax Interest Income (ANTII)": ["This is the total of the Tax-interest expense and income amounts allocable to the period of account for all UK group companies. If there is Aggregate net tax-interest expense, aggregate net tax-interest income is nil. Refer to s390 TIOPA 2010 for further details."],
    "Interest allowance (s396(1))": ["This is the sum of the basic interest allowance and aggregate net tax-interest income. Refer to s396 TIOPA 2010 for further details."]
}

const titleTooltipCDRMap = {
    "Interest Allowance": ["This is the interest allowance given by the Fixed ratio or Group ratio calculations above, as applicable. Refer to s396 TIOPA 2010 for further details."],
    "Unused interest allowance brought forward": ["This is the any unused interest allowance brought forward from prior periods, which is available for utilisation (i.e. is not expired and has not previously been used) in the current period. Refer to s393 TIOPA 2010 for further details."],
    "De minimis": ["This is the £2m de-minimis, pro-rated where the period of account is longer or shorter than 12 months. Refer to s392 TIOPA 2010 for further details."],
    "Interest capacity (Greater of interest allowance plus brought forward allowance and de minimis) (s392)": ["This is the greater of the interest allowance plus any available unused brought forward allowance or the de-minimis. Refer to s392 TIOPA 2010 for further details."],
    "Aggregate Net Tax Interest Expense": ["This is the total of the Tax-interest expense and income amounts allocable to the period of account for all UK group companies. If there is Aggregate net tax-interest income, aggregate net tax-interest expense is nil. Refer to s390 TIOPA 2010 for further details."],
    "Disallowed Amount (s373(1)-(2))": ["This is the disallowed amount for the group for the period. It is calculated as aggregate net tax-interest expense less interest capacity. Refer to s375 - s377 TIOPA 2010 for further details."],
    "Amount available for Reactivation (Group reactivation cap) (s373(3)-(4))": ["Where the aggregate net tax-interest expense is less than the interest allowance, it may be possible to reactivate amounts which have previously been disallowed. The amount that can be reactivated is limited to the interest reactivation cap, calculated as the interest allowance less the aggregate net tax-interest expense. Refer to s379 - s380 TIOPA 2010 for further details."]
}

const tableMaps = {
    titleTooltipFRMap,
    titleTooltipGRMap,
    titleTooltipCDRMap
}


const generateTableRowTitle = (name, column) => {
    const selectedMap = tableMaps["titleTooltip"+column+"Map"];
    return <div style={{display: "flex",gap: "0.5rem"}}>
        <span>{name}</span>
        <CustomTooltipIcon tooltipText={selectedMap[name].map(line => <p>{line}</p>)}/>
    </div>
}

const ReviewCalc2 = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [isPosting, setIsPosting] = useState(false);
    const auth = useContext(AuthContext)
    const [applyGroupRatioElection, setApplyGroupRatioElection] = useState(false);
    const [isError, setIsError] = useState(false);
    const [ultParentName, setUltParentName] = useState('');
    const [firstPoAStart, setFirstPoAStart] = useState();
    const [firstPoAEnd, setFirstPoAEnd] = useState();
    const [groupRatioElectionResult, setGroupRatioElectionResult] = useState(false)
    const [tableCDRDataFR, setTableCDRDataFR] = useState([
        { "title": "Interest Allowance", "GBP": 0},
        { "title": "Unused interest allowance brought forward", "GBP": 0},
        { "title": "De minimis", "GBP": 0},
        { "title": "Interest capacity (Greater of interest allowance plus brought forward allowance and de minimis) (s392)", "GBP": 0},
        { "title": "Aggregate Net Tax Interest Expense", "GBP": 0},
        { "title": "Disallowed Amount (s373(1)-(2))", "GBP": 0},
        { "title": "Amount available for Reactivation (Group reactivation cap) (s373(3)-(4))", "GBP": 0}
    ])
    const [tableCDRDataGR, setTableCDRDataGR] = useState([
        { "title": "Interest Allowance", "GBP": 0},
        { "title": "Unused interest allowance brought forward", "GBP": 0},
        { "title": "De minimis", "GBP": 0},
        { "title": "Interest capacity (Greater of interest allowance plus brought forward allowance and de minimis) (s392)", "GBP": 0},
        { "title": "Aggregate Net Tax Interest Expense", "GBP": 0},
        { "title": "Disallowed Amount (s373(1)-(2))", "GBP": 0},
        { "title": "Amount available for Reactivation (Group reactivation cap) (s373(3)-(4))", "GBP": 0}])
    const [tableFRData, setTableFRData] = useState(
        [{ "title": " " },
        { "title": " " },
        { "title": "Fixed ratio %", "GBP":  500},
        { "title": " " },
        { "title": "Aggregate Tax EBITDA", "GBP": 0 },
        { "title": "Fixed Ratio % of Tax EBITDA", "GBP": 0 },
        { "title": " " },
        { "title": "Adjusted Net Group Interest Expense (ANGIE)", "GBP": 0},
        { "title": "Excess debt cap brought forward", "GBP": 0},
        { "title": "Fixed Ratio Debt Cap (s400(1)", "GBP": 0},
        { "title": " " },
        { "title": "Basic Interest Allowance (s397(1))", "GBP": 0},
        { "title": "Aggregate Net Tax Interest Income (ANTII)", "GBP": 0},
        { "title": "Interest allowance (s396(1))", "GBP": 0}])
    const [tableGRData, setTableGRData] = useState(
        [{ "title": "Qualifying Net Group Interest Expense (QNGIE)", "GBP": 0 },
        { "title": "Group EBITDA", "GBP": 0 },
        { "title": "Group Ratio %", "GBP": 0},
        { "title": " " },
        { "title": "Aggregate Tax EBITDA", "GBP": 0},
        { "title": "Group Ratio % of Tax EBITDA", "GBP": 0 },
        { "title": " " },
        { "title": "Qualifying Net Group Interest Expense (QNGIE)", "GBP": 0},
        { "title": "Excess debt cap brought forward", "GBP": 0},
        { "title": "Group ratio debt cap (s400(2))", "GBP": 0},
        { "title": " " },
        { "title": "Basic Interest Allowance (s398(1)", "GBP": 0},
        { "title": "Aggregate Net Tax Interest Income (ANTII)", "GBP": 0},
        { "title": "Interest allowance (s396(1))", "GBP": 0}])

    // get calculated values
    const savedData = getGroupData();
    const savedPOAData = savedData?.periods_of_account[getPoaIndex()]
    useEffect(() => {
        if (getPoaIndex() > -1) {
            const poa = getReviewCalc2Values(savedData, getPoaIndex());
            if (poa) {
                savedData.periods_of_account[getPoaIndex()] = poa;
                setGroupData(savedData);
            }
        }
        else {
            toast.error(`could not find POA data`, {
                duration: 3 * 1000 // 3s
            })
        }
    }, [])

    useEffect(() => {
        // figures and table data set up for Fixed Ratio summary table 
        const aggregateTaxEBITDA = savedPOAData?.aggregate_tax_ebitda ? savedPOAData?.aggregate_tax_ebitda : 0;
        const fixedRatio = savedPOAData?.fr_percentage ? (savedPOAData?.fr_percentage * 100).toFixed(2) + "%" : 0;
        const fixedRatio30 = savedPOAData?.fr_percentage ? aggregateTaxEBITDA * savedPOAData?.fr_percentage : 0;
        const groupInterestAllowanceANGIE = savedPOAData?.adjusted_net_group_interest_expense ? savedPOAData?.adjusted_net_group_interest_expense : 0;
        const excessDebtCapBroughtForwardFR = savedPOAData?.excess_debt_cap_from_prior_period ? savedPOAData?.excess_debt_cap_from_prior_period : 0;
        const fixedRatioDebtCap = savedPOAData?.fr_debt_cap ? savedPOAData?.fr_debt_cap : 0;
        const basicInterestAllowanceFR = savedPOAData?.fr_basic_interest_allowance ? savedPOAData?.fr_basic_interest_allowance : 0;
        const aggregateNetTaxInterestIncome = savedPOAData?.aggregate_net_tax_interest_income ? savedPOAData?.aggregate_net_tax_interest_income : 0;
        const interestAllowanceFR = savedPOAData?.fr_interest_allowance ? savedPOAData?.fr_interest_allowance : 0;

        setTableFRData([
            { title: " " },
            { title: " " },
            { title: generateTableRowTitle("Fixed ratio %", "FR"), GBP: fixedRatio },
            { title: " " },
            { title: generateTableRowTitle("Aggregate Tax EBITDA", "FR"), GBP: Math.round(aggregateTaxEBITDA).toLocaleString() },
            { title: generateTableRowTitle("Fixed Ratio % of Tax EBITDA", "FR"), GBP: Math.round(fixedRatio30).toLocaleString() },
            { title: " " },
            { title: generateTableRowTitle("Adjusted Net Group Interest Expense (ANGIE)", "FR"), GBP: Math.round(groupInterestAllowanceANGIE).toLocaleString() },
            { title: generateTableRowTitle("Excess debt cap brought forward", "FR"), GBP: Math.round(excessDebtCapBroughtForwardFR).toLocaleString() },
            { title: generateTableRowTitle("Fixed Ratio Debt Cap (s400(1))", "FR"), GBP: Math.round(fixedRatioDebtCap).toLocaleString() },
            { title: " " },
            { title: generateTableRowTitle("Basic Interest Allowance (s397(1))", "FR"), GBP: Math.round(basicInterestAllowanceFR).toLocaleString() },
            { title: generateTableRowTitle("Aggregate Net Tax Interest Income (ANTII)", "FR"), GBP: Math.round(aggregateNetTaxInterestIncome).toLocaleString() },
            { title: generateTableRowTitle("Interest allowance (s396(1))", "FR"), GBP: Math.round(interestAllowanceFR).toLocaleString() }
        ]);
        

        const groupInterestAllowanceQNGIE = savedPOAData?.group_ratio_blended_election && savedPOAData?.blended_net_group_interest_expense ? Math.round(savedPOAData?.blended_net_group_interest_expense) : savedPOAData.qualifying_net_group_interest_expense;
        const groupInterestAllowanceEBITDA = savedPOAData?.group_ratio_election && savedPOAData?.group_ebitda ? Math.round(savedPOAData.group_ebitda) : "";
        const groupRatioPercentage = savedPOAData?.gr_percentage ? savedPOAData?.gr_percentage : 0;
        const groupRationPercentageTaxEBITDA = (aggregateTaxEBITDA * groupRatioPercentage) > 0 ? Math.round((aggregateTaxEBITDA * groupRatioPercentage) * 100) / 100 : 0;
        const excessDebtCapBroughtForwardGR = savedPOAData?.excess_debt_cap_from_prior_period ? savedPOAData?.excess_debt_cap_from_prior_period : 0;
        const groupRatioDebtCap = savedPOAData?.gr_debt_cap ? savedPOAData?.gr_debt_cap : 0;
        const basicInterestAllowanceGR = savedPOAData?.gr_basic_interest_allowance ? savedPOAData?.gr_basic_interest_allowance : 0;
        const interestAllowanceGR = savedPOAData?.gr_interest_allowance ? savedPOAData?.gr_interest_allowance : 0;
        setTableGRData([
            { title: generateTableRowTitle("Qualifying Net Group Interest Expense (QNGIE)", "GR"), GBP: groupInterestAllowanceQNGIE?.toLocaleString() },
            { title: generateTableRowTitle("Group EBITDA", "GR"), GBP: groupInterestAllowanceEBITDA?.toLocaleString() },
            { title: generateTableRowTitle("Group Ratio %", "GR"), GBP: (groupRatioPercentage * 100)?.toFixed(2) + "%" },
            { title: " " },
            { title: generateTableRowTitle("Aggregate Tax EBITDA", "GR"), GBP: Math.round(aggregateTaxEBITDA)?.toLocaleString() },
            { title: generateTableRowTitle("Group Ratio % of Tax EBITDA", "GR"), GBP: Math.round(groupRationPercentageTaxEBITDA)?.toLocaleString() },
            { title: " " },
            { title: generateTableRowTitle("Qualifying Net Group Interest Expense (QNGIE)", "GR"), GBP: Math.round(groupInterestAllowanceQNGIE)?.toLocaleString() },
            { title: generateTableRowTitle("Excess debt cap brought forward", "GR"), GBP: Math.round(excessDebtCapBroughtForwardGR)?.toLocaleString() },
            { title: generateTableRowTitle("Group ratio debt cap (s400(2))", "GR"), GBP: Math.round(groupRatioDebtCap)?.toLocaleString() },
            { title: " " },
            { title: generateTableRowTitle("Basic Interest Allowance (s398(1))", "GR"), GBP: Math.round(basicInterestAllowanceGR)?.toLocaleString() },
            { title: generateTableRowTitle("Aggregate Net Tax Interest Income (ANTII)", "GR"), GBP: Math.round(aggregateNetTaxInterestIncome)?.toLocaleString() },
            { title: generateTableRowTitle("Interest allowance (s396(1))", "GR"), GBP: Math.round(interestAllowanceGR)?.toLocaleString() }
        ]);
        


        // group interest allowance figures 
        // used to populate Computation of Disallowances and REactivatsion section 
        const GIA_GR_unused_allowance = savedPOAData?.allowance_calculations?.gr_unused_allowance ? savedPOAData?.allowance_calculations?.gr_unused_allowance : 0;
        const GIA_FR_unused_allowance = savedPOAData?.allowance_calculations?.fr_unused_allowance ? savedPOAData?.allowance_calculations?.fr_unused_allowance : 0;
        
        const GIA_de_minimis = savedPOAData?.interest_capacity_de_minimis ? savedPOAData?.interest_capacity_de_minimis : 0;
        const GIA_GR_interest_capacity = savedPOAData?.gr_interest_capacity ? savedPOAData?.gr_interest_capacity : 0;
        const GIA_FR_interest_capacity = savedPOAData?.fr_interest_capacity ? savedPOAData?.fr_interest_capacity : 0;
        const GIA_disallowed_amount_GR = savedPOAData?.gr_disallowance ? savedPOAData?.gr_disallowance : 0;
        const GIA_disallowed_amount_FR = savedPOAData?.fr_disallowance ? savedPOAData?.fr_disallowance : 0;
        const GIA_amount_available_Reactivation_GR = savedPOAData?.gr_group_reactivation_cap ? savedPOAData?.gr_group_reactivation_cap : 0;
        const GIA_amount_available_Reactivation_FR = savedPOAData?.fr_group_reactivation_cap ? savedPOAData?.fr_group_reactivation_cap : 0;
        const aggregateNetTaxInterestExpense = savedPOAData?.aggregate_net_tax_interest_expense ? savedPOAData?.aggregate_net_tax_interest_expense : 0;

        setTableCDRDataFR([
            { title: generateTableRowTitle("Interest Allowance", "CDR"), GBP: Math.round(interestAllowanceFR)?.toLocaleString() },
            { title: generateTableRowTitle("Unused interest allowance brought forward", "CDR"), GBP: Math.round(GIA_FR_unused_allowance)?.toLocaleString() },
            { title: generateTableRowTitle("De minimis", "CDR"), GBP: Math.round(GIA_de_minimis)?.toLocaleString() },
            { title: generateTableRowTitle("Interest capacity (Greater of interest allowance plus brought forward allowance and de minimis) (s392)", "CDR"), GBP: Math.round(GIA_FR_interest_capacity)?.toLocaleString() },
            { title: generateTableRowTitle("Aggregate Net Tax Interest Expense", "CDR"), GBP: Math.round(aggregateNetTaxInterestExpense)?.toLocaleString() },
            { title: generateTableRowTitle("Disallowed Amount (s373(1)-(2))", "CDR"), GBP: Math.round(GIA_disallowed_amount_FR)?.toLocaleString() },
            { title: generateTableRowTitle("Amount available for Reactivation (Group reactivation cap) (s373(3)-(4))", "CDR"), GBP: Math.round(GIA_amount_available_Reactivation_FR)?.toLocaleString() }
        ]);        

        setTableCDRDataGR([
            { title: generateTableRowTitle("Interest Allowance", "CDR"), GBP: Math.round(interestAllowanceGR)?.toLocaleString() },
            { title: generateTableRowTitle("Unused interest allowance brought forward", "CDR"), GBP: Math.round(GIA_GR_unused_allowance)?.toLocaleString() },
            { title: generateTableRowTitle("De minimis", "CDR"), GBP: Math.round(GIA_de_minimis)?.toLocaleString() },
            { title: generateTableRowTitle("Interest capacity (Greater of interest allowance plus brought forward allowance and de minimis) (s392)", "CDR"), GBP: Math.round(GIA_GR_interest_capacity)?.toLocaleString() },
            { title: generateTableRowTitle("Aggregate Net Tax Interest Expense", "CDR"), GBP: Math.round(aggregateNetTaxInterestExpense)?.toLocaleString() },
            { title: generateTableRowTitle("Disallowed Amount (s373(1)-(2))", "CDR"), GBP: Math.round(GIA_disallowed_amount_GR)?.toLocaleString() },
            { title: generateTableRowTitle("Amount available for Reactivation (Group reactivation cap) (s373(3)-(4))", "CDR"), GBP: Math.round(GIA_amount_available_Reactivation_GR)?.toLocaleString() }
        ]);        
        
    }, [])

    const updateCRTableData = (applyGroupRatioElection) => {
        setApplyGroupRatioElection(!applyGroupRatioElection);
    }


    useEffect(() => {
        (async () => {
            const savedData = getGroupData();  // Having the getGroupData() call in line 28 meant it didn't load for some reason? 
            if (!savedData) {
                return
            }
            // set local state variables based on getGroupData
            setUltParentName(savedData?.ult_parent_name || '')
            setFirstPoAStart(processDateJS(savedData?.model_first_period_start)?.format('DD/MM/YYYY'))
            setFirstPoAEnd(processDateJS(savedData?.model_first_period_end)?.format('DD/MM/YYYY'))
            setApplyGroupRatioElection(savedPOAData?.apply_group_ratio_election);
            updateCRTableData(!savedPOAData?.apply_group_ratio_election);

            var companies = savedData?.companies;
            // If companies is still 0, maybe it errored out above?
            if (!companies) {
                companies = []
            }
            setIsLoading(false);
            if(savedPOAData?.fr_interest_allowance > savedPOAData?.gr_interest_allowance){
                setGroupRatioElectionResult(false)
            } else if(savedPOAData?.fr_interest_allowance < savedPOAData?.gr_interest_allowance){
                setGroupRatioElectionResult(true)
            }
        })();
    }, [])

    const save = async () => {
        if (isError) {
            throw 'There is an error. Please fix before proceeding...';
        }
        
        if (getPoaIndex() > -1) {
            const poa = getReviewCalc2Values(savedData, getPoaIndex());
            if (poa) {
                savedData.periods_of_account[getPoaIndex()] = poa;
                setGroupData(savedData);
            }
        }

        setIsPosting(true);

        let grExcessDebtCapGenerated, frExcessDebtCapGenerated;
        if (getPoaIndex() > -1) {
            let { gr_excess_debt_cap_generated, fr_excess_debt_cap_generated } = getSelectedPeriod();
            grExcessDebtCapGenerated = gr_excess_debt_cap_generated;
            frExcessDebtCapGenerated = fr_excess_debt_cap_generated;
        } else {
            toast.error(`could not find POA data`, {
                duration: 3 * 1000 // 3s
            })
        }

        
        let updatedData = getGroupData()
        // set group values based on the GR/FR election
        const newPOA = {
            ...savedPOAData,
            apply_group_ratio_election: applyGroupRatioElection,
            ratio_type: applyGroupRatioElection ? 'Group Ratio' : 'Fixed Ratio',
            group_basic_interest_allowance: applyGroupRatioElection ? savedPOAData?.gr_basic_interest_allowance : savedPOAData?.fr_basic_interest_allowance,
            group_interest_allowance: applyGroupRatioElection ? savedPOAData?.gr_interest_allowance : savedPOAData?.fr_interest_allowance,
            group_debt_cap: applyGroupRatioElection ? savedPOAData?.gr_debt_cap : savedPOAData?.fr_debt_cap,
            group_percentage: applyGroupRatioElection ? savedPOAData?.gr_percentage : savedPOAData?.fr_percentage,
            group_interest_capacity: applyGroupRatioElection ? savedPOAData?.gr_interest_capacity : savedPOAData?.fr_interest_capacity,
            group_bf_interest_allowance: applyGroupRatioElection ? savedPOAData?.gr_bf_interest_allowance_used : savedPOAData?.fr_bf_interest_allowance_used,
            group_unexpired_allowance: applyGroupRatioElection ? savedPOAData?.allowance_calculations.gr_unexpired_allowance : savedPOAData?.allowance_calculations?.fr_unexpired_allowance,
            group_unused_allowance: applyGroupRatioElection ? savedPOAData?.allowance_calculations?.gr_unused_allowance : savedPOAData?.allowance_calculations?.fr_unused_allowance,
            group_disallowance: applyGroupRatioElection ? Math.round(savedPOAData?.gr_disallowance ) : Math.round(savedPOAData?.fr_disallowance ),
            group_excess_debt_cap: applyGroupRatioElection ? grExcessDebtCapGenerated : frExcessDebtCapGenerated,
            status_reviewcalc2_completed: true,
        }

        newPOA.group_excess_debt_cap = newPOA?.group_excess_debt_cap ?? Math.max(Math.min(newPOA?.group_debt_cap - newPOA?.group_percentage * newPOA?.aggregate_net_tax_interest_expense, 0), savedPOAData?.excess_debt_cap_from_prior_period + newPOA?.group_disallowance);
        newPOA.group_interest_reactivation_cap = Math.max(newPOA?.group_interest_allowance - newPOA?.aggregate_net_tax_interest_expense, 0)
        newPOA.period_unused_interest_allowance = Math.max(newPOA?.group_interest_allowance - newPOA?.aggregate_net_tax_interest_expense, 0)
        const totalBFD = getBFDTotal('bfd', newPOA);
        const totalBFDADJ = getBFDTotal('bfdadj', newPOA);
        newPOA.group_interest_reactivation_available = Math.min(newPOA?.group_interest_reactivation_cap, (totalBFD + totalBFDADJ));
        newPOA.unused_generated = Math.max((newPOA?.group_interest_allowance - newPOA?.aggregate_net_tax_interest_expense - newPOA?.group_interest_reactivation_available), 0);

        if(!newPOA?.apply_group_ratio_election){
            newPOA.group_ratio_blended_election = false;
            
            if((processDateJS(savedData?.group_ebitda_gains_year)?.isSame(newPOA?.period_start, 'day') ||
                processDateJS(savedData?.group_ebitda_gains_year)?.isAfter(newPOA?.period_start)) 
                &&
                (processDateJS(savedData?.group_ebitda_gains_year)?.isSame(newPOA?.period_end, 'day') ||
                processDateJS(savedData?.group_ebitda_gains_year)?.isBefore(newPOA?.period_end))){
                    updatedData.group_ebitda_gains = false;
                    updatedData.group_ebitda_gains_year = null;
            }
        }
        
        if (getPoaIndex() === -1) {
            throw `could not find POA data`;
        }

        updatedData.periods_of_account[getPoaIndex()] = newPOA;
        // allocate allowance to previous unused amounts
        const newJson = calculateUsedAllowances(updatedData, getPoaIndex())

        if (auth?.isReadOnlyUser(getGroupName())) {
            setGroupData(newJson);
            return;
        }
        
        const promise = sqlService.postResponse(newJson)
        return toast.promise(promise, {
            loading: 'Saving...',
            success: (response) => {
                setGroupData(newJson);
                setIsPosting(false);
                return `Changes saved to database`
            },
            error: 'Failed to save changes to database',
        })
    }

    if (isLoading)
        return (
            <Loading
                loadingType="linear"
                indeterminate={true}
                compact={false}
                className="page-loader"
            ></Loading>
        );

    return (
        <div className="ap-container">
                <div className="ap-container mt-4">
                    <CompanyPanel />
                </div>
                <p></p>

            <Panel>
                <RevCalcHeader groupname="Review Calculations - Fixed/Group Ratio Election" />
                <ReviewCalcNav currentStep={1} />
                <div className='col' style={{ padding: "0em 1em" }}>
                    <p>This page summarises the output of the CIR calculations, and allows you to choose whether or not to apply the Group Ratio election for the period.</p>
                </div>
                

                <div className="table-container">
                    <div className='col-6' style={{ padding: "0em 0.5em 0em 1em" }}>   
                        <Table originalData={tableFRData} hasTitle striped>
                            <Column field="title">FIXED RATIO COMPUTATION
                            </Column>
                            <Column field="GBP">GBP</Column>
                        </Table>                        
                    </div>
                    <div className='col-6' style={{ padding: "0em 1em 0em 0.5em" }}>
                        {savedPOAData?.group_ratio_election &&
                            <Table originalData={tableGRData} hasTitle striped>
                                <Column field="title">GROUP RATIO COMPUTATION</Column>
                                <Column field="GBP">GBP</Column>
                            </Table>   
                        }
                     
                    </div>

                </div>
                <div style={{ padding: "1.5em 1em" }}>
                    { savedPOAData?.group_ratio_election && (
                        <div style={{ padding: "1em 1em" }} className='row'>
                            <div className="ap-switch col-6">
                                <span>Does applying the group ratio election result in a higher interest allowance?</span>
                                <CustomTooltipIcon size="big" tooltipText={<><p>This indicates whether the group ratio may be beneficial, based on whether the interest allowance is higher under the group ratio method. It does not consider other metrics, for example whether the excess debt cap is lower under the group ratio method due to QNGIE being less than ANGIE.</p><p>The calculation only takes account of the group data entered. If estimates have been used, sections have not been completed, or elections that could be beneficial have not been included within the data entered for Group-EBITDA and QNGIE, the indicative calculation will not give an accurate outcome.</p>Should you wish to apply or disapply the group ratio to the Group’s CIR calculations, you can do so by toggling the switch below on or off.<p></p></>}/>  
                            </div>
                            {
                                groupRatioElectionResult ? (
                                    <Badge className='col-1' type='success-outlined' value='Yes' />
                                ) : (
                                    <Badge className='col-1' type='danger-outlined' value='No ' />
                                )
                            }
                        </div>
                    )}
                    <div style={{ padding: "1em 1em" }} className='row'>
                        <div className="ap-switch col-6">
                            <span>Apply Group Ratio Election?</span>
                            <CustomTooltipIcon tooltipText={<p>Should you wish to apply or disapply the group ratio to the Group’s CIR calculations, you can do so by toggling the switch below on or off.</p>} />
                        </div>
                        <Tooltip content='This switch is disabled because Group Ratio Election calculation was disabled in the Group Data tab.'
                            disabled={!savedPOAData?.group_ratio_election? false : true }>
                            <div className='col-1 ' style={{height:40}} >
                                <Switch className='col-1' style={{width:'fit-content'}} showIndicator
                                    disabled={!savedPOAData?.group_ratio_election}
                                    checked={applyGroupRatioElection}
                                    defaultChecked={applyGroupRatioElection}
                                    onChange={() => updateCRTableData(applyGroupRatioElection) }
                                />
                            </div>    
                        </Tooltip>
                    </div>
                </div>
                <div className='row'>
                    <div className='col' style={{ padding: "0em 2em 0em 2em" }}>
                        <Table originalData={applyGroupRatioElection ? tableCDRDataGR : tableCDRDataFR} hasTitle striped>
                            <Column field="title">COMPUTATION OF DISALLOWANCES AND REACTIVATIONS</Column>
                            <Column field="GBP">GBP</Column>
                        </Table>
                    </div>                     
                </div>

                <div className='container-fluid mt-4'>
                    <div className='d-flex justify-content-between align-items-center'> 
                        <div>
                            <BackButton disabled={isPosting}/>
                        </div>
                        <div className='d-flex gap-2'>
                        <Button onClick={save} loading={isPosting}>Save</Button>
                        <   div className='col-1'><NextButton loading={isPosting} preNavigation={save}>Next</NextButton></div>
                        </div>
                    </div>
                </div>


            </Panel>

        </div>
    );
};

export default ReviewCalc2;
