import dayjs from 'dayjs';
import { LOCAL_KEYS } from '../GroupContext';
import { GET, POST } from '../requestHandler'
const baseUrl = process.env.REACT_APP_BACKEND_API


const getGroupList = async () => {
    return await GET(`${baseUrl}/groups`);
};

const getAllGroupReport = async () => {
    return await GET(`${baseUrl}/allgroupreport`);
};

const getGroupData = async (groupId, isReadOnly = false) => {
    const res = await GET(`${baseUrl}/responses/group-name/${groupId}`, null, isReadOnly);
    
    if (res.status !== 200 || !res.data?.hasOwnProperty('group_data') || !res.data?.hasOwnProperty('readonly')){
        throw res;
    }

    return res;
};

const getGroupUsers = async (groupId) => {
    return await GET(`${baseUrl}/groupusers/${groupId}`);
};

const getGroupSupervisors = async (groupId) => {
    return await GET(`${baseUrl}/groupsupervisors/${groupId}`);
};

const postGroupUsers = async (groupId, users) => {
    return await POST(`${baseUrl}/groupusers/`, {group_name: groupId, users: users});
};

const postResponse = async (body) => {
    const res = await POST(`${baseUrl}/response/`, body);
    if (res.status !== 200){
        throw new Error( res.err );
    }

    localStorage.setItem(LOCAL_KEYS.lastPostRequest, res?.data?.timeStamp ? dayjs(res?.data?.timeStamp) : null);
    
    return res;
}

const postNewGroup = async (data) => {
    let res = '';
    try {
        res = await postResponse(data);
    }
    catch (error) {
        return {status: 400, err: error}
    }
    return res;
}

const postRemoveGroupLock = async (groupId) => {
    return await POST(`${baseUrl}/removelock/`, {group_name: groupId});
}
export const getWBSLogs = async () => {
    try {
        return await GET(`${baseUrl}/charge/all`);
    } catch (e) {
        return {status: 400, err: e}
    }
}

export const getChargeData = async ( group_name, poa_end, charge_type) => {
    
    const res = await GET(`${baseUrl}/charge`, {
        group_name,
        poa_end,
        charge_type,
    });

    if (res.status === 404){
        return {
            sumCharges: 0,
            maxNoCompanies: 0,
        };
    }

    return {
        sumCharges: res.data.reduce( (acc, v) => (acc + v["incremental_charge"]), 0 ),
        maxNoCompanies: Math.max( ...res.data.map( v => v["number_of_onesource_companies"] ) ),
    };
}

/**
 * Adds charge to wbs code log
 * @param body {object} 
 * @param body.charge_type {string}
 * @param body.group_name {string}
 * @param body.incremental_charge {float}
 * @param body.number_of_onesource_companies {number}
 * @param body.number_of_uk_companies {number}
 * @param body.poa_start {string}
 * @param body.poa_end {string}
 * @param body.return_type {string}
 * @param body.wbs_code {string}
 */
export const postCharge = async ( body ) => {
    let res;
    try {
        res = await POST(`${baseUrl}/charge`, body)
    } catch (e) {
        return {status: 400, err: e}
    }
    
    return res;
}

const postCreateGroupCopies = async (originalDocName, numberOfCopies, customNamePrefix) => {
    return await POST(`${baseUrl}/copy-group/`, { originalDocName, numberOfCopies, customNamePrefix });
}

const postCreateGroupCopiesAllResponses = async (originalDocName, numberOfCopies, customNamePrefix) => {
    return await POST(`${baseUrl}/copy-group-all-responses/`, { originalDocName, numberOfCopies, customNamePrefix });
}

const postProcessGroupCompanyAPs = async (originalDocName) => {
    return await POST(`${baseUrl}/process-grp-companyAPs/`, {originalDocName});
}

const postDeleteGroups = async (originalDocNames) => {
    return await POST(`${baseUrl}/delete-groups/`, { originalDocNames });
}

export default { getGroupList, getAllGroupReport, getGroupUsers, getGroupData, postGroupUsers, postResponse, postNewGroup, postRemoveGroupLock, getGroupSupervisors, postCreateGroupCopies, postProcessGroupCompanyAPs, postCreateGroupCopiesAllResponses, postDeleteGroups };
