import React, { useState, useEffect, useContext, useRef } from 'react';
import { Modal, Table, Column } from '@appkit4/react-components'; 
import { Button, Checkbox } from "../../components/ReadonlyAwareInputs";
import toast from '../../components/DismissibleToast';
import dayjs from 'dayjs';
import minMax from 'dayjs/plugin/minMax'
dayjs.extend(minMax)

const CompanyRefreshModal = ({ visible, set_visible, new_companies, missing_companies, handle_submit }) => {

    const [originalData, setOriginalData] = useState([])
    useEffect(() => {
        if (!visible) {
            return;
        }

        setOriginalData(new_companies)
        set_selected_companies([])
    }, [visible])

    const [selected_companies, set_selected_companies] = useState([]);

    const renderOrderCell = (row, field) => {
        const onCheckboxChange = (value) => {
            if (value) {
                selected_companies?.push(row);
            } else {
                selected_companies?.splice(selected_companies?.indexOf(row), 1);
            }
            set_selected_companies([...selected_companies]);
        }

        return (
            <Checkbox
                value={row[field]}
                checked={selected_companies?.includes(row)}
                onChange={(value) => onCheckboxChange(value)}>
            </Checkbox>
        );
    }

    const [checkValue, setCheckValue] = useState(false);
    const onCheckTotalChange = (value, event) => {
        if (event?.target?.parentNode?.className?.indexOf('ap-checkbox-label') > -1) {
            return;
        }

        setCheckValue(value => !value);
        if (value) {
            set_selected_companies([...originalData]);
        } else {
            set_selected_companies([]);
        }
    }

    useEffect(() => {
        if (selected_companies?.length === originalData?.length)
            setCheckValue(true)
        else
            setCheckValue(false);
    }, [selected_companies])

    const [copySuccess, setCopySuccess] = useState(false);
    const textAreaRef = useRef(null);

    const copyToClipboard = () => {
        textAreaRef?.current?.select();
        document.execCommand('copy');
        setCopySuccess(true);
        setTimeout(() => setCopySuccess(false), 2000);
    };

    return (
        <Modal
            visible={visible}
            title={`Refresh from OneSource`}
            ariaLabel={"Add new companies from OneSource"}
            onCancel={() => set_visible(false)}
            modalStyle={{ width: '50rem' }}
            footerStyle={{ paddingTop: '8px', marginTop: '-8px', minHeight: '64px' }}
            bodyStyle={{ minHeight: '200px' }}
            footer={
                <>
                    <Button neverReadonly onClick={() => set_visible(false)} kind="secondary">Cancel</Button>
                    <Button
                        kind="primary"
                        disabled={selected_companies?.length === 0}
                        onClick={() => handle_submit(selected_companies)}
                    >
                        Add {selected_companies?.length} {selected_companies?.length === 1 ? 'company' : 'companies'}
                    </Button>
                </>
            }
        >
            <div>
                {missing_companies?.length > 0 && (
                    <div className='mb-4'>
                        <h3>⚠️ The following companies could not be found in OneSource for the current group</h3>
                        <p>Please review this list and ensure that these companies should be included in the model.</p>
                        <ul style={{ paddingTop: 10 }}>
                            {missing_companies?.map((company, index) => (
                                <li key={index}>{company?.company_name}</li>
                            ))}
                        </ul>

                        {/* <textarea
                            ref={textAreaRef}
                            value={missing_companies.map(c => c.company_name).join('\n')}
                            style={{ position: 'absolute', left: '-9999px' }}
                            readOnly
                        />
                        <button onClick={copyToClipboard} className="btn btn-primary">
                            {copySuccess ? 'Copied!' : 'Copy List'}
                        </button> */}

                    </div>
                )}


                {new_companies?.length > 0 && (
                    <div style={{ paddingTop: 10 }}>
                        <h3>⚠️ We found some companies in OneSource that do not exist in the current model</h3>
                        <p style={{paddingBottom: 10}}>Please review this list and select any companies you wish to add</p>

                        <Table className="ap-table-checkable" originalData={originalData} hasTitle selectedIndexs={selected_companies} striped>
                            <Column field='company_ID' renderCell={renderOrderCell} >
                                <Checkbox
                                    checked={checkValue}
                                    onChange={(value, event) => onCheckTotalChange(value, event)}>
                                </Checkbox>
                            </Column>
                            <Column field='company_name' sortKey='company_name'>Company Name</Column>
                            <Column field='tax_office' sortKey='tax_office'>Tax Office</Column>
                            <Column field='utr' sortKey='utr'>UTR</Column>
                        </Table>
                    </div>

                )}
            </div>
        </Modal>
    );
};

export default CompanyRefreshModal;
