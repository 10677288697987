
import { Routes, Route, useLocation } from 'react-router-dom';
import { useEffect, useState, useContext } from 'react';
import {
  Header,
  HeaderOptionItem,
  Avatar,
  Footer
} from '@appkit4/react-components';

import Welcome from "./pages/home/Welcome";
import NewGroup from "./pages/new-group/NewGroup";
import UserAccess from "./pages/user-management/ManageUserAccess";
import GroupConfig from "./pages/groupConfig/groupConfig";
import GroupPOA from './pages/GroupPOA/GroupPOA';
import GroupCompanies from './pages/group-companies/GroupCompanies';
import CompanyAP from './pages/company-ap/CompanyAP';
import ReportingCompany from './pages/reporting-company/ReportingCompany';
import ReviewCalc2 from './pages/ReviewCalc2';
import ReviewCalc3 from './pages/ReviewCalc3';
import ReviewCalc4 from './pages/ReviewCalc4';
import ReviewCalc1 from './pages/ReviewCalc1';
import GroupData from './pages/group-data/POAGroupData';
import EntityInput from './pages/entity-input/EntityInput';
import { Toaster } from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import { getLastNavigatedLocation, getGroupName, setLastNavigatedLocation, setGroupData, setPoaIndex, getUserIsReadonly, LOCAL_KEYS } from './services/GroupContext';
import Outputs from './pages/Outputs';
import { ProtectedRoute } from './pages/ProtectedRoute';
import AuthCallback from './pages/AuthCallback';
import { AuthContext } from './services/AuthProvider';
import authService from './services/auth/auth.service';
import Logout from './pages/logout';
import IdleWarnModal from './pages/IdleWarnModal';
import Unauthorised from './pages/ErrorHandler';
import sqlService from './services/sqldatabase/sqldatabase.service';
import CookiesStatement from './pages/cookiesStatement';
import PrivacyStatement from './pages/privacyStatement';
import EditBFD from './pages/editBFD/EditBFD';
import { GroupAccessRoute } from './components/GroupAccessRoute';
import Summary from './pages/Summary';
import EditCFD from './pages/editCFD/EditCFD';
import { allPages as appPages, periodAccessPages, ProgressNav } from './components/ProgressNav';
import ReadonlyBanner from './pages/ReadonlyBanner';
import PeriodFinalisedBanner from './components/PeriodFinalisedBanner'
import SupportPanel from './components/SupportPanel'
import toast from './components/DismissibleToast';
import dayjs from 'dayjs';
import { refreshReadonly } from './components/ReadonlyAwareInputs';


function App() {
  const [selectedPOA, setSelectedPOA] = useState({})
  const [idleWarningVisible, setIdleWarningVisible] = useState(false)
  const navigate = useNavigate();
  const location = useLocation();
  const auth = useContext(AuthContext);
  const initials = auth?.getInitials();

  const [groupNameState, setGroupNameState] = useState(null);

  const [isGroupAccessPage, setIsGroupAccessPage] = useState(false);
  const [isPeriodAccessPage, setIsPeriodAccessPage] = useState(false);
  const [supportPanelVisible, setSupportPanelVisible] = useState(false);

  const navtohome = async () => {
    const groupName = getGroupName();
    if (groupName) {
      auth?.setReadonly(false);
      try {
        await sqlService?.postRemoveGroupLock(groupName);
      } catch (error) {
        toast.error('Error Removing Group Lock on navigating to home for group: ', groupName);
        console.log(`error removing group lock on nav to home from group: ${groupName}`, error);
      }
    }

    setLastNavigatedLocation("/");

    const currentPath = window?.location?.pathname;
    if (currentPath === '/') {
      window?.location?.reload();
    } else {
      navigate('/');
    }
  };
  
  refreshReadonly();

  useEffect(() => {
    const newLocation = location.pathname.toLocaleLowerCase();
    const lastNavigatedLocation = getLastNavigatedLocation();
    
    if (appPages.includes( newLocation ) && newLocation !== lastNavigatedLocation){
      setLastNavigatedLocation(location.pathname);
    }

    if ( newLocation === "/"){
      if (lastNavigatedLocation !== "/"){
        const reOpenAsReadonly = getUserIsReadonly();

        const refreshPromise = sqlService?.getGroupData(getGroupName(), reOpenAsReadonly)
        .then((res) => {
          const {data} = res;

          const lastRequest = localStorage.getItem(LOCAL_KEYS.lastPostRequest)
          const responseTimestamp = data?.group_data?.timestamp;

          if (!dayjs(lastRequest).isSame(dayjs(responseTimestamp), 'second')) {
            // if some other user posted updates to the group while user was logged off, reset local storage json and navigate to group PoA
            setGroupData(data?.group_data);
            localStorage.removeItem(LOCAL_KEYS.cachedLocation);
            toast(
              `While you were logged out, updates were posted to the Group [${getGroupName()}]. \n You have been automatically navigated to the Group PoA page to review the latest changes.`, 
              {icon:"⚠️"}
            );
            navigate('/app/GroupPOA');
          } else if (reOpenAsReadonly){
            setGroupData(data?.group_data);
            navigate(lastNavigatedLocation);

          } else {
            // check lock to reopen as non-readonly
            if (!(data?.readonly?.value)){
              navigate(lastNavigatedLocation);

            } else {
              toast.error(`Failed to re-acquire group lock, it is currently being edited by ${data?.readonly?.user}`);

              setGroupData({});
              setPoaIndex(-1);
              setLastNavigatedLocation("/");
              auth.setReadonly(false);
            }
          }
        })

        toast.promise(refreshPromise, {
          loading:"Attempting to re-open group",
        })
        
      } else {
        setGroupData({});
        setPoaIndex(-1);
        setLastNavigatedLocation("/");
        auth.setReadonly(false);
      }
    }

    setGroupNameState(getGroupName());
    
    setIsGroupAccessPage(appPages.includes(location.pathname.toLowerCase()));
    setIsPeriodAccessPage(periodAccessPages.includes(location.pathname.toLowerCase()));
  }, [location])

  const logout = async () => {
    // end the session and revoke the token
    await authService?.logout();
  }

  return (
    <div className="container">
      <Toaster
        position='bottom-right' 
        toastOptions={{
          duration: 3000 
        }}
        />
      <div className="row">
        <Header
          type="solid"
          onClickLogo={() => {navtohome() }}
          titleTemplate={() => "Corporate Interest Restriction Analyser"}
          optionsTemplate={() => {
            return (
              <>
                <HeaderOptionItem
                  iconName="home-outline"
                  label="Home"
                  onClick={() => { navtohome() }}
                ></HeaderOptionItem>
                <HeaderOptionItem
                  iconName="help-question-outline"
                  label="Support"
                  onClick={() => { setSupportPanelVisible(true) }}
                ></HeaderOptionItem>
                { auth?.hasUser() && 
                  <HeaderOptionItem
                    iconName="logout-outline"
                    label="Logout"
                    onClick={logout}
                  ></HeaderOptionItem>
                }
              </>
            );
          }}
          userTemplate={() => (
            <div data-theme="orange">
              <Avatar
                label={initials}
                disabled={true}
              ></Avatar>
            </div>
          )}
        />
      </div>

      <div className="ap-container"> {/* add max width */}
      <IdleWarnModal ></IdleWarnModal>
        <div className="row">
          <div className="mt-md-1">
          {groupNameState && <h1 className='title2'>{groupNameState}</h1>}
          { isGroupAccessPage && <ProgressNav/> }
          { isGroupAccessPage && <ReadonlyBanner/> }
          { isPeriodAccessPage && <PeriodFinalisedBanner/> }
            <Routes>
              <Route path="" element={<ProtectedRoute><Welcome userEmail='test@pwc.com' /></ProtectedRoute>} />
              <Route path="app/new" element={<ProtectedRoute><NewGroup /></ProtectedRoute>} />
              <Route path="app/useraccess" element={<ProtectedRoute><GroupAccessRoute><UserAccess /></GroupAccessRoute></ProtectedRoute>} />
              <Route path="app/groupconfig" element={<ProtectedRoute><GroupAccessRoute><GroupConfig /></GroupAccessRoute></ProtectedRoute>} />
              <Route path="app/grouppoa" element={<ProtectedRoute><GroupAccessRoute><GroupPOA /></GroupAccessRoute></ProtectedRoute>} />
              <Route path="app/groupcompanies" element={<ProtectedRoute><GroupAccessRoute><GroupCompanies /></GroupAccessRoute></ProtectedRoute>} />
              <Route path="app/companyap" element={<ProtectedRoute><GroupAccessRoute><CompanyAP /></GroupAccessRoute></ProtectedRoute>} />
              <Route path="app/reportingcompany" element={<ProtectedRoute><GroupAccessRoute><ReportingCompany /></GroupAccessRoute></ProtectedRoute>} />
              <Route path="app/groupdata" element={<ProtectedRoute><GroupAccessRoute><GroupData /></GroupAccessRoute></ProtectedRoute>} />
              <Route path="app/reviewcalc1" element={<ProtectedRoute><GroupAccessRoute><ReviewCalc1 /></GroupAccessRoute></ProtectedRoute>} />
              <Route path="app/reviewcalc2" element={<ProtectedRoute><GroupAccessRoute><ReviewCalc2 /></GroupAccessRoute></ProtectedRoute>} />
              <Route path="app/reviewcalc3" element={<ProtectedRoute><GroupAccessRoute><ReviewCalc3 /></GroupAccessRoute></ProtectedRoute>} />
              <Route path="app/reviewcalc4" element={<ProtectedRoute><GroupAccessRoute><ReviewCalc4 /></GroupAccessRoute></ProtectedRoute>} />
              <Route path="app/outputs" element={<ProtectedRoute><GroupAccessRoute><Outputs /></GroupAccessRoute></ProtectedRoute>} />
              <Route path="app/summary" element={<ProtectedRoute><GroupAccessRoute><Summary /></GroupAccessRoute></ProtectedRoute>} />
              <Route path="app/entityinput" element={<ProtectedRoute><GroupAccessRoute><EntityInput/></GroupAccessRoute></ProtectedRoute>} />
              <Route path="app/editbfd" element={<ProtectedRoute><GroupAccessRoute><EditBFD /></GroupAccessRoute></ProtectedRoute>} />
              <Route path="app/editcfd" element={<ProtectedRoute><GroupAccessRoute><EditCFD /></GroupAccessRoute></ProtectedRoute>} />
              <Route path="auth/callback" element={<AuthCallback />} />
              <Route path="app/logout" element={<Logout />} />
              <Route path="app/unauthorised" element={<Unauthorised />} />
              <Route path="app/cookiesstatement" element={<CookiesStatement />} />
              <Route path="app/privacystatement" element={<PrivacyStatement />} />
            </Routes>
          </div>
          <SupportPanel
          visible={supportPanelVisible}
          setVisible={setSupportPanelVisible}
          >
          </SupportPanel>
          <Footer
            content={`© ${new Date().getFullYear()} PwC. All rights reserved. PwC refers to the PwC network and/or one or more of its member firms, each of which is a separate legal entity. Please see <a class='ap-link' href='https://www.pwc.com/structure'>www.pwc.com/structure</a> for further details.`}
            type="links"
            links={[{name: 'Privacy Policy', href: 'https://www.pwc.co.uk/who-we-are/privacy-statement.html', target: '_self'}, {name: 'Cookie Notice', href: window?.location?.origin + '/app/cookiesStatement', target: '_self'}]}
            className="mb-md-3 mt-md-3"
          ></Footer>
        </div>
      </div>
    </div>
  );
}

export default App;
