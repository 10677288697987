import React, { useState } from 'react';
import { Modal } from '@appkit4/react-components';
import { Button, CalendarPicker, Input } from "../../components/ReadonlyAwareInputs";
import { getGroupData, getPeriodByIndex, setGroupData } from '../../services/GroupContext';
import { formatReturnStatus } from './GroupPOA';
import toast from '../../components/DismissibleToast';
import { processDateJS } from '../../utils/dateProcessor';


const OpenPeriodModal = ({ visible, setVisible, periodIndexToOpen }) => {
    const groupData = getGroupData();

    const periodData = getPeriodByIndex(periodIndexToOpen);
    if (!periodData){
        return;
    }

    const periodsAfterCurrent = groupData.periods_of_account.map((_,i) => i).filter( index => {
        const period = groupData.periods_of_account[index];
        if (!period){
            return;
        }
        return processDateJS(period.period_start).isAfter( processDateJS(periodData.period_end) )
            && period?.return_status?.isFinalised;
    });

    return (
        <Modal
            visible={visible}
            title={"Open this period for editing?"}
            ariaLabel={"Open this period for editing?"}
            onCancel={() => setVisible(false)}
            maskCloseable={false}
            modalStyle={{ width: '33.75rem' }}
            footerStyle={{ paddingTop: '8px', marginTop: '-8px', minHeight: '64px' }}
            footer={
                <>
                    <Button neverReadonly onClick={() => setVisible(false)} kind="secondary">Cancel</Button>
                    <Button kind="primary" disabled={!periodData} onClick={() => {
                        if (! periodData.return_status.isFinalised){
                            toast.error("This period is already open");
                        } else {

                            const periodIndicesToReopen = [periodIndexToOpen, ...periodsAfterCurrent];
                            
                            groupData.periods_of_account = groupData.periods_of_account.map((period, index) => {
                                if (periodIndicesToReopen.includes(index)){
                                    period.return_status.isRevised = period.return_status.isSubmitted || period.return_status.isRevised;
                                    period.return_status.isFinalised = false;
                                    period.return_status.isSubmitted = false;

                                    period.return_status.revisedNotes = period.return_status.revisedNotes ?? [];
                                    period.return_status.revisedNotes = typeof period.return_status.revisedNotes === "string" ?  [period.return_status.revisedNotes] : period.return_status.revisedNotes;
                                    period.return_status.revisedNotes.push( "" );
                                }
                                
                                return period
                            })


                            setGroupData( groupData );
                        }

                        setVisible(false);
                        return;
                    }}>Open Period</Button>
                </>
            }
        >
            {!periodData
                ? <div>The selected period could not be retrieved</div>
                : <div>
                    <thead>
                        <tr style={{textAlign: 'left'}}>
                            <th className="col" key={1}>Start Date</th>
                            <th className="col" key={2}>End Date</th>
                            <th className="col" key={3}>Return Status</th>
                        </tr>
                    </thead>
                    <td key={1} style={{ verticalAlign: 'middle' }}>
                        <CalendarPicker
                            readonly
                            format='DD/MM/YYYY' placeholder='dd/mm/yyyy' className="col" fieldWidth={150}
                            value={new Date(periodData['period_start'])}
                        />
                    </td>
                    <td key={2} style={{ verticalAlign: 'middle' }}>
                        <CalendarPicker
                            readonly
                            format='DD/MM/YYYY' placeholder='dd/mm/yyyy' className="col" fieldWidth={150}
                            value={new Date(periodData['period_end'])}
                        />
                    </td>
                    <td>
                        <Input readonly value={formatReturnStatus(periodData['return_status'])} />
                    </td>
                    <br/>
                    <div style={{color:"#E0301E", textAlign:"right"}}>
                        {periodsAfterCurrent?.length > 0 ? "Warning: any subsequent periods will also be reopened" : ""}
                    </div>
                </div>
            }
        </Modal>
    );
};

export default OpenPeriodModal;
