import hotToast from 'react-hot-toast';

const dismissibleMessage = (t, message) => {
  return <div style={{display:"flex", justifyContent:"space-between", alignItems:"center"}}>
    <p style={{marginRight:"5px", marginBottom:0}}>{message}</p>
    <span 
      style={{ cursor: 'pointer', lineHeight:"29px", marginRight:"-12px"}}
      class="Appkit4-icon icon-close-fill ap-font-12 ap-container-12"
      onClick={() => hotToast.dismiss(t.id)} 
    />
  </div>
}

const toast = (message, ...args) =>
  hotToast((t) => dismissibleMessage(t, message), ...args);

toast.error = (message, ...args) =>
  hotToast.error((t) => dismissibleMessage(t, message), ...args);

toast.success = (message, ...args) =>
  hotToast.success((t) => dismissibleMessage(t, message), ...args);

toast.promise = (promise, messages, opts) =>
  hotToast.promise(promise,
    {
      ...messages,
      success: (t) => dismissibleMessage(t, typeof messages.success === "function" ? messages?.success(t) : messages?.success ?? ""),
      error: (t) => dismissibleMessage(t, typeof messages.error === "function" ? messages?.error(t) : messages?.error ?? ""),
    },
    opts,
  );


export default toast;