import { cap_LC_conversion_values } from "../../utils/CapCurrencyValues";
const getReviewCalc4Values = (poa) => {
    let CFD_NTLR;
    let CFD_NTDC;
    let CFD_TLR;
    let CFD_TDC;
    let CFD_OFA;
    const new_caps = poa?.adjusted_caps?.map((cap, index) => {
        const prevComp = index > 0 ? poa?.adjusted_caps[index - 1]?.company_ID : null;
        // for first AP of each company
        if ( index === 0 || cap.company_ID !== prevComp) {
            // reactivation amounts
            if(!cap?.bfd_non_trading_loan_relationships_gbp){
                cap.bfd_non_trading_loan_relationships_gbp = 0
            }
            if(!cap?.bfd_non_trading_derivatives_gbp){
                cap.bfd_non_trading_derivatives_gbp = 0
            } 
            if(!cap?.bfd_trading_loan_relationships_gbp){
                cap.bfd_trading_loan_relationships_gbp = 0
            } 
            if(!cap?.bfd_trading_derivatives_gbp){
                cap.bfd_trading_derivatives_gbp = 0
            }   
            if(!cap?.bfd_other_financing_gbp){
                cap.bfd_other_financing_gbp = 0
            }  
            cap.reactivation_amount_gbp = cap?.bfd_non_trading_loan_relationships_gbp 
                + cap?.bfd_non_trading_derivatives_gbp
                + cap?.bfd_trading_loan_relationships_gbp
                + cap?.bfd_trading_derivatives_gbp
                + cap?.bfd_other_financing_gbp
                + cap?.bfdadj_non_trading_loan_relationships_gbp
                + cap?.bfdadj_non_trading_derivatives_gbp
                + cap?.bfdadj_trading_loan_relationships_gbp
                + cap?.bfdadj_trading_derivatives_gbp
                + cap?.bfdadj_other_financing_gbp;

            cap.ntlr_reactivation_amount_gbp = cap?.bfd_non_trading_loan_relationships_gbp + cap?.bfdadj_non_trading_loan_relationships_gbp;
            cap.ntdc_reactivation_amount_gbp = cap?.bfd_non_trading_derivatives_gbp + cap?.bfdadj_non_trading_derivatives_gbp;
            cap.tlr_reactivation_amount_gbp = cap?.bfd_trading_loan_relationships_gbp + cap?.bfdadj_trading_loan_relationships_gbp;
            cap.tdc_reactivation_amount_gbp = cap?.bfd_trading_derivatives_gbp + cap?.bfdadj_trading_derivatives_gbp;
            cap.ofa_reactivation_amount_gbp = cap?.bfd_other_financing_gbp + cap?.bfdadj_other_financing_gbp;
            // company interest reactivation cap
            cap.interest_reactivation_cap_gbp = Math.round(cap?.proportion_of_poa_as_group_company * poa?.group_interest_reactivation_cap); //JW edit to ensure that no decimals can arise, which prevents proceding to next page. 

            // amount available for reactivation
            cap.amount_available_for_reactivation_gbp = Math.round(Math.min(cap?.reactivation_amount_gbp, cap?.interest_reactivation_cap_gbp)); //JW edit to ensure that no decimals can arise, which prevents proceding to next page. This should never do anything on this section as every input is already rounded, but I've added for completeness to prevent any possible proceed errors.

            //reactivation section allocation
            if (!cap.election_override_automatic_allocations){
                cap = calculate_reactivation_subtotals(cap)
            }

        }

        // [TODO] John Check lines
            
            // remove ability to use disallowances in current period based on certain conditions (corrections to previously calculated values)
            // NTLR
        //     if (cap?.trade_uncommercial_or_non_statutory) {
        //         cap.bfdadj_non_trading_loan_relationships_gbp = cap?.bfdadj_non_trading_loan_relationships_gbp - cap?.ntlr_disallowance_gbp
        //     }
        //     else if (cap?.investment_business_ceased || cap?.trade_small_or_negligible || cap?.cta10_change_in_ownership_rules) {
        //         cap.bfdadj_non_trading_loan_relationships_gbp = - (cap.bfd_non_trading_loan_relationships_gbp + cap?.ntlr_disallowance_gbp - cap?.cpr_non_trading_loan_relationships_gbp)
        //     }
        //     else {
        //         cap.bfdadj_non_trading_loan_relationships_gbp = 0;
        //     }
        //     // NTDC
        //     if (cap?.trade_uncommercial_or_non_statutory) {
        //         cap.bfdadj_non_trading_derivatives_gbp = cap?.bfdadj_non_trading_derivatives_gbp - cap?.ntdc_disallowance_gbp
        //     }
        //     else if (cap?.investment_business_ceased || cap?.trade_small_or_negligible || cap?.cta10_change_in_ownership_rules) {
        //         cap.bfdadj_non_trading_derivatives_gbp = - (cap?.bfd_non_trading_derivatives_gbp + cap?.ntdc_disallowance_gbp - cap?.cpr_non_trading_derivatives_gbp)
        //     }
        //     else {
        //         cap.bfdadj_non_trading_derivatives_gbp = 0;
        //     }
        //     // TLR
        //     if (cap?.trade_uncommercial_or_non_statutory) {
        //         cap.bfdadj_trading_loan_relationships_gbp = cap?.bfdadj_trading_loan_relationships_gbp - cap?.tlr_disallowance_gbp
        //     }
        //     else if (cap?.investment_business_ceased || cap?.trade_small_or_negligible || cap?.cta10_change_in_ownership_rules) {
        //         cap.bfdadj_trading_loan_relationships_gbp = - (cap?.bfd_trading_loan_relationships_gbp + cap?.tlr_disallowance_gbp - cap?.cpr_trading_loan_relationships_gbp)
        //     }
        //     else {
        //         cap.bfdadj_trading_loan_relationships_gbp = 0;
        //     }
        //     // TDC
        //     if (cap?.trade_uncommercial_or_non_statutory) {
        //         cap.bfdadj_trading_derivatives_gbp = cap?.bfdadj_trading_derivatives_gbp - cap?.tdc_disallowance_gbp
        //     }
        //     else if (cap?.investment_business_ceased || cap?.trade_small_or_negligible || cap?.cta10_change_in_ownership_rules) {
        //         cap.bfdadj_trading_derivatives_gbp = - (cap?.bfd_trading_derivatives_gbp + cap?.tdc_disallowance_gbp - cap?.cpr_trading_derivatives_gbp)
        //     }
        //     else {
        //         cap.bfdadj_trading_derivatives_gbp = 0;
        //     }
        //     // OFA
        //     if (cap?.trade_uncommercial_or_non_statutory) {
        //         cap.bfdadj_other_financing_gbp = cap?.bfdadj_other_financing_gbp - cap?.ofa_disallowance_gbp
        //     }
        //     else if (cap?.investment_business_ceased || cap?.trade_small_or_negligible || cap?.cta10_change_in_ownership_rules) {
        //         cap.bfdadj_other_financing_gbp = - (cap?.bfd_other_financing_gbp + cap?.ofa_disallowance_gbp - cap?.cpr_other_financing_gbp)
        //     }
        //     else {
        //         cap.bfdadj_other_financing_gbp = 0;
        //     }

        //     // CFD amounts (save these to a variable so they can be used in following APs)
        //     CFD_NTLR = cap?.bfd_non_trading_loan_relationships_gbp + cap?.ntlr_disallowance_gbp - cap?.cpr_non_trading_loan_relationships_gbp + cap?.bfdadj_non_trading_loan_relationships_gbp;
        //     cap.cfd_non_trading_loan_relationships_gbp = CFD_NTLR

        //     // adding handling for when cap.cap.bfd_non_trading_derivatives_gbp is null
        //     const bfd_non_trading_derivatives_gbp = cap?.bfd_non_trading_derivatives_gbp ? cap?.bfd_non_trading_derivatives_gbp : 0;
        //     CFD_NTDC = bfd_non_trading_derivatives_gbp + cap?.ntdc_disallowance_gbp - cap?.cpr_non_trading_derivatives_gbp + cap?.bfdadj_non_trading_derivatives_gbp;
        //     cap.cfd_non_trading_derivatives_gbp = CFD_NTDC;
        //     CFD_TLR = cap?.bfd_trading_loan_relationships_gbp + cap?.tlr_disallowance_gbp - cap?.cpr_trading_loan_relationships_gbp + cap?.bfdadj_trading_loan_relationships_gbp;
        //     cap.cfd_trading_loan_relationships_gbp = CFD_TLR;
        //     CFD_TDC = cap?.bfd_trading_derivatives_gbp + cap?.tdc_disallowance_gbp - cap?.cpr_trading_derivatives_gbp + cap?.bfdadj_trading_derivatives_gbp;
        //     cap.cfd_trading_derivatives_gbp = CFD_TDC;
        //     CFD_OFA = cap?.bfd_other_financing_gbp + cap?.ofa_disallowance_gbp - cap?.cpr_other_financing_gbp + cap?.bfdadj_other_financing_gbp;
        //     cap.cfd_other_financing_gbp = CFD_OFA;
        // }
        // else {
        //     // (Multi-row formulas) reactivation amounts for following APs
        //     cap.ntlr_reactivation_amount_gbp = CFD_NTLR;
        //     cap.ntdc_reactivation_amount_gbp = CFD_NTDC;
        //     cap.tlr_reactivation_amount_gbp = CFD_TLR;
        //     cap.tdc_reactivation_amount_gbp = CFD_TDC;
        //     cap.ofa_reactivation_amount_gbp = CFD_OFA;

        //     // BFD amounts for following APs
        //     if(!cap?.bfd_non_trading_loan_relationships_gbp){
        //         cap.bfd_non_trading_loan_relationships_gbp = 0
        //     }
        //     if(!cap?.bfd_non_trading_derivatives_gbp){
        //         cap.bfd_non_trading_derivatives_gbp = 0
        //     } 
        //     if(!cap?.bfd_trading_loan_relationships_gbp){
        //         cap.bfd_trading_loan_relationships_gbp = 0
        //     } 
        //     if(!cap?.bfd_trading_derivatives_gbp){
        //         cap.bfd_trading_derivatives_gbp = 0
        //     }   
        //     if(!cap?.bfd_other_financing_gbp){
        //         cap.bfd_other_financing_gbp = 0
        //     }                     
        //     cap.bfd_non_trading_loan_relationships_gbp = cap?.ntlr_reactivation_amount_gbp;
        //     cap.bfd_non_trading_derivatives_gbp = cap?.ntdc_reactivation_amount_gbp;
        //     cap.bfd_trading_loan_relationships_gbp = cap?.tlr_reactivation_amount_gbp;
        //     cap.bfd_trading_derivatives_gbp = cap?.tdc_reactivation_amount_gbp;
        //     cap.bfd_other_financing_gbp = cap?.ofa_reactivation_amount_gbp;

        //     // CFD amounts for following APs
        //     cap.cfd_non_trading_loan_relationships_gbp = cap?.ntlr_reactivation_amount_gbp + cap?.ntlr_disallowance_gbp;
        //     cap.cfd_non_trading_derivatives_gbp = cap?.ntdc_reactivation_amount_gbp + cap?.ntdc_disallowance_gbp;
        //     cap.cfd_trading_loan_relationships_gbp = cap?.tlr_reactivation_amount_gbp + cap?.tlr_disallowance_gbp;
        //     cap.cfd_trading_derivatives_gbp = cap?.tdc_reactivation_amount_gbp + cap?.tdc_disallowance_gbp;
        //     cap.cfd_other_financing_gbp = cap?.ofa_reactivation_amount_gbp + cap?.ofa_disallowance_gbp;
        //     cap.cfd_total = cap?.cfd_non_trading_loan_relationships_gbp + cap?.cfd_non_trading_derivatives_gbp + cap?.cfd_trading_loan_relationships_gbp + cap?.cfd_trading_derivatives_gbp + cap?.cfd_other_financing_gbp;

        //     // set bfdadj and cpr values to 0 for following APs as reactivations can only happen in first period
        //     cap.bfdadj_non_trading_loan_relationships_gbp = 0;
        //     cap.bfdadj_non_trading_derivatives_gbp = 0;
        //     cap.bfdadj_trading_loan_relationships_gbp = 0;
        //     cap.bfdadj_trading_derivatives_gbp = 0;
        //     cap.bfdadj_other_financing_gbp = 0;
        //     cap.bfdadj_subtotal_gbp = 0;
        //     cap.cpr_non_trading_loan_relationships_gbp = 0;
        //     cap.cpr_non_trading_derivatives_gbp = 0;
        //     cap.cpr_trading_loan_relationships_gbp = 0;
        //     cap.cpr_trading_derivatives_gbp = 0;
        //     cap.cpr_other_financing_gbp = 0;
        //     cap.cpr_total_gbp = 0;
        //     cap.interest_reactivation_cap_gbp = 0;
        //     cap.amount_available_for_reactivation_gbp = 0;
        // }
        // // CPD amounts
        cap.cpd_non_trading_loan_relationships_gbp = cap?.ntlr_disallowance_gbp;
        cap.cpd_non_trading_derivatives_gbp = cap?.ntdc_disallowance_gbp;
        cap.cpd_trading_loan_relationships_gbp = cap?.tlr_disallowance_gbp;
        cap.cpd_trading_derivatives_gbp = cap?.tdc_disallowance_gbp;
        cap.cpd_other_financing_gbp = cap?.ofa_disallowance_gbp;
        cap.cpd_total_gbp = cap?.cpd_non_trading_loan_relationships_gbp + cap?.cpd_non_trading_derivatives_gbp + cap?.cpd_trading_loan_relationships_gbp + cap?.cpd_trading_derivatives_gbp + cap?.cpd_other_financing_gbp;
        
        return cap;
    })
    // add LC conversion here
    const LC_CAPS = new_caps?.map((cap) => {
            cap_LC_conversion_values?.forEach((key) => {
                if (cap?.hasOwnProperty(key)) {
                    const newKey = key.replace('_gbp', '')
                    if (cap[key] && cap[key]?.hasOwnProperty('value')) {
                        cap[newKey].value = cap[key]?.value * cap?.fx_rate
                    }
                    else {
                        cap[newKey] = cap[key] * cap?.fx_rate
                    }
                }
            })
        return cap;
    })
    poa.adjusted_caps = LC_CAPS
    return poa;
}

const calculate_reactivation_subtotals = (cap, available_reactivations) => {

    cap.cpr_non_trading_loan_relationships_gbp = cap?.amount_available_for_reactivation_gbp > 0 && cap.ntlr_reactivation_amount_gbp > 0 ? Math.min(available_reactivations, cap.ntlr_reactivation_amount_gbp) : 0;
    available_reactivations -= cap?.cpr_non_trading_loan_relationships_gbp;

    cap.cpr_non_trading_derivatives_gbp = cap?.amount_available_for_reactivation_gbp > 0 && cap?.ntdc_reactivation_amount_gbp > 0 ? Math.min(available_reactivations, cap?.ntdc_reactivation_amount_gbp) : 0;
    available_reactivations -= cap?.cpr_non_trading_derivatives_gbp;

    cap.cpr_trading_loan_relationships_gbp = cap?.amount_available_for_reactivation_gbp > 0 && cap?.tlr_reactivation_amount_gbp > 0 ? Math.min(available_reactivations, cap?.tlr_reactivation_amount_gbp) : 0;
    available_reactivations -= cap?.cpr_trading_loan_relationships_gbp;

    cap.cpr_trading_derivatives_gbp = cap?.amount_available_for_reactivation_gbp > 0 && cap?.tdc_reactivation_amount_gbp > 0 ? Math.min(available_reactivations, cap?.tdc_reactivation_amount_gbp) : 0;
    available_reactivations -= cap?.cpr_trading_derivatives_gbp;
    
    cap.cpr_other_financing_gbp = cap?.amount_available_for_reactivation_gbp > 0 && cap?.ofa_reactivation_amount_gbp > 0 ? Math.min(available_reactivations, cap?.ofa_reactivation_amount_gbp) : 0;
    
    cap.cpr_total_gbp = cap?.cpr_non_trading_loan_relationships_gbp + cap?.cpr_non_trading_derivatives_gbp + cap?.cpr_trading_loan_relationships_gbp + cap?.cpr_trading_derivatives_gbp + cap?.cpr_other_financing_gbp;

    return cap;
}

export { getReviewCalc4Values, calculate_reactivation_subtotals };